import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import LogoutTimer from "./LogoutTimer";
import ItedlTimer from "./IdelTimer";
import RefreshInternalToken from "./RefreshInternalToken";
import DisableModule from "../components/pages/DisableModule";
import { decrypt, encrypt } from "../utils/encodingdecoding";
import { connect, useDispatch, useSelector } from "react-redux";
import { getProfile, clearSetting } from "../actions/setting";
import PaymentDoneModal from "../components/pages/setting/PaymentDoneModal";
import { countFirst, paymentModalOpen } from "../actions/intro";

const ProtectedRoute = ({ children, getProfile, Setting, clearSetting }) => {
    const navigate = useNavigate();
    const temp = JSON.parse(localStorage.getItem("token_rare"));
    const moduleAccess = localStorage.getItem("moduleAccess") && JSON.parse(decrypt(localStorage.getItem("moduleAccess")));
    let tempCardStatus = localStorage.getItem("cardStatus") && decrypt(localStorage.getItem("cardStatus"));
    let packageNameList = localStorage.getItem("packageNameList") && JSON.parse(decrypt(localStorage.getItem("packageNameList")));
    const [moduleAccessValue, setModuleAccessValue] = useState(true);
    const paymet = useSelector((state) => state.intro.paymentMoadal);
    const location = useLocation();
    const [loader, setLoader] = useState(false);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        // moduleAccess.push({
        //     moduleName : "CUstomer Group",
        //     pageName:"CUstomerGroup",
        //     isEnable: true,
        //     isHide:false
        //     })
        if (tempCardStatus === "Active") {
            if (moduleAccess) {
                if (children.props.children) {
                    let temp = children.props.children[0].props.children.toLowerCase().split(",");
                    temp?.filter((ele) =>
                        moduleAccess?.filter((access) => {
                            if (ele === access?.pageName?.toLowerCase()) {
                                if (access?.isEnable == true) {
                                    setModuleAccessValue(true);
                                } else {
                                    setModuleAccessValue(false);
                                }
                            }
                        })
                    );
                } else {
                    setModuleAccessValue(true);
                }
            }
        } else {
            if (location.pathname.includes("/contact-us") || location.pathname.includes("/intro") || location.pathname.includes("/profile-settings")) {
                setModuleAccessValue(true);
            } else {
                setModuleAccessValue(false);
            }
        }

        //eslint-disable-next-line
    }, [children, tempCardStatus]);

    useEffect(() => {
        if (location.pathname.includes("/profile-settings?p03")) {
            getStatus();
        }
    }, [location.pathname]);

    useEffect(() => {
        const getStatus = async () => {
            await clearSetting();
            let body = {
                email: localStorage.getItem("email"),
                merchcode: localStorage.getItem("merchantcode"),
            };
            let token = await JSON.parse(localStorage.getItem("token_gen"));
            await getProfile(token?.access_token, body);
        };
        let intervalId;
        if (!tempCardStatus) {
            if (Setting.cardStatus === "Active") {
                setShow(true);
                dispatch(paymentModalOpen(paymet + 1));
            } else {
                intervalId = setInterval(getStatus, 10000);
            }
        }
        return () => {
            clearInterval(intervalId);
        };
    }, [Setting]);

    useEffect(() => {
        if (Setting.plan != "") {
            localStorage.setItem("cardStatus", encrypt(Setting.cardStatus));
            localStorage.setItem("plan", encrypt(Setting.plan));
            localStorage.setItem("moduleAccess", encrypt(JSON.stringify(Setting.moduleAccess)));
            localStorage.setItem("packageNameList", encrypt(JSON.stringify(Setting.packageNameList)));
        }
    }, [Setting]);

    const getStatus = async () => {
        setLoader(true);
        await clearSetting();
        let body = {
            email: localStorage.getItem("email"),
            merchcode: localStorage.getItem("merchantcode"),
        };
        let token = await JSON.parse(localStorage.getItem("token_gen"));
        await getProfile(token?.access_token, body);
        setLoader(false);
    };

    if (!temp || temp === null || undefined) {
        return <Navigate to="/signin" />;
    }

    return (
        <>
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <PaymentDoneModal show={show} />
            {moduleAccessValue === true ? children : <DisableModule PackageNameList={packageNameList} CardStatus={tempCardStatus} />}
            <LogoutTimer />
            {tempCardStatus === "Active" ? null : <ItedlTimer />}
            <RefreshInternalToken />
        </>
    );
};
const mapStateToProps = (state) => ({
    Setting: state.Setting,
});

const mapDispatchToProps = {
    getProfile,
    clearSetting,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
