import React, { useEffect, useState } from "react";
import Header from "../../layout/Header";
import { ValidatorForm } from "react-form-validator-core";
import { Link, useNavigate } from "react-router-dom";
import PasswordValidator from "../../partial/PasswordValidator";
import { eyeSeen, eyeSeenFocus, eyeUnseen, eyeUnseenFocus, backIcon2 } from "../../../Image/Index";
import { updatePassword } from "../campaigns/services";
import swal from "sweetalert";
import introJs from 'intro.js';
import 'intro.js/introjs.css'; 
import {documentDropdown,profileDropdown} from '../../../actions/intro'
import { useDispatch, useSelector } from 'react-redux';
import ModalCard from "../../layout/ModalCard";

const ChangePasswordSettings = () => {
    let navigate = useNavigate();
    const [currentPasswordFocus, setCurrentPasswordFocus] = useState(false);
    const [newPasswordFocus, setNewPasswordFocus] = useState(false);
    const [isRevealNewPassword, setIsRevealNewPassword] = useState(false);
    const [isRevealOldPassword, setIsRevealOldPassword] = useState(false);
    const [loader, setLoader] = useState(false);
    const [isConfirmPassword, setIsConfirmPassword] = useState(false);
    const [confirmFocus, setConfirmFocus] = useState(false);
    const [formData, setFormData] = useState({
        emailID: localStorage.getItem("email"),
        oldPassword: "",
        newPassword: "",
        comfirmPassword: "",
    });
    const [showModal,setshowModal]=useState(false)
    const intro = introJs();
    const dispatch = useDispatch();
    const modalOpen = useSelector(state => state.intro.modalOpen);

    const { emailID, oldPassword, newPassword, comfirmPassword } = formData;
    const onChange = (e) => setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });

    const handleCancel = () =>
        setFormData({
            ...formData,
            emailID: "",
            oldPassword: "",
            newPassword: "",
            comfirmPassword: "",
        });

    useEffect(() => {
        ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
            if (value !== newPassword && value !== "") {
                return false;
            }
            return true;
        });

        // eslint-disable-next-line
    }, [comfirmPassword, newPassword]);
    const handleSubmit = async () => {
        if (newPassword === comfirmPassword) {
            let body = { email: emailID, oldpassword: oldPassword, newpassword: newPassword };
            setLoader(true);
            let token = await JSON.parse(localStorage.getItem("token_gen"));
            let response = await updatePassword(token?.access_token, body);
            const { message } = response;
            setLoader(false);
            if (message.toLowerCase() === "your password is sucessfully updated") {
                setFormData({
                    ...formData,
                    oldPassword: "",
                    newPassword: "",
                    comfirmPassword: "",
                });
                swal({
                    position: "center",
                    icon: "success",
                    title: message,
                    showConfirmButton: false,
                    timer: 5000,
                });
            } else {
                swal({
                    position: "center",
                    icon: "error",
                    title: message,
                    showConfirmButton: false,
                    timer: 5000,
                });
            }
        } else {
            swal({
                title: " Oops!",
                text: "please match new and confirm password",
                icon: "warning",
                button: "oh no!",
                timer: 5000,
            });
        }
    };
    // let complete=false
    // useEffect(() => { 
    //     const handleLoad=()=>{ 
    //    // temp=1
    
    //      const steps = [
    //         {
    //             element: document.getElementById('documentationm'),
    //             title: 'Documentation',
    //             intro: "Access thorough documentation and resources for optimal utilization of the loyalty software"
    //           },
    //           {
    //             element:document.getElementById('help_FAQ'),
    //             title: 'Help and FAQ  ',
    //             intro: "Get answers to common queries and seek help through the dedicated Help and FAQ section",
             
    //           },
              
    
    //      ]
    //      intro.setOptions({
    //          steps: steps,
    //          exitOnOverlayClick: false,
    //        });
     
    //      intro.start();
    //      } 
    //      if( localStorage.getItem('firstLogin')){
    //         dispatch(documentDropdown(true))
    //      setTimeout(()=>{
    //         handleLoad()
    //      },500)
    //     }
         
       
    // }, []);
    //    intro.oncomplete(function(element) {
    //  complete=true

    //     dispatch(documentDropdown(false))
    //     dispatch(profileDropdown(false))

    //     localStorage.setItem('firstLogin','')

    // });
    // intro.onexit(() => {
    //     setshowModal(true)
    //     localStorage.setItem('firstLogin','')
    //     dispatch(documentDropdown(false))
    //     dispatch(profileDropdown(false))
    //   });
   
    return (
        <>
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
                        {showModal && <ModalCard/>}
            <Header name={"Change Password"} />
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__profileSettingsMain">
                        <div className="dashboard__heading">
                            <div className="dashboard__headingLeft">
                                <div className="dashboard__headingBackLink">
                                    <Link to="" onClick={() => navigate(-1)}>
                                        <img src={backIcon2} alt="" />
                                    </Link>
                                </div>
                                <div className="dashboard__headingContent">
                                    <h3>Change Password</h3>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard__profileSettingsBox">
                            <ValidatorForm className="customForm" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>Current Password</label>
                                            <span className="inputIcon">{currentPasswordFocus ? <img className="onFocus" title={isRevealOldPassword ? "Hide password" : "Show password"} src={isRevealOldPassword ? eyeUnseenFocus : eyeSeenFocus} alt="eye" onClick={() => setIsRevealOldPassword((prevState) => !prevState)} /> : <img  title={isRevealOldPassword ? "Hide password" : "Show password"} src={isRevealOldPassword ? eyeUnseen : eyeSeen} alt="eye" onClick={() => setIsRevealOldPassword((prevState) => !prevState)} />}</span>
                                            <PasswordValidator placeholder="Enter Current Password" className="form-control" name="oldPassword" maxLength="16" value={oldPassword} type={isRevealOldPassword ? "text" : "password"} onChange={onChange} validators={["required"]} errorMessages={["Please enter password."]}  />
                                            {/* <img className="inputIcon" src={lockIcon} alt="" /> */}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>New Password</label>
                                            <span className="inputIcon">{newPasswordFocus ? <img className="onFocus" title={isRevealNewPassword ? "Hide password" : "Show password"} src={isRevealNewPassword ? eyeUnseenFocus : eyeSeenFocus} alt="eye" onClick={() => setIsRevealNewPassword((prevState) => !prevState)} /> : <img  title={isRevealNewPassword ? "Hide password" : "Show password"} src={isRevealNewPassword ? eyeUnseen : eyeSeen} alt="eye" onClick={() => setIsRevealNewPassword((prevState) => !prevState)} />}</span>
                                            <PasswordValidator placeholder="Enter New Password" className="form-control" name="newPassword" minLength="6" maxLength="16" value={newPassword} type={isRevealNewPassword ? "text" : "password"} onChange={onChange} validators={["required", "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,16}$"]} errorMessages={["Please enter password.", "condition"]} />
                                            {/* <PasswordValidator placeholder="New Password" className="form-control" name="newPassword" minLength="6" maxLength="16" value={newPassword} type={isRevealPassword ? "text" : "password"} onChange={onChange} validators={["required", "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,16}$"]} errorMessages={["Please enter password.", "condition"]} onFocus={() => setPasswordFocus(true)} onBlur={() => setPasswordFocus(false)} /> */}
                                            {/* <img className="inputIcon" src={lockIcon} alt="" /> */}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>Confirm Password</label>
                                            <span className="inputIcon">{confirmFocus ? <img className="onFocus" title={isConfirmPassword ? "Hide password" : "Show password"} src={isConfirmPassword ? eyeUnseenFocus : eyeSeenFocus} alt="eye" onClick={() => setIsConfirmPassword((prevState) => !prevState)} /> : <img title={isConfirmPassword ? "Hide password" : "Show password"} src={isConfirmPassword ? eyeUnseen : eyeSeen} alt="eye" onClick={() => setIsConfirmPassword((prevState) => !prevState)} />}</span>
                                            <PasswordValidator placeholder="Enter Confirm Password" className="form-control" name="comfirmPassword" maxLength="16" value={comfirmPassword} type={isConfirmPassword ? "text" : "password"} onChange={onChange} validators={["required", "isPasswordMatch"]} errorMessages={[" Please enter confirm password.", "Password Mismatch."]}  />
                                            {/* <img className="inputIcon" src={lockIcon} alt="" /> */}
                                        </div>
                                    </div>
                                    <div className="col-sm-12  col-md-12 col-lg-12 col-xl-12">
                                        <div className="button-section" style={{ justifyContent: "flex-start" }}>
                                            <button type="button" className="btn btn-danger" onClick={handleCancel}>
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn btn-primary">
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </ValidatorForm>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChangePasswordSettings;
