import { useEffect, useState } from "react";
import introJs from "intro.js";
import "intro.js/introjs.css";
import { useNavigate } from "react-router-dom";
import { gift_cards_layout, header_main, home_section_left, home_section_right,redemption_successful} from '../Image/Index'
import ModalCard from "../components/layout/ModalCard";
import ModalCardEnd from "../components/layout/ModalCardIn";

const IntroSucessRedeem=()=>{


const intro=introJs()
let complete=false
let exitOnLast=false
const [show,setShow]=useState(false)
const navigate=useNavigate()
    useEffect(() => { 
      localStorage.setItem('firstLogin', 'customer')
        const handleLoad=()=>{ 
         const steps = [
            {
                element: document.getElementById('rewards'),
                title: 'Successfully Redeemed',
                intro: " Customers receive confirmation of successful redemption, providing them with assurance that their rewards or offers have been applied as intended "
              },
          
              
             
         ]
         intro.setOptions({
             steps: steps,
            exitOnOverlayClick: false,
             doneLabel:"Done",
             tooltipClass: 'customTooltip'
           });
         intro.start();
         } 
         setTimeout(()=>{
            handleLoad()
         },600)
         return () => {
            intro.exit();
        }; 
        
       }, []);
       intro.oncomplete(function(element) {
        complete=true
        setShow(true)  
        localStorage.setItem('firstLogin','')  


    });
    intro.onexit(() => {
        if(!complete) {
            localStorage.setItem('firstLogin','')  
           setShow(true)
            }
            
        
      });
      intro.onchange(function(element) {
        switch (this._currentStep){
            case 0:
              setTimeout(()=>{
                let cross=  document.getElementsByClassName("introjs-skipbutton")[0]
                cross?.addEventListener('click', function() {
                exitOnLast=true
                localStorage.setItem('firstLogin','')   
                setShow(true)  
                });
              },600)  
      
              
                   break;
                 
           }
      
      });
    return(<>
    {show && <ModalCardEnd show={show} customer={true}/>}

    <div className="cj_home_one">
      <div className="cj_home_one_header">
          <img src={header_main} alt=""/>
      </div>
      <div className="cj_home_one_body">
          <div className="cj_home_one_body_left">
          <img src={home_section_left} alt=""/>
          </div>
          <div className="cj_home_one_body_right">
          <img src={home_section_right} alt=""/>
          </div>
      </div>
      <div className="cj_home_one_big_body">
          <img src={gift_cards_layout} alt=""/>
      </div>
      <div className="cj_home_one_redemeed">
        <img id='rewards' src={redemption_successful}/>
      </div>
    </div>

    
    {/* <img src={earn_reward_points} style={{"height":'400px'}} ></img>
    <img id='rewards' src={earn_reward_points}></img> */}
    
    </>)
}
export default IntroSucessRedeem