import React, { useState, useEffect } from "react";
import { Modal, Button, CloseButton } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { getVoucherEnquiry } from "../pages/campaigns/services";

const VoucherViewModal = (props) => {
    const location = useLocation();
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false);
    const { name, voucherValidityDate, voucherVisibilityDate, earningValue, termsAndCondition, redeemUrl, totalRedemptionLimit, nxpoints, earningType, perPersonRedemption, perPersonRedemptionCount, merchant, voucherGeneratedType, voucherType, title, isActive, numberOfVouchers, templateHitCounter, lastGenratedNumberOfVoucher, tags, description } = formData 

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("token_gen"));
        try{
            if (props.show) {
                setLoader(true);
                const response = getVoucherEnquiry(token?.access_token, props.voucherCode);
                response
                    .then((res) => {
                        if(res.data){
                        setFormData(res.data);
                       
                    }
                    setLoader(false);
                    })
                    .catch((err) => {
                        setLoader(false);
                    });
            } else setLoader(false);
        }
        catch(e){
            console.error(e)
        }
    }, [props.show, props.voucherCode]); 

    return (
        <>
            <Modal show={props.show} onHide={props.close} size="lg" centered>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: "20px" }}>Voucher View</Modal.Title>
                    <CloseButton onClick={props.close} variant="white" />
                </Modal.Header>
                <Modal.Body className="dashboard__transactionsModal" style={{ position: "relative", minHeight: "85px" }}>
                    {loader ? (
                        <div className="mainPreloaderMain" style={{ position: "absolute" }}>
                            <div className="mainPreloader">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    ) : (
                        <div className="dashboard__transactionsModalProfile">
                            <div className="dashboard__transactionsModalDetails">
                                <div className="dashboard__transactionsModalBottom">
                                    <ul>
                                        <li>
                                            <span>Name:</span>{" "}
                                            <span className="characterLimitProperty" title={name}>
                                                {name ? name:''}
                                            </span>
                                        </li>
                                        <li>
                                            <span>Voucher Validity Start Date:</span>{" "}
                                            <span className="characterLimitProperty" title={voucherValidityDate?.startDate}>
                                                {voucherValidityDate?.startDate?.slice(0, 10)}
                                            </span>
                                        </li>
                                        <li>
                                            <span>Voucher Validity End Date:</span>{" "}
                                            <span className="characterLimitProperty" title={voucherValidityDate?.endDate}>
                                                {voucherValidityDate?.endDate?.slice(0, 10)}
                                            </span>
                                        </li>

                                        <li>
                                            <span>Voucher Visibility Date:</span>{" "}
                                            <span className="characterLimitProperty" title={voucherVisibilityDate}>
                                                {voucherVisibilityDate?.slice(0, 10)}
                                            </span>
                                        </li>
                                        <li>
                                            <span>Voucher Generated Type:</span>{" "}
                                            <span className="characterLimitProperty" title={voucherGeneratedType}>
                                                {voucherGeneratedType === 0 ? "Imported" : "Generated"}
                                            </span>
                                        </li>
                                        <li>
                                            <span>Earning Type:</span>{" "}
                                            <span className="characterLimitProperty" title={earningType}>
                                                {earningType}
                                            </span>
                                        </li>
                                        <li>
                                            <span>Earning Value:</span>{" "}
                                            <span className="characterLimitProperty" title={earningValue}>
                                                {earningValue}
                                            </span>
                                        </li>
                                        <li>
                                            <span>Description:</span>{" "}
                                            <span className="characterLimitProperty" title={description}>
                                                {description?description:"NA"}
                                            </span>
                                        </li>
                                        <li>
                                            <span>Status:</span>{" "}
                                            <span className="characterLimitProperty" title={isActive ? "true" : ""}>
                                                {isActive !== undefined ? formData.isActive.toString() : ""}
                                            </span>
                                        </li>
                                        <li>
                                            <span>RedeemUrl:</span>{" "}
                                            <span className="characterLimitProperty" title={redeemUrl}>
                                                {redeemUrl?redeemUrl:"NA"}
                                            </span>
                                        </li>
                                        <li>
                                            <span>TermsAndCondition:</span>{" "}
                                            <span className="characterLimitProperty" title={termsAndCondition}>
                                                {termsAndCondition?termsAndCondition:"NA"}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.close}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default VoucherViewModal;
