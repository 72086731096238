import React from "react";
// import jwt from "jsonwebtoken";
import axios from "./axios";
import store from "../store";
import { LOAD_TOKEN, AUTH_ERROR } from "../actions/type";
import jwt_decode from "jwt-decode";
import { baseURL } from "./url";

export const isTokenExpired=(token) =>{
    if (!token) return new Promise((resolve) => resolve(false));
    const now = new Date();
    if (token && jwt_decode(token)) {
        const expiry = jwt_decode(token);
        // console.log((expiry) ,now.getTime()/1000)
    	return new Promise((resolve) =>
    		resolve(((now.getTime()/1000)+60) < expiry.exp)
    	);
    }
    return new Promise((resolve) => resolve(false));
}

export const getNewAuthToken = async () => {
    let body = {
        clientid: localStorage.getItem("clientid"),
        grant_type: "client_credentials",
        clientsecret: localStorage.getItem("clientsecret"),
    };
		const { dispatch } = store;
		try {
			localStorage.removeItem("token_gen");
			const fetchResponse = await fetch(baseURL + "internaltoken", {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify(body),
            });
            // fetchResponse.json().then((res) => {
            //     console.log(JSON.stringify(res))
            //     localStorage.setItem("token_gen",JSON.stringify(res));
            //     dispatch({ type: LOAD_TOKEN, payload: res.access_token });
            //     return fetchResponse.json();

            // })
            return await fetchResponse.json();
            
			
		} catch (err) {
			dispatch({ type: AUTH_ERROR });
			return new Promise((resolve) => resolve(null));
		}
	}

    // async handleLogout(err) {
    //     const status = err?.response?.status;
    //     if (status === 403) return store.dispatch({ type: AUTH_ERROR });
    //     if (status === 408) return store.dispatch({ type: AUTH_ERROR });
    //     if (status === 418) {
    //         const { dispatch } = store;
    //         localStorage.removeItem("token");
    //         const res = await axios.post("/api/authenticate");
    //         const { token } = res.data.results;
    //         dispatch({ type: LOAD_TOKEN, payload: token });
    //         return store.dispatch({ type: AUTH_ERROR });
    //     }
    // }
// }

// export default new Utils();
