import React, { memo, useEffect, useRef, useState } from "react";
import Header from "../components/layout/Header";
import { Link, useNavigate } from "react-router-dom";
import { decrypt } from "../utils/encodingdecoding";
import { getUserJourneyReport } from "../actions/dashboard";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { connect } from "react-redux";
import { clearCampaigns } from "../actions/campaigns";
import { getBusinessGoal, clearBusinessGoal } from "../actions/businessGoal";
import introJs from "intro.js";
import "intro.js/introjs.css";
import ModalCard from "../components/layout/ModalCard";

const IntroAnalyticalReport = memo(({ clearCampaigns, getBusinessGoal, clearBusinessGoal, BusinessGoal }) => {
    const navigate = useNavigate();
    const [FormData, setFormData] = useState({});
    const [heading, setHeading] = useState("");
    const [year, setYear] = useState({});
    const [Report1, setReport1] = useState([]);
    const [Report2, setReport2] = useState([]);
    const [name, setName] = useState();
    const intro = introJs();
    let exitOnLast = false;
    const [loader, setLoader] = useState(false);
    const [showModal, setshowModal] = useState(false);
    const tooltip = {
        cannotLoseThem: `Customer who were doing transaction till last month but haven't done anything from last 30 days.`,
        loyalCustomers: `Customer who is doing transaction every month.`,
        champions: `Customer who higest RFM along with every month.`,
        atRisk: `Customer who is not doing transacation since last 3 months.`,
        needAttention: `Customer who is doing low transaction.`,
        potentialLoyalists: `Customer who has High to low transaction amount.`,
        hibernating: `No transaction in last 1 year.`,
        aboutToSleep: `Who has 0 transaction in last 6 months.`,
        promising: `Similar amount transaction in every month.`,
        newCustomers: `Customer who has done Transaction in last 3 months but never did before.`,
    };
    let staticData = {
        TotalCustomer: 2950,
        NewCustomer: 238,
        LoyalCustomer: 494,
        CanNotLoseThem: 293,
        Champions: 498,
        Hibernation: 120,
        AtRisk: 263,
        AboutToSleep: 350,
        Needattention: 182,
        PotentialLoyalist: 303,
        Promising: 209,
        Total_Male: 734114,
        Total_female: 314433,
        AgeWise: {
            "18-20": 27523,
            "21-30": 285847,
            "31-40": 285923,
            "41-50": 267839,
            "51-60": 181415,
            "61-70": 0,
            "71-80": 0,
            "81-90": 0,
        },
        Average: 25020,
        Totaltrn: 1048547,
        Monthwisetrn_result: {
            "4-2023": "31753741",
            "3-2023": "708715127",
            "2-2023": "991600187",
            "1-2023": "1091293931",
            "12-2022": "1126547324",
            "11-2022": "1103449398",
            "10-2022": "1142555985",
            "9-2022": "770228680",
            "8-2022": "794629926",
            "7-2022": "793859284",
            "6-2022": "781460546",
            "5-2022": "789124447",
            "4-2022": "764396944",
            "3-2022": "815216355",
            "2-2022": "730358829",
            "1-2022": "818814120",
            "12-2021": "820733134",
            "11-2021": "796195069",
            "10-2021": "815852248",
            "9-2021": "790869482",
            "8-2021": "808597838",
            "7-2021": "822675739",
            "6-2021": "795575362",
            "5-2021": "822056289",
            "4-2021": "798654613",
            "3-2021": "819967038",
            "2-2021": "747704509",
            "1-2021": "816375358",
            "12-2020": "826058670",
            "11-2020": "792869299",
            "10-2020": "820248055",
            "9-2020": "793207870",
            "8-2020": "816779482",
            "7-2020": "821551168",
            "6-2020": "790715281",
            "5-2020": "823755891",
            "4-2020": "343590609",
        },
        Monthwisetrn_count: {
            "4-2023": "946",
            "3-2023": "33554",
            "2-2023": "53246",
            "1-2023": "58589",
            "12-2022": "60325",
            "11-2022": "58517",
            "10-2022": "60839",
            "9-2022": "23471",
            "8-2022": "24273",
            "7-2022": "24138",
            "6-2022": "23761",
            "5-2022": "24092",
            "4-2022": "23356",
            "3-2022": "25022",
            "2-2022": "22459",
            "1-2022": "25219",
            "12-2021": "25222",
            "11-2021": "24395",
            "10-2021": "25117",
            "9-2021": "24473",
            "8-2021": "24895",
            "7-2021": "25280",
            "6-2021": "24439",
            "5-2021": "25184",
            "4-2021": "24557",
            "3-2021": "25302",
            "2-2021": "23001",
            "1-2021": "25091",
            "12-2020": "25398",
            "11-2020": "24287",
            "10-2020": "25208",
            "9-2020": "24259",
            "8-2020": "25255",
            "7-2020": "25143",
            "6-2020": "24378",
            "5-2020": "25280",
            "4-2020": "10576",
        },
        datastatus: "Sample ",
        discriminator: "SampleReportData",
        id: "cea17f66-9936-48a2-9ff3-4ffa59d71a2e",
        _rid: "e6g0AKwYiWPkHgAAAAAAAA==",
        _self: "dbs/e6g0AA==/colls/e6g0AKwYiWM=/docs/e6g0AKwYiWPkHgAAAAAAAA==/",
        _etag: '"0000ea23-0000-2000-0000-646312030000"',
        _attachments: "attachments/",
        _ts: 1684214275,
    };
    const businessGoalStatic = [
        {
            industry: "E-commerce",
            goals: [
                {
                    businessgoal: "Brand Awareness",
                    description: "Grab potential customers when they become aware of your business through various rewards programs such as loyalty points, new deals & offers, and gift cards",
                },
                {
                    businessgoal: "Gain Consideration",
                    description: "Customers typically research and compare products, prices, and reviews on the website or through other sources. At this stage give great deals and offers on various products to engage customers.",
                },
                {
                    businessgoal: "Improve Conversions",
                    description: "Offer Valuable and appealing loyalty programs to your customers. Rewards can include discounts on future purchases, free shipping, exclusive access to products, or new deals, cashback and many more.",
                },
                {
                    businessgoal: "Post-Purchase Activities",
                    description: "Keep engaging with your customers after the purchase as well. This is an excellent opportunity for your business to provide personalized communication to the customer, including information about related products or promotions, as well as asking for feedback on the purchase experience.",
                },
                {
                    businessgoal: "Increase Retention",
                    description: "Focus on retaining customers through various strategies such as customer loyalty programs, personalized product recommendations, and special deals and promotions.",
                },
                {
                    businessgoal: "Build brand Advocacy",
                    description: "Incentivize your customers to become brand advocates and share their positive experiences with others through social media, reviews, and word-of-mouth recommendations.",
                },
            ],
        },
        {
            industry: "Transportation",
            goals: [
                {
                    businessgoal: "Research and Booking",
                    description: "This stage involves the customer researching and selecting a transportation service, such as a taxi or a ride-hailing app, and making a booking. This stage must include some exciting offers and best suited pricing option.",
                },
                {
                    businessgoal: "Departure",
                    description: "This stage involves the customer traveling to the transportation pick-up point, such as a taxi stand or a designated pick-up location for a ride-hailing app. Try to offer points for ride booking and various redemption options to make ride affordable",
                },
                {
                    businessgoal: "In-transit experience",
                    description: "This stage involves the customer traveling to their destination and experiencing the transportation service. This can include interactions with the driver or other passengers, as well as any amenities and deals provided by the service.",
                },
                {
                    businessgoal: "Arrival and post-trip experience",
                    description: "This stage involves the customer arriving at their destination and completing any necessary payment or feedback for the transportation service. Offer various discounts to customers for their next trip to encourage them choose your services again.",
                },
            ],
        },
        {
            industry: "Travel",
            goals: [
                {
                    businessgoal: "Inspiration",
                    description: "At this stage, customers become inspired to travel through various channels such as advertising, social media, or word-of-mouth referrals.",
                },
                {
                    businessgoal: "Research",
                    description: "Provide customers with better pricing options and great deals (Once inspired, customers begin researching different travel options, comparing features, pricing, and reading reviews. This includes researching different destinations, hotels, airlines, and travel packages, as well as visiting their websites)",
                },
                {
                    businessgoal: "Planning",
                    description: "After researching, the customer may engage with a travel agent or an online booking platform to plan their trip. This stage require sharing exclusive deals to make their travelling cheap and convenient.",
                },
                {
                    businessgoal: "Booking",
                    description: "Once the customer has made a decision, they will book their trip through a travel agent or an online booking platform that provides them with best deals. This stage requires a straightforward and transparent booking process, with an efficient checkout process.",
                },
                {
                    businessgoal: "Pre-trip",
                    description: "Before the trip, customers may require ongoing support and access to travel information. This includes travel guildes, documents, hotels tie-up, special rewards etc.",
                },
                {
                    businessgoal: "On-trip",
                    description: "During the trip, customers may require additional support and access to local information. This includes transportation, accommodation, tours, and activities.",
                },
                {
                    businessgoal: "Post-trip",
                    description: "After the trip, customers may require additional support and access to feedback and reviews. This includes feedback on the travel experience. Share points and incentive for such activities.",
                },
                {
                    businessgoal: "Loyalty",
                    description: "The final stage is where satisfied customers become loyal to the travel provider and recommend it to others through word-of-mouth referrals, social media, or online reviews.",
                },
            ],
        },
        {
            industry: "Retail",
            goals: [
                {
                    businessgoal: "Awareness",
                    description: "Push customer for signup with rewards program(At this stage, potential customers become aware of retail stores and brands through various channels such as advertising, referrals, or social media).",
                },
                {
                    businessgoal: "Research",
                    description: "Provide customers with better pricing options and great deals (Once aware, customers begin researching different products, comparing features, and pricing, and reading reviews. This includes researching different retailers, brands, and products, as well as visiting their websites.)",
                },
                {
                    businessgoal: "Consideration",
                    description: "After researching, the customer may visit a store or engage to get more information about products. This stage is crucial for the retailer to provide best deals on products and build customer loyalty.",
                },
                {
                    businessgoal: "Purchase",
                    description: "Once the customer has made a decision, they will make a purchase, either in-store or online. This stage requires a straightforward and transparent purchase process, with different heart-winning payments options and delivery process.",
                },
                {
                    businessgoal: "Post-Purchase",
                    description: "After the purchase, customers may require ongoing support and access to related product information and various deals and offers.",
                },
                {
                    businessgoal: "Loyalty",
                    description: "The final stage is where satisfied customers become loyal to the retailer and recommend it to others through word-of-mouth referrals, social media, or online reviews.",
                },
            ],
        },
        {
            industry: "Healthcare",
            goals: [
                {
                    businessgoal: "Brand Awareness",
                    description: "Improve patient outcomes when they become aware of your healthcare services through various loyalty and reward programs.",
                },
                {
                    businessgoal: "Gain Consideration",
                    description: "Patients research different healthcare providers, compare features, and pricing, and read reviews. Offer them discounts on healthcare services, personalized healthcare plans, or even access to exclusive events or services.",
                },
                {
                    businessgoal: "Treatment Stage",
                    description: "Once the patient has made a decision, they will receive treatment, which may include consultations, tests, procedures, or medication. You need to provide them with excellent offers, rewards, and service to keep them engaged with your brands.",
                },
                {
                    businessgoal: "Follow-up",
                    description: "After treatment, provide customers with personalized communication, discounted check-ups, free health plans, or medication. Keep customers engaged with various customer loyalty programs.",
                },
                {
                    businessgoal: "Loyalty",
                    description: "Provide a positive experience, and attract & retain patients to drive long-term success and referrals through word-of-mouth marketing. Encourage loyalty with Personalized communication and special promotions to patients.",
                },
            ],
        },
        {
            industry: "Automotive",
            goals: [
                {
                    businessgoal: "Brand Awareness",
                    description: "Grab potential customers when they become aware of your business through various service rewards and loyalty programs for old or new vehicles.",
                },
                {
                    businessgoal: "Gain Consideration",
                    description: "Customers typically research and compare products, prices, and reviews on the website or through other sources. Provide incentives for vehicle upgrades with the dealership, such as discounts or special financing rates to influence their purchasing decision",
                },
                {
                    businessgoal: "Increase Purchases",
                    description: "Offer Valuable and appealing loyalty programs to your customers. Rewards can include discounts on future services and maintenance, including exclusive access to new deals, and many more.",
                },
                {
                    businessgoal: "Take the Ownership",
                    description: "Provide ongoing support to the customer with personalized communication, offers, and discounts on maintenance services, repairs, and accessories. This is a crucial stage for building customer loyalty and retention.",
                },
                {
                    businessgoal: "Build Advocacy",
                    description: "Push your satisfied customers to become brand advocates by offering them special offers and deals to increase word-of-mouth referrals, and online reviews.",
                },
            ],
        },
        {
            industry: "RealEstate",
            goals: [
                {
                    businessgoal: "Awareness",
                    description: "Push customer for signup with rewards program(At this stage, potential customers become aware of retail stores and brands through various channels such as advertising, referrals, or social media)",
                },
                {
                    businessgoal: "Research",
                    description: "Provide customers with better pricing options and great deals (Once aware, customers begin researching different products, comparing features, and pricing, and reading reviews. This includes researching different retailers, brands, and products, as well as visiting their websites.) ",
                },
                {
                    businessgoal: "Consideration",
                    description: "After researching, the customer may visit a store or engage to get more information about products. This stage is crucial for the retailer to provide best deals on products and build customer loyalty",
                },
                {
                    businessgoal: "Purchase",
                    description: "Once the customer has made a decision, they will make a purchase, either in-store or online. This stage requires a straightforward and transparent purchase process, with different heart-winning payments options and delivery process",
                },
                {
                    businessgoal: "Post-Purchase",
                    description: "After the purchase, customers may require ongoing support and access to related product information and various deals and offers. ",
                },
                {
                    businessgoal: "Loyalty",
                    description: "The final stage is where satisfied customers become loyal to the retailer and recommend it to others through word-of-mouth referrals, social media, or online reviews.",
                },
            ],
        },
        {
            industry: "Restaurant",
            goals: [
                {
                    businessgoal: "Awareness and Discovery",
                    description: "Push for a visit or online ordering with exclusive offers(This stage involves the customer becoming aware of a restaurant through advertising, online reviews, word-of-mouth, or other means)",
                },
                {
                    businessgoal: "Research and Booking",
                    description: "Offer best pricing and great deals. This stage involves the customer researching the restaurant online, reading reviews, checking menus, and booking a table either online, via phone or through an app.",
                },
                {
                    businessgoal: "Ordering",
                    description: "This stage involves the customer ordering food and drinks from the server, who may make recommendations or answer any questions the customer has.",
                },
                {
                    businessgoal: "Dining Experience",
                    description: "This stage involves the customer enjoying their meal and any additional experiences the restaurant may offer, such as live music, wine tastings or dessert courses.",
                },
                {
                    businessgoal: "Payment and Feedback",
                    description: "This stage involves the customer settling their bill and providing feedback on their experience, either by completing a survey, leaving a review or talking to the server or manager.",
                },
            ],
        },
        {
            industry: "FinancialServices",
            goals: [
                {
                    businessgoal: "Brand Awareness",
                    description: "Grab the potential customers when they become aware of your business through various rewards programs such as loyalty points, new deals & offers and gift cards.",
                },
                {
                    businessgoal: "Gain Consideration",
                    description: "Once aware, customers begin researching different financial services, comparing features, pricing, and reading reviews. At this stage give great deals and offers on various credit cards and services to engage customers.",
                },
                {
                    businessgoal: "Increase Purchases",
                    description: "Before opening a bank account, getting a loan, or applying for a credit card customer always looks for great deals and offers. This stage requires various rewards and incentives to keep customers engaged.",
                },
                {
                    businessgoal: "Take the Ownership",
                    description: "Provide customers with new offers and deals after the purchase to retail them for long.",
                },
                {
                    businessgoal: "Build Loyalty",
                    description: "Convert your customers into brand advocates to increase the word-of-mouth referrals, and online reviews.",
                },
            ],
        },
    ];
    const [state, setState] = useState({
        cannotLoseThem: false,
        loyalCustomers: false,
        champions: false,
        atRisk: false,
        needAttention: false,
        potentialLoyalists: false,
        hibernating: false,
        aboutToSleep: false,
        promising: false,
        newCustomers: false,
        businessGoal: [],
        goals: [],
    });
    const { cannotLoseThem, loyalCustomers, champions, atRisk, needAttention, potentialLoyalists, hibernating, aboutToSleep, promising, newCustomers, businessGoal, goals } = state;
    const targetRef = useRef(null);
    useEffect(() => {
        if (localStorage.getItem("name")) {
            let tempName = decrypt(localStorage.getItem("name"));
            tempName = `${tempName[0].toUpperCase()}${tempName.slice(1)}`;
            setName(tempName);
        }
    }, []);

    const fetch = async () => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const Id = localStorage.getItem("Id");
        //const tedata = await getUserJourneyReport(token?.access_token, Id);
        setFormData(staticData);
        setHeading(staticData[0]?.datastatus);
        setLoader(false);
    };

    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        if (FormData?.Monthwisetrn_count) {
            const asArray =
                Object.entries(FormData?.Monthwisetrn_count) &&
                Object.entries(FormData?.Monthwisetrn_count).map((arr) => {
                    const data = arr[0];
                    return data.split("-")[1];
                });
            function onlyUnique(value, index, array) {
                return array.indexOf(value) === index;
            }
            var uniques = asArray.filter(onlyUnique);
            setYear(uniques.sort().reverse());
            filterData(uniques[0], FormData?.Monthwisetrn_count).then((res) => {
                setReport1(res);
            });
        }
    }, [FormData]);

    useEffect(() => {
        if (FormData?.Monthwisetrn_result) {
            const asArray =
                Object.entries(FormData?.Monthwisetrn_result) &&
                Object.entries(FormData?.Monthwisetrn_result).map((arr) => {
                    const data = arr[0];
                    return data.split("-")[1];
                });
            function onlyUnique(value, index, array) {
                return array.indexOf(value) === index;
            }
            var uniques = asArray.filter(onlyUnique);
            setYear(uniques.sort().reverse());
            filterData(uniques[0], FormData?.Monthwisetrn_result).then((res) => {
                setReport2(res);
            });
        }
    }, [FormData]);
    useEffect(() => {
        //   fetchBusinessGoal();
    }, []);

    useEffect(() => {
        setState({ ...state, businessGoal: businessGoalStatic });
    }, []);

    useEffect(() => {
        const industry = decrypt(localStorage.getItem("schemaName"));
        businessGoal &&
            businessGoal.map((item) => {
                if (item.industry === industry) {
                    setState({ ...state, goals: item.goals });
                }
            });
    }, [businessGoal]);

    const fetchBusinessGoal = async () => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        await clearBusinessGoal();
        await getBusinessGoal(token.access_token);
        setLoader(false);
    };

    const handleSkipClick = async () => {
        let tags = [];
        let goal;
        goals.map((element) => {
            goal = element.businessgoal.replace(/-/g, " ");
            tags.push(`business goal:${goal.toLowerCase()}`);
        });
        await clearCampaigns();
        navigate("/sample-campaigns", { state: tags });
    };

    const filterData = async (data, object) => {
        const asArray = await Object.entries(object);
        let filtered = await asArray.filter(([key, value]) => key.split("-")[1] === data);
        let year = filtered[0];
        year = year[0].split("-")[1];

        let allMonths = Array.from({ length: 12 }, (_, i) => i + 1);
        let mergedArray =
            allMonths &&
            allMonths.map((month) => {
                let matchingData = filtered.find((d) => parseInt(d[0].split("-")[0]) == month);
                return matchingData ? matchingData : [`${month}-${year}`, 0];
            });

        const n = mergedArray.length;
        for (let i = 0; i < n - 1; i++) {
            for (let j = 0; j < n - i - 1; j++) {
                const monthA = parseInt(mergedArray[j][0].split("-")[0]);
                const monthB = parseInt(mergedArray[j + 1][0].split("-")[0]);
                if (monthA > monthB) {
                    const temp = mergedArray[j];
                    mergedArray[j] = mergedArray[j + 1];
                    mergedArray[j + 1] = temp;
                }
            }
        }
        const tempdata =
            (await mergedArray) &&
            mergedArray.map((arr) => {
                return arr[1];
            });
        return tempdata;
    };
    let complete = false;

    useEffect(() => {
        const handleLoad = () => {
            // temp=1

            const steps = [
                {
                    element: document.getElementById("canLose"),
                    title: "Can't Lose them",
                    intro: "Check out your most valuable customers who have made recent purchases.",
                },
                // {
                //     element: document.getElementById('loyal_customers'),
                //     title: 'Loyal Customers',
                //     intro: 'Continue to reward and nurture loyalty of consistent and highly engaged customers.'
                // },
                // {
                //     element: document.getElementById('champions_intro'),
                //     title: 'Champions',
                //     intro: 'Identify growth opportunities and potential customers for expanding your loyalty program.'
                // },
                // {
                //     element: document.getElementById('atRisk_intro'),
                //     title: 'At Risk',
                //     intro: 'Keep an eye on the Customers who were once active but have started to show a decline in purchasing frequency.',

                // },
                {
                    element: document.getElementById("into_needAttention"),
                    title: "Need Attention",
                    intro: " Target Customers who have recently made purchases but haven't been frequent shoppers.",
                },
                // {
                //     element: document.getElementById('intro_potentail'),
                //     title: 'Potential Loyalists ',
                //     intro: "Invest in building loyalty of customers who have shown increasing frequency but haven't been around for long. ",

                // },
                // {
                //     element: document.getElementById('intro_hibernating'),
                //     title: 'Hibernating',
                //     intro: "Try to reactivate customers who used to shop frequently but haven't made recent purchases",

                // },
                // {
                //     element: document.getElementById('intro_aboutToSleep'),
                //     title: 'About To Sleep',
                //     intro: "Try to reactivate customers who used to shop frequently but haven't made recent purchases",

                // },
                // {
                //     element: document.getElementById('intro_promising'),
                //     title: 'Promising ',
                //     intro: "Nurture customers with tailored incentives who have made recent purchases and show potential for increased engagement.",

                // },
                {
                    element: document.getElementById("intro_newCustomer"),
                    title: "New Customers",
                    intro: "Focus on providing an excellent first experience to turn them into loyal customers.",
                },
                {
                    element: document.getElementById("businessGoal"),
                    title: "Select your Business Goal  ",
                    intro: " Choose your business objective to customize your loyalty program accordingly.",
                },
                // {
                //     element: document.getElementById('campaignsinfos'),
                //     title: 'Create Campaigns  ',
                //     intro: 'Develop and oversee a variety of loyalty campaigns catering to specific customer segments.',

                // },
                // {
                //     element: document.getElementById('businessGoal'),
                //     title: 'Create Campaigns  ',
                //     intro: 'Develop and oversee a variety of loyalty campaigns catering to specific customer segments.',

                // },
            ];
            intro.setOptions({
                steps: steps,
                doneLabel: "Next",
                exitOnOverlayClick: false,
            });

            intro.start();
        };
        setTimeout(() => {
            handleLoad();
        }, 500);

        return () => {
            intro.exit();
        };
    }, []);

    intro.oncomplete(function (element) {
        complete = true;
        setTimeout(() => {
            if (!exitOnLast) {
                navigate("/intro/business-goals-selection");
            }
        }, 700);
    });

    intro.onexit(() => {
        if (!complete) {
            localStorage.setItem("firstLogin", "");
            setshowModal(true);
        }
    });
    intro.onchange(function (element) {
        switch (this._currentStep) {


            case 3:
                let cross = document.getElementsByClassName("introjs-skipbutton")[0];
                setTimeout(()=>{
                    cross?.addEventListener("click", function () {
                        setshowModal(true);
                        localStorage.setItem("firstLogin", "");
                        exitOnLast = true;
                    });
                },400)
                break;
        }
    });
    return (
        <>
            <Header name={`${heading ? heading : ""} Analytical Report`} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            {showModal && <ModalCard show={showModal}/>}
            <div className="analyticalReport">
                <div className="analyticalReportRFMAnalysis">
                    <div className="analyticalReportRFMAnalysis__topSection analyticalReportRFMAnalysis__topSection__left">
                        <div className="container">
                            <div className="analyticalReportRFMAnalysis__rightSection">
                                <h3>
                                    <sup>1</sup>Getting Started
                                </h3>
                                <p>Here are some reports to got you help you get started and understand the points where you can improve your performance in order to increase sales.</p>
                            </div>
                        </div>
                    </div>
                    <div className="analyticalReportRFMAnalysis__bottomSection">
                        <div className="container">
                            <div className="analyticalReportRFMAnalysis__paragraph">
                                <p>
                                    <strong>RFM Analysis is one of the most exciting parts of the Dashboard</strong>. It presents the latest customer's activity or purchase, the frequency of the customer's transactions or visits, and the purchasing power of a customer. Interpret the RFM segments to understand the behaviors of customers, and rewards them accordingly.
                                </p>
                                <p>
                                    Upload your Existing Data for Reports, Data should be atleast 6 Months old from current Date.{" "}
                                    <Link to="/uploading-existing-datas" state={{ from: "upload" }}>
                                        Click here to upload data
                                    </Link>
                                </p>
                            </div>
                            {/* <div className="analyticalReportRFMAnalysis__box analyticalReportRFMAnalysis__box">
                                <div className="analyticalReportRFMAnalysis__details">
                                    <h1>Total Customers {FormData?.TotalCustomer}</h1>
                                </div>
                            </div> */}
                            <div className="analyticalReportRFMAnalysis__content">
                                <div className="analyticalReportRFMAnalysis__yAxis">
                                    <span className="label">Frequency Score</span>
                                    <ul>
                                        <li className="legend">1</li>
                                        <li className="legend">2</li>
                                        <li className="legend">3</li>
                                        <li className="legend">4</li>
                                        <li className="legend">5</li>
                                    </ul>
                                </div>
                                <div className="analyticalReportRFMAnalysis__boxes">
                                    <div id="canLose" className="analyticalReportRFMAnalysis__box analyticalReportRFMAnalysis__box--first">
                                        <div className="analyticalReportRFMAnalysis__details">
                                            <i
                                                className="arRFMa_tooltip"
                                                id="cannotLoseThem"
                                                onClick={() => {
                                                    setState({ cannotLoseThem: !cannotLoseThem });
                                                }}
                                                ref={targetRef}
                                                onMouseLeave={() => {
                                                    setState({ cannotLoseThem: false });
                                                }}
                                            >
                                                i
                                            </i>
                                            <Overlay target={document.getElementById("cannotLoseThem")} rootClose={true} onHide={() => setState({ cannotLoseThem: false })} show={cannotLoseThem} placement="bottom">
                                                {(props) => (
                                                    <Tooltip id="box1" {...props}>
                                                        {tooltip.cannotLoseThem}
                                                    </Tooltip>
                                                )}
                                            </Overlay>
                                            <h3>
                                                Can't Lose them <br />
                                                {FormData[0]?.CanNotLoseThem}
                                                {` (${FormData[0]?.CanNotLoseThem ? ((FormData[0]?.CanNotLoseThem / FormData[0]?.TotalCustomer) * 100).toFixed(2) : 0}) %`}
                                                {/* 8,620 (3.59%) */}
                                            </h3>
                                        </div>
                                    </div>
                                    <div id="loyal_customers" className="analyticalReportRFMAnalysis__box analyticalReportRFMAnalysis__box--second">
                                        <div className="analyticalReportRFMAnalysis__details">
                                            <i
                                                className="arRFMa_tooltip"
                                                id="loyalCustomers"
                                                onClick={() => {
                                                    setState({ loyalCustomers: !loyalCustomers });
                                                }}
                                                ref={targetRef}
                                                onMouseLeave={() => {
                                                    setState({ loyalCustomers: false });
                                                }}
                                            >
                                                i
                                            </i>
                                            <Overlay target={document.getElementById("loyalCustomers")} show={loyalCustomers} placement="bottom" rootClose={true} onHide={() => setState({ loyalCustomers: false })}>
                                                {(props) => (
                                                    <Tooltip id="box1" {...props}>
                                                        {tooltip.loyalCustomers}
                                                    </Tooltip>
                                                )}
                                            </Overlay>
                                            <h3>
                                                Loyal Customers <br />
                                                {FormData[0]?.LoyalCustomer}
                                                {` (${FormData[0]?.LoyalCustomer ? ((FormData[0]?.LoyalCustomer / FormData[0]?.TotalCustomer) * 100).toFixed(2) : 0}) %`}
                                                {/* 8,620 (3.59%) */}
                                            </h3>
                                        </div>
                                    </div>
                                    <div id="champions_intro" className="analyticalReportRFMAnalysis__box analyticalReportRFMAnalysis__box--third">
                                        <div className="analyticalReportRFMAnalysis__details">
                                            <i
                                                className="arRFMa_tooltip"
                                                id="champions"
                                                onClick={() => {
                                                    setState({ champions: !champions });
                                                }}
                                                ref={targetRef}
                                                onMouseLeave={() => {
                                                    setState({ champions: false });
                                                }}
                                            >
                                                i
                                            </i>
                                            <Overlay target={document.getElementById("champions")} show={champions} placement="bottom" rootClose={true} onHide={() => setState({ champions: false })}>
                                                {(props) => (
                                                    <Tooltip id="box1" {...props}>
                                                        {tooltip.champions}
                                                    </Tooltip>
                                                )}
                                            </Overlay>
                                            <h3>
                                                Champions <br />
                                                {FormData[0]?.Champions}
                                                {` (${FormData[0]?.Champions ? ((FormData[0]?.Champions / FormData[0]?.TotalCustomer) * 100).toFixed(2) : 0}) %`}
                                            </h3>
                                        </div>
                                    </div>
                                    <div id="atRisk_intro" className="analyticalReportRFMAnalysis__box analyticalReportRFMAnalysis__box--fourth">
                                        <div className="analyticalReportRFMAnalysis__details">
                                            <i
                                                className="arRFMa_tooltip"
                                                id="atRisk"
                                                onClick={() => {
                                                    setState({ atRisk: !atRisk });
                                                }}
                                                ref={targetRef}
                                                onMouseLeave={() => {
                                                    setState({ atRisk: false });
                                                }}
                                            >
                                                i
                                            </i>
                                            <Overlay target={document.getElementById("atRisk")} show={atRisk} placement="bottom" rootClose={true} onHide={() => setState({ atRisk: false })}>
                                                {(props) => (
                                                    <Tooltip id="box1" {...props}>
                                                        {tooltip.atRisk}
                                                    </Tooltip>
                                                )}
                                            </Overlay>
                                            <h3>
                                                At Risk <br />
                                                {FormData[0]?.AtRisk}
                                                {` (${FormData[0]?.AtRisk ? ((FormData[0]?.AtRisk / FormData[0]?.TotalCustomer) * 100).toFixed(2) : 0}) %`}
                                            </h3>
                                        </div>
                                    </div>
                                    <div id="into_needAttention" className="analyticalReportRFMAnalysis__box analyticalReportRFMAnalysis__box--fifth">
                                        <div className="analyticalReportRFMAnalysis__details">
                                            <i
                                                className="arRFMa_tooltip"
                                                id="needAttention"
                                                onClick={() => {
                                                    setState({ needAttention: !needAttention });
                                                }}
                                                ref={targetRef}
                                                onMouseLeave={() => {
                                                    setState({ needAttention: false });
                                                }}
                                            >
                                                i
                                            </i>
                                            <Overlay target={document.getElementById("needAttention")} show={needAttention} placement="bottom" rootClose={true} onHide={() => setState({ needAttention: false })}>
                                                {(props) => (
                                                    <Tooltip id="box1" {...props}>
                                                        {tooltip.needAttention}
                                                    </Tooltip>
                                                )}
                                            </Overlay>
                                            <h3>
                                                Need Attention <br />
                                                {FormData[0]?.Needattention}
                                                {` (${FormData[0]?.Needattention ? ((FormData[0]?.Needattention / FormData[0]?.TotalCustomer) * 100).toFixed(2) : 0}) %`}
                                            </h3>
                                        </div>
                                    </div>
                                    <div id="intro_potentail" className="analyticalReportRFMAnalysis__box analyticalReportRFMAnalysis__box--sixth">
                                        <div className="analyticalReportRFMAnalysis__details">
                                            <i
                                                className="arRFMa_tooltip"
                                                id="potentialLoyalists"
                                                onClick={() => {
                                                    setState({ potentialLoyalists: !potentialLoyalists });
                                                }}
                                                ref={targetRef}
                                                onMouseLeave={() => {
                                                    setState({ potentialLoyalists: false });
                                                }}
                                            >
                                                i
                                            </i>
                                            <Overlay target={document.getElementById("potentialLoyalists")} show={potentialLoyalists} placement="bottom" rootClose={true} onHide={() => setState({ potentialLoyalists: false })}>
                                                {(props) => (
                                                    <Tooltip id="box1" {...props}>
                                                        {tooltip.potentialLoyalists}
                                                    </Tooltip>
                                                )}
                                            </Overlay>
                                            <h3>
                                                Potential Loyalists <br />
                                                {FormData[0]?.PotentialLoyalist}
                                                {` (${FormData[0]?.PotentialLoyalist ? ((FormData[0]?.PotentialLoyalist / FormData[0]?.TotalCustomer) * 100).toFixed(2) : 0}) %`}
                                            </h3>
                                        </div>
                                    </div>
                                    <div id="intro_hibernating" className="analyticalReportRFMAnalysis__box analyticalReportRFMAnalysis__box--seventh">
                                        <div className="analyticalReportRFMAnalysis__details">
                                            <i
                                                className="arRFMa_tooltip"
                                                id="hibernating"
                                                onClick={() => {
                                                    setState({ hibernating: !hibernating });
                                                }}
                                                ref={targetRef}
                                                onMouseLeave={() => {
                                                    setState({ hibernating: false });
                                                }}
                                            >
                                                i
                                            </i>
                                            <Overlay target={document.getElementById("hibernating")} show={hibernating} placement="bottom" rootClose={true} onHide={() => setState({ hibernating: false })}>
                                                {(props) => (
                                                    <Tooltip id="box1" {...props}>
                                                        {tooltip.hibernating}
                                                    </Tooltip>
                                                )}
                                            </Overlay>
                                            <h3>
                                                Hibernating <br />
                                                {FormData[0]?.Hibernation}
                                                {` (${FormData[0]?.Hibernation ? ((FormData[0]?.Hibernation / FormData[0]?.TotalCustomer) * 100).toFixed(2) : 0}) %`}
                                            </h3>
                                        </div>
                                    </div>
                                    <div id="intro_aboutToSleep" className="analyticalReportRFMAnalysis__box analyticalReportRFMAnalysis__box--eighth">
                                        <div className="analyticalReportRFMAnalysis__details">
                                            <i
                                                className="arRFMa_tooltip"
                                                id="aboutToSleep"
                                                onClick={() => {
                                                    setState({ aboutToSleep: !aboutToSleep });
                                                }}
                                                ref={targetRef}
                                                onMouseLeave={() => {
                                                    setState({ aboutToSleep: false });
                                                }}
                                            >
                                                i
                                            </i>
                                            <Overlay target={document.getElementById("aboutToSleep")} show={aboutToSleep} placement="bottom" rootClose={true} onHide={() => setState({ aboutToSleep: false })}>
                                                {(props) => (
                                                    <Tooltip id="box1" {...props}>
                                                        {tooltip.aboutToSleep}
                                                    </Tooltip>
                                                )}
                                            </Overlay>
                                            <h3>
                                                About To Sleep <br />
                                                {FormData[0]?.AboutToSleep}
                                                {` (${FormData[0]?.AboutToSleep ? ((FormData[0]?.AboutToSleep / FormData[0]?.TotalCustomer) * 100).toFixed(2) : 0}) %`}
                                            </h3>
                                        </div>
                                    </div>
                                    <div id="intro_promising" className="analyticalReportRFMAnalysis__box analyticalReportRFMAnalysis__box--ninth">
                                        <div className="analyticalReportRFMAnalysis__details">
                                            <i
                                                className="arRFMa_tooltip"
                                                id="promising"
                                                onClick={() => {
                                                    setState({ promising: !promising });
                                                }}
                                                ref={targetRef}
                                                onMouseLeave={() => {
                                                    setState({ promising: false });
                                                }}
                                            >
                                                i
                                            </i>
                                            <Overlay target={document.getElementById("promising")} show={promising} placement="bottom" rootClose={true} onHide={() => setState({ promising: false })}>
                                                {(props) => (
                                                    <Tooltip id="box1" {...props}>
                                                        {tooltip.promising}
                                                    </Tooltip>
                                                )}
                                            </Overlay>
                                            <h3>
                                                Promising <br />
                                                {FormData[0]?.Promising}
                                                {` (${FormData[0]?.Promising ? ((FormData[0]?.Promising / FormData[0]?.TotalCustomer) * 100).toFixed(2) : 0}) %`}
                                            </h3>
                                        </div>
                                    </div>
                                    <div id="intro_newCustomer" className="analyticalReportRFMAnalysis__box analyticalReportRFMAnalysis__box--tenth">
                                        <div className="analyticalReportRFMAnalysis__details">
                                            <i
                                                className="arRFMa_tooltip"
                                                id="newCustomers"
                                                onClick={() => {
                                                    setState({ newCustomers: !newCustomers });
                                                }}
                                                ref={targetRef}
                                                onMouseLeave={() => {
                                                    setState({ newCustomers: false });
                                                }}
                                            >
                                                i
                                            </i>
                                            <Overlay target={document.getElementById("newCustomers")} show={newCustomers} placement="bottom" rootClose={true} onHide={() => setState({ newCustomers: false })}>
                                                {(props) => (
                                                    <Tooltip id="box1" {...props}>
                                                        {tooltip.newCustomers}
                                                    </Tooltip>
                                                )}
                                            </Overlay>
                                            <h3>
                                                New Customers <br />
                                                {FormData[0]?.NewCustomer}
                                                {` (${FormData[0]?.NewCustomer ? ((FormData[0]?.NewCustomer / FormData[0]?.TotalCustomer) * 100).toFixed(2) : 0}) %`}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="analyticalReportRFMAnalysis__xAxis">
                                    <ul>
                                        <li className="legend">1</li>
                                        <li className="legend">2</li>
                                        <li className="legend">3</li>
                                        <li className="legend">4</li>
                                        <li className="legend">5</li>
                                    </ul>
                                    <span className="label">Recency Score</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="analyticalReportChartAnalysisTwo">
                    <div className="container">
                        <div className="analyticalReportChartAnalysisTwo__bottomSection analyticalReportChartAnalysisTwo__bottomSection_2">
                            <div className="analyticalReportChartAnalysisTwo__rightSection">
                                <div className="analyticalReportChartAnalysisTwo__paragraph">
                                    <p>With the Help of AI and existing data, use Novus Loyalty to set up campaign to supercharge your growth.</p>
                                </div>
                                <div className="analyticalReportChartAnalysisTwo__buttonSection">
                                    <Link id="businessGoal" disabled className="btn btn-primary">
                                        Select Your Business Goals
                                        <i className="fa-solid fa-arrow-right"></i>
                                    </Link>
                                    {/* <Link to="" className="btn btn-outline-primary">
                                        Contact Novus Support
                                        <i className="fa-solid fa-comment-dots"></i>
                                    </Link> */}
                                    <Link to="/sample-campaigns" className="btn btn-link" onClick={handleSkipClick}>
                                        Skip
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});
const mapStateToProps = (state) => ({ BusinessGoal: state.BusinessGoal.data });
const mapDispatchToProps = {
    clearCampaigns,
    getBusinessGoal,
    clearBusinessGoal,
};

export default connect(mapStateToProps, mapDispatchToProps)(IntroAnalyticalReport);
