import React from "react";
import Slider from "react-slick";

function AuthenticationSliderLeft() {
    let settings = {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 5000,
        // adaptiveHeight: true,
    };

    return (
        <div className="signUpPage__left">
            {/* <div className="signUpPage__backButton">
				<Link to="https://www.novusloyalty.com/">
					<i className="fa-solid fa-arrow-left"></i> <span>Back</span>
				</Link>
			</div> */}
            <div className="signUpPage__slider">
                <Slider {...settings}>
                    <div className="signUpPage__box">
                        <h4>Acquire</h4>
                        <h2>Connect with the customers that matter and get the results you need</h2>
                        <p>Turn visitors into new customers with an AI-based loyalty platform for higher app downloads and sign-ups</p>
                        <figure>
                            <img src={"/Images/carouselFirst.png"} alt="" />
                        </figure>
                    </div>
                    <div className="signUpPage__box">
                        <h4>Engage</h4>
                        <h2>Boost active participation through engagement with the right audience</h2>
                        <p>Know your customers to create personalized content & deliver through their preferred channels at the right time</p>
                        <figure>
                            <img src={"/Images/carouselSecond.png"} alt="" />
                        </figure>
                    </div>
                    <div className="signUpPage__box">
                        <h4>Retain</h4>
                        <h2>Build long-lasting customer relationships with an enhanced loyalty experience</h2>
                        <p>Use Novus to turn existing customers into repeat buyers & prevent them from switching to competitors</p>
                        <figure>
                            <img src={"/Images/carouselThird.png"} alt="" />
                        </figure>
                    </div>
                    <div className="signUpPage__box">
                        <h4>Grow</h4>
                        <h2>Embrace data-driven actionable insights to meet customer expectations</h2>
                        <p>Develop an evergreen growth strategy to earn more customers, increase their lifetime value & reduce cost per acquisition</p>
                        <figure>
                            <img src={"/Images/carouselFourth.png"} alt="" />
                        </figure>
                    </div>
                </Slider>
            </div>
        </div>
    );
}

export default AuthenticationSliderLeft;
