import { getNewAuthToken, isTokenExpired } from "../utils";
import { baseURL } from "../utils/url";
import { CLEAR_VOUCHER_TEMPLATE, VOUCHER_TEMPLATE_DETAIL } from "./type";

export const voucherTemplateDetail = (token) => async (dispatch) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getVoucherTemplateByMerchant", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        fetchResponse
            .json()
            .then((res) => {
                if (res.errors == null) {
                    dispatch({
                        type: VOUCHER_TEMPLATE_DETAIL,
                        payload: res?.data,
                        show: false,
                    });
                    return res?.data;
                } else {
                    dispatch({
                        type: VOUCHER_TEMPLATE_DETAIL,
                        show: false,
                    });
                    return null;
                }
            })
            .catch(() => {
                return null;
            });
    } catch (error) {
        return error;
    }
};

export const clearVoucherTemplate = () => async (dispatch) => {
    dispatch({ type: CLEAR_VOUCHER_TEMPLATE });
};
