

import introJs from "intro.js";
import "intro.js/introjs.css";
import { useEffect, useState } from "react";
import ModalCard from "../components/layout/ModalCard";
import { your_logo, sign_in } from '../Image/Index'
import { useNavigate } from "react-router-dom";

const IntroCustomer = () => {
    //let exitOnLast=0
    const [show, setShow] = useState(false);

    const intro = introJs()
    const navigate = useNavigate()
    let complete = false
    let exitOnLast = false

    const [showModal, setShowModal] = useState(false);


    useEffect(() => {
        localStorage.setItem('firstLogin', 'customer')
        const handleLoad = () => {
            const steps = [
                {
                    element: document.getElementById('signIn'),
                    title: 'Sign In',
                    intro: "Log in to your account to access and earn rewards. If you're new, create an account to get started."
                },



            ]
            intro.setOptions({
                steps: steps,
                exitOnOverlayClick: false,
                doneLabel: "Next",
                tooltipClass: 'customTooltip'
            });
            intro.start();
        }
        setTimeout(() => {
            handleLoad()
        }, 500)
        return () => {
            intro.exit();
        };

    }, []);
    intro.oncomplete(function (element) {
        complete = true
        setTimeout(() => {
            if (!exitOnLast) navigate("/intro/customer/rewards")
        }, 700)


    });
    intro.onexit(() => {
        if (!complete) {
            localStorage.setItem('firstLogin', '')
            setShow(true)
        }


    });


    intro.onchange(function (element) {
        switch (this._currentStep) {
            case 0:
                setTimeout(() => {
                    let cross = document.getElementsByClassName("introjs-skipbutton")[0]
                    cross?.addEventListener('click', function () {
                        exitOnLast = true
                        localStorage.setItem('firstLogin', '')
                        setShowModal(true)
                    });
                }, 600)


                break;

        }

    });

    return (
        <>
            {showModal && <ModalCard show={showModal} customer={true}/>}
            <div className="journey___box">
                <div className="logo___box">
                    <img src={your_logo} alt="" />    
                </div>
                <div className="user___box">
                    <img id='signIn' src={sign_in} />
                </div>
            </div>
            {/* <img src={your_logo} style={{ alignItems: 'center' }}></img><br />
            <img id='signIn' src={sign_in} style={{
                height: '400px', width: '900px', justifyContent: 'center',
                alignItems: 'center'
            }} ></img> */}




        </>


    )
}
export default IntroCustomer