import { combineReducers } from "redux";
import Dashboard from "./dashboard";
import Setting from "./setting";
import Transaction from "./transaction";
import Customer from "./customer";
import Campaigns from "./campaigns";
import modals from "./modals";
import authReducer from "./authReducer";
import BusinessGoal from "./businessGoal";
import UploadFileData from "./uploadData";
import VoucherTemplate from "./voucher";
import AlertModal from "./alertModal";
import intro from './intro'
export default combineReducers({
    modals,
    Dashboard,
    Setting,
    Transaction,
    Customer,
    Campaigns,
    authReducer,
    BusinessGoal,
    UploadFileData,
    VoucherTemplate,
    AlertModal,
    intro,
});
