import React, { useEffect, useState } from 'react'
import { emailReport } from '../../../actions/auth';
import Header from '../../layout/Header';
import { dashboardIconThree } from '../../../Image/Index';
import { Link } from 'react-router-dom';

function EmailDashboard() {

    const [loader, setLoader] = useState(false);
    const [totalFailed, setTotalFailed] = useState([])
    const [totalSuccess, setTotalSuccess] = useState([]);
    const [totalEmails, setTotalEmails] = useState([]);

    const updateData = () => {
    setLoader(true)
        const token = JSON.parse(localStorage.getItem("token_gen"));
        emailReport(token?.access_token).then((res) => {
            var totalEmail = 0;
            var totalSuccess = 0;
            var totalFail = 0;
            res?.data?.forEach(item => {
                totalEmail += item.totalEmails;
            });
            res?.data?.forEach(item => {
                totalSuccess += item.totalSuccess
            });
            res?.data?.forEach(item => {
                totalFail += Math.abs(item.totalFail)
            });
            setTotalFailed(totalFail)
            setTotalSuccess(totalSuccess)
            setTotalEmails(totalEmail);
            setLoader(false)
        });
    }

    useEffect(() => {
        updateData()
    }, [])

    return (
        <>
            <Header name={"Email Dashboard"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="analyticalReport">
                <div className="analyticalReportChartAnalysisOne">
                    <div id="dashboard" className="container">
                        <div className="analyticalReportChartAnalysisOne__bottomSection">
                            <div className="analyticalReportChartAnalysisOne__heading">
                                <h2>Email Report Overview</h2>
                            </div>
                            <div className="analyticalReportChartAnalysisOne__leftSection fullSize">
                                <div className="analyticalReportChartAnalysisOne__box">
                                    <div className="analyticalReportChartAnalysisOne__header">
                                        <div className="analyticalReportChartAnalysisOne__left">
                                            <div className="analyticalReportChartAnalysisOne__image">
                                                <img src={dashboardIconThree} alt="" />
                                            </div>
                                            <div className="analyticalReportChartAnalysisOne__details">
                                                <Link to = "/emailReport" style={{fontSize:"1rem",fontWeight:"700"}}>Email Sent</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="analyticalReportChartAnalysisOne__reportSection">
                                        <h3>{totalEmails ? totalEmails : 0}</h3>
                                    </div>
                                </div>
                                <div className="analyticalReportChartAnalysisOne__box">
                                    <div className="analyticalReportChartAnalysisOne__header">
                                        <div className="analyticalReportChartAnalysisOne__left">
                                            <div className="analyticalReportChartAnalysisOne__image">
                                                <img src={dashboardIconThree} alt="" />
                                            </div>
                                            <div className="analyticalReportChartAnalysisOne__details">
                                            <Link to = "/emailReport" style={{fontSize:"1rem",fontWeight:"700"}}>Total Success Email</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="analyticalReportChartAnalysisOne__reportSection">
                                        <h3>{totalSuccess ? totalSuccess : 0}</h3>
                                    </div>
                                </div>
                                <div className="analyticalReportChartAnalysisOne__box">
                                    <div className="analyticalReportChartAnalysisOne__header">
                                        <div className="analyticalReportChartAnalysisOne__left">
                                            <div className="analyticalReportChartAnalysisOne__image">
                                            <img src={dashboardIconThree} alt="" />
                                            </div>
                                            <div className="analyticalReportChartAnalysisOne__details">
                                            <Link to = "/emailReport" style={{fontSize:"1rem",fontWeight:"700"}}>Failed</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="analyticalReportChartAnalysisOne__reportSection">
                                        <h3>{totalFailed ? totalFailed :0}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmailDashboard