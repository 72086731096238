import React, { useEffect, useState, useRef } from "react";
import Header from "../components/layout/Header";
import TextValidator from "../components/partial/TextValidator";
import { ValidatorForm } from "react-form-validator-core";
import { Link, useNavigate } from "react-router-dom";
import { backIcon2 } from "../Image/Index";
import { connect } from "react-redux";
import { getProfile, updateProfile, clearSetting } from "../actions/setting";
import swal from "sweetalert";
import { merchantLogo } from "../actions/setting";
import { BarCode, getMerchantAndLogo } from "../actions/auth";
import { decrypt } from "../utils/encodingdecoding";
import {profileDropdown,documentDropdown} from '../actions/intro'
import { useDispatch, useSelector } from 'react-redux';

import introJs from 'intro.js';
import 'intro.js/introjs.css'; 
import ModalCard from "../components/layout/ModalCard";
import { COLUMNS } from "../components/pages/setting/columnn";
import { Upload } from "../components/pages/setting/upload";

const IntroProfileSettings = ({ getProfile, updateProfile, Setting, clearSetting }) => {
    const formRef = useRef();
    let navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    let exitOnLast=false
    const [formData, setFormData] = useState({
        name: "",
        emailID: "",
        contactNumber: "",
        selectedTypeId: "",
        companyName: "",
        detailAboutProject: "",
        tAndC: false,
        error: false,
        rewardRatio: "25",
        timeZone: "US",
        currency: "USD",
        needSelectedTypeId: "",
    });
    const intro = introJs();
    const dispatch = useDispatch();
    const modalOpen = useSelector(state => state.intro.modalOpen);
    const [showModal,setShowModal]=useState(false)

    const { name, emailID, contactNumber, selectedTypeId, companyName, detailAboutProject, needSelectedTypeId, rewardRatio, timeZone, currency } = formData;
    const onChange = (e) => setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });
    const handleCancel = () =>
        setFormData({
            ...formData,
            contactNumber: "",
            detailAboutProject: "",
            rewardRatio: "",
            timeZone: "",
            currency: "",
        });

    useEffect(() => {
        fetch();
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        setFormData({
            name: Setting.name,
            emailID: Setting.email,
            contactNumber: Setting.contactnumber,
            selectedTypeId: Setting.industry,
            companyName: Setting.companyname,
            detailAboutProject: Setting.projectdetails,
            rewardRatio: Setting.rewardRatio,
            timeZone: Setting.timeZone,
            currency: Setting.currency,
            needSelectedTypeId: Setting.need,
        });
    }, [Setting]);

    const fetch = async () => {
        setLoader(true);
        await clearSetting();
        let body = {
            email: localStorage.getItem("email"),
            merchcode: localStorage.getItem("merchantcode"),
        };
        let token = await JSON.parse(localStorage.getItem("token_gen"));
        //await getProfile(token.access_token, body);
        setLoader(false);
    };
    const onSubmit = async () => {
        setLoader(true);
        let body = {
            email: emailID,
            rewardRatio: rewardRatio,
            timeZone: timeZone,
            projectdetails: detailAboutProject,
            contactnumber: contactNumber,
            currency: currency,
        };
        let token = await JSON.parse(localStorage.getItem("token_gen"));
        await updateProfile(token.access_token, body);
        await fetch();
        setLoader(false);
        swal({
            position: "center",
            icon: "success",
            title: "Details Updated",
            showConfirmButton: false,
            timer: 10000,
        });
    };
    const imageInputRef = useRef();

    const [data, setData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [brandName, setBrandName] = useState("");
    const [size, setSIze] = useState(false);

    const onChangeData = (e) => {
        setBrandName(e.target.value);
    };
    const handleFile = (e) => {
        if (e.target.files[0] && e.target.files[0]?.size > 1 * 1024 * 1024) {
            setSIze(true);
        } else {
            const filedata = e.target.files[0];
            setSelectedFile(filedata);
            setSIze(false);
        }
    };
    const handleCancelData = () => {
        setSelectedFile(null);
        setBrandName("");
    };
    const Submit = async (e) => {
        e.preventDefault();
        let token = JSON.parse(localStorage.getItem("token_gen"));
        setLoader(true);
        (await !size) &&
            merchantLogo(token?.access_token, brandName, selectedFile)
                .then((res) => {
                    swal({
                        position: "center",
                        icon: "success",
                        title: res.message,
                        showConfirmButton: false,
                        timer: 10000,
                    });
                    setSelectedFile(null);
                    fetchData();
                    setBrandName("");
                })
                .catch((err) => {
                    swal({
                        position: "center",
                        icon: "error",
                        title: err.message,
                        showConfirmButton: false,
                        timer: 10000,
                    });
                });
        imageInputRef.current.value = "";

        setLoader(false);
    };
    const fetchData = async () => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const id = localStorage.getItem("Id");
        //const response = await getMerchantAndLogo(token.access_token, id);
        let response=[]
        setLoader(false);
        Array.isArray(response) && setData(response);
    };

    useEffect(() => {
        fetchData();
    }, []);
    const [dataa, setDataa] = useState();
    const values = decrypt(localStorage.getItem("merchantcode")) === "MER000844" ? `https://demo.novusloyalty.com/auth/login` : decrypt(localStorage.getItem("merchantcode")) === "MER000014" ? `https://makemytrip.novusloyalty.com/auth/login` : decrypt(localStorage.getItem("merchantcode")) === "MER000003" ? "https://domino.novusloyalty.com/auth/login" : `https://rewards-novusloyalty.azurewebsites.net/${decrypt(localStorage.getItem("merchantcode"))}`;
//     const fetchBar = async () => {
//             const response = await BarCode(values);
//             setDataa(response.data);
//    };
   let complete=false
   useEffect(() => { 
    const handleLoad=()=>{ 

     const steps = [
        {
            element: document.getElementById('collapseThreee'),
            title: 'Customer Portal',
            intro: "This is customers' gateway to customer's loyalty program account. Here, they can check their points, rewards, transaction history, and exclusive offers."
          },
         
        

     ]
     intro.setOptions({
         steps: steps,
        exitOnOverlayClick: false,
        doneLabel:"Next",
        tooltipClass: 'customTooltip'

       });
 
     intro.start();
     } 
        dispatch(profileDropdown(true))
     setTimeout(()=>{
        handleLoad()
     },500)
    
     
   //dispatch(documentDropdown(true))
   }, []);
   intro.oncomplete(function(element) {
    complete=true
    setTimeout(()=>{
        dispatch(profileDropdown(false))
        if(!exitOnLast) { 
            localStorage.setItem('firstLogin','customer')
            window.location.href= "/intro/customer"; 
    }
    }
,700
    )
   

   // dispatch(documentDropdown(false))
});
intro.onexit(() => {
    if(!complete) {
        localStorage.setItem('firstLogin','')  
       // dispatch(documentDropdown(false))
     //   dispatch(profileDropdown(false))
        setShowModal(true)
    }
    else{
      //  dispatch(documentDropdown(false))
      //  dispatch(profileDropdown(false))
    }    
  });
  intro.onchange(function(element) {
    switch (this._currentStep){
        case 0:
          setTimeout(()=>{
            let cross=  document.getElementsByClassName("introjs-skipbutton")[0]
            cross?.addEventListener('click', function() {
            exitOnLast=true
            setShowModal(true)
            localStorage.setItem('firstLogin','')     
            });
          },600)            
               break;
       }

});
   //eslint-disable-line
    return (
        <>
            <Header name={"Profile Settings"} />
            {/* {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )} */}
                    {showModal && <ModalCard show={showModal}/>}
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__profileSettingsMain">
                        <div className="dashboard__heading">
                            <div className="dashboard__headingLeft">
                                <div className="dashboard__headingBackLink">
                                    <Link to="" onClick={() => navigate(-1)}>
                                        <img src={backIcon2} alt="" />
                                    </Link>
                                </div>
                                <div className="dashboard__headingContent">
                                    <h3>Profile Settings</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion" id="accordionExample">
                        <div  className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button collapsed" disabled={localStorage.getItem('firstLogin')} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    Profile Settings
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse"  aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div  className="accordion-body">
                                    <div className="dashboard__profileSettingsMain">
                                        <div className="dashboard__heading">
                                            <div className="dashboard__headingLeft">
                                                <div className="dashboard__headingContent">
                                                    <h3>Profile Settings</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dashboard__profileSettingsBox" >
                                            <ValidatorForm className="customForm" ref={formRef} onSubmit={(e) => onSubmit(e)}>
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                        <div className="form-group">
                                                            <label>Name (required)</label>
                                                            <TextValidator placeholder="ABC Bank" className="form-control" name="name" value={name} disabled onChange={onChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                        <div className="form-group withIcon">
                                                            <label>Email ID</label>
                                                            <TextValidator placeholder="novus@loyalty.com" className="form-control" name="emailID" value={emailID} disabled onChange={onChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                        <div className="form-group withIcon">
                                                            <label>Contact Number</label>
                                                            <TextValidator type="text" className="form-control " name="contactNumber" value={contactNumber} validators={["required", "matchRegexp:^[1-9][0-9]{9,12}$"]} errorMessages={["Please enter your contact number", "Please enter a valid number"]} onChange={onChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                        <div className="form-group withIcon">
                                                            <label>Industry</label>
                                                            <TextValidator placeholder="Industry" className="form-control" name="industry" value={selectedTypeId} disabled onChange={onChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                        <div className="form-group withIcon">
                                                            <label>What you Need</label>
                                                            <TextValidator placeholder="what you need" className="form-control" name="what you need" value={needSelectedTypeId} disabled />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                        <div className="form-group withIcon">
                                                            <label>Company Name</label>
                                                            <TextValidator type="text" name="companyName" value={companyName} className="form-control" disabled />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div className="form-group withIcon">
                                                            <label>Details About your Project</label>
                                                            <TextValidator type="text" placeholder="Details about your project" className="form-control" name="detailAboutProject" value={detailAboutProject} validators={["required"]} errorMessages={["Please Enter Details About your Project"]} onChange={onChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div className="button-section">
                                                            <button type="button" className="btn btn-danger" onClick={handleCancel}>
                                                                Cancel
                                                            </button>
                                                            <button type="submit" className="btn btn-primary">
                                                                Update
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ValidatorForm>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id='upload_logo'className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" disabled={localStorage.getItem('firstLogin')} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Upload Logo
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="dashboard__profileSettingsMain">
                                        <div className="dashboard__heading">
                                            <div className="dashboard__headingLeft">
                                                <div className="dashboard__headingContent">
                                                    <h3>Upload Logo</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dashboard__profileSettingsBox">
                                            <ValidatorForm className="customForm" ref={formRef} onSubmit={(e) => Submit(e)}>
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                        <div className="form-group withIcon">
                                                            <label>Logo</label>
                                                            <input className="form-control" type="file" ref={imageInputRef} accept={"image/png, image/gif, image/jpeg, image/jpg"} required onChange={handleFile} />
                                                            {size && <span style={{ color: "red" }}>file size not more than 1 MB</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                        <div className="form-group withIcon">
                                                            <label>Brand Name</label>
                                                            <TextValidator placeholder="Brand Name" className="form-control" name="emailID" validators={["required"]} errorMessages={[" Please enter brands name."]} value={brandName} onChange={onChangeData} />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p style={{ color: "black", fontSize: "13px" }}>
                                                            {" "}
                                                            <span style={{ color: "red" }}>*</span>only one logo can be uploded,Newly uploded logo will replace old
                                                        </p>
                                                    </div>
                                                    <h6 style={{ color: "black", fontSize: "18px" }}>Uploded Logo</h6>
                                                    {data && (
                                                        <div>
                                                            <Upload columns={COLUMNS} data={data} />
                                                        </div>
                                                    )}
                                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div className="button-section">
                                                            <button type="button" className="btn btn-danger" onClick={handleCancelData}>
                                                                Cancel
                                                            </button>
                                                            <button type="submit" className="btn btn-primary">
                                                                Upload
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ValidatorForm>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseThreee" aria-expanded="true" aria-controls="collapseThreee">
                                    Customer Portal
                                </button>
                            </h2>
                            <div id="collapseThreee" disabled={true} className="accordion-collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="dashboard__profileSettingsMain">
                                        <div className="dashboard__heading">
                                            <div className="dashboard__headingLeft">
                                                <div className="dashboard__headingContent">
                                                    <h3> Customer Portal</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dashboard__profileSettingsBox">
                                            <div className="profileSettingsBoxCSP">
                                                <div disabled={true} className="profileSettingsmerchantbox">
                                                    {decrypt(localStorage.getItem("merchantcode")) === "MER000844" ? (
                                                        <Link disabled={true} className="customForm" target="_blank" to={`https://demo.novusloyalty.com/auth/login`}>
                                                            {`https://demo.novusloyalty.com/auth/login`}
                                                        </Link>
                                                    ) : decrypt(localStorage.getItem("merchantcode")) === "MER000014" ? (
                                                        <Link disabled={true} className="customForm" target="_blank" to={`https://makemytrip.novusloyalty.com/auth/login`}>
                                                            {`https://makemytrip.novusloyalty.com/auth/login`}
                                                        </Link>
                                                    ) : decrypt(localStorage.getItem("merchantcode")) === "MER000003" ? (
                                                        <Link disabled={true} className="customForm" target="_blank" to={`https://domino.novusloyalty.com/auth/login`}>
                                                            {`https://domino.novusloyalty.com/auth/login`}
                                                        </Link>
                                                    ) : (
                                                        <Link disabled={true} className="customForm" target="_blank" to={`https://rewards-novusloyalty.azurewebsites.net/${decrypt(localStorage.getItem("merchantcode"))}`}>
                                                            {`https://rewards-novusloyalty.azurewebsites.net/${decrypt(localStorage.getItem("merchantcode"))}`}
                                                        </Link>
                                                    )}
                                                </div>
                                                <div className="profileSettingsqrcodebox">
                                                    <div className="">
                                                        <p>Generate QR Code</p>
                                                        <Link disabled={true} download>
                                                            <i className="fa-solid fa-download"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="profileSettingsBoxCSPdetails">
                                                <p> Need customer domain ?</p>
                                                <Link disabled={true} to="/contact-us" className="btn btn-outline-primary">
                                                    Contact Novus Support
                                                    <i className="fa-solid fa-comment-dots"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => ({
    Setting: state.Setting,
});

const mapDispatchToProps = {
    getProfile,
    updateProfile,
    clearSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(IntroProfileSettings);
