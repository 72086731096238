import { Link } from "react-router-dom";

export const COLUMNS = [
    {
        Header: "Name",
        Footer: "Name",
        accessor: "name",
        sticky: "left",
    },
    {
        Header: "Template Code",
        Footer: "Template Code",
        accessor: "templateCode",
        disableFilters: true,
        sticky: "left",
    },

    {
        Header: "Generated Vouchers",
        Footer: "Generated Vouchers",
        accessor: "generatedVouchers",
    },

    // {
    //     Header: "Tagline",
    //     Footer: "Tagline",
    //     accessor: "tagline",
    // },

    {
        Header: "Voucher Generated Type",
        Footer: "Voucher Generated Type",
        accessor: "voucherGeneratedType",
    },
    // {
    //     Header: "Tags",
    //     Footer: "Tags",
    //     accessor: "tags",
    // },
    {
        Header: "Status",
        Footer: "Status",
        accessor: "isactive",
    },

    {
        Header: "Action",
        Footer: "Action",
        accessor: "action",
    },
];
