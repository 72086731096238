import { useEffect, useState } from "react";
import introJs from "intro.js";
import "intro.js/introjs.css";
import { useNavigate } from "react-router-dom";
import { gift_cards_layout, header_main, home_section_left, home_section_right, in_store_redemtion_button, redemption_box} from '../Image/Index'
import ModalCard from "../components/layout/ModalCard";

const IntroStoreId=()=>{


const intro=introJs()
let complete=false
let exitOnLast=false
const [show,setShow]=useState(false)
const navigate=useNavigate()
    useEffect(() => { 
      localStorage.setItem('firstLogin', 'customer')
        const handleLoad=()=>{ 
         const steps = [
            {
                element: document.getElementById('store_redeem'),
                title: 'In-Store Redemption',
                intro: "The 'In-Store Redemption' feature allows customers to redeem their rewards or offers at physical store locations. "
              },
            {
                element: document.getElementById('rewards'),
                title: 'Enter Store Id',
                intro: "Customers should input the unique Store ID of the physical store where they intend to redeem their rewards, ensuring a precise and hassle-free redemption."
              },
              
              
             
         ]
         intro.setOptions({
             steps: steps,
            exitOnOverlayClick: false,
             doneLabel:"Next",
             tooltipClass: 'customTooltip'
           });
         intro.start();
         } 
         setTimeout(()=>{
            handleLoad()
         },500)
         return () => {
            intro.exit();
        }; 
        
       }, []);
       intro.oncomplete(function(element) {
        complete=true
        setTimeout(()=>{
            if(!exitOnLast) navigate("/intro/customer/sucess")
        },700)


    });
    intro.onexit(() => {
        if(!complete) {
            localStorage.setItem('firstLogin','')  
           setShow(true)
            }
            
        
      });
      intro.onchange(function(element) {
        switch (this._currentStep){
            case 0:
              setTimeout(()=>{
                let cross=  document.getElementsByClassName("introjs-skipbutton")[0]
                cross?.addEventListener('click', function() {
                exitOnLast=true
                localStorage.setItem('firstLogin','')   
                setShow(true)  
                });
              },600)  
      
              
                   break;
                 
           }
      
      });
    return(<>
    {show && <ModalCard show={show} customer={true}/>}

    <div className="cj_home_one">
      <div className="cj_home_one_header">
          <img src={header_main} alt=""/>
      </div>
      <div className="cj_home_one_body">
          <div className="cj_home_one_body_left cj_home_one_body_left_with_button">
          <img src={home_section_left} alt=""/>
          <div id='store_redeem' className="rewards_button">
              <img src={in_store_redemtion_button}/>
          </div>
          </div>
          <div className="cj_home_one_body_right">
          <img src={home_section_right} alt=""/>
          </div>
      </div>
      <div className="cj_home_one_big_body">
          <img src={gift_cards_layout} alt=""/>
          <div className="redemption___box">
              <img id='rewards' src={redemption_box}/>
          </div>
      </div>
    </div>

    
    {/* <img src={earn_reward_points} style={{"height":'400px'}} ></img>
    <img id='rewards' src={earn_reward_points}></img> */}
    
    </>)
}
export default IntroStoreId