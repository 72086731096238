import React, { useEffect, useState } from "react";
import { Modal, Button, CloseButton } from "react-bootstrap";

const ModalReview = (props) => {
    const { show, close, data, setData, handleUrlParams, id } = props;
    const [getId, setGetId] = useState(id);
    const [state, setState] = useState();
    useEffect(() => {
        setGetId(id);
        data?.forEach((ele) => {
            if (ele.id === getId) {
                setState(ele.remarks ? ele.remarks : "");
            }
        });
        handleUrlParams();
    }, []);

    const handleChange = (e) => {
        setData((prev) =>
            prev.map((ele) => {
                if (ele.id === getId) {
                    return {
                        ...ele,
                        remarks: e.target.value,
                    };
                } else return ele;
            })
        );
        setState(e.target.value);
    };

    const handleClose = () => {
        setData((prev) =>
            prev.map((ele) => {
                if (ele.id === getId) {
                    return {
                        ...ele,
                        remarks: "",
                    };
                } else return ele;
            })
        );
        setState("");
        close();
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: "20px" }}>Add Review</Modal.Title>
                    <CloseButton onClick={handleClose} variant="white" />
                </Modal.Header>
                <Modal.Body style={{ position: "relative", minHeight: "85px" }}>
                    <>
                        <label>Enter your Review</label>
                        <input type="text" className="form-control" name="rewards" onChange={handleChange} value={state ? state : ""} />
                        {/* <input type="text" className="form-control" maxLength={totalAvailableValue.toString().length} name="inputRedeemPoints" defaultValue={totalAvailableValue} onChange={(e) => handleChange(e, state)} /> */}
                        {/* <span className="errorMessage">{error}</span> */}
                    </>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={close}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalReview;
