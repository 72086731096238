import React, { useEffect, useState } from "react";
import Paginated from "./Paginated";
import { COLUMNS } from "./columnn";
import { ContactUsData } from "../../actions/auth";
import AdminHeader from "../layout/AdminHeader";

const Contact = () => {
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);

    const fetchData = async () => {
        setLoader(true);
        const response = await ContactUsData();

        // Filter out data where the email ends with "@clavax.com"
        const filteredData = response.filter((val) => !val.email || (!val.email.endsWith("@clavax.com") && !val.email.endsWith("@clavax.us")));

        // Convert Created to date only (remove time)
        filteredData.forEach((val) => {
            val.Created = val.Created.split("T")[0];
        });

        // Sort filteredData based on Created date
        filteredData.sort((a, b) => (a.Created > b.Created ? -1 : 1));

        setLoader(false);
        setData(filteredData);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <AdminHeader name={"Contact Us Query"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}

            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>
                        <div className="dashboard__customersCard">
                            <div className="dashboard__header">
                                <div className="dashboard__left">
                                    <h4>Contact Us Query</h4>
                                </div>
                            </div>
                            <div className="dashboard__body">
                                <Paginated data={data} columns={COLUMNS} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;
