import { DASHBOARD, CLEAR_DASHBOARD } from "../actions/type";

const initialState = {
    Total_transactions: 0,
    total_Customer: 0,
    Total_unqualifiedCampaign: 0,
    Total_qualifiedCampaign: 0,
    TotalRedeem:0,
    MonthwiseTransaction: {},
};

const getDashboard = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case DASHBOARD:
            return {
                ...state,
                Total_transactions: payload.Total_transactions,
                total_Customer: payload.total_Customer,
                Total_unqualifiedCampaign: payload.Total_unqualifiedCampaign,
                Total_qualifiedCampaign: payload.Total_qualifiedCampaign,
                TotalRedeem: payload.TotalRedeem,
                MonthwiseTransaction: payload.MonthwiseTransaction,
            };
        case CLEAR_DASHBOARD:
            return {
                ...state,
                Total_transactions: 0,
                total_Customer: 0,
                Total_unqualifiedCampaign: 0,
                Total_qualifiedCampaign: 0,
                TotalRedeem: 0,
                MonthwiseTransaction: {},
            };
        default:
            return state;
    }
};
export default getDashboard;
