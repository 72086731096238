import { getNewAuthToken, isTokenExpired } from "../utils";
import { baseURL } from "../utils/url";
import { GET_UPLOAD_FILE_DETAILS, CLEAR_UPLOAD_FILE_DETAILS } from "./type";

export const getFileUploadDetail = (token, id, type) => async (dispatch) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `fileuploaddetails?id=${id}&filetype=${type}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // body: JSON.stringify(data),
            // id: data,
        });
        fetchResponse
            .json()
            .then((res) => {
                if (res.message.length >= 0) {
                    dispatch({
                        type: GET_UPLOAD_FILE_DETAILS,
                        payload: res.message,
                    });
                    return res;
                } else {
                    return null;
                }
            })
            .catch(() => {
                return null;
            });
    } catch (error) {
        return error;
    }
};
export const clearFileUploadDetail = () => async (dispatch) => {
    dispatch({ type: CLEAR_UPLOAD_FILE_DETAILS });
};
