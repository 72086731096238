import { getNewAuthToken, isTokenExpired } from "../../../utils";
import { baseURL } from "../../../utils/url";

export const schema = async (token, type) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "schema?type=" + type, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const campaign = async (token, data) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "campaigns", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const updateProfile = async (token, data) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "updateprofile", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const updatePassword = async (token, data) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "updatepassword", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const UpdateCampaign = async (token, data, campaigncode) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `updateCampaigns?id=${campaigncode}`, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const customerRegistration = async (token, data) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "customerregistration", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: data,
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};
export const transactionRegistration = async (token, data) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "transactionregistration", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: data,
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};

export const transactionRedeem = async (token, data) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "transactionredeem", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: data,
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};

export const customerView = async (token, data) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                // console.log(res)
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `CustomerProfileData?customerCode=${data}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // body: JSON.stringify(data),
            id: data,
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const getTransactionEnquiry = async (token, data) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `transactionEnquiry?id=${data}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // body: JSON.stringify(data),
            id: data,
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};
export const getmerchantschema = async (token, data) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `merchantschema?id=${data}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // body: JSON.stringify(data),
            id: data,
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const contactus = async (token, data) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "contactus", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};

export const growthprospect = async (id) => {
    try {
        const fetchResponse = await fetch(baseURL + `growthprospect?id=${id}`, {
            method: "POST",
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};

export const totalspend = async (id) => {
    try {
        const fetchResponse = await fetch(baseURL + `totalspend?id=${id}`, {
            method: "POST",
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};

export const getVoucherTemplate = async (token) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getVoucherTemplate", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};

export const RedeemVoucher = async (token, body) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "redeemvoucher", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const getVoucherEnquiry = async (token, data) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `voucherTemplateView?code=${data}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // body: JSON.stringify(data),
            code: data,
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const createVoucherTemplate = async (token, body) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "addVoucherTemplate", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};
export const updateVoucherTemplate = async (token, code, body) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `updateVoucherTemplate?code=${code}`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};
export const updateTheme = async (token, themeid, body) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `UpdateTheme?themeid=${themeid}`, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const uploadVoucher = async (file, token, templateCode) => {
    const formData = new FormData();
    formData.append("file", file, "file.csv");
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(`${baseURL}voucherUpload?templateCode=${templateCode}`, {
            method: "POST",
            body: formData,
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
            },
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const getVoucherTemplateByMerchantCode = async (token) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getVoucherTemplateByMerchant", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};

export const getVoucherTemplateStatusByCode = async (token, body) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getStatusRedeemVoucher", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const getMerchantWallet = async (token, id) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getMerchantWallet?id=" + id, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};

export const getWalletSummary = async (token, id) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getWalletSummary?id=" + id, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};
export const getBusinessGoalReport = async (token, data) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                // console.log(res)
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `businessGoalReport?industry=${data}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // body: JSON.stringify(data),
            id: data,
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const getCampaignCodeReport = async (token, data) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                // console.log(res)
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `campaignCodeReport?code=${data}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // body: JSON.stringify(data),
            id: data,
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const getRFMReport = async (token) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                // console.log(res)
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getReport", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // body: JSON.stringify(data),
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const createCustomer = async (token, body) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "creteCustomer", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};
export const updateCustomer = async (token, body) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "updateCustomerGroup", {
            method: "PATCH",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const getPerformanceReport = async (token, id, campaignCode, fromdate, todate) => {
    const valid = await isTokenExpired(token);

    try {
        if (!valid) {
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        }

        let apiUrl = baseURL + "getPerformanceReportByMerchant?id=" + id;
        if (campaignCode) {
            apiUrl += "&campaignCode=" + campaignCode;
        }
        if (fromdate && todate) {
            apiUrl += `&fromdate=${fromdate}&todate=${todate}`;
        }
        if (campaignCode && fromdate && todate.length == 1) {
            apiUrl += `&campaignCode=${campaignCode}&fromdate=${fromdate}&todate=${todate}`;
        }

        const fetchResponse = await fetch(apiUrl, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });

        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const getCustomerGroupDataBySelectValue = async (token, merchantCode, customerGroupValue) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                // console.log(res)
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `getIsCustomerGroupData?merchantCode=${merchantCode}&customerGroupValue=${customerGroupValue}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // body: JSON.stringify(data),
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const getVouchersWallet = async (token, id) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getVoucherWallet?merchantCode=" + id, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};

export const UpdateCustomerPortalData = async (token, isReciptUpload, inStoreRedeption, displayFirst, internal, external, isPointImg) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `updateCustomerPortal?isReciptUpload=${isReciptUpload}&inStoreRedeption=${inStoreRedeption}&displayFirst=${displayFirst}&internal=${internal}&external=${external}&isPointImg=${isPointImg}`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const merchantNotification = async (token, body, isAdmin) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `merchantNotificationAlert?isAdmin=${isAdmin}`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const getMerchantNotificationAlert = async (token) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getMerchantNotification", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};
