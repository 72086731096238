import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

const BarChartAnalyticalReport1 = ({ data }) => {
    const [Data, setData] = useState({
        labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        datasets: [
            {
                label: "Transaction Count ",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: true,
                tension: 0.4,
                backgroundColor: "#9A00C0",
                //backgroundColor: "#0070BB",
                // borderColor: "#9A00C0",
                // borderWidth: 2,
                // pointStyle: "circle",
                // pointRadius: 8,
                // pointBorderWidth: 2,
                // pointBorderColor: "#9A00C0",
                // pointBackgroundColor: "#ffffff",
                // pointHoverRadius: 8,
                // pointHoverBorderWidth: 2,
                // pointHoverBackgroundColor: "#ffffff",
            },
        ],
    });

    useEffect(() => {
        setData({ ...Data, datasets: [{ ...Data.datasets[0], data: data }] });
    }, [data]);

    const options = {
        indexAxis: "x",
        element: {
            legend: {
                position: "top",
            },
        },
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <>
            <div className="analyticalReportChartAnalysisOne__barChartReport">
                <Bar data={Data} options={options} />
            </div>
        </>
    );
};

export default BarChartAnalyticalReport1;
