import React from "react";
import { Navigate } from "react-router-dom";

export const AdminProtectedRoute = ({ children }) => {
    const temp = localStorage.getItem("saasemail");

    if (!temp || temp === null || undefined) {
        return <Navigate to="/saas-admin" />;
    }

    return <>{children}</>;
};

export default AdminProtectedRoute;
