import React from "react";

export const GlobalFilter = ({ filter, debouncedResults }) => {
    return (
        <div className="customTable__searchFilter">
            <label>Search:</label>
            <input className="form-control"maxLength="40" type="text" value={filter} onChange={debouncedResults} />
        </div>
    );
};
