import React, { useEffect, useRef, useState } from "react";
import { decrypt } from "../../utils/encodingdecoding";
import Header from "../layout/Header";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../partial/TextValidator";
import axios from "../../utils/axios";
import { contactus } from "../pages/campaigns/services";
import swal from "sweetalert";
import { createToken } from "../../actions/auth";
import { Link, useNavigate } from "react-router-dom";
import TextAreaValidator from "../partial/TextAreaValidator";
import ReCAPTCHA from "react-google-recaptcha";
import { backIcon2 } from "../../Image/Index";
import { connect } from "react-redux";
import { getProfile, clearSetting } from "../../actions/setting";

const ContactUs = ({ getProfile, clearSetting, Setting }) => {
    let navigate = useNavigate();
    const recaptchaRef = useRef();
    const [data, setData] = useState();
    const [geoInfo, setGeoInfo] = useState();
    const [recaptchaFilled, setRecaptchaFilled] = useState(false);
    const [formData, setFormData] = useState({
        contactNumber: "",
        projectdetails: "",
        name: "",
        email: "",
        ip: "",
        country: "",
    });
    const [loader, setLoader] = useState(false);
    const { name, email, contactNumber, projectdetails } = formData;
    const [recaptchaValueData,setRecaptchaValueData] = useState()

    useEffect(() => {
        const validateEmail = (email) => {
            return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/); // eslint-disable-line
        };
        ValidatorForm.addValidationRule("validEmail", (value) => {
            if (validateEmail(value)) {
                return true;
            }
            return false;
        });
    }, [email]);

    // useEffect(() => {
    //     fetchData();
    // }, []);

    // const fetchData = async () => {
    //     const decryptedName = Setting.name;

    //     const decryptedEmail = Setting.email;
    //     const decryptedContact = Setting.contactnumber;
    //     setFormData({ ...formData, name: decryptedName, email: decryptedEmail ,contactNumber:decryptedContact});
    // };

    const fetch = async () => {
        setLoader(true);
        await clearSetting();
        let body = {
            email: localStorage.getItem("email"),
            merchcode: localStorage.getItem("merchantcode"),
        };
        let token = await JSON.parse(localStorage.getItem("token_gen"));
        const temp = localStorage.getItem("token_rare");
        if (!temp) {
            setLoader(false);
        } else {
            await getProfile(token?.access_token, body);
            setLoader(false);
        }
    };

    useEffect(() => {
        fetch();
    }, []);
    useEffect(() => {
        const decryptedName = Setting.name;
        const decryptedEmail = Setting.email;
        const decryptedContact = Setting.contactnumber;
        setFormData({ ...formData, name: decryptedName, email: decryptedEmail, contactNumber: decryptedContact });
    }, [Setting]);

    const temp = localStorage.getItem("token_rare");

    useEffect(() => {
        getGeoInfo();
    }, []);

    const getGeoInfo = () => {
        axios
            .get("https://ipapi.co/json/")
            .then((response) => {
                let data = response.data;
                setGeoInfo(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        setFormData({ ...formData, ip: geoInfo?.ip, country: geoInfo?.country_name });
    }, [geoInfo]);

    const onChange = (e) => {
        setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });
    };

    const fetchToken = async () => {
        let response = await createToken();

        setData(response.token);
    };
    useEffect(() => {
        fetchToken();
    }, []);
    const onSubmit = async () => {
        let tempToken = data;
        if (temp) {
            setLoader(true);
            let response = await contactus(tempToken, formData);
            setLoader(false);
            if (response?.message === "Details Submitted Sucessfully") {
                swal({
                    position: "center",
                    icon: "success",
                    title: response?.message,
                    showConfirmButton: false,
                    timer: 5000,
                });
                setFormData({ ...formData, contactNumber: "", projectdetails: "" });
            } else {
                swal({
                    position: "center",
                    icon: "error",
                    title: response?.message,
                    showConfirmButton: false,
                    timer: 5000,
                });
            }
        } else {
  
  
            if (!recaptchaFilled) {
                setRecaptchaValueData("Please confirm you're not a robot.")
           
            }
    
            if(recaptchaFilled) {
                setLoader(true);
                let response = await contactus(tempToken, formData);
                setLoader(false);
                if (response?.message === "Details Submitted Sucessfully") {
                    swal({
                        position: "center",
                        icon: "success",
                        title: response?.message,
                        showConfirmButton: false,
                        timer: 5000,
                    });
                    setFormData({ ...formData, contactNumber: "", projectdetails: "" });
                } else {
                    swal({
                        position: "center",
                        icon: "error",
                        title: response?.message,
                        showConfirmButton: false,
                        timer: 5000,
                    });
                }
            }
        }
    };
    const onRecaptchaChange = (value) => {
        setRecaptchaValueData(null);
        if (value) {
            setRecaptchaFilled(true);
        }
    };
  

    const recaptchastyle = {
        display: "inline-block",
        marginBottom: "20px",
    };
    useEffect(() => {
        ValidatorForm.addValidationRule("minimumlength", (value) => {
            if (value.length > 0 && value.length < 50) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule("maximumlength", (value) => {
            if (value.length > 500) {
                return false;
            }
            return true;
        });
    }, [projectdetails]);
    function validateCharactersOnly(event) {
        const input = event.target.value;
        const regex = /^[A-Za-z\s]*$/;

        if (!regex.test(input)) {
            event.target.value = input.replace(/[^A-Za-z\s]/g, "");
        }
    }

    function validateNumbersOnly(event) {
        const input = event.target.value;
        const regex = /^[0-9]*$/;

        if (!regex.test(input)) {
            event.target.value = input.replace(/[^0-9]/g, "");
        }
    }

    const tokenRare = localStorage.getItem("token_rare");

    useEffect(() => {
        const validateName = (name) => {
             return /^[A-Za-z\s&,$,%,*,@,!,.,,,]*$/.test(name);
        };
        ValidatorForm.addValidationRule("validNameData", (value) => {
            if (validateName(value)) {
                return true;
            }
            return false;
        });
    }, [projectdetails]);
    return (
        <>
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <Header name={"Contact Us"} />
            <div className="dashboard__content">
                <div className="container-fluid">
                    {temp && (
                        <div className="dashboard__heading" style={{ marginBottom: "0rem" }}>
                            <div className="dashboard__headingLeft">
                                <div className="dashboard__headingBackLink">
                                    <Link to="" onClick={() => navigate(-1)}>
                                        <img src={backIcon2} alt="" />
                                        <div className="dashboard__headingContent">
                                            <h3>Back</h3>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}
                    {temp ? (
                        <div className="bookademopopupbox" style={{ paddingTop: "0px" }}>
                            <div className="bookademo_formbox">
                                <ValidatorForm className="signUpPage__formSection" onSubmit={onSubmit}>
                                    <TextValidator className="form-control" placeholder="Name" onInput={validateCharactersOnly} name="name" value={name} maxLength="50" onChange={onChange} validators={["required"]} errorMessages={["Name is required."]} />
                                    <TextValidator className="form-control" placeholder="Email" name="email" value={email} maxLength="50" onChange={onChange} validators={["required", "validEmail"]} errorMessages={["Email address is required.", "Please Enter valid Email"]} />

                                    <TextValidator className="form-control" onInput={validateNumbersOnly} placeholder="Contact Number" name="contactNumber" value={contactNumber} onChange={onChange} validators={["required", "matchRegexp:^(?!\\d*(\\d)\\1{7,}|0123456789|1234567890)\\d{10,15}$"]} errorMessages={["Please enter your contact number", "Please enter a valid number"]} />

                                    <TextAreaValidator placeholder="Description" className="form-control" name="projectdetails" value={projectdetails} onChange={onChange} validators={["required", "minimumlength", "maximumlength","validNameData"]} errorMessages={["Please enter details about your project", "Minimum length should be 50 character", "Maximum length should be 500 character",,"Enter valid description",]} />
                                    <button type="submit" className="">
                                        Submit
                                    </button>
                                </ValidatorForm>

                                {!temp && (
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <p className="text-end">
                                                <Link to="/signin">Sign In</Link>
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="bookademopopupbox">
                            <div className="bookademo_formbox">
                                <ValidatorForm className="signUpPage__formSection" onSubmit={onSubmit}>
                                    <TextValidator className="form-control" placeholder="Name" onInput={validateCharactersOnly} name="name" value={name} maxLength="50" onChange={onChange} validators={["required"]} errorMessages={["Name is required."]} />
                                    <TextValidator className="form-control" placeholder="Email" name="email" value={email} maxLength="50" onChange={onChange} validators={["required", "validEmail"]} errorMessages={["Email address is required.", "Please Enter valid Email"]} />

                                    <TextValidator className="form-control" onInput={validateNumbersOnly} placeholder="Contact Number" name="contactNumber" value={contactNumber} onChange={onChange} validators={["required", "matchRegexp:^(?!\\d*(\\d)\\1{7,}|0123456789|1234567890)\\d{10,15}$"]} errorMessages={["Please enter your contact number", "Please enter a valid number"]} />

                                    <TextAreaValidator placeholder="Description" className="form-control" name="projectdetails" value={projectdetails} onChange={onChange} validators={["required", "minimumlength", "maximumlength","validNameData"]} errorMessages={["Please enter details about your project", "Minimum length should be 50 character", "Maximum length should be 500 character","Enter valid description",]} />
                                    <ReCAPTCHA
                                        style={recaptchastyle}
                                        theme="light"
                                        ref={recaptchaRef}
                                        sitekey={process.env.REACT_APP_GOOGLE_KEY}
                                        onChange={onRecaptchaChange}
                                    />
                                      {recaptchaValueData && <div style={{ color: "red",marginBottom:"10px",marginTop:"-14px"}}>
                                        {recaptchaValueData}</div>}
                        
                                    <button type="submit" className="">
                                        Submit
                                    </button>
                                </ValidatorForm>

                                {/* {!temp && (
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <p className="text-end">
                                                <Link to="/signin">Sign In</Link>
                                            </p>
                                        </div>
                                    </div>
                                )} */}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => ({
    Setting: state.Setting,
});

const mapDispatchToProps = {
    getProfile,
    clearSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
