import React, { useEffect, useState } from 'react'
import Header from '../../layout/Header';
import { Modal, Button, CloseButton } from "react-bootstrap";
import swal from 'sweetalert';
import { GetThemeData, PostSelectedData, PostThemeData, deleteThemeData } from '../../../actions/auth';
import { Link } from 'react-router-dom';
import { updateTheme } from '../campaigns/services';
import _ from 'lodash';

function Theme() {
    const [loader, setLoader] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const handlePreview = () => setPreview(false);
    const [preview, setPreview] = useState(false);
    const [dataPreview ,setDataPreview] = useState()
    const [themeId,setThemeId] = useState()
    const [dataTheme, setDataTheme] = useState()
    const [newTheme, setNewTheme] = useState({});
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const initialState = {
        name: "",
        colors: {
            bgColor: "#fafafa",
            text: "#000000",
            footer: "#000000",
            header: {
                text: "#000000",
                background: "#e3f2fd",
            },
            overviewData: {
                text: "#FFFFFF",
                background: "#7F8C8D",
            },
            overviewCampaign: {
                text: "#FFFFFF",
                background: "#7F8C8D",
            },
            button: {
                text: "#FFFFFF",
                background: "#000000",
            },
            div: {
                text: "#FFFFFF",
                background: "#7F8C8D",
            },
            link: {
                text: "#FFFFFF",
                background: "#2056a5",
            }
        },
        font: "Roboto"
    };
    const [editTest, setEditTest] = useState(true)
    const [state, setState] = useState(initialState);

    const handleCampaigns = () => {
        setShow(true);
        setEditTest(true)
        setState(initialState)
    };
    const getThemeObj = () => {
        const themeObj = {};
        themeObj[_.camelCase(state.name)] = {
            "name": state?.name,
            "colors": {
                "bgColor": state?.colors?.bgColor,
                "text": state?.colors?.text,
                "footer": state?.colors?.footerbgColor,
                "header": {
                    "text": state?.colors?.header?.text,
                    "background": state?.colors?.header?.background,
                },
                "overviewData": {
                    "text": state?.colors?.overviewData?.text,
                    "background": state?.colors?.overviewData?.background,
                },
                "overviewCampaign": {
                    "text": state?.colors?.overviewCampaign?.text,
                    "background": state?.colors?.overviewCampaign?.background,
                },
                "button": {
                    "text": state?.colors?.button?.text,
                    "background": state?.colors?.button?.background,
                },
                "div": {
                    "text": state?.colors?.div?.text,
                    "background": state?.colors?.div?.background,
                },
                "link": {
                    "text":state?.colors?.link?.text,
                    "background": state?.colors?.link?.background,
                }
            },
            "font": "Roboto"
        };
        const body = themeObj[_.camelCase(state.name)];
        return { themeName: state.name, body };
    }
    useEffect(() => {
        const updated = getThemeObj();
        setNewTheme({ ...updated });
    }, [state]);
    const handleChange = (e, colorType) => {
        if (colorType === 'name') {
            setState(prevState => ({
                ...prevState,

                name: e.target.value

            }));
        }
        else if (colorType === 'bgColor') {
            setState(prevState => ({
                ...prevState,
                colors: {
                    ...prevState.colors,
                    bgColor: e.target.value
                }
            }));
        } else if (colorType === 'textColor') {
            setState(prevState => ({
                ...prevState,
                colors: {
                    ...prevState.colors,
                    text: e.target.value
                }
            }));
        }
        else if (colorType === 'headerColor') {
            setState(prevState => ({
                ...prevState,
                colors: {
                    ...prevState.colors,
                    header: {
                        ...prevState.colors.header,
                        background: e.target.value
                    }
                }
            }));
        }
        else if (colorType === 'headertextColor') {
            setState(prevState => ({
                ...prevState,
                colors: {
                    ...prevState.colors,
                    header: {
                        ...prevState.colors.header,
                        text: e.target.value
                    }
                }
            }));
        }
        else if (colorType === 'overviewBgColor') {
            setState(prevState => ({
                ...prevState,
                colors: {
                    ...prevState.colors,
                    overviewData: {
                        ...prevState.colors.overviewData,
                        background: e.target.value
                    }
                }
            }));
        }
        else if (colorType === 'overviewtextColor') {
            setState(prevState => ({
                ...prevState,
                colors: {
                    ...prevState.colors,
                    overviewData: {
                        ...prevState.colors.overviewData,
                        text: e.target.value
                    }
                }
            }));
        }
         else if (colorType === 'overviewDataBgColor') {
            setState(prevState => ({
                ...prevState,
                colors: {
                    ...prevState.colors,
                    overviewCampaign: {
                        ...prevState.colors.overviewCampaign,
                        background: e.target.value
                    }
                }
            }));
        }
        else if (colorType === 'overviewDatatextColor') {
            setState(prevState => ({
                ...prevState,
                colors: {
                    ...prevState.colors,
                    overviewCampaign: {
                        ...prevState.colors.overviewCampaign,
                        text: e.target.value
                    }
                }
            }));
        }
        else if (colorType === 'buttonBgColor') {
            setState(prevState => ({
                ...prevState,
                colors: {
                    ...prevState.colors,
                    button: {
                        ...prevState.colors.button,
                        background: e.target.value
                    }
                }
            }));
        }
        else if (colorType === 'buttontextColor') {
            setState(prevState => ({
                ...prevState,
                colors: {
                    ...prevState.colors,
                    button: {
                        ...prevState.colors.button,
                        text: e.target.value
                    }
                }
            }));
        }
        else if (colorType === 'linkBgColor') {
            setState(prevState => ({
                ...prevState,
                colors: {
                    ...prevState.colors,
                    link: {
                        ...prevState.colors.link,
                        background: e.target.value
                    }
                }
            }));
        }
        else if (colorType === 'linktextColor') {
            setState(prevState => ({
                ...prevState,
                colors: {
                    ...prevState.colors,
                    link: {
                        ...prevState.colors.link,
                        text: e.target.value
                    }
                }
            }));
        }
        else if (colorType === 'divBgColor') {
            setState(prevState => ({
                ...prevState,
                colors: {
                    ...prevState.colors,
                    div: {
                        ...prevState.colors.div,
                        background: e.target.value
                    }
                }
            }));
        }
        else if (colorType === 'divtextColor') {
            setState(prevState => ({
                ...prevState,
                colors: {
                    ...prevState.colors,
                    div: {
                        ...prevState.colors.div,
                        text: e.target.value
                    }
                }
            }));
        }
        else if (colorType === 'footerColor') {
            setState(prevState => ({
                ...prevState,
                colors: {
                    ...prevState.colors,
                    footer: e.target.value
                }
            }));
        }
        


    };


    const createTheme = () => {
        setLoader(true)
        if (editTest === true) {
            if (dataTheme === undefined || dataTheme?.length < 5) {
                let tempToken = JSON.parse(localStorage.getItem("token_gen"));
                tempToken = tempToken?.access_token;
                PostThemeData(tempToken, newTheme).then((res) => {
                    if (res.message === "success") {
                        swal({
                            position: "center",
                            icon: "success",
                            title: "Theme Created Successfully",
                            showConfirmButton: false,
                            timer: 2000,
                        });
                        setState(initialState)
                        setShow(false);
                        setLoader(false)
                        setEditTest(true)
                        fetch()
                            .then((data) => {
                                setDataTheme(res.data.themesData)
                                setSelectedItem(res.data.selectedTheme)
                                setLoader(false);
                            })
                            .catch((error) => {
                            });
                    } else {
                        swal({
                            position: "center",
                            icon: "error",
                            title: res.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                        setLoader(false)
                    }
                });
            }
            else {
                swal({
                    position: "center",
                    icon: "error",
                    title: "Only five theme are allowed",
                    showConfirmButton: false,
                    timer: 2000,
                })
                setLoader(false)
            }
    }
    else {
        let tempToken = JSON.parse(localStorage.getItem("token_gen"));
        tempToken = tempToken?.access_token;
        const response = updateTheme(tempToken,themeId, state).then((res) => {
            if (res.message === "success") {
                swal({
                    position: "center",
                    icon: "success",
                    title: "Theme Update Successfully",
                    showConfirmButton: false,
                    timer: 2000,
                });
                setState(initialState)
                setShow(false);
                setEditTest(true)
                setLoader(false);
                fetch()
                    .then((data) => {
                        console.log(res)
                        setDataTheme(res.data.themesData)
                        setSelectedItem(res.data.selectedTheme)
                       
                    })
                    .catch((error) => {
                    });
               
            } else {
                swal({
                    position: "center",
                    icon: "error",
                    title: res.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                setLoader(false)
            }
        });
    }
    }


    const fetch = async () => {
        setLoader(true);
        let tempToken = JSON.parse(localStorage.getItem("token_gen"));
        tempToken = tempToken?.access_token;
        try {
            const res = await GetThemeData(tempToken);
            setDataTheme(res.data.themesData)
            setSelectedItem(res.data.selectedTheme)
            setEditTest(true)

        } catch (error) {
        }
        setLoader(false);
    };

    useEffect(() => {
        fetch();
    }, []);

    const handleDelete = async (item) => {
        setLoader(true);
        let token = JSON.parse(localStorage.getItem("token_gen"));
        deleteThemeData(token?.access_token, item.id)
            .then((Setting) => {
                swal({
                    position: "center",
                    icon: "success",
                    title: "Theme Deleted Successfully",
                    showConfirmButton: false,
                    timer: 10000,
                });
                setEditTest(true)
                fetch()
                    .then((data) => {
                        setDataTheme(data.themesData)
                        setSelectedItem(data.selectedTheme)
                    })
                    .catch((error) => {
                    });
                setLoader(false);
            })
            .catch((err) => {
                swal({
                    position: "center",
                    icon: "error",
                    title: err.message,
                    showConfirmButton: false,
                    timer: 10000,
                });
                setLoader(false);
            });
    };
const handlePreviewData  = (item) => {
    console.log(item)
    setPreview(true)
    setDataPreview(item)
}
    const handleEdit = (item) => {
        console.log(item)
        setThemeId(item.id)
        setShow(true)
        setEditTest(false)
        setState({
            name: item?.body?.name,
            colors: {
                bgColor: item?.body?.colors?.bgColor,
                text: item?.body?.colors?.text,
                footer: item?.body?.colors?.footer,
                header: {
                    text: item?.body?.colors?.header?.text,
                    background: item?.body?.colors?.header?.background,
                },
                overviewData: {
                    text: item?.body?.colors?.overviewData?.text,
                    background: item?.body?.colors?.overviewData?.background,
                },
                overviewCampaign: {
                    text: item?.body?.colors?.overviewCampaign?.text,
                    background: item?.body?.colors?.overviewCampaign?.background,
                },
                button: {
                    text: item?.body?.colors?.button?.text,
                    background: item?.body?.colors?.button?.background,
                },
                div: {
                    text: item?.body?.colors?.div?.text,
                    background: item?.body?.colors?.div?.background,
                },
                link: {
                    text: item?.body?.colors?.link?.text,

                    background: item?.body?.colors?.link?.background,
                }
            },
            font: "Roboto"
        })

      
    }
    const themeSwitcher = (item) => {
        setLoader(true);
        let tempToken = JSON.parse(localStorage.getItem("token_gen"));
        tempToken = tempToken?.access_token;
        PostSelectedData(tempToken, item.id).then((res) => {
            if (res.message === "success") {
                swal({
                    position: "center",
                    icon: "success",
                    title: "Theme Activated Successfully",
                    showConfirmButton: false,
                    timer: 5000,
                });
                setEditTest(true)
                fetch()
                    .then((data) => {
                        setDataTheme(data.themesData)
                        setSelectedItem(data.selectedTheme)
                    })
                    .catch((error) => {
                    });
                setLoader(false);
            } else {
                swal({
                    position: "center",
                    icon: "error",
                    title: res.message,
                    showConfirmButton: false,
                    timer: 5000,
                });
            }
            setLoader(false)
        });
    };

    console.log(state)
    return (
        <>
            <Header name={"Theme"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>
                        <div className="dashboard__heading">
                            <div className="dashboard__headingLeft">
                                <div className="dashboard__headingContent">
                                    <h3>Select Theme for Customer Portal</h3>
                                    <button className="btn btn-primary" onClick={handleCampaigns} >Create a Theme</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        {dataTheme?.length >= 1 ?
                            dataTheme.map((item, index) => (
                                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4" key={index}>
                                    {/* <ThemeCard selected={selectedItem} data={item} /> */}
                                    {/* <div className=dashboard__programsCard activeCard"> */}
                                    <div className={selectedItem === item?.id ? "dashboard__programsCard activeCard":"dashboard__programsCard"}>
                                        <div >

                                            <div className='d-flex justify-content-between'>

                                            
                                            <h5>
                                                {item?.body?.name}
                                            </h5>

                                            <div className='actionBtnRow' > <Link onClick={() => handlePreviewData(item)}><i className="fa-solid fa-eye" title="preview" style={{ fontSize: "20px" }}></i></Link><Link onClick={() => handleEdit(item)}><i className="fa fa-edit" title="Edit" style={{ fontSize: "20px",marginLeft:"10px" }}></i></Link>   <Link onClick={() => handleDelete(item)}><i className="fa fa-trash" title="delete" style={{ fontSize: "20px",marginLeft:"5px" }}></i></Link></div>
                                              

                                            </div>


                                            {selectedItem === item?.id ?
                                                <p>Theme Activated for Customer portal</p>
                                                :
                                                <p>Activate Theme</p>
                                            }
                                        </div>
                                        {/* <div style={{ backgroundColor: item?.body?.colors?.div?.background, color: item?.body?.colors?.div?.text, height: "30px" }}>
                                            <p style={{ padding: "5px" }}>Div Color</p>
                                        </div> */}
                                        {/* <div className='row' style={{ marginTop: "10px" }}>
                                            <div className='col-md-8'>
                                                <button className="btn" style={{ backgroundColor: item?.body?.colors?.button?.background, color: item?.body?.colors?.button?.text, }} >
                                                    Button Color
                                                </button> {'  '}
                                            </div>
                                            <div className='col-md-4'>
                                                <button className="btn" style={{ backgroundColor: item?.body?.colors?.link?.background, color: item?.body?.colors?.link?.text }}>
                                                    <Link to="" style={{ color: item?.body?.colors?.link?.text }}>Link Color</Link>
                                                </button>
                                            </div>
                                        </div> */}
                                        {/* <div style={{ marginTop: "10px", backgroundColor: item?.body?.colors?.footer, color: item?.body?.colors?.div?.text, height: "30px" }}>
                                            <p style={{ padding: "5px" }}>Footer Color</p>
                                        </div> */}
                                        <button className='themeData' onClick={() => { themeSwitcher(item) }}> {selectedItem === item?.id ? "Activated" : "Activate"}</button>
                                    </div>
                                </div>
                            )) : (
                                <h4>There is no theme to show</h4>
                            )}
                    </div>

                </div>
            </div>
            <Modal show={show} id="showm" onHide={handleClose} size="lg" centered keyboard={false}>
            <Modal.Header> {editTest === true ?
                        <Modal.Title style={{ fontSize: "20px" }}>Create New Theme</Modal.Title> :
                        <Modal.Title style={{ fontSize: "20px" }}>Update Theme</Modal.Title>
                    }

                    <CloseButton onClick={handleClose} variant="white" />
                </Modal.Header>
                <Modal.Body>
                    <div className=''>
                        <div className="">
                            <div className='row theme-selection'>
                                <div className='col-md-6 color-align'>
                                    <label htmlFor="th_name">Theme Name:</label> {' '}
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={state.name}
                                        placeholder="Specify a name"
                                        onChange={(e) => handleChange(e, 'name')} />
                                </div>
                            </div>
                            <div className='row theme-selection'>
                                <div className='col-md-6 color-align'>
                                    <label htmlFor="bg_color">Background Color:</label> {' '}
                                    <input
                                        type="color"
                                        value={state.colors.bgColor}
                                        onChange={(e) => handleChange(e, 'bgColor')}
                                    />

                                </div>
                                <div className=' col-md-6 color-align'>
                                    <label htmlFor="txt_color">Text Color:</label> {' '}
                                    <input
                                        type="color"
                                        value={state.colors.text}
                                        onChange={(e) => handleChange(e, 'textColor')}
                                    />
                                </div>
                            </div>
                            <div className='row theme-selection'>
                                <div className='col-md-6 color-align'>
                                    <label htmlFor="header_bg_color">Header Background Color:</label> {' '}
                                    <input
                                        type="color"
                                        value={state?.colors?.header?.background}
                                        onChange={(e) => handleChange(e, 'headerColor')}
                                    />
                                </div>
                                <div className='col-md-6 color-align'>
                                    <label htmlFor="header_txt_color">Text Color:</label> {' '}
                                    <input
                                        type="color"
                                        value={state?.colors?.header?.text}
                                        onChange={(e) => handleChange(e, 'headertextColor')}
                                    />
                                </div>
                            </div>
                            <div className='row theme-selection'>
                                <div className='col-md-6 color-align'>
                                    <label htmlFor="btn_bg_color">Button Background Color:</label> {' '}
                                    <input
                                        type="color"
                                        value={state?.colors?.button?.background}
                                        onChange={(e) => handleChange(e, 'buttonBgColor')}
                                    />
                                </div>
                                <div className='col-md-6 color-align'>
                                    <label htmlFor="btn_txt_color">Text Color:</label> {' '}

                                    <input
                                        type="color"
                                        value={state?.colors?.button?.text}
                                        onChange={(e) => handleChange(e, 'buttontextColor')}
                                    />
                                </div>
                            </div>
                            <div className='row theme-selection'>
                                <div className='col-md-6 color-align'>
                                    <label htmlFor="btn_bg_color">Link Background Color:</label> {' '}
                                    <input
                                        type="color"
                                        value={state?.colors?.link?.background}
                                        onChange={(e) => handleChange(e, 'linkBgColor')}
                                    />
                                </div>
                                <div className='col-md-6 color-align'>
                                    <label htmlFor="link_color">Text Color:</label> {' '}
                                    <input
                                        type="color"
                                        value={state?.colors?.link?.text}
                                        onChange={(e) => handleChange(e, 'linktextColor')}
                                    />
                                </div>
                            </div>
                            <div className='row theme-selection'>
                                <div className='col-md-6 color-align'>
                                    <label htmlFor="div_bg_color">Div Background Color:</label> {' '}
                                    <input
                                        type="color"
                                        value={state?.colors?.div?.background}
                                        onChange={(e) => handleChange(e, 'divBgColor')}
                                    />
                                </div>
                                <div className='col-md-6 color-align'>
                                    <label htmlFor="div_txt_color">Text Color:</label> {' '}
                                    <input
                                        type="color"
                                        value={state?.colors?.div?.text}
                                        onChange={(e) => handleChange(e, 'divtextColor')}
                                    />
                                </div>
                            </div>
                            <div className='row theme-selection'>
                                <div className='col-md-6 color-align'>
                                    <label htmlFor="data_bg_color">Overview data Background Color:</label> {' '}
                                    <input
                                        type="color"
                                        value={state.colors.overviewData?.background}
                                        onChange={(e) => handleChange(e, 'overviewBgColor')}
                                    />
                                </div>
                                <div className='col-md-6 color-align'>
                                    <label htmlFor="data_txt_color">Text Color:</label> {' '}
                                    <input
                                        type="color"
                                        value={state.colors.overviewData?.text}
                                        onChange={(e) => handleChange(e, 'overviewtextColor')}
                                    />
                                </div>
                            </div>
                            <div className='row theme-selection'>
                                <div className='col-md-6 color-align'>
                                    <label htmlFor="campaign_bg_color">Overview Campaign Background Color:</label> {' '}
                                    <input
                                        type="color"
                                        value={state.colors.overviewCampaign?.background}
                                        onChange={(e) => handleChange(e, 'overviewDataBgColor')}
                                    />
                                </div>
                                <div className='col-md-6 color-align'>
                                    <label htmlFor="campaign_txt_color">Text Color:</label> {' '}
                                    <input
                                        type="color"
                                        value={state?.colors?.overviewCampaign?.text}
                                        onChange={(e) => handleChange(e, 'overViewDatatextColor')}
                                    />
                                </div>
                            </div>
                            <div className='row theme-selection'>
                                <div className='col-md-6 color-align'>
                                    <label htmlFor="footer_color">Footer Color:</label> {' '}
                                    <input
                                        type="color"
                                        value={state?.colors?.footer}
                                        onChange={(e) => handleChange(e, 'footerColor')}
                                    />
                                </div>
                            </div>
                            <div>
                                    {editTest === true ?
                                        <button className='btn btn-primary'
                                            style={{ float: 'right' }}
                                            onClick={createTheme}
                                            disabled={state.name.trim().length === 0}>
                                            Create Theme
                                        </button> :
                                        <button className='btn btn-primary'
                                            style={{ float: 'right' }}
                                            onClick={createTheme}
                                            disabled={state.name.trim().length === 0}>
                                            Update Theme
                                        </button>}

                                </div>
                            <div>


                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={preview} id="showm" onHide={handlePreview} size="lg" centered keyboard={false}>
                    <Modal.Header> 
                        <Modal.Title style={{ fontSize: "20px" }}>Preview</Modal.Title> 
                        
                   
                        <CloseButton onClick={handlePreview} variant="white" />
                    </Modal.Header>
                    <Modal.Body>
                        <div className=''>
                           
                            <div className="themeData">
                                <span><b>{dataPreview?.themeName}</b></span>
                                <div className='preview' style={{ backgroundColor: dataPreview?.body?.colors?.bgColor, color: dataPreview?.body?.colors?.text, fontFamily: dataPreview?.body?.font }}>
                                    <p>
                                        This is for preview only. 
                                    </p>
                                    <div style={{ backgroundColor: dataPreview?.body?.colors?.header?.background, color: dataPreview?.body?.colors?.header?.text, fontFamily: dataPreview?.body?.font }}>
                                        <p style={{ padding: "5px" }}>header Color</p>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-8'>
                                            <button className="btn" style={{ backgroundColor: dataPreview?.body?.colors?.button?.background, color: dataPreview?.body?.colors?.button?.text, fontFamily: dataPreview?.body?.font}}>
                                               Button Color
                                            </button> {'  '}
                                        </div>
                                        <div className='col-md-4'>
                                            <button className="btn" style={{ backgroundColor: dataPreview?.body?.colors?.link?.background, color: dataPreview?.body?.colors?.link?.text, fontFamily: dataPreview?.body?.font}}>
                                                <a href="#" style={{ color:dataPreview?.body?.colors?.link?.text, fontFamily: dataPreview?.body?.font }}>Link Color</a>
                                            </button>
                                        </div>
                                    </div>
                                    <div style={{ backgroundColor: dataPreview?.body?.colors?.div?.background, color: dataPreview?.body?.colors?.link?.text, fontFamily: dataPreview?.body?.font, height: "30px" }}>
                                        <p style={{ marginTop: "10px", padding: "5px" }}>Div Color</p>
                                    </div>
                                    <div style={{ backgroundColor: dataPreview?.body?.colors?.footer, color: dataPreview?.body?.colors?.div?.text, fontFamily: dataPreview?.body?.font, height: "30px" }}>
                                        <p style={{ marginTop: "30px", padding: "5px" }}>Footer Color</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handlePreview}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
        </>
    )
}

export default Theme