import { Link } from "react-router-dom";
export const COLUMNS = [
    {
        Header: "Company Name",
        Footer: "Company Name",
        accessor: "uploadedSource",
        sticky: "left",
        // Cell: ({ value }) => {
        // 	return format(new Date(value), "dd/MM/yyyy");
        // },
    },
    {
        Header: "Image Link",
        Footer: "Image Link",
        accessor: "blobFilePath",
        Cell: ({ value }) => (
            <Link to={value}>
                <i className="fa-solid fa-download"></i>
            </Link>
        ),
    },
];
