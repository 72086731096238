import React, { useState, useEffect } from "react";
import Header from "../../layout/Header";
import { getCustomerReview, updateCustomerReview } from "../../../actions/auth";
import Paginated from "./Paginated";
import { COLUMNS } from "./column";
import { getDate } from "../../../utils/helper";
import { connect } from "react-redux";
import { getCustomer, clearCustomer } from "../../../actions/customer";
import { useLocation, useNavigate } from "react-router-dom";
import { transactionRegistration } from "../campaigns/services";
import swal from "sweetalert";
import ModalReview from "./ModalReview";
const CustomerReview = ({ getCustomer, Customer, clearCustomer }) => {
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [cusReview, setCusReview] = useState([]);
    const [cusData, setCusData] = useState([]);
    const [show, setShow] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    const query = useQuery();
    const handleUrlParams = () => {
        const { pathname } = location;
        navigate(pathname, { replace: true });
    };
    
    useEffect(() => {
        const handleUpdate = async (value, status, id, mobile) => {
            setLoader(true);
            let tempToken = JSON?.parse(localStorage.getItem("token_gen"));
            const body = {};
            let tempRemark = cusReview?.filter((ele) => ele.id === id);
            tempRemark = tempRemark[0].remarks;
            const updateRev = await updateCustomerReview(tempToken?.access_token, body, id, tempRemark, status);
            const transBody = {
                activity: "review",
                reviewurl: value,
                mobile: mobile,
                schemacode: "TSC000024",
            };
            const transData = await transactionRegistration(tempToken?.access_token, JSON.stringify(transBody));
            if (transData?.message === "Request processed successfully.") {
                let tempToken = JSON.parse(localStorage.getItem("token_gen"));
                tempToken = tempToken?.access_token;
                const id = localStorage?.getItem("Id");
                const tempReview = await getCustomerReview(tempToken, id);
                if (tempReview?.message === "success") {
                    swal({
                        position: "center",
                        icon: "success",
                        title: "Review update Successfully",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                } else {
                    swal({
                        position: "center",
                        icon: "error",
                        title: tempReview?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }
                setCusReview(tempReview?.data);
            } else {
                swal({
                    position: "center",
                    icon: "error",
                    title: transData?.message,
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
            handleUrlParams();
            setLoader(false);
        };
        if (query.size > 0) {
            if (query.get("remark")) {
                setShow(true);
            } else {
                const value = query.get("value");
                const status = query.get("status");
                const id = query.get("id");
                const mobile = query.get("mobile");
                handleUpdate(value, status, id, mobile);
            }
        }
    }, [location]);

    useEffect(() => {
        const fetch = async () => {
            setLoader(true);
            let tempToken = JSON.parse(localStorage.getItem("token_gen"));
            tempToken = tempToken?.access_token;
            const id = localStorage.getItem("Id");
            const cusReview = getCustomerReview(tempToken, id);
            const cusData = getCustomer(tempToken, id, 1, 10000);
            Promise.all([cusReview, cusData]).then((values) => {
                if (values?.[0]?.message === "success") {
                    setCusReview(values?.[0]?.data);
                }
                setLoader(false);
            });
        };
        fetch();
    }, []);
   
   
    useEffect(() => {
        setCusData(Customer?.data);
    }, [Customer]);
    useEffect(() => {
        if (cusReview?.length > 0) {
            const tempData = cusReview.map((ele) => {
                if (cusData?.data?.data?.length > 0) {
                    const flag = cusData.data.data.find((cus) => cus.customerData.customercode === ele.customercode);
                    if (flag) {
                        const action = { link: ele.link, id: ele.id, mobile: flag.customerData.mobile, status: ele.status, other: ele.other };
                        const createdAt = getDate(ele.createdAt, "MM-DD-YYYY");
                        const remarkId = { id: ele.id, remark: ele.remarks };
                        return {
                            ...ele,
                            customername: flag.customerData.name,
                            action: action,
                            createdAt: createdAt,
                            remarkId: remarkId,
                        };
                    }
                }
                return ele;
            });
            setData(tempData?.length ? tempData.reverse() : []);
        }
    }, [cusData, location, cusReview]);
    const onSubmit = async () => {};
    return (
        <>
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <Header name={"Customer Review"} />
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__customersCard">
                        <div className="dashboard__header">
                            <div className="dashboard__left">
                                <h4>Customer Review Details</h4>
                            </div>
                        </div>
                        <div className="dashboard__body">
                            <Paginated data={data || []} columns={COLUMNS} setData={setData} />
                        </div>
                    </div>
                </div>
                {show && <ModalReview show={show} close={() => setShow(false)} data={cusReview} id={query.get("remark")} setData={setCusReview} handleUrlParams={handleUrlParams} />}
            </div>
        </>
    );
};
const mapStateToProps = (state) => ({
    Customer: state.Customer,
});
const mapDispatchToProps = {
    getCustomer,
    clearCustomer,
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerReview);