import React, { useEffect, useState } from "react";
import { logo } from "../../Image/Index";
import { Link, useNavigate } from "react-router-dom";
import { Internal_Token, signupConfirmation, Token_Generator } from "../../actions/auth";
import AuthenticationSliderLeft from "./common/AuthenticationSliderLeft";
import { decrypt, encrypt } from "../../utils/encodingdecoding";

const VerifyYourEmail = () => {
    const navigate = useNavigate();
    const [verifyEmail, setVerifyEmail] = useState(false);
    const [errorMessages, setErrorMessages] = useState(false);
    const [loader, setLoader] = useState(false);
    const [response, setResponse] = useState({});
    const [message, setMessage] = useState(false);

    useEffect(() => {
        fetch(window.location.href.split("code=")[1] || "3ff19ad8-2308-41f2-b8cd-b6265f7b8224YXLYRVWRGP");
    }, [window.location.href.split("code=")[1]]);

    const fetch = async (id) => {
        setLoader(true);
        const token = await Token_Generator();
        await signupConfirmation(token?.access_token, id).then((res) => {
            if (res?.message === "Valid Login Details") {
                setResponse({});
                setVerifyEmail(true);
                setErrorMessages(false);
                setLoader(false);
                sessionStorage.setItem("token", JSON.stringify(true));
                localStorage.setItem("token_rare", JSON.stringify(true));
                let body = {
                    clientid: res?.clientid,
                    grant_type: "client_credentials",
                    clientsecret: res?.clientsecret,
                };
                const response = Internal_Token(body);
                response.then((respo) => {
                    localStorage.setItem("token_gen", JSON.stringify(respo));
                    localStorage.setItem("Id", res?.merchantcode);
                    localStorage.setItem("clientid", res?.clientid);
                    localStorage.setItem("schemaId", res?.schemaId);
                    localStorage.setItem("merchantcode", res?.merchantcode);
                    localStorage.setItem("clientsecret", res?.clientsecret);
                    localStorage.setItem("schemaName", res?.schemaName);
                    localStorage.setItem("email", res?.email);
                    if (decrypt(res?.email) === "deepak@clavax.com" || decrypt(res?.email) === "deepikab@clavax.com" || decrypt(res?.email) === "tarunm@clavax.com") {
                        localStorage.setItem("saasemail", res?.email);
                        localStorage.setItem("superAdmin", true);
                    }

                    localStorage.setItem("loginKey", res?.loginKey);
                    localStorage.setItem("name", res?.name);
                    localStorage.setItem("dashboard", "UD");
                    sessionStorage.setItem("dashboard", "UD");
                    localStorage.setItem("cardStatus", res?.cardStatus);
                    localStorage.setItem("plan", res?.plan);
                    localStorage.setItem("moduleAccess", res?.moduleAccess);
                    localStorage.setItem("packageNameList", encrypt(JSON.stringify(res?.packageNameList)));
                    sessionStorage.removeItem("incorrectPasswordAttempts");

                    if (decrypt(res?.cardStatus) === "Active") {
                        res?.packageNameList.filter((item) => {
                            if (item.id == decrypt(res?.plan)) {
                                if (item.plan == "Trail") {
                                    navigate("/intro");
                                } else {
                                    navigate("/dashboard");
                                }
                            }
                        });
                    } else {
                        navigate("/intro");
                    }
                    setMessage(false);
                    setLoader(false);
                });
            } else {
                localStorage.setItem("token", JSON.stringify(false));
                setMessage(true);
                setLoader(false);
                setResponse(response);
                setErrorMessages(true);
            }
        });
    };

    return (
        <section className="section signUpPage">
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="signUpPage__main">
                <AuthenticationSliderLeft />
                <div className="signUpPage__right">
                    {verifyEmail && (
                        <div className="signUpPage__heading">
                            <figure>
                                <img src={logo} alt="logo" />
                            </figure>
                            <h1>Congratulations</h1>
                            <h4>
                                Your Email Id is Verified. Please <Link to="/signin">Click Here</Link> to Login
                            </h4>
                        </div>
                    )}
                    {errorMessages && !verifyEmail && (
                        <div className="signUpPage__heading">
                            <figure>
                                <img src={logo} alt="logo" />
                            </figure>
                            <h4>This link is expired</h4>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default VerifyYourEmail;
