import React, { useState, useEffect } from "react";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../components/partial/TextValidator";
import SelectValidator from "../components/partial/SelectValidator";
import TextAreaValidator from "../components/partial/TextAreaValidator";
import Paginated from "../components/pages/campaigns/paginated/Paginated";
import { /* getEmailTemplatePlaceholder,*/ getCustomerGroupList, getEmailTemplate, postCombineCommunicationFlow, getCommunicationFlowsList, updateCommunicationFlow } from "../actions/campaigns";
import { getDate, getDropdownObject } from "../utils/helper";
import swal from "sweetalert";
import { Link, useLocation, useNavigate } from "react-router-dom";
//import {ModalCard} from '../../layout/ModalCard'
import introJs from "intro.js";
import "intro.js/introjs.css";
import ModalCard from "../components/layout/ModalCard";
const IntroCommunicationFlowTab = ({ code }) => {
    const location = useLocation();
    const intro = introJs();
    const typeOpt = [
        { label: "Transactional", value: "Transactional" },
        // { label: "Promotional", value: "Promotional" },
    ];
    let exitOnLast = false;
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const communicationFlowOpt = [
        { label: "Send E-Mail", value: "SendMail" },
        { label: "Send SMS", value: "SendSMS" },
    ];
    const [formData, setFormData] = useState({
        campaignCode: "", //NTH-CAM004685
        communicationFlowName: "",
        editCommunicationStatus: true,
        communicationFlowBody: [],
    });
    const [showModal, setShowModal] = useState(false);
    const [groupList, setGroupList] = useState([]);
    const [emailTemplate, setEmailTemplate] = useState({});
    const [dataList, setDataList] = useState([]);
    const [edit, setEdit] = useState(false);
    const [data, setData] = useState([]);
    const { communicationFlowName } = formData;
    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            campaignCode: code,
        }));
    }, [code]);

    useEffect(() => {
        const tempData = dataList;
        tempData?.map((val, ind) => {
            // eslint-disable-next-line
            return (val.communicationFlowName = val.communicationFlowName);
        });
        tempData?.map((val) => {
            return (val.description = `you have ${val?.communicationFlowBody[0]?.containerBody?.length} Communication Flow `);
        });
        tempData?.map((val, ind) => {
            // eslint-disable-next-line
            return (val.action = (
                <Link state={location.state} onClick={() => onClick(val)}>
                    <i className="fa-solid fa-eye"></i> View Details
                </Link>
            ));
        });
        setData(tempData);
    }, [dataList]);
    useEffect(() => {
        const temp = [];
        const token = JSON.parse(localStorage.getItem("token_gen"));
        // getEmailTemplate(token?.access_token).then((res) => {
        //     res?.campaigns?.map((val) => {
        //         return temp.push({ label: val.name, value: val.id });
        //     });
        //     setEmailTemplate(temp);
        // });
    }, []);

    useEffect(() => {
        const temp = [];
        const token = JSON.parse(localStorage.getItem("token_gen"));
        // getCustomerGroupList(token?.access_token).then((res) => {
        //     res?.data?.map((val) => {
        //         return temp.push({ label: val.customerGroupName, value: val.customerGroupName });
        //     });
        //     setGroupList(temp);
        // });
    }, []);

    const updateDataList = () => {
        const token = JSON.parse(localStorage.getItem("token_gen"));
        code &&
            getCommunicationFlowsList(token?.access_token, code).then((res) => {
                setDataList(res?.data);
            });
    };

    useEffect(() => {
        updateDataList();
    }, [code]); //eslint-disable-line

    useEffect(() => {
        const temp = formData;
        temp?.communicationFlowBody.length && temp?.communicationFlowBody.map((val, ind) => ind === 0 && val.containerBody.map((value, map) => value.type === "Transactional" && ((value.time = null), (value.customerGroupId = null))));
        setFormData(temp);
    }, [formData.communicationFlowBody]); //eslint-disable-line

    useEffect(() => {
        !formData.communicationFlowBody.length && setEdit(false);
    }, [formData.communicationFlowBody]);

    const handleAddMore = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            communicationFlowBody: [
                ...prevFormData.communicationFlowBody,
                {
                    communicationFlowFor: "",
                    containerBody: [
                        {
                            stepName: "",
                            message: null,
                            customerGroupId: "",
                            type: "",
                            status: "",
                            isExecuted: false,
                            emailPlaceholder: null,
                            emailTemplateId: null,
                            smsPlaceholder: null,
                            time: 0,
                        },
                    ],
                },
            ],
        }));
    };
    const onClick = (e) => {
        var temp = {};
        window.scrollTo(0, 0);
        setEdit(true);
        temp.campaignCode = e?.campaignCode;
        temp.communicationFlowName = e?.communicationFlowName;
        temp.editCommunicationStatus = true;
        temp.communicationFlowBody = e?.communicationFlowBody;
        temp.id = e?.id;
        setFormData(temp);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleShowHide = (event, ind) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            communicationFlowBody: prevFormData.communicationFlowBody.map((value, i) =>
                ind === i
                    ? {
                          ...value,
                          communicationFlowFor: event,
                      }
                    : value
            ),
        }));
    };

    const handleChangeState = (type, value, index) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            communicationFlowBody: prevFormData.communicationFlowBody.map((ele, i) =>
                index === i
                    ? {
                          ...ele,
                          containerBody: [
                              ele.communicationFlowFor === "SendMail"
                                  ? {
                                        ...ele.containerBody[0],
                                        [type]: value,
                                        message: null,
                                        smsPlaceholder: null,
                                    }
                                  : ele.communicationFlowFor === "SendSMS"
                                  ? {
                                        ...ele.containerBody[0],
                                        [type]: value,
                                        emailPlaceholder: null,
                                        emailTemplateId: null,
                                    }
                                  : {
                                        ...ele.containerBody[0],
                                        [type]: value,
                                    },
                          ],
                      }
                    : ele
            ),
        }));
    };

    const onCancle = (index) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            communicationFlowBody: prevFormData.communicationFlowBody.filter((value, i) => index !== i),
        }));
    };

    const handleSubmit = async () => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        !edit &&
            (await postCombineCommunicationFlow(token?.access_token, formData).then((res) => {
                console.log(res?.message === "Request processed successfully.");
                if (res?.message === "Request processed successfully.") {
                    swal({
                        position: "center",
                        icon: "success",
                        title: "Communication Flow Created Successfully.",
                        showConfirmButton: false,
                        timer: 5000,
                    });
                    updateDataList();
                } else {
                    swal({
                        position: "center",
                        icon: "error",
                        title: res?.message,
                        showConfirmButton: false,
                        timer: 5000,
                    });
                }
            }));
        edit &&
            (await updateCommunicationFlow(token?.access_token, formData, formData?.id).then((res) => {
                updateDataList();
                swal({
                    position: "center",
                    icon: "success",
                    title: "Request processed successfully.",
                    showConfirmButton: false,
                    timer: 5000,
                });
            }));
        setFormData((prevFormData) => ({
            ...prevFormData,
            communicationFlowName: "",
            communicationFlowBody: [],
        }));
        setEdit(false);
        setLoader(false);
    };

    const COLUMNS = [
        {
            Header: "Communication Flow Name",
            Footer: "Communication Flow Name",
            accessor: "communicationFlowName",
            sticky: "left",
        },
        {
            Header: "Description",
            Footer: "Description",
            accessor: "description",
            disableFilters: true,
            sticky: "left",
        },
        {
            Header: "Action",
            Footer: "Action",
            accessor: "action",
        },
    ];
    let complete = false;

    useEffect(() => {
        const handleLoad = () => {
            const steps = [
                {
                    element: document.getElementById("communication"),
                    title: "Communication Flow ",
                    intro: "Establish communication flow to engage customers with rewards updates, and personalized messages. ",
                },

                {
                    element: document.getElementById("flow_for"),
                    title: "Communication Flow Type",
                    intro: "Decide how you'll communicate with customers and set the conditions within your loyalty campaigns ",
                },
                {
                    element: document.getElementById("create_commm_flow"),
                    title: "Create Flow ",
                    intro: "Finalize and activate your communication sequence by clicking the 'Create Communication Flow' button.  ",
                },
                {
                    element: document.getElementById("preview"),
                    title: " Communication Email Preview ",
                    intro: `Review and evaluate the content and presentation of your emails before sending them to customers.`,
                },
                {
                    element: document.getElementById("growth"),
                    title: "Growth Prospect  ",
                    intro: "  Identify growth opportunities and potential areas for expanding your loyalty program. ",
                },
            ];
            intro.setOptions({
                steps: steps,
                exitOnOverlayClick: false,
                doneLabel: "Next",
                tooltipClass: "customTooltip",
            });

            intro.start();
        };
        handleAddMore();
        setTimeout(() => {
            handleLoad();
        }, 500);
        return () => {
            intro.exit();
        };
    }, []);
    intro.oncomplete(function (element) {
        complete = true;
        //window.location.href= "/intro/customers-list";
        setTimeout(() => {
            if (!exitOnLast) {
                navigate("/intro/growth");
            }
        }, 700);
    });
    intro.onexit(() => {
        if (!complete) {
            localStorage.setItem("firstLogin", "");
            setShowModal(true);
        }
    });
    intro.onchange(function (element) {
        switch (this._currentStep) {
            case 2:
                setTimeout(()=>{
                    let heperIntro=document.getElementsByClassName("introjs-helperLayer")[0]
                    heperIntro?.classList.add('helperlayerzoomed')

                },300)
                document.getElementById('create_commm_flow')?.classList.add('zoom_in')
            case 4:
                setTimeout(() => {
                    let cross = document.getElementsByClassName("introjs-skipbutton")[0];
                    cross?.addEventListener("click", function () {
                        exitOnLast = true;
                        localStorage.setItem("firstLogin", "");
                        setShowModal(true);
                    });
                }, 600);

                break;
        }
    });

    return (
        <ValidatorForm className="customForm" onSubmit={handleSubmit}>
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            {showModal && <ModalCard show={showModal}/>}
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="dashboard__customFormBox">
                        <div className="dashboard__heading">
                            <div id="communication" className="dashboard__left">
                                <h5>Create/Edit Communication Flow</h5>
                            </div>
                        </div>
                        <div className="dashboard__customFormCard">
                            <div className="dashboard__middle">
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div id="flow_name" className="form-group">
                                            <label>Communication Flow Name</label>
                                            <TextValidator className="form-control" validators={["required"]} errorMessages={["Field can't be empty"]} name="communicationFlowName" value={communicationFlowName} placeholder="Communication Flow Name" onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>
                                {formData?.communicationFlowBody.map((arr, ind) => (
                                    <div key={ind} className="communication_flow_for">
                                        <button type="button" onClick={() => onCancle(ind)} className="cffclose">
                                            <i className="fa fa-close"></i>
                                        </button>
                                        <div id="flow_for">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                    <div className="form-group">
                                                        <label>Communication Flow for:</label>
                                                        <SelectValidator validators={["required"]} errorMessages={["Field can't be empty"]} placeholder="Select" className="custom-ReactSelect bordered" name="type" value={arr?.communicationFlowFor ? getDropdownObject(arr?.communicationFlowFor, communicationFlowOpt) : ""} options={communicationFlowOpt} onChange={(e) => handleShowHide(e.value, ind)} />
                                                    </div>
                                                </div>
                                            </div>
                                            {(arr?.communicationFlowFor === "SendMail" || localStorage.getItem("firstLogin")) && (
                                                <div className="row" id="SendMail">
                                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div id="email" className="row">
                                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                                <div className="form-group">
                                                                    <label>Step Name:</label>
                                                                    <TextValidator className="form-control" name="stepName" placeholder="Step Name" validators={["required"]} errorMessages={["Field can't be empty"]} value={arr?.containerBody[0]?.stepName} onChange={(e) => handleChangeState("stepName", e.target.value, ind)} />
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                                <div className="form-group">
                                                                    <label>Type:</label>
                                                                    <SelectValidator validators={["required"]} errorMessages={["Field can't be empty"]} placeholder="Type" className="custom-ReactSelect bordered" name="type" value={arr?.containerBody[0]?.type ? getDropdownObject(arr?.containerBody[0]?.type, typeOpt) : ""} options={typeOpt} onChange={(e) => handleChangeState("type", e.value, ind)} />
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                        <div className="form-group">
                                                            <label>Email Placeholder:</label>
                                                            <SelectValidator validators={["required"]} errorMessages={["Field can't be empty"]} placeholder="---select---" className="custom-ReactSelect bordered" name="emailPlaceholder" options={ePlaceHolder} isMulti value={getMultiDropdownObject(arr?.containerBody[0]?.emailPlaceholder, ePlaceHolder,)}
                                                                onChange={(e) => handleChangeState("emailPlaceholder", e.map(val => { return val.value }), ind)} />
                                                        </div>
                                                    </div> */}
                                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                                <div className="form-group">
                                                                    <label>Email Template:</label>
                                                                    {<SelectValidator validators={["required"]} errorMessages={["Field can't be empty"]} placeholder="---select---" className="custom-ReactSelect bordered" name="emailTemplateId" value={!localStorage.getItem("firstLogin") ? getDropdownObject(arr?.containerBody[0]?.emailTemplateId, emailTemplate) : ""} options={emailTemplate} onChange={(e) => handleChangeState("emailTemplateId", e.value, ind)} />}
                                                                </div>
                                                            </div>
                                                            {arr?.containerBody[0]?.type === "Promotional" && (
                                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                                    <div className="form-group">
                                                                        <label>Customer Group List:</label>
                                                                        <SelectValidator validators={["required"]} errorMessages={["Field can't be empty"]} placeholder="---select---" className="custom-ReactSelect bordered" name="customerGroupId" value={getDropdownObject(arr?.containerBody[0]?.customerGroupId, groupList)} options={groupList} onChange={(e) => handleChangeState("customerGroupId", e.value, ind)} />
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {arr?.containerBody[0]?.type === "Promotional" && (
                                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                                    <div className="form-group">
                                                                        <label>Set Timing:</label>
                                                                        <TextValidator type="date" className="form-control" name="time" value={getDate(arr?.containerBody[0]?.time, "YYYY-MM-DD")} onChange={(e) => handleChangeState("time", Date.parse(e.target.value), ind)} />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {arr?.communicationFlowFor === "SendSMS" && (
                                                <div className="row" id="SendSMS">
                                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div className="row">
                                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                                <div className="form-group">
                                                                    <label>Step Name:</label>
                                                                    <TextValidator className="form-control" name="stepName" placeholder="Send Mail" validators={["required"]} errorMessages={["Field can't be empty"]} value={arr?.containerBody[0]?.stepName} onChange={(e) => handleChangeState("stepName", e.target.value, ind)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                                <div className="form-group">
                                                                    <label>Type:</label>
                                                                    <SelectValidator validators={["required"]} errorMessages={["Field can't be empty"]} placeholder="" className="custom-ReactSelect bordered" name="type" value={getDropdownObject(arr?.containerBody[0]?.type, typeOpt)} options={typeOpt} onChange={(e) => handleChangeState("type", e.value, ind)} />
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                            <div className="form-group">
                                                                <label>SMS Placeholder:</label>
                                                                <SelectValidator
                                                                    validators={["required"]}
                                                                    errorMessages={["Field can't be empty"]}
                                                                    placeholder="---select---"
                                                                    className="custom-ReactSelect bordered"
                                                                    name="smsPlaceholder"
                                                                    options={ePlaceHolder}
                                                                    isMulti
                                                                    value={getMultiDropdownObject(arr?.containerBody[0]?.smsPlaceholder, ePlaceHolder)}
                                                                    onChange={(e) =>
                                                                        handleChangeState(
                                                                            "smsPlaceholder",
                                                                            e.map((val) => {
                                                                                return val.value;
                                                                            }),
                                                                            ind
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div> */}
                                                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                <div className="form-group">
                                                                    <label>Message:</label>
                                                                    <TextAreaValidator className="form-control" name="message" value={arr?.containerBody[0]?.message ? arr?.containerBody[0]?.message : ""} onChange={(e) => handleChangeState("message", e.target.value, ind)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                                {arr?.containerBody[0]?.type === "Promotional" && (
                                                                    <div className="form-group">
                                                                        <label>Customer Group List:</label>
                                                                        <SelectValidator validators={["required"]} errorMessages={["Field can't be empty"]} placeholder="---select---" className="custom-ReactSelect bordered" name="customerGroupId" value={getDropdownObject(arr?.containerBody[0]?.customerGroupId, groupList)} options={groupList} onChange={(e) => handleChangeState("customerGroupId", e.value, ind)} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {arr?.containerBody[0]?.type === "Promotional" && (
                                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                                    <div className="form-group">
                                                                        <label>Set Timing:</label>
                                                                        <TextValidator type="date" className="form-control" name="time" value={getDate(arr?.containerBody[0]?.time, "YYYY-MM-DD")} onChange={(e) => handleChangeState("time", Date.parse(e.target.value), ind)} />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group">
                                            <br />
                                            <button type="button" id="add_button" className="btn btn-primary" onClick={() => handleAddMore()}>
                                                Add More Communication Flow
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="button-section">
                        <button type="submit" id="create_commm_flow" className="btn btn-primary" disabled={true}>
                            {!edit ? "Create Communication Flow" : "Update Communication Flow"}
                        </button>
                    </div>
                </div>
                <div id="comm_List" className="col-sm-12 col-md-12 col-lg-12">
                    <div className="dashboard__customFormBox">
                        <div className="dashboard__heading">
                            <div className="dashboard__left">
                                <h5>Communication Flow List</h5>
                            </div>
                        </div>
                        <div className="dashboard__customFormCard">
                            <div className="dashboard__middle">
                                <div className="row">
                                    <table className="table table-striped table-bordered" role="table">
                                        <thead>
                                            <tr role="row">
                                                <th colSpan="1" role="columnheader">
                                                    Communication Flow Name
                                                </th>
                                                <th colSpan="1" role="columnheader">
                                                    Description
                                                </th>
                                                <th colSpan="1" role="columnheader">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody role="rowgroup">
                                            <tr role="row">
                                                <td role="cell">Rewarded Points Email</td>
                                                <td role="cell">you have 1 Communication Flow </td>
                                                <td role="cell">
                                                    <a href="/my-campaigns/edit-campaign">
                                                        <i className="fa-solid fa-eye"></i> View Details
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr role="row">
                                                <td role="cell">Special Voucher</td>
                                                <td role="cell">you have 1 Communication Flow </td>
                                                <td role="cell">
                                                    <a href="/my-campaigns/edit-campaign">
                                                        <i className="fa-solid fa-eye"></i> View Details
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="preview" className="col-md-12">
                <div className="comm_email_sample">
                    <h2>Communication Email Preview</h2>
                    <div className="comm_email_sample_box">
                        <figure>
                            <img src="https://www.novusloyalty.com/img/novus_loyalty_logo.png" />
                        </figure>
                        <p>We are delighted to inform you that you have earned</p>
                        <div className="comm_email_sample_boxed">
                            <h3>150</h3>
                            <h4>Reward Points</h4>
                            <p>on your recent shopping trip with us.</p>
                        </div>
                        <p>Thank you for choosing us for your purchases. We look forward to serving you again in the future</p>
                    </div>
                </div>
            </div>
        </ValidatorForm>
    );
};

export default IntroCommunicationFlowTab;
