import { HIDE_SITE_LOADER, SHOW_SITE_LOADER } from "../actions/type";
const initialState = {
    siteLoader: false,
};

const modals = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case HIDE_SITE_LOADER:
            return { ...state, siteLoader: false };
        case SHOW_SITE_LOADER:
            return { ...state, siteLoader: true };
        default:
            return state;
    }
};
export default modals;
