import React, { useEffect, useRef, useState } from 'react'
import { ValidatorForm } from 'react-form-validator-core'
import TextValidator from '../partial/TextValidator';
import TextAreaValidator from '../partial/TextAreaValidator';
import { getMerchantNotificationAlert, merchantNotification } from '../pages/campaigns/services';
import { getDate } from '../../utils/helper';
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import AdminHeader from '../layout/AdminHeader';
import { Token_Generator } from '../../actions/auth';

function AlertNotification() {

    const [loader, setLoader] = useState(false);
    const formRef = useRef();
    const [selectedData, setSelectedData] = useState(null)
    const [selectedEndData, setSelectedEndData] = useState(null)
    const [selectData, setSelectData] = useState(null)
    const [selectEndData, setSelectEndData] = useState(null)
    const [data,setData] = useState({})

   
   
   
    const [formData, setFormData] = useState({
        merchant:{
            tital:"",
            discription: "",
            startDateTime: "",
            endDateTime: "",
            status:false,
        },
        customer: {
            tital:"",
            discription:"",
            startDateTime:"",
            endDateTime:"",
            status:false,
        }
    });

    // const [formDatas, setFormDatas] = useState({
    //     merchant:{
    //         tital:"",
    //         discription:"",
    //         startDateTime:"",
    //         endDateTime:"",
    //         status:false
    //     },

    //     customer: {
    //         tital:"",
    //         discription:"",
    //         startDateTime:"",
    //         endDateTime:"",
    //         status:false,
    //     }
    // });
   
   


  

    const handleChangeData = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState, 
            customer: {
                ...prevState.customer,
                [name]: value,
            },
        }));
    }
    



    const handleDateChange = (date) => {
        setSelectedData(date);
        setFormData(prevState => ({
            ...prevState, 
            customer: {
                ...prevState.customer,
                startDateTime: getDate(date, "DD/MM/YYYY:HH:mm")
            },
        }));
    }

    const handleDateEndChange = (date) => {
        setSelectedEndData(date)
        setFormData(prevState => ({
            ...prevState, 
            customer: {
                ...prevState.customer,
                endDateTime: getDate(date, "DD/MM/YYYY:HH:mm")
            },
        }));
    }

    const handleStatusToggle = () => {
        setFormData(prevState => ({
            ...prevState, 
            customer: {
                ...prevState.customer,
                status: !prevState.customer.status
            }
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState, 
            merchant: {
                ...prevState.merchant,
                [name]: value,
            },
        }));
    }
    const handleDateChanges = (date) => {
        setSelectData(date);
        setFormData(prevState => ({
            ...prevState, 
            merchant: {
                ...prevState.merchant,
                startDateTime: getDate(date, "DD/MM/YYYY:HH:mm")
            },


        }));
    }

    const handleDateEndChanges = (date) => {
        setSelectEndData(date)
        setFormData(prevState => ({
            ...prevState, 
            merchant: {
                ...prevState.merchant,
                endDateTime: getDate(date, "DD/MM/YYYY:HH:mm")
            },
        }));
    }

    const handleStatus = () => {
        setFormData(prevState => ({
            ...prevState, 
            merchant: {
                ...prevState.merchant,
                status: !prevState.merchant.status
            }
        }));
    };




    const handleSubmitAlert = async () => {
        setLoader(true);
  
        const token = await Token_Generator();

        await merchantNotification(token?.access_token, formData, true).then((res) => {

            swal({
                position: "center",
                icon: "success",
                title: "Request processed successfully.",
                showConfirmButton: false,
                timer: 5000,
            });

        });

        setLoader(false);
    };
    const handleSubmit = async () => {
        setLoader(true); 
     

        const token = await Token_Generator();
        await merchantNotification(token?.access_token, formData, true).then((res) => {

            swal({
                position: "center",
                icon: "success",
                title: "Request processed successfully.",
                showConfirmButton: false,
                timer: 5000,
            });

        });

        setLoader(false);
    };

    const fetchNotificationAlert = async () => {
        setLoader(true);
           
        const token = await Token_Generator();
       await getMerchantNotificationAlert(token?.access_token)
       
            .then((res) => {
                setData(res.data)
                    const customerData = res.data.cutomer;
                    const merchantData = res.data.merchant;
     
            
                    setFormData(prevState => ({
                        ...prevState,
                        merchant: {
                            tital: merchantData?.tital || "",
                            discription: merchantData?.discription || "",
                            startDateTime: merchantData?.startDateTime || "",
                            endDateTime: merchantData?.endDateTime || "",
                            status: merchantData?.status || false
                        },
                        customer: {
                            tital: customerData?.tital || "",
                            discription: customerData?.discription || "",
                            startDateTime: customerData?.startDateTime || "",
                            endDateTime: customerData?.endDateTime || "",
                            status: customerData?.status || false
                        }
                        
                    }));
                }
            )
        setLoader(false)
    };
useEffect(()=>{
    fetchNotificationAlert()
},[])
    return (
        <>
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <AdminHeader name={"Alert Notification"} />
            <div className="dashboard__content">
                <div className="container-fluid">




                    <ValidatorForm className="signUpPage__formSection" ref={formRef} onSubmit={handleSubmitAlert} >
                        <div className="dashboard__customersCard">
                            <div className="dashboard__header">
                                <div className="dashboard__left">
                                    <h4 style={{ color: "white" }}>Merchant</h4>
                                </div>
                            </div>
                            <div className="dashboard__body" style={{ minHeight: "10px" }}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group">
                                            <label>Alert Title</label>
                                            <TextValidator type="text"
                                                value={formData.merchant.tital}
                                                onChange={handleChange}
                                                placeholder="Alert Title"
                                                className="form-control"
                                                maxLength="20"
                                                name="tital" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>Start Date Time</label>
                                            <DatePicker
                                                selected={selectData}
                                                value={formData?.merchant?.startDateTime}
                                                onChange={handleDateChanges}
                                                dateFormat="dd/MM/yyyy:hh:mm"
                                                showTimeSelect
                                                timeIntervals={30}
                                                timeFormat="hh:mm"
                                                placeholder="Enter Start date"
                                            />

                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>End Date Time</label>
                                            <DatePicker
                                                selected={selectEndData}
                                                value={formData.merchant.endDateTime}
                                                onChange={handleDateEndChanges}
                                                dateFormat="dd/MM/yyyy:hh:mm"
                                                showTimeSelect
                                                timeIntervals={30}
                                                timeFormat="hh:mm"
                                                placeholder="Enter End date"
                                            />

                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="person" style={{ display: "flex", alignItems: "center", width: "100%", marginTop: "50px" }}>
                                            <p className="voucher" style={{ display: "flex", width: "100%", marginBottom: "0", color: "black" }}>
                                                Status
                                            </p>
                                            <div className="">
                                                <label className="toggle">
                                                    <input type="checkbox" name="status" checked={formData.merchant.status} onChange={handleStatus} />
                                                    <span className="slider"></span>
                                                    <span className="labels" data-on="Active" data-off="Inactive"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>




                                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-8">
                                        <div className="form-group withIcon">
                                            <label>Notification Description</label>

                                            <TextAreaValidator type="text" maxLength="100" value={formData.merchant.discription} onChange={handleChange} placeholder="Notification Description" className="form-control" name="discription" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div className="button-section">
                                        <button type="submit" className="btn btn-primary" style={{ float: "right" }}>
                                            Submit
                                        </button>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </ValidatorForm>
                    <ValidatorForm className="signUpPage__formSection" ref={formRef} onSubmit={handleSubmit} >
                        <div className="dashboard__customersCard">
                            <div className="dashboard__header">
                                <div className="dashboard__left">
                                    <h4 style={{ color: "white" }}>Customer</h4>
                                </div>
                            </div>
                            <div className="dashboard__body" style={{ minHeight: "10px" }}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group">
                                            <label>Alert Title</label>
                                            <TextValidator type="text"
                                                value={formData.customer.tital}
                                                onChange={handleChangeData}
                                                placeholder="Alert Title"
                                                className="form-control"
                                                maxLength="20"
                                                name="tital" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>Start Date Time</label>
                                            <DatePicker
                                                selected={selectedData}
                                                value={formData.customer.startDateTime}
                                                onChange={handleDateChange}
                                                dateFormat="dd/MM/yyyy:hh:mm"
                                                showTimeSelect
                                                timeIntervals={30}
                                                timeFormat="hh:mm"
                                                placeholder="Enter Start date"
                                            />

                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>End Date Time</label>
                                            <DatePicker
                                                selected={selectedEndData}
                                                value={formData.customer.endDateTime}
                                                onChange={handleDateEndChange}
                                                dateFormat="dd/MM/yyyy:hh:mm"
                                                showTimeSelect
                                                timeIntervals={30}
                                                timeFormat="hh:mm"
                                                placeholder="Enter End date"
                                            />

                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="person" style={{ display: "flex", alignItems: "center", width: "100%", marginTop: "50px" }}>
                                            <p className="voucher" style={{ display: "flex", width: "100%", marginBottom: "0", color: "black" }}>
                                                Status
                                            </p>
                                            <div className="">
                                                <label className="toggle">
                                                    <input type="checkbox" name="status" checked={formData.customer.status} onChange={handleStatusToggle} />
                                                    <span className="slider"></span>
                                                    <span className="labels" data-on="Active" data-off="Inactive"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-8">
                                        <div className="form-group withIcon">
                                            <label>Notification Description</label>
                                                   <TextAreaValidator type="text" maxLength="100" value={formData.customer.discription} onChange={handleChangeData} placeholder="Notification Description" className="form-control" name="discription" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div className="button-section">
                                        <button type="submit" className="btn btn-primary" style={{ float: "right" }}>
                                            Submit
                                        </button>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </ValidatorForm>
                    
                   


                </div>
            </div>
        </>
    )
}

export default AlertNotification