import React, { memo, useEffect, useState } from "react";
import { ValidatorForm } from "react-form-validator-core";
import Switch from "react-switch";
import TextValidator from "../components/partial/TextValidator";
import TextAreaValidator from "../components/partial/TextAreaValidator";
import introJs from "intro.js";
import "intro.js/introjs.css";
import { useLocation } from "react-router-dom";
import { getDate } from "../utils/helper";
import ModalCard from "../components/layout/ModalCard";
const IntroCampaignDetailsTab = memo((props) => {
    const location = useLocation();
    const state = location.state;
    const intro = introJs();
    let exitOnLast = false;
    const { onChange, formateData, error, handleTabChange } = props;

    const { name, endDateTime, startDateTime, description, status, CreatedBy } = formateData;
    const onSubmit = () => {};
    const [showModal, setshow] = useState(false);

    const handleNext = () => {
        handleTabChange({ key: "Earning Rules" });
    };
    useEffect(() => {
        const handleLoad = () => {
            const steps = [
                {
                    element: document.getElementById("cpm_details"),
                    title: "Campaign Details ",
                    intro: "Access in-depth information about a selected campaign, including goals, duration, and description.",
                },
            ];
            intro.setOptions({
                steps: steps,
                exitOnOverlayClick: false,
                doneLabel: "Next",
                tooltipClass: "customTooltip",
            });

            intro.start();
        };
        if (typeof state !== "object") {
            setTimeout(() => {
                handleLoad();
            }, 300);
        }
    }, []);
    intro.oncomplete(function (element) {});
    intro.onexit(() => {
        setTimeout(() => {
            if (!exitOnLast) props.onCompleteIntro("Earning Rules");
        }, 900);
    });
    intro.onchange(function (element) {
        switch (this._currentStep) {
            case 0:
                setTimeout(() => {
                    let cross = document.getElementsByClassName("introjs-skipbutton")[0];
                    cross?.addEventListener("click", function () {
                        exitOnLast = true;
                        localStorage.setItem("firstLogin", "");
                        setshow(true);
                    });
                }, 600);
                break;
        }
    });

    return (
        <ValidatorForm id="cpm_details" className="customForm" onSubmit={(e) => onSubmit(e)}>
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <div className="form-group">
                        <label>Campaign Name</label>
                        <TextValidator placeholder="Campaign Name" type="text" className="form-control" value={name} name="name" onChange={onChange} />
                        <span className="errorMessage">{error && error.errName && error.errNameMsg}</span>
                    </div>
                </div>
                {/* <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
					<div className="form-group">
						<label>{labelName}</label>
						<TextValidator type="text" className="form-control" />
					</div>
				</div> */}
                {showModal && <ModalCard show={showModal}/>}
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <div className="form-group">
                        <label>Start Date</label>

                        <TextValidator type="date" className="form-control" min={getDate(new Date(), "YYYY-MM-DD")} value={getDate(startDateTime, "YYYY-MM-DD")} name="startDateTime" onChange={onChange} />
                        <span className="errorMessage">{error && error.errStartDate && error.errStartDateMsg}</span>
                        <span className="errorMessage">{error && error.errDateDiff && error.errDateDiffMsg}</span>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <div className="form-group">
                        <label>End Date</label>
                        <TextValidator type="date" className="form-control" min={getDate(startDateTime, "YYYY-MM-DD")} value={getDate(endDateTime, "YYYY-MM-DD")} name="endDateTime" onChange={onChange} />
                        <span className="errorMessage">{error && error.errEndDate && error.errEndDateMsg}</span>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group">
                        <label>Description</label>
                        <TextAreaValidator placeholder="Description" className="form-control" value={description || ""} name="description" onChange={onChange} />
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group withSwitch">
                        <label>
                            Status:
                            <Switch name="status" value={status} checked={status} onChange={onChange} onColor={status ? "#50BC14" : "#E50000"} onHandleColor="#50BC14" handleDiameter={30} uncheckedIcon={true} checkedIcon={false} boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)" height={20} width={48} className="react-switch" />
                        </label>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="button-section">
                        <button type="button" className="btn btn-primary" onClick={handleNext}>
                            Next
                        </button>
                        {/* <button type="button" className="btn btn-outline-primary">
							Save as draft
						</button>
						<button type="button" className="btn btn-primary">
							Publish Offer
						</button> */}
                    </div>
                </div>
            </div>
        </ValidatorForm>
    );
});

export default IntroCampaignDetailsTab;
