import React, { useState, useMemo, useEffect } from "react";
import Header from "../../layout/Header";
import CampaignDetailsTab from "./CampaignDetailsTab";
import CreateEarningRulesTabLoyalty from "./loyalty/CreateEarningRulesTabLoyalty";
import CommunicationFlowTab from "./CommunicationFlowTab";
import { backIcon2 } from "../../../Image/Index";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import { decrypt } from "../../../utils/encodingdecoding";
import { getDate, handleClick } from "../../../utils/helper";
import CreateEarningRuleReferral from "./referral/CreateEarningRuleReferral";
import { clearBusinessGoal, getBusinessGoal } from "../../../actions/businessGoal";

const NewLoyaltyCampaign = ({ getBusinessGoal, clearBusinessGoal, BusinessGoal }) => {
    let navigate = useNavigate();
    const location = useLocation();

    const [key, setKey] = useState("Campaign Details");
    let commTabCode = decrypt(localStorage.getItem("code"));

    const nextSixMonth = handleClick();

    const [formateData, SetFormateData] = useState({
        version: "15",
        code: "IBM-CAM000059",
        coalitionCampaignCode: null,
        coalitionProgramCode: null,
        status: true,
        priority: 999,
        name: location.state === "points" ? "Point campaign" : location.state === "voucher" ? "Voucher campaign" : "Referral campaign",
        businessGoal: "",
        description: "New campaign to grow my organization",
        timeZone: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
        isTest: false,
        isVisibile: true,
        startDateTime: getDate(new Date(), "YYYY-MM-DD"),
        endDateTime: nextSixMonth,
        visibilityDateTime: "0001-01-01T00:00:00",
        offerDetail: {
            offerCode: "",
            title: location.state === "points" ? "Point campaign" : location.state === "voucher" ? "Voucher campaign" : "Referral campaign",
            programCode: null,
            internalLink: null,
            externalLink: null,
            imageLink: null,
            mobileImageLink: null,
            logoImageLink: null,
            description: null,
            redemptionProcess: null,
            escalationMatrix: null,
            termAndCondition: null,
            purchaseable: {
                isPurchaseable: false,
                isActivated: false,
                price: 0,
                validityPeriod: 86400,
                validityPeriodType: 0,
                isImported: false,
                voucherTemplate: null,
            },
            longDescription: null,
        },
        tags: [],
        internalTags: [],
        earningRule: {
            earningRuleEvalutionType: 2,
            rules: [
                {
                    earnigWhoData: {
                        EarnigWho: "",
                        Customer: "",
                    },
                    infixRule: [
                        {
                            id: "transactionType-3",
                            entityName: "",
                            field: "",
                            format: null,
                            jsonPath: "$.Transaction[?(@.TransactionType==Transaction)]",
                            operator: "",
                            type: "",
                            value: "",
                            data: {
                                class: "Transaction",
                            },
                        },
                    ],
                    prefixRule: {
                        condition: "AND",
                        rules: [
                            {
                                id: "transactionType-3",
                                field: "transactionType-3",
                                type: "string",
                                input: "text",
                                operator: "equal",
                                value: "Transaction",
                                data: {
                                    class: "Transaction",
                                },
                            },
                        ],
                        valid: true,
                    },
                    data: [
                        {
                            RedeemType: location.state === "voucher" ? "Voucher" : "Fix-Point",
                            Value: 10,
                            isEvery: false,
                            FullPartial: "Full",
                            TransactionField: "",
                            RewardRatio: 25,
                            FixPercentage: "",
                            AccountType: "",
                            EarnigWho: "Customer",
                            Sponsors: [
                                {
                                    Type: "Merchant",
                                    Code: decrypt(localStorage.getItem("merchantcode")),
                                    Percent: 100,
                                },
                            ],
                            redeemRule: {
                                redeemRuleBy: false,
                                afterSpecificTransaction: false,
                            },
                            expirationRule: {
                                afterDuration: "Day(s)",
                                afterSpecificNumber: 365,
                                onAfter: true,
                            },
                        },
                    ],
                    function: [
                        {
                            infixRule: [],
                            prefixRule: null,
                            duration: "",
                        },
                    ],
                    dbFunctionRule: [
                        {
                            sqlRule: null,
                            infixRule: [],
                            prefixRule: null,
                        },
                    ],
                },
            ],
        },
        InfixQualifyingRules: [],
        PrefixQualifyingRules: [],
        merchantTransactionSchema: {
            schemaId: decrypt(localStorage.getItem("schemaId")),
            schemaName: location.state === "referral" ? "Referral" : decrypt(localStorage.getItem("schemaName").replace(/\s/g, "")),
            merchants: [decrypt(localStorage.getItem("merchantcode"))],
        },
        processingMode: 0,
        approvalStatus: 1,
        restrictedPrograms: [],
        campaignGlobalLimit: {
            isGlobalCampaignLimit: false,
            globalLimits: [
                {
                    walletKey: "point",
                    walletName: "point",
                    maxLimit: 0,
                    isDailyLimit: false,
                    dailyLimit: 0,
                    limitConsumed: 0,
                    limitRemained: 0,
                    liveConsumptionStatus: null,
                },
                {
                    walletKey: "discount",
                    walletName: "discount",
                    maxLimit: 0,
                    isDailyLimit: false,
                    dailyLimit: 0,
                    limitConsumed: 0,
                    limitRemained: 0,
                    liveConsumptionStatus: null,
                },
                {
                    walletKey: "currency",
                    walletName: "currency",
                    maxLimit: 0,
                    isDailyLimit: false,
                    dailyLimit: 0,
                    limitConsumed: 0,
                    limitRemained: 0,
                    liveConsumptionStatus: null,
                },
            ],
        },
        campaignCustomerLimit: {
            customerLimits: [
                {
                    limit: 1,
                    limitType: "2",
                    maxLimit: "1",
                    timePeriod: "1",
                    walletKey: "point",
                    walletName: "point",
                },
                {
                    limit: 0,
                    limitType: "Daily",
                    maxLimit: 0,
                    timePeriod: "0",
                    walletKey: "discount",
                    walletName: "discount",
                },
                {
                    limit: 0,
                    limitType: "Daily",
                    maxLimit: 0,
                    timePeriod: "0",
                    walletKey: "currency",
                    walletName: "currency",
                },
            ],
            isCustomerLimit: false,
        },
        selfMadeCampaign: false,
        taglastcheck: null,
        type: "",
        Range: 0,
        Tiers: null,
        Membership: null,
        merchantId: [decrypt(localStorage.getItem("merchantcode"))],
    });

    const [error, setError] = useState({
        errName: false,
        errNameMsg: "Please enter campaign name",
        errBusiness: false,
        errBusinessMsg: "Please select business goal",
        errStartDate: false,
        errStartDateMsg: "Please select start date",
        errEndDate: false,
        errEndDateMsg: "Please select end date",
        errDateDiff: false,
        errDateDiffMsg: "Start date cannot be greater than or equal to End date.",
    });
    const { errDateDiff } = error;

    let tempName = false,
        tempStartDate = false,
        tempEndDate = false,
        tempBusiness = false;

    useEffect(() => {
        if (formateData.startDateTime >= formateData.endDateTime) {
            setError({ ...error, errDateDiff: true });
        } else {
            setError({ ...error, errDateDiff: false });
        }
    }, [formateData.startDateTime, formateData.endDateTime]); //eslint-disable-line

    const onChange = (e) => {
        if (e === false || e === true) {
            SetFormateData({ ...formateData, ...{ status: e } });
        } else if (e.value) {
            SetFormateData({ ...formateData, ...{ businessGoal: e.value } });
        } else {
            SetFormateData({ ...formateData, ...{ [e.target.name]: e.target.value } });
        }
        if (formateData.name !== "") {
            tempName = false;
        }
        if (formateData.startDateTime !== "") {
            tempStartDate = false;
        }
        if (formateData.endDateTime !== "") {
            tempEndDate = false;
        }
        if (formateData.businessGoal !== "") {
            tempBusiness = false;
        }

        setError({ ...error, errName: tempName ? true : false, errEndDate: tempEndDate ? true : false, errStartDate: tempStartDate ? true : false, errBusiness: tempBusiness ? true : false });
    };

    const handleTabChange = (callback) => {
        if (formateData.name === "") {
            tempName = true;
        }
        if (formateData.startDateTime === "") {
            tempStartDate = true;
        }
        if (formateData.endDateTime === "") {
            tempEndDate = true;
        }
        if (formateData.businessGoal === "") {
            tempBusiness = true;
        }

        if (callback.key) {
            if (!tempName && !tempStartDate && !tempEndDate && !errDateDiff && !tempBusiness) {
                setKey(callback.key);
            }
        }

        setError({ ...error, errName: tempName ? true : false, errEndDate: tempEndDate ? true : false, errStartDate: tempStartDate ? true : false, errBusiness: tempBusiness ? true : false });
    };

    const handleSelect = (k) => {
        let commTabKey = decrypt(localStorage.getItem("key"));
        if (localStorage.getItem("firstLogin")) {
            setKey(k);
            return;
        }

        if (k === "Communication Flow") {
            if (!commTabKey) {
                return;
            } else {
                setKey(commTabKey);
            }
        } else {
            setKey(k);
        }
    };

    const renderCampaignDetailsTab = useMemo(() => {
        return <CampaignDetailsTab onChange={(e) => onChange(e)} formateData={formateData} error={error} handleTabChange={handleTabChange} onCompleteIntro={handleSelect} />;
    }, [formateData, error]); //eslint-disable-line

    const renderEarningRulesTab = useMemo(() => {
        return <CreateEarningRulesTabLoyalty onChange={(e) => onChange(e)} formateData={formateData} handleTabChange={handleTabChange} formPage={location.state} onCompleteIntro={handleSelect} />;
    }, [formateData]); //eslint-disable-line

    const renderEarningRulesTabReferral = useMemo(() => {
        return <CreateEarningRuleReferral onChange={(e) => onChange(e)} formateData={formateData} handleTabChange={handleTabChange} />;
    }, [formateData]); //eslint-disable-line

    return (
        <>
            <Header name={"Create My Campaigns"} />
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="dashboard__heading">
                                <div className="dashboard__headingLeft">
                                    <div className="dashboard__headingBackLink">
                                        <Link to="" onClick={() => navigate(-1)}>
                                            <img src={backIcon2} alt="" />
                                            <div className="dashboard__headingContent">
                                                <h3>Create My Campaign</h3>
                                            </div>
                                        </Link>
                                        <div className="dashboard__headingContent">
                                            <p>Set how your customers will earn points</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard__tabSection">
                                <Tabs id="controlled-tab-example" activeKey={key} onSelect={handleSelect} onClick={handleTabChange}>
                                    <Tab eventKey="Campaign Details" title="Campaign Details">
                                        {renderCampaignDetailsTab}
                                    </Tab>
                                    <Tab eventKey="Earning Rules" title="Earning Rules" disabled={formateData.name && formateData.endDateTime && formateData.startDateTime && formateData.businessGoal && errDateDiff === false ? false : true}>
                                        {renderEarningRulesTab}
                                    </Tab>
                                    {/* {location.state === "points" || location.state === "voucher" ? (
                                        <Tab eventKey="Earning Rules" title="Earning Rules" disabled={formateData.name && formateData.endDateTime && formateData.startDateTime && formateData.businessGoal && errDateDiff === false ? false : true}>
                                            {renderEarningRulesTab}
                                        </Tab>
                                    ) : (
                                        <Tab eventKey="Earning Rules" title="Earning Rules" disabled={formateData.name && formateData.endDateTime && formateData.startDateTime && formateData.businessGoal && errDateDiff === false ? false : true}>
                                            {renderEarningRulesTabReferral}
                                        </Tab>
                                    )} */}

                                    <Tab eventKey="Communication Flow" title="Communication Flow" disabled>
                                        {key === "Communication Flow" && <CommunicationFlowTab code={commTabCode} />}
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => ({
    BusinessGoal: state.BusinessGoal.data,
});
const mapDispatchToProps = {
    getBusinessGoal,
    clearBusinessGoal,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewLoyaltyCampaign);
