import React from "react";
import { Modal, Button, CloseButton } from "react-bootstrap";
import { getDate } from "../../../utils/helper";

const RedeemableModal = (props) => {
    const { show, close, walletSummary } = props;

    return (
        <>
            <Modal show={show} onHide={close} centered>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: "20px" }}>Available Reward For Redeem</Modal.Title>
                    <CloseButton onClick={close} variant="white" />
                </Modal.Header>
                <Modal.Body style={{ position: "relative", minHeight: "85px" }}>
                    {walletSummary?.length ? (
                        walletSummary.map((wallet, i) => (
                            <div key={i}>
                                <label>Redeem Points AfterDate : </label> {getDate(wallet?.redeemableAfterDate, "YYYY-MM-DD")}
                                <br />
                                <label>Value To Redeem : </label> {wallet?.avaliableValueToRedeem}
                            </div>
                        ))
                    ) : (
                        <label>Value to Redeem : 0</label>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    {/* <Button onClick={handleRedeemApi} disabled={disable ? true : false}>
                            Redeem Points
                        </Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default RedeemableModal;
