import { PROFILE_DROPDOWN ,INTRO_DOCUMENTATION, INTROMODAL, START_TOUR,COUNT_FIRST,SHOW_MODAL,PAYMENT_MODAL} from "./type";

export const profileDropdown = (data) => async (dispatch) => {
    dispatch({ type: PROFILE_DROPDOWN, payload: data });
};
export const documentDropdown = (data) => async (dispatch) => {
    dispatch({ type: INTRO_DOCUMENTATION, payload: data });
};
export const modalCard = (data) => async (dispatch) => {
    dispatch({ type: INTROMODAL, payload: data });
};
export const startTour = (data) => async (dispatch) => {
    dispatch({ type: START_TOUR, payload: data });
};
export const countFirst = (data) => async (dispatch) => {
    dispatch({ type: COUNT_FIRST, payload: data });
};
export const showModalOnstart=(data)=>(dispatch)=>{
    dispatch({ type: SHOW_MODAL, payload: data });
}
export const paymentModalOpen=(data)=>(dispatch)=>{
    dispatch({ type: PAYMENT_MODAL, payload: data });

}
