import React, { useState } from 'react'
import Header from '../../layout/Header'
import { shopify } from '../../../Image/Index'
import { Link } from 'react-router-dom'

function Integration() {
    return (
        <div>
            <Header  />

            <div className="dashboard__content">
                <div className="container-fluid">

                    <div className="dashboard__transactionsCard">
                        <div className="dashboard__header">
                            <div className="dashboard__left">
                                <h4>Integration Hub: Automate Customer and Transaction Processes</h4>
                            </div>
                        </div>

                        <div className="dashboard__body">
                            <div className='enhance-block'>
                                <p>Enhance your loyalty programs by consolidating information across all touchpoints, ensuring a cohesive and personalized customer experience.</p>
                            </div>
 
                            <div className='integration-container'>
                                <div className='integration'>
                                    <Link to="https://x-series-support.lightspeedhq.com/hc/en-us" target="_blank"><img src="https://theme.zdassets.com/theme_assets/821742/d9a7b350346b57b76b3c0f8baa1c730600fbc375.svg" style={{height:"30px"}}></img></Link>
                                    <p> Novus Loyalty can integrate with Lightspeed Retail (X-Series) to fetch detailed sales and customer data from your retail operations. This integration enables real-time tracking of customer purchases, and sales performance, providing valuable insights to drive targeted loyalty programs and personalized marketing efforts.</p>
                                </div>
                                <div className='integration'>
                                    <Link to="https://www.shopify.com/" target="_blank"><img src={shopify} ></img></Link>
                                    <p> Novus Loyalty can seamlessly integrate with Shopify to access customer purchase history, product preferences, and shopping behaviors from your e-commerce platform. This data integration empowers you to create customized loyalty rewards, personalized promotions, and improve customer engagement based on detailed transactional data and customer interactions.</p>  
                                </div>
                                <div className='integration'>
                                    <h4>Comprehensive API Integration:</h4>
                                    <p>Novus Loyalty’s flexible API integration capabilities allow it to connect with various other software solutions you may be using. This means Novus can fetch and utilize data from different systems such as CRM, ERP, or POS platforms, consolidating information to enhance your loyalty programs and deliver a cohesive customer experience across all touchpoints.</p>                        
                                </div>
                            </div>
 
                           
                        </div>
                    </div>
                </div></div>
        </div>
    )
}

export default Integration