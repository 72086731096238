import React, { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import { useNavigate } from "react-router-dom";
import { getDate, selectValueGetter } from "../utils/helper";
import ProgramsCard from "../components/partial/ProgramsCard";
import { getCampaigns, clearCampaigns } from "../actions/campaigns";
import Page from "../components/pages/Pagination";
import Paginated from "../components/pages/campaigns/paginated/Paginated";

import SelectValidator from "../components/partial/SelectValidator";
import { ValidatorForm } from "react-form-validator-core";
import { Modal, Button, CloseButton } from "react-bootstrap";
import { connect } from "react-redux";
// import { getmerchantschema } from "./campaigns/services";
import introJs from "intro.js";
import "intro.js/introjs.css";
import ModalCard from "../components/layout/ModalCard";
import { useSelector } from "react-redux";
const IntroLProgram = ({ getCampaigns, clearCampaigns, modalView }) => {
    const [data, setData] = useState([]);
    // eslint-disable-next-line
    const [selectedCampaign, setSelectedCampaign] = useState();
    const intro = introJs();
    let exitOnLast = false;
    const Campaigns=[
        {
            "version": "4",
            "code": "NTH-CAM005151",
            "coalitionCampaignCode": null,
            "coalitionProgramCode": null,
            "status": true,
            "priority": 999,
            "name": "Point campaign",
            "description": "New campaign to grow my organization",
            "timeZone": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
            "isTest": false,
            "isVisibile": true,
            "Taglastcheck": null,
            "Type": null,
            "Tiers": null,
            "Membership": null,
            "Range": null,
            "startDateTime": "2023-07-31T00:00:00",
            "endDateTime": "2024-01-31T00:00:00",
            "visibilityDateTime": "0001-01-01T00:00:00",
            "offerDetail": {
                "offerCode": "",
                "title": "Point campaign",
                "programCode": null,
                "internalLink": null,
                "externalLink": null,
                "imageLink": null,
                "mobileImageLink": null,
                "logoImageLink": null,
                "description": null,
                "redemptionProcess": null,
                "escalationMatrix": null,
                "termAndCondition": null,
                "purchaseable": {
                    "isPurchaseable": false,
                    "isActivated": false,
                    "price": 0,
                    "validityPeriod": 86400,
                    "validityPeriodType": 0,
                    "isImported": false,
                    "voucherTemplate": null
                },
                "longDescription": null,
                "code": null,
                "tags": null,
                "startDateTime": "0001-01-01T00:00:00",
                "endDateTime": null,
                "visibilityDateTime": "0001-01-01T00:00:00",
                "status": false,
                "data": null,
                "merchantName": null,
                "_CampaignRejection": null,
                "isEditable": true,
                "category": null
            },
            "tags": [],
            "internalTags": [],
            "earningRule": {
                "earningRuleEvalutionType": 2,
                "rules": [
                    {
                        "infixRule": [
                            {
                                "entityName": "Transaction",
                                "field": "activityType",
                                "jsonPath": "$.Transaction[?(@.activityType=='fgtg7ui')]",
                                "op": "equal",
                                "value": [
                                    "fgtg7ui"
                                ],
                                "type": "string",
                                "format": null
                            }
                        ],
                        "prefixRule": {
                            "condition": "AND",
                            "valid": true,
                            "rules": [
                                {
                                    "id": "activityType-5",
                                    "field": "activityType-5",
                                    "type": "string",
                                    "input": "text",
                                    "operator": "equal",
                                    "value": "fgtg7ui",
                                    "data": {
                                        "class": "Transaction"
                                    }
                                }
                            ]
                        },
                        "data": [
                            {
                                "Sponsors": [
                                    {
                                        "Type": "Sponsor",
                                        "Code": "SPO000057",
                                        "Percent": 100
                                    }
                                ],
                                "expirationRule": {
                                    "afterDuration": "Day(s)",
                                    "afterSpecificNumber": 365,
                                    "onAfter": true
                                },
                                "redeemRule": {
                                    "redeemRuleBy": false,
                                    "afterSpecificTransaction": false
                                },
                                "Value": 10,
                                "EarnigWho": "Customer",
                                "RedeemType": "Fix-Point",
                                "RewardRatio": 25,
                                "TransactionFieldForEvery": "",
                                "isEvery": false,
                                "everyValue": 0
                            }
                        ],
                        "function": [
                            {
                                "infixRule": [],
                                "prefixRule": null,
                                "duration": ""
                            }
                        ],
                        "dbFunctionRule": [
                            {
                                "sqlRule": "",
                                "infixRule": [],
                                "prefixRule": null
                            }
                        ]
                    }
                ]
            },
            "InfixQualifyingRules": [],
            "PrefixQualifyingRules": [],
            "merchantTransactionSchema": {
                "schemaId": "a1a9e205-4e90-4c1a-8de8-6815e4e0348c",
                "schemaName": "Travel",
                "merchants": [
                    "MER001070"
                ]
            },
            "processingMode": 0,
            "approvalStatus": 1,
            "restrictedPrograms": [],
            "campaignGlobalLimit": {
                "isGlobalCampaignLimit": false,
                "globalLimits": []
            },
            "campaignCustomerLimit": {
                "isCustomerLimit": false,
                "customerLimits": [
                    {
                        "walletKey": "point",
                        "walletName": "point",
                        "maxLimit": 0,
                        "timePeriod": "2",
                        "limit": 0
                    },
                    {
                        "walletKey": "discount",
                        "walletName": "discount",
                        "maxLimit": 0,
                        "timePeriod": "2",
                        "limit": 0
                    },
                    {
                        "walletKey": "currency",
                        "walletName": "currency",
                        "maxLimit": 0,
                        "timePeriod": "2",
                        "limit": 0
                    }
                ]
            },
            "referralRule": {
                "isRefferalEnabled": null,
                "campaignCodes": null,
                "referralFunctionRule": null,
                "mappedSchema": null
            },
            "selfMadeCampaign": false,
            "merchantId": [
                "MER001070"
            ],
            "schemaCode": null,
            "id": "d93b9e0e-2ed9-4e5c-b930-426914a7e600",
            "discriminator": "Campaign",
            "createdDate": "2023-07-31T07:01:34.6128261Z",
            "updatedDate": "2023-07-31T07:01:34.6128261Z",
            "createdBy": "asftoc_310723065537638263833398271537",
            "updatedBy": "asftoc_310723065537638263833398271537"
        },
        {
            "version": "2",
            "code": "NTH-CAM005174",
            "coalitionCampaignCode": null,
            "coalitionProgramCode": null,
            "status": true,
            "priority": 999,
            "name": "Referral campaign",
            "description": "New campaign to grow my organization",
            "timeZone": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
            "isTest": false,
            "isVisibile": true,
            "Taglastcheck": null,
            "Type": null,
            "Tiers": null,
            "Membership": null,
            "Range": null,
            "startDateTime": "2023-08-10T00:00:00",
            "endDateTime": "2024-02-10T00:00:00",
            "visibilityDateTime": "0001-01-01T00:00:00",
            "offerDetail": {
                "offerCode": "",
                "title": "Referral campaign",
                "programCode": null,
                "internalLink": null,
                "externalLink": null,
                "imageLink": null,
                "mobileImageLink": null,
                "logoImageLink": null,
                "description": null,
                "redemptionProcess": null,
                "escalationMatrix": null,
                "termAndCondition": null,
                "purchaseable": {
                    "isPurchaseable": false,
                    "isActivated": false,
                    "price": 0,
                    "validityPeriod": 86400,
                    "validityPeriodType": 0,
                    "isImported": false,
                    "voucherTemplate": null
                },
                "longDescription": null,
                "code": null,
                "tags": null,
                "startDateTime": "0001-01-01T00:00:00",
                "endDateTime": null,
                "visibilityDateTime": "0001-01-01T00:00:00",
                "status": false,
                "data": null,
                "merchantName": null,
                "_CampaignRejection": null,
                "isEditable": true,
                "category": null
            },
            "tags": [],
            "internalTags": [],
            "earningRule": {
                "earningRuleEvalutionType": 2,
                "rules": [
                    {
                        "infixRule": [
                            {
                                "entityName": "Transaction",
                                "field": "activityType",
                                "jsonPath": "$.Transaction[?(@.activityType=='Referee')]",
                                "op": "in",
                                "value": [
                                    "Referee"
                                ],
                                "type": "string",
                                "format": null
                            }
                        ],
                        "prefixRule": {
                            "condition": "AND",
                            "valid": true,
                            "rules": [
                                {
                                    "id": "activityType-4",
                                    "field": "activityType-4",
                                    "type": "string",
                                    "input": "text",
                                    "operator": "in",
                                    "value": "Referee",
                                    "data": {
                                        "class": "Transaction"
                                    }
                                }
                            ]
                        },
                        "data": [
                            {
                                "Sponsors": [
                                    {
                                        "Type": "Sponsor",
                                        "Code": "SPO000057",
                                        "Percent": 100
                                    }
                                ],
                                "expirationRule": {
                                    "afterDuration": "Day(s)",
                                    "afterSpecificNumber": 365,
                                    "onAfter": true
                                },
                                "redeemRule": {
                                    "redeemRuleBy": false,
                                    "afterSpecificTransaction": false
                                },
                                "Value": 10,
                                "RedeemType": "Fix-Point",
                                "RewardRatio": 25,
                                "TransactionFieldForEvery": "",
                                "isEvery": false,
                                "everyValue": 0,
                                "EarnigWho": "Customer",
                                "IsReferral": true,
                                "CustomerIdent": "customercode"
                            }
                        ],
                        "function": [
                            {
                                "infixRule": [],
                                "prefixRule": null,
                                "duration": ""
                            }
                        ],
                        "dbFunctionRule": [
                            {
                                "sqlRule": "",
                                "infixRule": [],
                                "prefixRule": null
                            }
                        ]
                    }
                ]
            },
            "InfixQualifyingRules": [],
            "PrefixQualifyingRules": [],
            "merchantTransactionSchema": {
                "schemaId": "a1a9e205-4e90-4c1a-8de8-6815e4e0348c",
                "schemaName": "Referral",
                "merchants": [
                    "MER001070"
                ]
            },
            "processingMode": 0,
            "approvalStatus": 1,
            "restrictedPrograms": [],
            "campaignGlobalLimit": {
                "isGlobalCampaignLimit": false,
                "globalLimits": []
            },
            "campaignCustomerLimit": {
                "isCustomerLimit": false,
                "customerLimits": [
                    {
                        "walletKey": "point",
                        "walletName": "point",
                        "maxLimit": 0,
                        "timePeriod": "2",
                        "limit": 0
                    },
                    {
                        "walletKey": "discount",
                        "walletName": "discount",
                        "maxLimit": 0,
                        "timePeriod": "2",
                        "limit": 0
                    },
                    {
                        "walletKey": "currency",
                        "walletName": "currency",
                        "maxLimit": 0,
                        "timePeriod": "2",
                        "limit": 0
                    }
                ]
            },
            "referralRule": {
                "isRefferalEnabled": null,
                "campaignCodes": null,
                "referralFunctionRule": null,
                "mappedSchema": null
            },
            "selfMadeCampaign": false,
            "merchantId": null,
            "schemaCode": null,
            "id": "0cb1ad5b-111b-4eb4-8f6a-c3b652a13d45",
            "discriminator": "Campaign",
            "createdDate": "2023-08-10T05:58:41.1427363Z",
            "updatedDate": "2023-08-10T05:58:41.1427363Z",
            "createdBy": "asftoc_310723065537638263833398271537",
            "updatedBy": "asftoc_310723065537638263833398271537"
        },
        {
            "version": "3",
            "code": "NTH-CAM005175",
            "coalitionCampaignCode": null,
            "coalitionProgramCode": null,
            "status": true,
            "priority": 999,
            "name": "Planning of Travel",
            "description": null,
            "timeZone": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
            "isTest": false,
            "isVisibile": false,
            "Taglastcheck": null,
            "Type": null,
            "Tiers": null,
            "Membership": null,
            "Range": null,
            "startDateTime": "2023-05-17T00:00:00",
            "endDateTime": "2024-05-17T00:00:00",
            "visibilityDateTime": "0001-01-01T00:00:00",
            "offerDetail": {
                "offerCode": "",
                "title": "Point campaign",
                "programCode": null,
                "internalLink": null,
                "externalLink": null,
                "imageLink": null,
                "mobileImageLink": null,
                "logoImageLink": null,
                "description": null,
                "redemptionProcess": null,
                "escalationMatrix": null,
                "termAndCondition": null,
                "purchaseable": {
                    "isPurchaseable": false,
                    "isActivated": false,
                    "price": 0,
                    "validityPeriod": 86400,
                    "validityPeriodType": 0,
                    "isImported": false,
                    "voucherTemplate": null
                },
                "longDescription": null,
                "code": null,
                "tags": null,
                "startDateTime": "0001-01-01T00:00:00",
                "endDateTime": null,
                "visibilityDateTime": "0001-01-01T00:00:00",
                "status": false,
                "data": null,
                "merchantName": null,
                "_CampaignRejection": null,
                "isEditable": true,
                "category": null
            },
            "tags": [],
            "internalTags": [],
            "earningRule": {
                "earningRuleEvalutionType": 0,
                "rules": []
            },
            "InfixQualifyingRules": [],
            "PrefixQualifyingRules": [],
            "merchantTransactionSchema": {
                "schemaId": "4e2b7e6d-1fed-45a0-8640-26b1af6f5f20",
                "schemaName": "Travel",
                "merchants": [
                    "MER001070"
                ]
            },
            "processingMode": 0,
            "approvalStatus": 1,
            "restrictedPrograms": [],
            "campaignGlobalLimit": {
                "isGlobalCampaignLimit": false,
                "globalLimits": []
            },
            "campaignCustomerLimit": {
                "isCustomerLimit": false,
                "customerLimits": [
                    {
                        "walletKey": "point",
                        "walletName": "point",
                        "maxLimit": 0,
                        "timePeriod": "2",
                        "limit": 0
                    },
                    {
                        "walletKey": "discount",
                        "walletName": "discount",
                        "maxLimit": 0,
                        "timePeriod": "2",
                        "limit": 0
                    },
                    {
                        "walletKey": "currency",
                        "walletName": "currency",
                        "maxLimit": 0,
                        "timePeriod": "2",
                        "limit": 0
                    }
                ]
            },
            "referralRule": {
                "isRefferalEnabled": null,
                "campaignCodes": null,
                "referralFunctionRule": null,
                "mappedSchema": null
            },
            "selfMadeCampaign": false,
            "merchantId": [
                "MER001070"
            ],
            "schemaCode": null,
            "id": "29565a67-e417-4385-93ad-88af065a4108",
            "discriminator": "Campaign",
            "createdDate": "2023-08-10T06:17:34.7856706Z",
            "updatedDate": "2023-08-10T06:17:34.7856706Z",
            "createdBy": "asftoc_310723065537638263833398271537",
            "updatedBy": "asftoc_310723065537638263833398271537"
        },
        {
            "version": "2",
            "code": "NTH-CAM005206",
            "coalitionCampaignCode": null,
            "coalitionProgramCode": null,
            "status": true,
            "priority": 999,
            "name": "Post-trip Offers",
            "description": null,
            "timeZone": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
            "isTest": false,
            "isVisibile": false,
            "Taglastcheck": null,
            "Type": null,
            "Tiers": null,
            "Membership": null,
            "Range": null,
            "startDateTime": "2023-05-17T00:00:00",
            "endDateTime": "2024-05-17T00:00:00",
            "visibilityDateTime": "0001-01-01T00:00:00",
            "offerDetail": {
                "offerCode": "",
                "title": "Point campaign",
                "programCode": null,
                "internalLink": null,
                "externalLink": null,
                "imageLink": null,
                "mobileImageLink": null,
                "logoImageLink": null,
                "description": null,
                "redemptionProcess": null,
                "escalationMatrix": null,
                "termAndCondition": null,
                "purchaseable": {
                    "isPurchaseable": false,
                    "isActivated": false,
                    "price": 0,
                    "validityPeriod": 86400,
                    "validityPeriodType": 0,
                    "isImported": false,
                    "voucherTemplate": null
                },
                "longDescription": null,
                "code": null,
                "tags": null,
                "startDateTime": "0001-01-01T00:00:00",
                "endDateTime": null,
                "visibilityDateTime": "0001-01-01T00:00:00",
                "status": false,
                "data": null,
                "merchantName": null,
                "_CampaignRejection": null,
                "isEditable": true,
                "category": null
            },
            "tags": [],
            "internalTags": [],
            "earningRule": {
                "earningRuleEvalutionType": 0,
                "rules": [
                    {
                        "infixRule": [
                            {
                                "entityName": "Transaction",
                                "field": "activityType",
                                "jsonPath": "$.Transaction[?(@.activityType=='Post-trip')]",
                                "op": "equal",
                                "value": [
                                    "Post-trip"
                                ],
                                "type": "string",
                                "format": null
                            }
                        ],
                        "prefixRule": {
                            "condition": "AND",
                            "rules": [
                                {
                                    "id": "activityType-5",
                                    "field": "activityType-5",
                                    "type": "string",
                                    "input": "text",
                                    "operator": "equal",
                                    "value": "Post-trip",
                                    "data": {
                                        "class": "Transaction"
                                    }
                                }
                            ],
                            "valid": true
                        },
                        "data": [
                            {
                                "RedeemType": "Fix-Point",
                                "Value": 10,
                                "isEvery": false,
                                "earning_frequency": true,
                                "RewardRatio": 25,
                                "EarnigWho": "Customer",
                                "AccountWalletType": "Point",
                                "Sponsors": [
                                    {
                                        "Type": "Sponsor",
                                        "Code": "SPO000057",
                                        "Percent": 100
                                    }
                                ],
                                "redeemRule": {
                                    "redeemRuleBy": false,
                                    "afterSpecificTransaction": false
                                },
                                "expirationRule": {
                                    "onAfter": true,
                                    "afterSpecificNumber": 365,
                                    "afterDuration": "Day(s)"
                                }
                            }
                        ],
                        "function": [
                            {
                                "infixRule": [],
                                "prefixRule": null,
                                "duration": ""
                            }
                        ],
                        "dbFunctionRule": [
                            {
                                "sqlRule": "",
                                "infixRule": [],
                                "prefixRule": null
                            }
                        ]
                    }
                ]
            },
            "InfixQualifyingRules": [],
            "PrefixQualifyingRules": [],
            "merchantTransactionSchema": {
                "schemaId": "4e2b7e6d-1fed-45a0-8640-26b1af6f5f20",
                "schemaName": "Travel",
                "merchants": [
                    "MER001070"
                ]
            },
            "processingMode": 0,
            "approvalStatus": 1,
            "restrictedPrograms": [],
            "campaignGlobalLimit": {
                "isGlobalCampaignLimit": false,
                "globalLimits": []
            },
            "campaignCustomerLimit": {
                "isCustomerLimit": false,
                "customerLimits": [
                    {
                        "walletKey": "point",
                        "walletName": "point",
                        "maxLimit": 0,
                        "timePeriod": "2",
                        "limit": 0
                    },
                    {
                        "walletKey": "discount",
                        "walletName": "discount",
                        "maxLimit": 0,
                        "timePeriod": "2",
                        "limit": 0
                    },
                    {
                        "walletKey": "currency",
                        "walletName": "currency",
                        "maxLimit": 0,
                        "timePeriod": "2",
                        "limit": 0
                    }
                ]
            },
            "referralRule": {
                "isRefferalEnabled": null,
                "campaignCodes": null,
                "referralFunctionRule": null,
                "mappedSchema": null
            },
            "selfMadeCampaign": false,
            "merchantId": null,
            "schemaCode": null,
            "id": "4bdc9788-a506-4b77-b006-2d73679781f9",
            "discriminator": "Campaign",
            "createdDate": "2023-08-29T09:50:41.3509508Z",
            "updatedDate": "2023-08-29T09:50:41.3509509Z",
            "createdBy": "asftoc_310723065537638263833398271537",
            "updatedBy": "asftoc_310723065537638263833398271537"
        },
        {
            "version": "4",
            "code": "NTH-CAM005208",
            "coalitionCampaignCode": null,
            "coalitionProgramCode": null,
            "status": true,
            "priority": 999,
            "name": "Point campaign",
            "description": "New campaign to grow my organization",
            "timeZone": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
            "isTest": false,
            "isVisibile": true,
            "Taglastcheck": null,
            "Type": null,
            "Tiers": null,
            "Membership": null,
            "Range": null,
            "startDateTime": "2023-08-31T00:00:00",
            "endDateTime": "2024-03-02T00:00:00",
            "visibilityDateTime": "0001-01-01T00:00:00",
            "offerDetail": {
                "offerCode": "",
                "title": "Point campaign",
                "programCode": null,
                "internalLink": null,
                "externalLink": null,
                "imageLink": null,
                "mobileImageLink": null,
                "logoImageLink": null,
                "description": null,
                "redemptionProcess": null,
                "escalationMatrix": null,
                "termAndCondition": null,
                "purchaseable": {
                    "isPurchaseable": false,
                    "isActivated": false,
                    "price": 0,
                    "validityPeriod": 86400,
                    "validityPeriodType": 0,
                    "isImported": false,
                    "voucherTemplate": null
                },
                "longDescription": null,
                "code": null,
                "tags": null,
                "startDateTime": "0001-01-01T00:00:00",
                "endDateTime": null,
                "visibilityDateTime": "0001-01-01T00:00:00",
                "status": false,
                "data": null,
                "merchantName": null,
                "_CampaignRejection": null,
                "isEditable": true,
                "category": null
            },
            "tags": [],
            "internalTags": [],
            "earningRule": {
                "earningRuleEvalutionType": 2,
                "rules": [
                    {
                        "infixRule": [
                            {
                                "entityName": "Transaction",
                                "field": "activityType",
                                "jsonPath": "$.Transaction[?(@.activityType!=432)]",
                                "op": "not_equal",
                                "value": [
                                    "432"
                                ],
                                "type": "string",
                                "format": null
                            }
                        ],
                        "prefixRule": {
                            "condition": "AND",
                            "valid": true,
                            "rules": [
                                {
                                    "id": "activityType-5",
                                    "field": "activityType-5",
                                    "type": "string",
                                    "input": "text",
                                    "operator": "not_equal",
                                    "value": "432",
                                    "data": {
                                        "class": "Transaction"
                                    }
                                }
                            ]
                        },
                        "data": [
                            {
                                "Sponsors": [
                                    {
                                        "Type": "Sponsor",
                                        "Code": "SPO000057",
                                        "Percent": 100
                                    }
                                ],
                                "expirationRule": {
                                    "afterDuration": "Day(s)",
                                    "afterSpecificNumber": 365,
                                    "onAfter": true
                                },
                                "redeemRule": {
                                    "redeemRuleBy": false,
                                    "afterSpecificTransaction": false
                                },
                                "Value": 10,
                                "EarnigWho": "Customer",
                                "RedeemType": "Fix-Point",
                                "RewardRatio": 25,
                                "TransactionFieldForEvery": "bookingValue",
                                "isEvery": true,
                                "everyValue": "435"
                            }
                        ],
                        "function": [
                            {
                                "infixRule": [],
                                "prefixRule": null,
                                "duration": ""
                            }
                        ],
                        "dbFunctionRule": [
                            {
                                "sqlRule": "{\"sql\":\"c.transactionDetail.activityType != '432'\"}",
                                "infixRule": [
                                    {
                                        "entityName": "Transaction",
                                        "field": "c.transactionDetail",
                                        "jsonPath": "",
                                        "op": "not_equal",
                                        "value": [
                                            "432"
                                        ],
                                        "type": "",
                                        "format": ""
                                    }
                                ],
                                "prefixRule": {
                                    "condition": "AND",
                                    "valid": true,
                                    "rules": [
                                        {
                                            "id": "c.transactionDetail.activityType",
                                            "field": "c.transactionDetail.activityType",
                                            "type": "string",
                                            "input": "text",
                                            "operator": "not_equal",
                                            "value": "432",
                                            "data": {
                                                "class": "Transaction"
                                            }
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                ]
            },
            "InfixQualifyingRules": [],
            "PrefixQualifyingRules": [],
            "merchantTransactionSchema": {
                "schemaId": "a1a9e205-4e90-4c1a-8de8-6815e4e0348c",
                "schemaName": "Travel",
                "merchants": [
                    "MER001070"
                ]
            },
            "processingMode": 0,
            "approvalStatus": 1,
            "restrictedPrograms": [],
            "campaignGlobalLimit": {
                "isGlobalCampaignLimit": false,
                "globalLimits": []
            },
            "campaignCustomerLimit": {
                "isCustomerLimit": false,
                "customerLimits": [
                    {
                        "walletKey": "point",
                        "walletName": "point",
                        "maxLimit": 0,
                        "timePeriod": "2",
                        "limit": 0
                    },
                    {
                        "walletKey": "discount",
                        "walletName": "discount",
                        "maxLimit": 0,
                        "timePeriod": "2",
                        "limit": 0
                    },
                    {
                        "walletKey": "currency",
                        "walletName": "currency",
                        "maxLimit": 0,
                        "timePeriod": "2",
                        "limit": 0
                    }
                ]
            },
            "referralRule": {
                "isRefferalEnabled": null,
                "campaignCodes": null,
                "referralFunctionRule": null,
                "mappedSchema": null
            },
            "selfMadeCampaign": false,
            "merchantId": [
                "MER001070"
            ],
            "schemaCode": null,
            "id": "1c794cb8-b29f-4c58-9dbf-334322d15195",
            "discriminator": "Campaign",
            "createdDate": "2023-09-05T11:32:25.3986297Z",
            "updatedDate": "2023-09-05T11:32:25.3986298Z",
            "createdBy": "asftoc_310723065537638263833398271537",
            "updatedBy": "asftoc_310723065537638263833398271537"
        },
        {
            "version": "2",
            "code": "NTH-CAM005220",
            "coalitionCampaignCode": null,
            "coalitionProgramCode": null,
            "status": true,
            "priority": 999,
            "name": "Post-trip Offers",
            "description": null,
            "timeZone": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
            "isTest": false,
            "isVisibile": false,
            "Taglastcheck": null,
            "Type": null,
            "Tiers": null,
            "Membership": null,
            "Range": null,
            "startDateTime": "2023-05-17T00:00:00",
            "endDateTime": "2024-05-17T00:00:00",
            "visibilityDateTime": "0001-01-01T00:00:00",
            "offerDetail": {
                "offerCode": "",
                "title": "Point campaign",
                "programCode": null,
                "internalLink": null,
                "externalLink": null,
                "imageLink": null,
                "mobileImageLink": null,
                "logoImageLink": null,
                "description": null,
                "redemptionProcess": null,
                "escalationMatrix": null,
                "termAndCondition": null,
                "purchaseable": {
                    "isPurchaseable": false,
                    "isActivated": false,
                    "price": 0,
                    "validityPeriod": 86400,
                    "validityPeriodType": 0,
                    "isImported": false,
                    "voucherTemplate": null
                },
                "longDescription": null,
                "code": null,
                "tags": null,
                "startDateTime": "0001-01-01T00:00:00",
                "endDateTime": null,
                "visibilityDateTime": "0001-01-01T00:00:00",
                "status": false,
                "data": null,
                "merchantName": null,
                "_CampaignRejection": null,
                "isEditable": true,
                "category": null
            },
            "tags": [],
            "internalTags": [],
            "earningRule": {
                "earningRuleEvalutionType": 0,
                "rules": [
                    {
                        "infixRule": [
                            {
                                "entityName": "Transaction",
                                "field": "activityType",
                                "jsonPath": "$.Transaction[?(@.activityType=='Post-trip')]",
                                "op": "equal",
                                "value": [
                                    "Post-trip"
                                ],
                                "type": "string",
                                "format": null
                            }
                        ],
                        "prefixRule": {
                            "condition": "AND",
                            "rules": [
                                {
                                    "id": "activityType-5",
                                    "field": "activityType-5",
                                    "type": "string",
                                    "input": "text",
                                    "operator": "equal",
                                    "value": "Post-trip",
                                    "data": {
                                        "class": "Transaction"
                                    }
                                }
                            ],
                            "valid": true
                        },
                        "data": [
                            {
                                "RedeemType": "Fix-Point",
                                "Value": 10,
                                "isEvery": false,
                                "earning_frequency": true,
                                "RewardRatio": 25,
                                "EarnigWho": "Customer",
                                "AccountWalletType": "Point",
                                "Sponsors": [
                                    {
                                        "Type": "Sponsor",
                                        "Code": "SPO000057",
                                        "Percent": 100
                                    }
                                ],
                                "redeemRule": {
                                    "redeemRuleBy": false,
                                    "afterSpecificTransaction": false
                                },
                                "expirationRule": {
                                    "onAfter": true,
                                    "afterSpecificNumber": 365,
                                    "afterDuration": "Day(s)"
                                }
                            }
                        ],
                        "function": [
                            {
                                "infixRule": [],
                                "prefixRule": null,
                                "duration": ""
                            }
                        ],
                        "dbFunctionRule": [
                            {
                                "sqlRule": "",
                                "infixRule": [],
                                "prefixRule": null
                            }
                        ]
                    }
                ]
            },
            "InfixQualifyingRules": [],
            "PrefixQualifyingRules": [],
            "merchantTransactionSchema": {
                "schemaId": "4e2b7e6d-1fed-45a0-8640-26b1af6f5f20",
                "schemaName": "Travel",
                "merchants": [
                    "MER001070"
                ]
            },
            "processingMode": 0,
            "approvalStatus": 1,
            "restrictedPrograms": [],
            "campaignGlobalLimit": {
                "isGlobalCampaignLimit": false,
                "globalLimits": []
            },
            "campaignCustomerLimit": {
                "isCustomerLimit": false,
                "customerLimits": [
                    {
                        "walletKey": "point",
                        "walletName": "point",
                        "maxLimit": 0,
                        "timePeriod": "2",
                        "limit": 0
                    },
                    {
                        "walletKey": "discount",
                        "walletName": "discount",
                        "maxLimit": 0,
                        "timePeriod": "2",
                        "limit": 0
                    },
                    {
                        "walletKey": "currency",
                        "walletName": "currency",
                        "maxLimit": 0,
                        "timePeriod": "2",
                        "limit": 0
                    }
                ]
            },
            "referralRule": {
                "isRefferalEnabled": null,
                "campaignCodes": null,
                "referralFunctionRule": null,
                "mappedSchema": null
            },
            "selfMadeCampaign": false,
            "merchantId": null,
            "schemaCode": null,
            "id": "6a3b2a07-8937-42e2-bbf0-c1e48ca91139",
            "discriminator": "Campaign",
            "createdDate": "2023-09-06T05:05:56.5119813Z",
            "updatedDate": "2023-09-06T05:05:56.5119813Z",
            "createdBy": "asftoc_310723065537638263833398271537",
            "updatedBy": "asftoc_310723065537638263833398271537"
        }
    ]
    const [expired, setExpired] = useState([]);
    const [active, setActive] = useState([]);
    const [activeTrue, setActiveTrue] = useState(false);
    const [expiredTrue, setExpiredTrue] = useState(false);
    const [normalView, setNormalView] = useState(true);
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPreviousPage, setHasPreviousPage] = useState(false);
    const [total, setTotal] = useState(0);
    const [loader, setLoader] = useState(false);
    const [valueselect, setValueSelect] = useState("all");
    // const [valueselected, setValueSelected] = useState("alldata");
    const [showButton, setShowButton] = useState(false);
    const [option] = useState([
        { label: "All Campaigns", value: "all" },
        { label: "Active campaigns", value: "active" },
        { label: "Expired campaigns", value: "expired" },
    ]);

    const [options] = useState([
        { label: "Points", value: "points" },
        { label: "Referral", value: "referral" },
        { label: "Voucher", value: "voucher" },
    ]);
    // const [options, setOptions] = useState();

    const pageSize = 6;
    const navigate = useNavigate();
    useEffect(() => {
        fetch();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        activeExpired();
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        //campaign();
        // eslint-disable-next-line
    }, []);

    const fetch = async () => {
        setLoader(true);
        await clearCampaigns();
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const id = localStorage.getItem("Id");
        // const merchantCode = localStorage.getItem("merchantcode");
       // await getCampaigns(token?.access_token, id);
        // let response = await getmerchantschema(token?.access_token, merchantCode);
        // let temp = [];
        // if (response.length > 0) {
        //     response.map((item) => temp.push({ label: item.schemaName, value: item.schemaId }));
        //     setOptions(temp);
        // } else {
        //     temp.push({ label: "No options" });
        //     setOptions(temp);
        // }

        setLoader(false);
    };

    const campaign = async () => {
        setHasNextPage(Campaigns?.data.length >= pageSize);
        setHasPreviousPage(currentPage > 0);
        setTotal(Campaigns?.data.length);
        if (Campaigns?.data.length > 0) {
            setData(Campaigns?.data.reverse());
            setShowButton(true);
        } else {
            setShowButton(false);
            setData([]);
        }
    };
    const handleClose = () => setShow(false);

    const activeExpired = () => {
        let active = [];
        let expired = [];
        let newDate = new Date();
        for (let i = 0; i < data.length; i++) {
            let date = new Date(data[i].endDateTime);
            if (newDate - date <= 0) {
                active.push(data[i]);
            } else {
                expired.push(data[i]);
            }
            setActive(active);
            setExpired(expired);
        }
    };

    const unPeople = (e) => {
        if (e.value === "all") {
            if (active.length > 0) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
            setValueSelect("all");
            setNormalView(true);
            setActiveTrue(false);
            setExpiredTrue(false);
            setCurrentPage(0);
            setTotal(data.length);
        } else if (e.value === "active") {
            if (active.length > 0) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
            setValueSelect("active");
            setNormalView(false);
            setActiveTrue(true);
            setExpiredTrue(false);
            setHasNextPage(active.length >= pageSize);
            setCurrentPage(0);
            setHasPreviousPage(currentPage > 0);
            setTotal(active.length);
        } else if (e.value === "expired") {
            if (expired.length > 0) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
            setValueSelect("expired");
            setNormalView(false);
            setActiveTrue(false);
            setExpiredTrue(true);
            setHasNextPage(expired.length >= pageSize);
            setHasPreviousPage(currentPage > 0);
            setTotal(expired.length);
            setCurrentPage(0);
        }
    };
    const pageCount = Math.ceil(total / pageSize);

    // const handleSelect = (e) => {
    //     // if (e.target.value) {
    //     //     setSelectedCampaign(e.target.value);
    //     //     setShow(false);
    //     console.log(e);
    //     if (e.value === "points") {
    //         navigate("/my-campaigns/create-campaign");
    //         setValueSelected("points");
    //     } else if (e.value === "referral") {
    //         navigate("/referral-program/create-campaign");
    //         setValueSelected("referral");
    //     } else if (e.target.value === "signup") {
    //         navigate("/signup-program/create-campaign");
    //     }
    // };

    const onHandleSelect = (e) => {
        if (e.value) {
            navigate("create-campaign", {
                state: e.value,
            });
        }
    };

    const handleCampaigns = () => {
        setShow(true);
    };
    const onSubmit = () => {};
    let complete = false;
    useEffect(() => {
        const handleLoad = () => {
            const steps = [
                {
                    element: document.getElementById("crate_new_cmp"),
                    title: "Create Campaign  ",
                    intro: "Adjust existing campaigns to align them with your business goals.",
                },
            ];
            intro.setOptions({
                steps: steps,
                exitOnOverlayClick: false,
                doneLabel: "Next",
                tooltipClass: "customTooltip",
            });
            intro.start();
        }
            setTimeout(() => {
                handleLoad()
            }, 700)
            return () => {
                intro.exit();
            };     
    }, []);
    intro.oncomplete(function (element) {
        complete = true;
        
     

    });

    intro.onexit(() => {
        setTimeout(()=>{
            if(!exitOnLast)  onHandleSelect({ value: "points" });
        },700)
    });
    intro.onchange(function (element) {
        switch (this._currentStep) {
            case 0:
                setTimeout(() => {
                    let cross = document.getElementsByClassName("introjs-skipbutton")[0];
                   if(cross) cross?.addEventListener("click", function () {
                        exitOnLast = true;
                        localStorage.setItem("firstLogin", "");
                        setShowModal(true)
                    });
                }, 650);

                break;
        }
    });
    return (
        <>
            <Header name={"My Campaigns"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            {showModal && <ModalCard show={showModal}/>}
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="dashboard__heading">
                                <div className="dashboard__headingLeft">
                                    <div className="dashboard__headingContent">
                                        <h3>My Campaigns</h3>
                                        <p>Set how your customers will earn points </p>
                                        <Button id='crate_new_cmp' disabled={true} onClick={handleCampaigns} className="btn btn-primary btn-lg">
                                            Create New Campaign
                                        </Button>
                                    </div>
                                </div>
                                <div className="dashboard__headingRight">
                                    <div className="form-group">
                                        <ValidatorForm onSubmit={onSubmit}>
                                            <SelectValidator placeholder="Select" className="" name="choice" value={selectValueGetter(option, valueselect)} options={option} onChange={unPeople} />
                                        </ValidatorForm>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {normalView &&
                                data.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((item, index) => (
                                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4" key={index}>
                                        <ProgramsCard path="/my-campaigns/edit-campaign" id={item.code} data={item} index={currentPage * 6 + index} heading={item.name} startDate={getDate(item.startDateTime, "MM-DD-YYYY")} endDate={getDate(item.endDateTime, "MM-DD-YYYY")} text={item?.description} campaignCode={item.code} sample={false} firstData={index === 0 ? true : false} />
                                    </div>
                                ))}
                            {activeTrue &&
                                active.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((item, index) => (
                                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4" key={index}>
                                        <ProgramsCard path="/my-campaigns/edit-campaign" id={item.code} data={item} index={currentPage * 6 + index} heading={item.name} startDate={getDate(item.startDateTime, "MM-DD-YYYY")} endDate={getDate(item.endDateTime, "MM-DD-YYYY")} text={item?.description} campaignCode={item.code} sample={false} />
                                    </div>
                                ))}
                            {expiredTrue &&
                                expired.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((item, index) => (
                                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4" key={index}>
                                        <ProgramsCard path="/my-campaigns/edit-campaign" id={item.code} data={item} index={currentPage * 6 + index} heading={item.name} startDate={getDate(item.startDateTime, "MM-DD-YYYY")} endDate={getDate(item.endDateTime, "MM-DD-YYYY")} text={item?.description} campaignCode={item.code} sample={false} />
                                    </div>
                                ))}
                        </div>
                        {showButton ? (
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="dashboard__paginationBox">
                                    <Page currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} hasNextPage={hasNextPage} hasPreviousPage={hasPreviousPage} value={5} />
                                </div>
                            </div>
                        ) : (
                            <h4>There is no campaign to show</h4>
                        )}
                    </div>
                </div>
            </div>
            <Modal show={show} id="showm" onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: "20px" }}>Create New Campaign</Modal.Title>
                    <CloseButton onClick={handleClose} variant="white" />
                </Modal.Header>
                <Modal.Body>
                    <p>Please select which type of Campaign you want to create</p>
                    <div className="form-group">
                        <ValidatorForm onSubmit={onSubmit}>
                            <SelectValidator placeholder="Select" className="" name="choice" options={options} onChange={onHandleSelect} />
                        </ValidatorForm>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
const mapStateToProps = (state) => ({
    Campaigns: state.Campaigns,
    modalView: state.intro.modalOpen,
});
const mapDispatchToProps = {
    getCampaigns,
    clearCampaigns,
};

export default connect(mapStateToProps, mapDispatchToProps)(IntroLProgram);
