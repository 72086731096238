import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { access_denied_hover } from "../../Image/Index";
import { decrypt } from "../../utils/encodingdecoding";

const DisableModule = ({ PackageNameList }) => {
    const [plan, setPlan] = useState({});

    useEffect(() => {
        PackageNameList.filter((ele) => {
            if (ele?.plan?.toLowerCase() === "enterprise") {
                setPlan(ele);
            }
        });
    }, [PackageNameList]);

    // useEffect(() => {
    //     PackageNameList.sort(function (a, b) {
    //         return parseFloat(a.id) - parseFloat(b.id);
    //     });
    //     console.log(PackageNameList);

    //     var currentPackage = Number(decrypt(localStorage.getItem("plan"))) ? Number(decrypt(localStorage.getItem("plan"))) : PackageNameList[0].id;
    //     const len = PackageNameList.length;
    //     for (let i = 0; i < len; i++) {
    //         if (currentPackage && currentPackage == PackageNameList[i].id) {
    //             currentPackage = PackageNameList[i].id;
    //         }
    //     }
    //     let nextPackage = PackageNameList.find((o) => o.id == currentPackage + 1);
    //     nextPackage = nextPackage ? nextPackage : PackageNameList.find((o) => o.id == currentPackage);
    //     setPlan(nextPackage);
    // }, [PackageNameList]);

    return (
        <div className="dashboard__content">
            <div className="container-fluid">
                <div className="dashboard__notAccessible">
                    <div className="notAccessibleBox">
                        <figure>
                            <img src={access_denied_hover} alt="" />
                        </figure>
                        {decrypt(localStorage.getItem("cardStatus")) === "Active" ? <h3>You are not authorized to access this page. Please upgrade your plan to access this module.</h3> : <h3>You are not authorized to access this page. Please add your card to access this module.</h3>}
                        <div className="plan_buttons">
                            {decrypt(localStorage.getItem("cardStatus")) && (
                                <div className="">
                                    <Link className="btn btn-primary" target="_blank" to={plan.payLink + "&prefilled_email=" + decrypt(localStorage.getItem("email"))}>
                                        {plan.plan}
                                    </Link>
                                </div>
                            )}
                            {!decrypt(localStorage.getItem("cardStatus")) && (
                                <div className="">
                                    <Link className="btn btn-primary" target="_blank" to={PackageNameList[0]?.payLink + "&prefilled_email=" + decrypt(localStorage.getItem("email"))}>
                                        Start free trial
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DisableModule;
