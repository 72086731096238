import React, { useEffect, useRef, useState } from "react";
// import { logo, envelope } from "../../Image/Index";
import { ValidatorForm } from "react-form-validator-core";
import { forgetPassword, Token_Generator } from "../../actions/auth";
import TextValidator from "../partial/TextValidator";
import AuthenticationSliderLeft from "./common/AuthenticationSliderLeft";
import { Link } from "react-router-dom";
import swal from "sweetalert";

function ForgotPassword() {
    const formRef = useRef();
    const [formData, setFormData] = useState({
        email: "",
    });
    const [loader, setLoader] = useState(false);
    const { email } = formData;
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        const validateEmail = (email) => {
            //eslint-disable-next-line
            return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        };
        ValidatorForm.addValidationRule("validEmail", (value) => {
            if (validateEmail(value)) {
                return true;
            }
            return false;
        });
    }, [email]);
    const onChange = (e) => {
        setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });
        if (clicked) setClicked(false);
    };
    const onSubmit = async () => {
        setLoader(true);
        setClicked(true);
        let data = { email: email };
        const token = await Token_Generator();
        const res = await forgetPassword(token?.access_token, data);
        if (res.message === "Password Reset Email Sent") {
            setLoader(false);
            swal({
                position: "center",
                icon: "success",
                title: "Reset password link successfully sent on registered email.",
                showConfirmButton: false,
                timer: 5000,
            });
        } else {
            swal({
                title: " Oops!",
                text: res.message,
                icon: "error",
                button: "oh no!",
                timer: 5000,
            });

            setLoader(false);
            setClicked(false);
        }

        // navigate("/reset-password");
        // let response = await forgotePassword(token?.access_token,formData);
        // return response
    };

    return (
        <section className="section signUpPage">
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="signUpPage__main">
                <AuthenticationSliderLeft />
                <div className="signUpPage__right">
                    <div className="signUpPage__heading">
                        <figure>
                            <img src={"/Images/logo.png"} alt="logo" />
                        </figure>
                        <h1>Forgot Password</h1>
                        <h4>Please enter your registered email ID. You will receive a password reset link that can be used to change your current password.</h4>
                    </div>
                    <div className="signUpPage__form">
                        <ValidatorForm className="signUpPage__formSection" ref={formRef} onSubmit={(e) => onSubmit(e)}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group withIcon--forgetPassword">
                                        <TextValidator placeholder="Enter Email ID" className="form-control" name="email" maxLength="50" value={email} type="text" onChange={onChange} validators={["required", "validEmail"]} errorMessages={["Please enter your email", "Enter valid email"]} />
                                        <span className="passwordToggle">
                                            <img src={"images/envelope.png"} alt="" />
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary btn-lg w-100" disabled={clicked}>
                                            Send Password Reset Link
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <p>
                                            Already have an account? <Link to="/signin">Sign In</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </ValidatorForm>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ForgotPassword;
