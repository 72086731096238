import React, { useState, useEffect } from "react";
import { Modal, Button, CloseButton } from "react-bootstrap";
// import { getVoucherEnquiry } from "../pages/campaigns/services";
import swal from "sweetalert";
import { uploadVoucher } from "../pages/campaigns/services";

const UploadVoucherModal = ({ show, close, voucherCode, handleShowUploadModal }) => {
    const [loader, setLoader] = useState(false);
    const [state, setState] = useState({
        file: "",
        error: true,
        errorMsg: "Note:- Only .csv type files are supported.",
    });
    const [response, setResponse] = useState();
    const { file, error, errorMsg } = state;

    const onChange = (e) => {
        if (e.target.files[0].type.includes("csv")) {
            setState({ ...state, file: e.target.files[0], error: false });
        } else {
            setState({ ...state, error: true });
        }
    };

    const onSubmit = async () => {
        if (file) {
            const token = JSON.parse(localStorage.getItem("token_gen"));
            setLoader(true);
            const response = await uploadVoucher(file, token?.access_token, voucherCode);
            setLoader(false);
            if (response.message === "Voucher are processing to get status refresh the page.") {
                handleShowUploadModal(false, response.message);
            } else {
                handleShowUploadModal(false, response.message);
            }
        } else {
            setState({ ...state, error: true, errorMsg: "Please upload a file" });
        }
    };

    return (
        <>
            {loader ? (
                <div className="mainPreloaderMain" style={{ position: "absolute" }}>
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            ) : (
                <Modal show={show} onHide={close} size="lg" centered>
                    <Modal.Header>
                        <Modal.Title style={{ fontSize: "20px" }}>Upload File For Voucher Template Code:-{voucherCode}</Modal.Title>
                        <CloseButton onClick={close} variant="white" />
                    </Modal.Header>
                    <Modal.Body className="dashboard__transactionsModal" style={{ position: "relative", minHeight: "85px" }}>
                        <div className="dashboard__transactionsModalProfile">
                            <div className="dashboard__transactionsModalDetails">
                                <div className="dashboard__transactionsModalBottom">
                                    {/* <ValidatorForm className="signUpPage__formSection" onSubmit={onSubmit}> */}
                                    {/* <TextValidator className="form-control" placeholder="Name" value={name} readOnly maxLength="50" /> */}
                                    <div className="uploadingExistingData__uploadBoxUploadFile">
                                        <form encType="multipart/form-data">
                                            <input className="form-control" placeholder="Uploaded File" type="file" accept=".csv,.xls,.xlsx" onChange={onChange} />
                                            {error && <span style={{ color: "red" }}>{errorMsg}</span>}
                                        </form>
                                    </div>
                                    {/* </ValidatorForm> */}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={onSubmit} disabled={error ? true : false}>
                            Submit
                        </Button>
                        <Button onClick={close}>Close</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default UploadVoucherModal;
