import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

const LineChartAnalyticalReport1 = ({data}) => {
	const [Data, setData] = useState({
		labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
		datasets: [
			{
				label: "Transaction Amount",
				data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
				fill: true,
				tension: 0.4,
				backgroundColor: "rgba(154, 0, 192, 0.35)",
				borderColor: "#9A00C0",
				borderWidth: 2,
				pointStyle: "circle",
				pointRadius: 8,
				pointBorderWidth: 2,
				pointBorderColor: "#9A00C0",
				pointBackgroundColor: "#ffffff",
				pointHoverRadius: 8,
				pointHoverBorderWidth: 2,
				pointHoverBackgroundColor: "#ffffff",
			},
		],
	});

	const options = {
		indexAxis: "x",
		element: {
			legend: {
				position: "top",
			},
		},
		responsive: true,
		scales: {
			y: {
				beginAtZero: true,
			},
		},
	};
	useEffect(() => {
		setData({ ...Data, datasets: [{ ...Data.datasets[0], data: data }] });
	},[data])

	return (
		<>
			<div className="analyticalReportChartAnalysisOne__lineChartReport">
				&nbsp;
				<Line data={Data} options={options} />
			</div>
		</>
	);
};

export default LineChartAnalyticalReport1;
