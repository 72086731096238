import React, { useEffect, useRef, useState } from "react";
import { decrypt } from "../../utils/encodingdecoding";
import Header from "../layout/Header";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../partial/TextValidator";
import { Link, useNavigate } from "react-router-dom";
// import { "images/eyeSeen", "images/eyeSeenFocus", "images/eyeUnseen", "images/eyeUnseenFocus" } from "../../Image/Index";
import AdminHeader from "../layout/AdminHeader";
import { Token_Generator } from "../../actions/auth";

const SaasLogin = () => {
    const navigate = useNavigate();
    const formRef = useRef();
    const [data, setData] = useState();
    const [geoInfo, setGeoInfo] = useState();
    const [formData, setFormData] = useState({
        contactNumber: "",
        detailAboutProject: "",
        password: "",
        email: "",
        ip: "",
        country: "",
    });
    const [loader, setLoader] = useState(false);
    const [loginError, setLoginError] = useState(false); // State variable for login error

    const { password, email, contactNumber, detailAboutProject } = formData;

    useEffect(() => {
        setFormData({
            ...formData,
            ip: geoInfo?.ip,
            country: geoInfo?.country_name,
        });
    }, [geoInfo]); // eslint-disable-line react-hooks/exhaustive-deps

    const onChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value.trim(), // Trim leading and trailing spaces from the value
        }));
        setMessage(false);
    };
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [pswdFocus, setPswdFocus] = useState(false);
    const [message, setMessage] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();

        const trimmedEmail = email.trim();
        const trimmedPassword = password.trim();
        if (trimmedEmail === "saas-admin@clavax.com" && trimmedPassword === "Admin@123" ) {

            localStorage.setItem("saasemail", email);
            sessionStorage.setItem("dashboard", "AD");
            // Successful login logic
            navigate("/register");
            setMessage(false);

            setLoginError(false); // Reset login error state
        } else {
            // Failed login logic
            setMessage(true);
            setLoginError(true); 
        }
    };

    return (
        <>
            <AdminHeader name={"Saas-Admin"} />
            <div className="dashboard__mainPagee" style={{ backgroundColor: "#e3f2fd", minHeight: "100vh" }}>
                <div className="bookademopopupbox">
                    <div className="bookademo_formbox">
                        <ValidatorForm className="signUpPage__formSection" ref={formRef} onSubmit={(e) => onSubmit(e)}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="form-label">Email ID</label>
                                        <TextValidator className="form-control" placeholder="Enter Email ID" name="email" maxLength="50" value={email} type="email" onChange={onChange} validators={["required"]} errorMessages={["Email address is required."]} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="form-label">Password</label>
                                        <div className="form-group withIcon" style={{ position: "relative" }}>
                                            <TextValidator placeholder="Enter Password" className="form-control" name="password" value={password} type={isRevealPwd ? "text" : "password"} onChange={onChange} validators={["required"]} errorMessages={["Password is required."]}  />
                                            <span
                                                className="passwordToggle"
                                                style={{
                                                    display: "block",
                                                    position: "absolute",
                                                    top: "0.4rem",
                                                    right: "0.75rem",
                                                    width: "1.25rem",
                                                    zIndex: "99",
                                                }}
                                            >
                                                {pswdFocus ? <img className="onFocus" title={isRevealPwd ? "Hide password" : "Show password"} src={isRevealPwd ? "/Images/eyeUnseenFocus.png" : "/Images/eyeSeenFocus.png"} alt="eye" onClick={() => setIsRevealPwd((prevState) => !prevState)} /> : <img  title={isRevealPwd ? "Hide password" : "Show password"} src={isRevealPwd ? "/Images/eyeUnseen.png" : "/Images/eyeSeen.png"} alt="eye" onClick={() => setIsRevealPwd((prevState) => !prevState)} />}
                                            </span>
                                            {loginError && (
                                                <p className="error-message" style={{ color: "red", fontSize: "15px", textAlign: "left" }}>
                                                    Invalid email or password
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary btn-lg w-100">
                                            Sign in
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ValidatorForm>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SaasLogin;
