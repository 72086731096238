import { getNewAuthToken, isTokenExpired } from '../utils';
import { baseURL } from '../utils/url'

export const getSchema = async (token) => {
	const valid = await isTokenExpired(token);
	try {
		if (!valid) await getNewAuthToken().then((res) => {
			localStorage.setItem("token_gen", JSON.stringify(res))
			token = res?.access_token
		})
		const fetchResponse = await fetch(baseURL + "schema", {
			method: "GET",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
		return await fetchResponse;
	} catch (error) {
		return error;
	}
};

export const getMerchantSchema = async (token) => {
	const valid = await isTokenExpired(token);
	try {
		if (!valid) await getNewAuthToken().then((res) => {
			localStorage.setItem("token_gen", JSON.stringify(res))
			token = res?.access_token
		})
		const fetchResponse = await fetch(baseURL + "getmerchantschema", {
			method: "GET",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
		return await fetchResponse;
	} catch (error) {
		return error;
	}
};
export const getCustomer = async (token) => {
	const valid = await isTokenExpired(token);
	try {
		if (!valid) await getNewAuthToken().then((res) => {
			localStorage.setItem("token_gen", JSON.stringify(res))
			token = res?.access_token
		})
		const fetchResponse = await fetch(baseURL + "getcustomer", {
			method: "GET",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
		
		return await fetchResponse.json();
	} catch (error) {
		return error;
	}
};