import React, { useState, useEffect } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";


const UploadBanner = ({ columns, data }) => {
  
    const { getTableProps, getTableBodyProps, rows, headerGroups, page, nextPage, previousPage, canPreviousPage, canNextPage, pageOptions, state, gotoPage, setPageSize, prepareRow, setGlobalFilter } = useTable(
        {
            columns,
            data,
 
        },
        useGlobalFilter,
        usePagination
    );
    const { pageIndex, pageSize } = state;

    const handlePageIndex = (event) => {
        if (event.target.value !== "") {
            const number = event.target.value ? Number(event.target.value) - 1 : 0;
            gotoPage(number);
        }
    };

    return (
        <div className="customTable">
          
            <div className="customTable__body">
                <div className="table-responsive">
                    <table className="table table-striped table-bordered" {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        {data?.length ? (
                            <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return <td {...cell.getCellProps()}>{cell.render("Cell") ? cell.render("Cell") : "Null"}</td>;
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan={9}>
                                        <p style={{ textAlign: "center", margin: 0 }}>No data found</p>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
          
        </div>
    );
};

export default UploadBanner;
