import React, { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import { profileDropdown, documentDropdown } from "../actions/intro";
import { useDispatch } from "react-redux";
import introJs from "intro.js";
import "intro.js/introjs.css";
import ModalCard from "../components/layout/ModalCard";
import ModalDocumentation from "../components/layout/DocumentationModal";
import ModalCardEnd from "../components/layout/ModalCardIn";
const categories = [
    {
        value: 58,
        name: "Fashion & Lifestyle",
    },
    {
        value: 40,
        name: "Travel & Entertainment",
    },
    {
        value: 154,
        name: "Recommended",
    },
    {
        value: 29,
        name: "Electronics",
    },
    {
        value: 21,
        name: "Home & Living",
    },
    {
        value: 6,
        name: "Gold & Jewelry",
    },
    {
        value: 11,
        name: "Food & Restaurant",
    },
    {
        value: 1,
        name: "Recommended,Electronics",
    },
];
const country = [
    {
        label: "United States",
    },
    {
        label: "Canada",
    },
    {
        label: "India",
    },
    {
        label: "Germany",
    },
    {
        label: "United Kingdom",
    },
    {
        label: "Switzerland",
    },
    {
        label: "Australia",
    },
    {
        label: "France",
    },
    {
        label: "Italy",
    },
    {
        label: "Spain",
    },
];
const IntroGiftCard1 = () => {
    const [documentation, setDocumentation] = useState(false);
    let exitOnLast = false;
    const [showModal, setShowModal] = useState(false);
    const [notLast,setNotLast]=useState(false)

    const intro = introJs();
    const dispatch = useDispatch();

    let complete = false;
    useEffect(() => {
        const handleLoad = () => {
            const steps = [
                {
                    element: document.getElementById("country"),
                    title: "Select your country ",
                    intro: "Select your country to check the available Gift cards/Vouchers.",
                },
                {
                    element: document.getElementById("gift_card"),
                    title: "Search Gift Cards",
                    intro: "Browse and discover available gift card options for integration into your loyalty program",
                },
                {
                    element: document.getElementById("intro_documentation"),
                    title: "Documentation",
                    intro: "Feature is your go-to resource hub, offering guides, resources, and instructions to assist users in understanding software functionalities.",
                },
            ];
            intro.setOptions({
                steps: steps,
                exitOnOverlayClick: false,
                tooltipClass: "customTooltip",
            });

            intro.start();
        };
        setTimeout(() => {
            handleLoad();
        }, 500);
    }, []);
    intro.oncomplete(function (element) {
        setDocumentation(true);
        complete = true;
    });
    intro.onexit(() => {
        if (!complete) {
            localStorage.setItem("firstLogin", "");
            setNotLast(true);
        } else {
            dispatch(documentDropdown(false));
            dispatch(profileDropdown(false));
        }
    });
    intro.onchange(function (element) {
        switch (this._currentStep) {
            case 2:
                setTimeout(() => {
                    let cross = document.getElementsByClassName("introjs-skipbutton")[0];
                    cross?.addEventListener("click", function () {
                        exitOnLast = true;
                        localStorage.setItem("firstLogin", "");
                    });
                }, 600);
                break;
        }
    });
    const shoWLastModal = (Ishow) => {
        setShowModal(Ishow)
        //setDocumentation(Ishow);
    };
    return (
        <>
            <Header name={"Gift Card"} />
            {!showModal && documentation && <ModalDocumentation close={shoWLastModal} />}
            {showModal && <ModalCardEnd show={showModal} />}
            {notLast && <ModalCard show={notLast}/>}
            <div className="dashboard__content">
                <div className="giftcard_mainbox">
                    <div className="gc_sidebar">
                        <button id="country" className="open_country_box">
                            <h3> Country</h3>
                        </button>
                        <input type="text" placeholder="Enter Country" />

                        <div className="form-group">
                            <div className="select_all_countries">
                                <span>Select All</span>
                                <input type="checkbox" />
                            </div>

                            <div className="country-select-form-box">
                                {country.map((ele, i) => (
                                    <ul key={i}>
                                        <li>{ele.label}</li>
                                        <input type="checkbox" name="isChecked" />
                                    </ul>
                                ))}
                                <p>Search More Countries..........</p>
                            </div>
                        </div>
                    </div>

                    <div className="gc_contentbox">
                        <div className="gc_content_pbox">
                            <input type="text" id="gift_card"  placeholder="Search Gift Cards/Vouchers" disabled />
                            <div>
                                <div className="select_all_giftcard">
                                    <div>Select All</div> <input type="checkbox" />
                                </div>
                                <div className="accordion" id="accordionExample">
                                    {categories?.map((category, i) => (
                                        <div className="accordion-item" key={i}>
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${category.value}`} aria-expanded="false" aria-controls={category.value}>
                                                    {category.name + "  (" + category.value + ")"}
                                                </button>
                                                <input type="checkbox" />
                                            </h2>

                                            <div className="accordion-collapse collapse" aria-labelledby={`#${i}`} data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <div className="campaignSelection__boxes">
                                                        <div className="gc_inmainbox"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="row" style={{ paddingBottom: "20px" }}>
                                <div className="col-sm-12  col-md-12 col-lg-12 col-xl-12">
                                    <div className="button-section" style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <button type="submit" className="btn btn-primary" disabled>
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IntroGiftCard1;
