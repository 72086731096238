import React, { useEffect, useState } from "react";
import { Modal, Button, CloseButton } from "react-bootstrap";
import { getProgramPoint } from "../../../actions/auth";

const RedeemRewardsModal = (props) => {
    const { show, close, totalAvailableValue, handleRedeemApi, handleChange, error, successMsg, disable } = props;
    const [state, setState] = useState();
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        const getMinimumPoint = async () => {
            try {
                setLoader(true);
                let token = await JSON.parse(localStorage.getItem("token_gen"));
                const response = await getProgramPoint(token?.access_token);
                setState(response?.data[0]?.transactionPorcessingSettings.minimumRedeemValue);
                setLoader(false);
            } catch (err) {}
        };
        getMinimumPoint();
    }, []);

    return (
        <>
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <Modal show={show} onHide={close} centered>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: "20px" }}>Redeem Points</Modal.Title>
                    <CloseButton onClick={close} variant="white" />
                </Modal.Header>
                <Modal.Body style={{ position: "relative", minHeight: "85px" }}>
                    {successMsg ? (
                        <span className="successMessage">{successMsg}</span>
                    ) : (
                        <>
                            <label>Redeem Points</label>
                            <input type="text" className="form-control" maxLength={totalAvailableValue.toString().length} name="inputRedeemPoints" defaultValue={totalAvailableValue} onChange={(e) => handleChange(e, state)} />
                            <span className="errorMessage">{error}</span>
                        </>
                    )}
                </Modal.Body>
                {!successMsg && (
                    <Modal.Footer>
                        <Button onClick={handleRedeemApi} disabled={disable ? true : false}>
                            Redeem Points
                        </Button>
                    </Modal.Footer>
                )}
            </Modal>
        </>
    );
};

export default RedeemRewardsModal;
