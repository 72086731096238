import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../actions/authAction";
import { clearCampaigns } from "../actions/campaigns";
import { clearCustomer } from "../actions/customer";
import { clearDashboard } from "../actions/dashboard";
import { clearSetting } from "../actions/setting";
import { clearTransaction } from "../actions/transaction";
import { connect } from "react-redux";
import swal from "sweetalert";

const LogoutTimer = ({ logout, clearCampaigns, clearCustomer, clearDashboard, clearSetting, clearTransaction }) => {
    const minutes = 60; // Enter how many minutes you want

    const [lastActive, setLastActive] = useState(new Date());
    const logoutTimeout = 30 * 60 * 1000;
    const navigate = useNavigate();

    useEffect(() => {
        const channel = new BroadcastChannel("last-active-channel");

        channel.onmessage = (event) => {
            setLastActive(new Date(event.data));
        };

        return () => {
            channel.close();
        };
    }, []);

    useEffect(() => {
        const handleActivity = () => {
            const currentTime = new Date().getTime();
            setLastActive(currentTime);

            const channel = new BroadcastChannel("last-active-channel");
            channel.postMessage(currentTime);
        };

        window.addEventListener("click", handleActivity);
        window.addEventListener("keypress", handleActivity);
        window.addEventListener("mousemove", handleActivity);

        return () => {
            window.removeEventListener("click", handleActivity);
            window.removeEventListener("keypress", handleActivity);
            window.removeEventListener("mousemove", handleActivity);
        };
    }, []);

    useEffect(() => {
        let timeoutId;

        const checkLogoutTimeout = () => {
            const now = new Date();
            const elapsed = now - lastActive;

            if (elapsed >= logoutTimeout) {
                logout();
                clearCampaigns();
                clearCustomer();
                clearDashboard();
                clearSetting();
                clearTransaction();
                localStorage.removeItem("token_gen");
                localStorage.clear();
                sessionStorage.clear();
                navigate("/signin");
                swal({
                    title: "Oops!",
                    text: "Your session has expired",
                    icon: "warning",
                    button: "Oh no!",
                    timer: 5000,
                });
            } else {
                timeoutId = setTimeout(checkLogoutTimeout, logoutTimeout - elapsed);
            }
        };

        timeoutId = setTimeout(checkLogoutTimeout, logoutTimeout);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [lastActive, logoutTimeout, navigate, logout]);

    return null;
};

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
});

export default connect(mapStateToProps, {
    logout,
    clearCampaigns,
    clearCustomer,
    clearDashboard,
    clearSetting,
    clearTransaction,
})(LogoutTimer);
