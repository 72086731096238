import React, { memo, useEffect, useState } from "react";
import Header from "../components/layout/Header";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { dashboardIconOne, dashboardIconTwo, dashboardIconThree, backIcon2 } from "../Image/Index";
import LineChartAnalyticalReport1 from "../components/charts/LineChartAnalyticalReport1";
import BarChartAnalyticalReport1 from "../components/charts/BarChartAnalyticalReport1";
import BarChartAnalyticalReport2 from "../components/charts/BarChartAnalyticalReport2";
import DoughnutChartAnalyticalReport1 from "../components/charts/DoughnutChartAnalyticalReport1";
import introJs from "intro.js";
import "intro.js/introjs.css";
import { getUserJourneyReport } from "../actions/dashboard";
import { useSelector, useDispatch } from "react-redux";
import ModalCard from "../components/layout/ModalCard";
const staticData = [
    {
        TotalCustomer: 2950,
        NewCustomer: 238,
        LoyalCustomer: 494,
        CanNotLoseThem: 293,
        Champions: 498,
        Hibernation: 120,
        AtRisk: 263,
        AboutToSleep: 350,
        Needattention: 182,
        PotentialLoyalist: 303,
        Promising: 209,
        Total_Male: 734114,
        Total_female: 314433,
        AgeWise: {
            "18-20": 27523,
            "21-30": 285847,
            "31-40": 285923,
            "41-50": 267839,
            "51-60": 181415,
            "61-70": 0,
            "71-80": 0,
            "81-90": 0,
        },
        Average: 25020,
        Totaltrn: 1048547,
        Monthwisetrn_result: {
            "4-2023": "31753741",
            "3-2023": "708715127",
            "2-2023": "991600187",
            "1-2023": "1091293931",
            "12-2022": "1126547324",
            "11-2022": "1103449398",
            "10-2022": "1142555985",
            "9-2022": "770228680",
            "8-2022": "794629926",
            "7-2022": "793859284",
            "6-2022": "781460546",
            "5-2022": "789124447",
            "4-2022": "764396944",
            "3-2022": "815216355",
            "2-2022": "730358829",
            "1-2022": "818814120",
            "12-2021": "820733134",
            "11-2021": "796195069",
            "10-2021": "815852248",
            "9-2021": "790869482",
            "8-2021": "808597838",
            "7-2021": "822675739",
            "6-2021": "795575362",
            "5-2021": "822056289",
            "4-2021": "798654613",
            "3-2021": "819967038",
            "2-2021": "747704509",
            "1-2021": "816375358",
            "12-2020": "826058670",
            "11-2020": "792869299",
            "10-2020": "820248055",
            "9-2020": "793207870",
            "8-2020": "816779482",
            "7-2020": "821551168",
            "6-2020": "790715281",
            "5-2020": "823755891",
            "4-2020": "343590609",
        },
        Monthwisetrn_count: {
            "4-2023": "946",
            "3-2023": "33554",
            "2-2023": "53246",
            "1-2023": "58589",
            "12-2022": "60325",
            "11-2022": "58517",
            "10-2022": "60839",
            "9-2022": "23471",
            "8-2022": "24273",
            "7-2022": "24138",
            "6-2022": "23761",
            "5-2022": "24092",
            "4-2022": "23356",
            "3-2022": "25022",
            "2-2022": "22459",
            "1-2022": "25219",
            "12-2021": "25222",
            "11-2021": "24395",
            "10-2021": "25117",
            "9-2021": "24473",
            "8-2021": "24895",
            "7-2021": "25280",
            "6-2021": "24439",
            "5-2021": "25184",
            "4-2021": "24557",
            "3-2021": "25302",
            "2-2021": "23001",
            "1-2021": "25091",
            "12-2020": "25398",
            "11-2020": "24287",
            "10-2020": "25208",
            "9-2020": "24259",
            "8-2020": "25255",
            "7-2020": "25143",
            "6-2020": "24378",
            "5-2020": "25280",
            "4-2020": "10576",
        },
        datastatus: "Sample ",
        discriminator: "SampleReportData",
        id: "cea17f66-9936-48a2-9ff3-4ffa59d71a2e",
        _rid: "e6g0AKwYiWPkHgAAAAAAAA==",
        _self: "dbs/e6g0AA==/colls/e6g0AKwYiWM=/docs/e6g0AKwYiWPkHgAAAAAAAA==/",
        _etag: '"0000ea23-0000-2000-0000-646312030000"',
        _attachments: "attachments/",
        _ts: 1684214275,
    },
];
const IntroDashboard = memo(({}) => {
    const location = useLocation();
    const [FormData, setFormData] = useState([]);
    const [year, setYear] = useState({});
    const [Report1, setReport1] = useState([]);
    const [Report2, setReport2] = useState([]);
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const [showModal, setshowModal] = useState(false);
    // const [dashboard, setdashboard]=useState({})
    const intro = introJs();
    const modalShow = useSelector((state) => state.intro.modalOpen);
    const fetch = async () => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const Id = localStorage.getItem("Id");
        //   const tedata = await getUserJourneyReport(token?.access_token, Id);
        //  await getDashboard(token?.access_token, Id, "2023");
        //console.log("ddddd",tedata);
        setFormData(staticData);
        setLoader(false);
    };
    let exitOnLast = false;
    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        if (FormData[0]?.Monthwisetrn_count) {
            const asArray = Object.entries(FormData[0]?.Monthwisetrn_count).map((arr) => {
                const data = arr[0];
                return data.split("-")[1];
            });
            function onlyUnique(value, index, array) {
                return array.indexOf(value) === index;
            }
            var uniques = asArray.filter(onlyUnique);
            setYear(uniques.sort().reverse());
            filterData(uniques[0], FormData[0]?.Monthwisetrn_count).then((res) => {
                setReport1(res);
            });
        }
        // else {
        //     setReport1(Object.values(Dashboard.MonthwiseTransaction));
        // }
    }, [FormData]);

    useEffect(() => {
        if (FormData[0]?.Monthwisetrn_result) {
            const asArray = Object.entries(FormData[0]?.Monthwisetrn_result).map((arr) => {
                const data = arr[0];
                return data.split("-")[1];
            });
            function onlyUnique(value, index, array) {
                return array.indexOf(value) === index;
            }
            var uniques = asArray.filter(onlyUnique);
            setYear(uniques.sort().reverse());
            filterData(uniques[0], FormData[0]?.Monthwisetrn_result).then((res) => {
                setReport2(res);
            });
        }
        // else {
        //     setReport2(Object.values(Dashboard.MonthwiseTransaction));
        // }
    }, [FormData]);

    const filterData = async (data, object) => {
        const asArray = await Object.entries(object);
        let filtered = await asArray.filter(([key, value]) => key.split("-")[1] === data);
        let year = filtered[0];
        if (year && year[0]) {
            year = year[0].split("-")[1];
        }

        let allMonths = Array.from({ length: 12 }, (_, i) => i + 1);
        let mergedArray = allMonths.map((month) => {
            let matchingData = filtered.find((d) => parseInt(d[0].split("-")[0]) == month);
            return matchingData ? matchingData : [`${month}-${year}`, 0];
        });

        const n = mergedArray.length;
        for (let i = 0; i < n - 1; i++) {
            for (let j = 0; j < n - i - 1; j++) {
                const monthA = parseInt(mergedArray[j][0].split("-")[0]);
                const monthB = parseInt(mergedArray[j + 1][0].split("-")[0]);
                if (monthA > monthB) {
                    const temp = mergedArray[j];
                    mergedArray[j] = mergedArray[j + 1];
                    mergedArray[j + 1] = temp;
                }
            }
        }
        const tempdata = await mergedArray.map((arr) => {
            return arr[1];
        });
        return tempdata;
    };
    const handleSelectChange = async (data, arr, type) => {
        filterData(data, arr).then((res) => {
            if (type) setReport1(res);
            else setReport2(res);
        });
    };
    let complete = false;
    useEffect(() => {
        const handleLoad = () => {
            const steps = [
                {
                    element: document.getElementById("dashboardinfo"),
                    title: "Customer Spending Overview ",
                    intro: "Gain insights into customer spending behaviors to enhance your loyalty strategy.",
                },
                {
                    element: document.getElementById("analytical"),
                    title: "RFM Data Analysis ",
                    intro: "Customize your analysis to gain in-depth insights into customer behavior, segmentation, and trends and target customers with precision.",
                },
            ];
            intro.setOptions({
                steps: steps,
                doneLabel: "Next",
                exitOnOverlayClick: false,
            });
            intro.start();
        };
        window.addEventListener('load', handleLoad);
        return () => {
            intro.exit();
        };
    }, []);

    intro.oncomplete(function (element) {
        complete = true;
        setTimeout(() => {
            if (!exitOnLast) {
                navigate("/intro/analytics");
                // window.location.href= "/intro/my-campaigns";
            }
        }, 700);
    });
    intro.onexit(() => {
        if (!complete) {
            localStorage.setItem("firstLogin", "");
            setshowModal(true);
        }
    });
    intro.onchange(function (element) {
        switch (this._currentStep) {
            case 1:
                let cross = document.getElementsByClassName("introjs-skipbutton")[0];
                cross?.addEventListener("click", function () {
                    localStorage.setItem("firstLogin", "");
                    setshowModal(true);
                    exitOnLast = true;
                });
                break;
        }
    });

    return (
        <>
            <Header name={`${FormData[0]?.datastatus ? FormData[0]?.datastatus : ""}Dashboard`} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
                            {showModal && <ModalCard show={showModal}/>}
            <div className="analyticalReport">
                <div className="analyticalReportRFMAnalysis">
                    <div className="analyticalReportRFMAnalysis__topSection">
                        <div className="container">
                            <div className="analyticalReportRFMAnalysis__leftSection">
                                <div className="dashboard__headingBackLink dashboard__headingBackLink2">
                                    {location?.state?.prevPath && (
                                        <Link to={"/upload-existing-data"}>
                                            <img style={{ width: "18px" }} src={backIcon2} alt="" />
                                            <span>Back</span>
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showModal && <ModalCard show={showModal}/>}
                <div className="analyticalReportChartAnalysisOne">
                    <div id="dashboard" className="container">
                        <div className="analyticalReportChartAnalysisOne__bottomSection">
                            <div className="analyticalReportChartAnalysisOne__heading">
                                <h2>Customer Spending Overview</h2>
                            </div>
                            <div className="analyticalReportChartAnalysisOne__leftSection">
                                <div className="analyticalReportChartAnalysisOne__box">
                                    <div className="analyticalReportChartAnalysisOne__header">
                                        <div className="analyticalReportChartAnalysisOne__left">
                                            <div className="analyticalReportChartAnalysisOne__image">
                                                <img src={dashboardIconThree} alt="" />
                                            </div>
                                            <div className="analyticalReportChartAnalysisOne__details">
                                                <h3> Customers</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="analyticalReportChartAnalysisOne__reportSection">
                                        <h3>31232</h3>
                                    </div>
                                </div>

                                <div className="analyticalReportChartAnalysisOne__box">
                                    <div className="analyticalReportChartAnalysisOne__header">
                                        <div className="analyticalReportChartAnalysisOne__left">
                                            <div className="analyticalReportChartAnalysisOne__image">
                                                <img src={dashboardIconThree} alt="" />
                                            </div>
                                            <div className="analyticalReportChartAnalysisOne__details">
                                                <h3>Transactions</h3>
                                            </div>
                                        </div>
                                        {/* <div className="analyticalReportChartAnalysisOne__right">
                                            <div className="analyticalReportChartAnalysisOne__button">
                                                <button className="btn btn-primary btn-sm">
                                                    <i className="fa-solid fa-plus"></i> Expand
                                                </button>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="analyticalReportChartAnalysisOne__reportSection">
                                        <h3>{FormData[0]?.Totaltrn ? FormData[0]?.Totaltrn : 0}</h3>
                                    </div>
                                </div>
                                <div className="analyticalReportChartAnalysisOne__box">
                                    <div className="analyticalReportChartAnalysisOne__header">
                                        <div className="analyticalReportChartAnalysisOne__left">
                                            <div className="analyticalReportChartAnalysisOne__image">
                                                <img src={dashboardIconTwo} alt="" />
                                            </div>
                                            <div className="analyticalReportChartAnalysisOne__details">
                                                <h3>Customer Average Spend</h3>
                                            </div>
                                        </div>
                                        {/* <div className="analyticalReportChartAnalysisOne__right">
                                            <div className="analyticalReportChartAnalysisOne__button">
                                                <button className="btn btn-primary btn-sm">
                                                    <i className="fa-solid fa-plus"></i> Expand
                                                </button>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="analyticalReportChartAnalysisOne__reportSection">
                                        <h3>23131</h3>
                                        {/* <p>Customer Average Spend</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="analyticalReportChartAnalysisOne__rightSection">
                                <div className="analyticalReportChartAnalysisOne__box">
                                    <div className="analyticalReportChartAnalysisOne__header">
                                        <div className="analyticalReportChartAnalysisOne__left">
                                            <div className="analyticalReportChartAnalysisOne__image">
                                                <img src={dashboardIconOne} alt="" />
                                            </div>
                                            <div className="analyticalReportChartAnalysisOne__details">
                                                <h3>Transactions Count</h3>
                                            </div>
                                        </div>
                                        <div className="analyticalReportChartAnalysisOne__right">
                                            <div className="analyticalReportChartAnalysisOne__select">
                                                <select className="form-control selectArrow" onChange={(event) => handleSelectChange(event.target.value, FormData[0]?.Monthwisetrn_count, 1)}>
                                                    {Array.isArray(year) &&
                                                        year?.map((opt) => (
                                                            <option key={opt} value={opt}>
                                                                {opt}
                                                            </option>
                                                        ))}
                                                    {/* :<option>2023</option>} */}
                                                </select>
                                            </div>
                                            {/* <div className="analyticalReportChartAnalysisOne__button">
                                                <button className="btn btn-primary btn-sm">
                                                    <i className="fa-solid fa-plus"></i> Expand
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="analyticalReportChartAnalysisOne__chartSection">
                                        <BarChartAnalyticalReport1 data={Report1} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="analyticalReportChartAnalysisOne__topSection">
                            <div className="analyticalReportChartAnalysisOne__heading">
                                <h2>Data Overview</h2>
                            </div>
                            <div className="analyticalReportChartAnalysisOne__fullSection">
                                <div className="analyticalReportChartAnalysisOne__box">
                                    <div className="analyticalReportChartAnalysisOne__header">
                                        <div className="analyticalReportChartAnalysisOne__left">
                                            <div className="analyticalReportChartAnalysisOne__image">
                                                <img src={dashboardIconOne} alt="" />
                                            </div>
                                            <div className="analyticalReportChartAnalysisOne__details">
                                                <h3>Transactions Amount</h3>
                                            </div>
                                        </div>
                                        <div className="analyticalReportChartAnalysisOne__right">
                                            <div className="analyticalReportChartAnalysisOne__select">
                                                <select className="form-control selectArrow" onChange={(event) => handleSelectChange(event.target.value, FormData[0]?.Monthwisetrn_result, 0)}>
                                                    {Array.isArray(year) > 0 &&
                                                        year?.map((opt) => (
                                                            <option key={opt} value={opt}>
                                                                {opt}
                                                            </option>
                                                        ))}
                                                    {/* :<option>2023</option>} */}
                                                </select>
                                            </div>
                                            {/* <div className="analyticalReportChartAnalysisOne__button">
                                                <button className="btn btn-primary btn-sm">
                                                    <i className="fa-solid fa-plus"></i> Expand
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="analyticalReportChartAnalysisOne__chartSection">
                                        <LineChartAnalyticalReport1 data={Report2} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="analyticalReportChartAnalysisTwo">
                    <div className="container">
                        <div className="analyticalReportChartAnalysisTwo__topSection">
                            <div className="analyticalReportChartAnalysisTwo__heading">{Object.values(FormData[0]?.AgeWise ? FormData[0]?.AgeWise : {}).filter((val) => val > 0).length > 0 && <h2>Customer Demographic</h2>}</div>
                            {Object.values(FormData[0]?.AgeWise ? FormData[0]?.AgeWise : {}).filter((val) => val > 0).length > 0 && (
                                <div className="analyticalReportChartAnalysisTwo__leftSection">
                                    <div className="analyticalReportChartAnalysisTwo__box">
                                        <div className="analyticalReportChartAnalysisTwo__header">
                                            <div className="analyticalReportChartAnalysisTwo__left">
                                                <div className="analyticalReportChartAnalysisTwo__image">
                                                    <img src={dashboardIconTwo} alt="" />
                                                </div>
                                                <div className="analyticalReportChartAnalysisTwo__details">
                                                    <h3>Age Bifurcation</h3>
                                                </div>
                                            </div>
                                            {/* <div className="analyticalReportChartAnalysisTwo__right">
                                            <div className="analyticalReportChartAnalysisTwo__button">
                                                <button className="btn btn-primary btn-sm">
                                                    <i className="fa-solid fa-plus"></i> Expand
                                                </button>
                                            </div>
                                        </div> */}
                                        </div>
                                        <div className="analyticalReportChartAnalysisTwo__chartSection">
                                            <BarChartAnalyticalReport2 data={FormData[0]?.AgeWise} />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {Object.values(FormData[0]?.AgeWise ? FormData[0]?.AgeWise : {}).filter((val) => val > 0).length > 0 && (
                                <div className="analyticalReportChartAnalysisTwo__rightSection">
                                    <div className="analyticalReportChartAnalysisTwo__box">
                                        <div className="analyticalReportChartAnalysisTwo__header">
                                            <div className="analyticalReportChartAnalysisTwo__left">
                                                <div className="analyticalReportChartAnalysisTwo__image">
                                                    <img src={dashboardIconThree} alt="" />
                                                </div>
                                                <div className="analyticalReportChartAnalysisTwo__details">
                                                    <h3>Gender Bifurcation</h3>
                                                </div>
                                            </div>
                                            {/* <div className="analyticalReportChartAnalysisTwo__right">
                                            <div className="analyticalReportChartAnalysisTwo__button">
                                                <button className="btn btn-primary btn-sm">
                                                    <i className="fa-solid fa-plus"></i> Expand
                                                </button>
                                            </div>
                                        </div> */}
                                        </div>
                                        <div className="analyticalReportChartAnalysisTwo__chartSection">
                                            <DoughnutChartAnalyticalReport1 Total_Male={FormData[0]?.Total_Male} Total_female={FormData[0]?.Total_female} Others={0} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="analyticalReportChartAnalysisTwo__bottomSection">
                            {location?.state?.prevPath && (
                                <div className="analyticalReportChartAnalysisTwo__rightSection">
                                    <div className="analyticalReportChartAnalysisTwo__paragraph">
                                        <p>With the Help of AI and existing data, use Novus Loyalty to set up campaign to supercharge your growth.</p>
                                    </div>
                                    <div className="analyticalReportChartAnalysisTwo__buttonSection">
                                        <Link to="/business-goals-selection" className="btn btn-primary">
                                            Select Your Business Goals
                                            <i className="fa-solid fa-arrow-right"></i>
                                        </Link>
                                        {/* <Link to="" className="btn btn-outline-primary">
                                        Contact Novus Support
                                        <i className="fa-solid fa-comment-dots"></i>
                                    </Link> */}
                                        <Link to="/campaign-selection" state={{ path: "/analytical-report" }} className="btn btn-link">
                                            Skip
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

// const mapStateToProps = (state) => ({
// 	Dashboard: state.Dashboard,
// });

// const mapDispatchToProps = {
//     getDashboard,
// };
export default IntroDashboard;
// export default connect({}, {})(Dashboard);
