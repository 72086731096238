import React, { useState, useEffect } from "react";
import { decrypt } from "../../utils/encodingdecoding";
import { novus_logo } from "../../Image/Index";

const AdminHeader = (props) => {
    const [name, setName] = useState();

    useEffect(() => {
        if (localStorage.getItem("name")) {
            let tempName = decrypt(localStorage.getItem("name"));
            tempName = `${tempName[0].toUpperCase()}${tempName.slice(1)}`;
            setName(tempName);
        }
    }, []);

    const temp = localStorage.getItem("saasemail");
    return (
        <div className="dashboard__headerSection">
 
            {temp === null? (
                <figure>
                    <img src={novus_logo} alt="logo" width="120" loading="lazy" style={{ marginTop: "10px", marginLeft: "10px", paddingLeft: "15px" }} />
                </figure>
            ):null}

            <div className="dashboard__heading">
                <h3>{props.name}</h3>
            </div>
           
        </div>
    );
};

export default AdminHeader;
