import React, { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { voucherTemplateDetail, clearVoucherTemplate } from "../actions/voucher";
import { getDate } from "../utils/helper";
import { GenerateVoucher, DownloadVoucher } from "../actions/auth";
import swal from "sweetalert";
import introJs from 'intro.js';
import 'intro.js/introjs.css'; 
import ModalCard from "../components/layout/ModalCard";
import { useSelector } from "react-redux";
import VoucherViewModal from "../components/voucher/VoucherViewModal";
import UploadVoucherModal from "../components/voucher/UploadVoucherModal";
import { COLUMNS } from "../components/voucher/column";
import Paginated from "../components/voucher/Paginated";
const staticData=[
    {
        "tags": "N/A",
        "voucherUsed": false,
        "isused": false,
        "id": null,
        "name": "*******",
        "code": "*********",
        "voucherValidityDate": {
            "startDate": "*********",
            "endDate": "*********",
        },
        "voucherVisibilityDate": "*********",
        "perPersonRedemption": 0,
        "perPersonRedemptionCount": 0,
        "totalRedemption": 0,
        "merchant": 0,
        "voucherGeneratedType": "Generated",
        "voucherType": 0,
        "title": "",
        "description": "",
        "isActive": true,
        "internalTags": null,
        "numberOfVouchers": 5,
        "templateHitCounter": 4,
        "lastGenratedNumberOfVoucher": 20,
        "usedVoucherCount": 0,
        "merchantCode": [],
        "generatePath": "https://novusapi.azurewebsites.net/voucher-files/VoucherTemplate_VOT000200_1008202332091470018.csv",
        "importVoucher": [
            {
                "totalCount": 0,
                "successVoucherCount": 0,
                "unSuccessVoucherCount": 0,
                "createdDate": "2023-07-20T06:56:04+00:00",
                "blobFileName": {
                    "fileName": "",
                    "success": "",
                    "unSuccess": ""
                }
            }
        ],
        "uploadStatus": "",
        "earningType": "point",
        "earningValue": "89",
        "totalRedemptionLimit": 0,
        "redeemUrl": "",
        "termsAndCondition": "",
        "nxpoints": 0,
        "merchantId": [
            "MER000605"
        ],
        "discriminator": null,
        "createdDate": "2023-09-15T06:15:40.3978373Z",
        "updatedDate": "2023-09-15T06:15:40.3978375Z",
        "createdBy": null,
        "updatedBy": null,
        "templateCode": "*********",
        "generatedVouchers": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    "No. of Generated Vouchers ",
                    20,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Used Vouchers ",
                    0,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Un-used Vouchers ",
                    20
                ]
            },
            "_owner": null,
            "_store": {}
        },
        "isactive": "*********",
        "action": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "View Details",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-eye"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "View Details"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "/voucher",
                            "state": "******",
                            "className": "edit_btn",
                            "csvalue": "Edit",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-pen"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Edit"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "className": "edit_btn",
                            "csvalue": "Generate Voucher",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-file"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Generate Voucher(s)"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "Generate File",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-file"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Generate File"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "https://novusapi.azurewebsites.net/voucher-files/VoucherTemplate_VOT000200_1008202332091470018.csv",
                            "className": "edit_btn",
                            "csvalue": "Generate File",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-download"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Download File"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    }
                ]
            },
            "_owner": null,
            "_store": {}
        }
    },
    {
        "tags": "N/A",
        "voucherUsed": false,
        "isused": false,
        "id": null,
        "name": "***********",
        "code": "***********",
        "voucherValidityDate": {
            "startDate": "2023-07-14T00:00:00",
            "endDate": "2023-07-14T00:00:00"
        },
        "voucherVisibilityDate": "2023-07-14T00:00:00",
        "perPersonRedemption": 0,
        "perPersonRedemptionCount": 0,
        "totalRedemption": 0,
        "merchant": 0,
        "voucherGeneratedType": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    "Imported",
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    " ",
                    {
                        "type": "p",
                        "key": null,
                        "ref": null,
                        "props": {
                            "style": {
                                "color": "#ff6a00"
                            },
                            "children": [
                                "Total Last Imported Voucher Count ",
                                5
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": "p",
                        "key": null,
                        "ref": null,
                        "props": {
                            "style": {
                                "color": "green"
                            },
                            "children": [
                                "Last Succesfull Imported Voucher Count ",
                                5
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": "p",
                        "key": null,
                        "ref": null,
                        "props": {
                            "style": {
                                "color": "red"
                            },
                            "children": [
                                "Last Unsuccessful Imported Voucher Count ",
                                0
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    }
                ]
            },
            "_owner": null,
            "_store": {}
        },
        "voucherType": 0,
        "title": "",
        "description": "",
        "isActive": true,
        "internalTags": null,
        "numberOfVouchers": 1,
        "templateHitCounter": 0,
        "lastGenratedNumberOfVoucher": 5,
        "usedVoucherCount": 0,
        "merchantCode": [],
        "generatePath": "https://novusapi.azurewebsites.net/voucher-files/VoucherTemplate_VOT000199_0408202334100834379.csv",
        "importVoucher": [
            {
                "totalCount": 5,
                "successVoucherCount": 5,
                "unSuccessVoucherCount": 0,
                "createdDate": "2023-07-14T10:39:05.9597323+00:00",
                "blobFileName": {
                    "fileName": "VoucherCameFromUploader1407202339100574952.csv",
                    "success": "https://assetmanagment.blob.core.windows.net/voucher-files/VoucherCameFromUploader1407202339100574952.csv",
                    "unSuccess": null
                }
            },
            {
                "totalCount": 0,
                "successVoucherCount": 0,
                "unSuccessVoucherCount": 0,
                "createdDate": "2023-07-14T10:38:38+00:00",
                "blobFileName": {
                    "fileName": "",
                    "success": "",
                    "unSuccess": ""
                }
            }
        ],
        "uploadStatus": "Complete",
        "earningType": "point",
        "earningValue": "23",
        "totalRedemptionLimit": 0,
        "redeemUrl": "",
        "termsAndCondition": "",
        "nxpoints": 0,
        "merchantId": [
            "MER000605"
        ],
        "discriminator": null,
        "createdDate": "2023-09-15T06:15:40.3978378Z",
        "updatedDate": "2023-09-15T06:15:40.3978378Z",
        "createdBy": null,
        "updatedBy": null,
        "templateCode": "*********",
        "generatedVouchers": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    "No. of Generated Vouchers ",
                    5,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Used Vouchers ",
                    0,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Un-used Vouchers ",
                    5
                ]
            },
            "_owner": null,
            "_store": {}
        },
        "isactive": "Active",
        "action": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "View Details",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-eye"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "View Details"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "/voucher",
                            "state": "VOT000199",
                            "className": "edit_btn",
                            "csvalue": "Edit",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-pen"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Edit"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "className": "edit_btn",
                            "csvalue": "Upload Voucher",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-upload"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Upload Voucher(s)"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "Generate File",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-file"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Generate File"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "https://novusapi.azurewebsites.net/voucher-files/VoucherTemplate_VOT000199_0408202334100834379.csv",
                            "className": "edit_btn",
                            "csvalue": "Generate File",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-download"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Download File"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    }
                ]
            },
            "_owner": null,
            "_store": {}
        }
    },
    {
        "tags": "N/A",
        "voucherUsed": false,
        "isused": false,
        "id": null,
        "name": "***********",
        "code": "***********",
        "voucherValidityDate": {
            "startDate": "2023-07-14T00:00:00",
            "endDate": "2023-07-14T00:00:00"
        },
        "voucherVisibilityDate": "2023-07-14T00:00:00",
        "perPersonRedemption": 0,
        "perPersonRedemptionCount": 0,
        "totalRedemption": 0,
        "merchant": 0,
        "voucherGeneratedType": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    "Imported",
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    " ",
                    {
                        "type": "p",
                        "key": null,
                        "ref": null,
                        "props": {
                            "style": {
                                "color": "#ff6a00"
                            },
                            "children": [
                                "Total Last Imported Voucher Count ",
                                5
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": "p",
                        "key": null,
                        "ref": null,
                        "props": {
                            "style": {
                                "color": "green"
                            },
                            "children": [
                                "Last Succesfull Imported Voucher Count ",
                                1
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": "p",
                        "key": null,
                        "ref": null,
                        "props": {
                            "style": {
                                "color": "red"
                            },
                            "children": [
                                "Last Unsuccessful Imported Voucher Count ",
                                4
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    }
                ]
            },
            "_owner": null,
            "_store": {}
        },
        "voucherType": 0,
        "title": "",
        "description": "",
        "isActive": true,
        "internalTags": null,
        "numberOfVouchers": 1,
        "templateHitCounter": 0,
        "lastGenratedNumberOfVoucher": 1,
        "usedVoucherCount": 0,
        "merchantCode": [],
        "generatePath": "",
        "importVoucher": [
            {
                "totalCount": 5,
                "successVoucherCount": 1,
                "unSuccessVoucherCount": 4,
                "createdDate": "2023-07-14T10:38:01.2538477+00:00",
                "blobFileName": {
                    "fileName": "VoucherCameFromUploader1407202350035359239.csv",
                    "success": "https://assetmanagment.blob.core.windows.net/voucher-files/VoucherCameFromUploader1407202350035359239.csv",
                    "unSuccess": null
                }
            },
            {
                "totalCount": 0,
                "successVoucherCount": 0,
                "unSuccessVoucherCount": 0,
                "createdDate": "2023-07-14T09:42:28+00:00",
                "blobFileName": {
                    "fileName": "",
                    "success": "",
                    "unSuccess": ""
                }
            }
        ],
        "uploadStatus": "Complete",
        "earningType": "point",
        "earningValue": "6",
        "totalRedemptionLimit": 0,
        "redeemUrl": "",
        "termsAndCondition": "",
        "nxpoints": 0,
        "merchantId": [
            "MER000605"
        ],
        "discriminator": null,
        "createdDate": "2023-09-15T06:15:40.3978379Z",
        "updatedDate": "2023-09-15T06:15:40.397838Z",
        "createdBy": null,
        "updatedBy": null,
        "templateCode": "*********",
        "generatedVouchers": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    "No. of Generated Vouchers ",
                    1,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Used Vouchers ",
                    0,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Un-used Vouchers ",
                    1
                ]
            },
            "_owner": null,
            "_store": {}
        },
        "isactive": "Active",
        "action": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "View Details",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-eye"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "View Details"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "/voucher",
                            "state": "VOT000198",
                            "className": "edit_btn",
                            "csvalue": "Edit",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-pen"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Edit"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "className": "edit_btn",
                            "csvalue": "Upload Voucher",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-upload"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Upload Voucher(s)"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "Generate File",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-file"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Generate File"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    false
                ]
            },
            "_owner": null,
            "_store": {}
        }
    },
    {
        "tags": "N/A",
        "voucherUsed": false,
        "isused": false,
        "id": null,
        "name": "***********",
        "code": "***********",
        "voucherValidityDate": {
            "startDate": "2023-07-14T00:00:00",
            "endDate": "2023-07-14T00:00:00"
        },
        "voucherVisibilityDate": "2023-07-14T00:00:00",
        "perPersonRedemption": 0,
        "perPersonRedemptionCount": 0,
        "totalRedemption": 0,
        "merchant": 0,
        "voucherGeneratedType": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    "Imported",
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    " ",
                    {
                        "type": "p",
                        "key": null,
                        "ref": null,
                        "props": {
                            "style": {
                                "color": "#ff6a00"
                            },
                            "children": [
                                "Total Last Imported Voucher Count ",
                                5
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": "p",
                        "key": null,
                        "ref": null,
                        "props": {
                            "style": {
                                "color": "green"
                            },
                            "children": [
                                "Last Succesfull Imported Voucher Count ",
                                1
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": "p",
                        "key": null,
                        "ref": null,
                        "props": {
                            "style": {
                                "color": "red"
                            },
                            "children": [
                                "Last Unsuccessful Imported Voucher Count ",
                                4
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    }
                ]
            },
            "_owner": null,
            "_store": {}
        },
        "voucherType": 0,
        "title": "",
        "description": "",
        "isActive": true,
        "internalTags": null,
        "numberOfVouchers": 1,
        "templateHitCounter": 0,
        "lastGenratedNumberOfVoucher": 1,
        "usedVoucherCount": 1,
        "merchantCode": [],
        "generatePath": "",
        "importVoucher": [
            {
                "totalCount": 5,
                "successVoucherCount": 1,
                "unSuccessVoucherCount": 4,
                "createdDate": "2023-07-14T09:37:44.3210424+00:00",
                "blobFileName": {
                    "fileName": "VoucherCameFromUploader1407202337094410299.csv",
                    "success": "https://assetmanagment.blob.core.windows.net/voucher-files/VoucherCameFromUploader1407202337094410299.csv",
                    "unSuccess": null
                }
            },
            {
                "totalCount": 0,
                "successVoucherCount": 0,
                "unSuccessVoucherCount": 0,
                "createdDate": "2023-07-14T09:36:26+00:00",
                "blobFileName": {
                    "fileName": "",
                    "success": "",
                    "unSuccess": ""
                }
            }
        ],
        "uploadStatus": "Complete",
        "earningType": "point",
        "earningValue": "6",
        "totalRedemptionLimit": 0,
        "redeemUrl": "",
        "termsAndCondition": "",
        "nxpoints": 0,
        "merchantId": [
            "MER000605"
        ],
        "discriminator": null,
        "createdDate": "2023-09-15T06:15:40.3978381Z",
        "updatedDate": "2023-09-15T06:15:40.3978381Z",
        "createdBy": null,
        "updatedBy": null,
        "templateCode": "*********",
        "generatedVouchers": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    "No. of Generated Vouchers ",
                    1,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Used Vouchers ",
                    1,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Un-used Vouchers ",
                    0
                ]
            },
            "_owner": null,
            "_store": {}
        },
        "isactive": "Active",
        "action": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "View Details",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-eye"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "View Details"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "/voucher",
                            "state": "VOT000197",
                            "className": "edit_btn",
                            "csvalue": "Edit",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-pen"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Edit"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "className": "edit_btn",
                            "csvalue": "Upload Voucher",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-upload"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Upload Voucher(s)"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "Generate File",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-file"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Generate File"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    false
                ]
            },
            "_owner": null,
            "_store": {}
        }
    },
    {
        "tags": "N/A",
        "voucherUsed": false,
        "isused": false,
        "id": null,
        "name": "***********",
        "code": "***********",
        "voucherValidityDate": {
            "startDate": "2023-07-14T00:00:00",
            "endDate": "2023-07-29T00:00:00"
        },
        "voucherVisibilityDate": "2023-07-14T00:00:00",
        "perPersonRedemption": 0,
        "perPersonRedemptionCount": 0,
        "totalRedemption": 0,
        "merchant": 0,
        "voucherGeneratedType": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    "Imported",
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    " ",
                    {
                        "type": "p",
                        "key": null,
                        "ref": null,
                        "props": {
                            "style": {
                                "color": "#ff6a00"
                            },
                            "children": [
                                "Total Last Imported Voucher Count ",
                                5
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": "p",
                        "key": null,
                        "ref": null,
                        "props": {
                            "style": {
                                "color": "green"
                            },
                            "children": [
                                "Last Succesfull Imported Voucher Count ",
                                1
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": "p",
                        "key": null,
                        "ref": null,
                        "props": {
                            "style": {
                                "color": "red"
                            },
                            "children": [
                                "Last Unsuccessful Imported Voucher Count ",
                                4
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    }
                ]
            },
            "_owner": null,
            "_store": {}
        },
        "voucherType": 0,
        "title": "",
        "description": "",
        "isActive": true,
        "internalTags": null,
        "numberOfVouchers": 1,
        "templateHitCounter": 0,
        "lastGenratedNumberOfVoucher": 1,
        "usedVoucherCount": 0,
        "merchantCode": [],
        "generatePath": "",
        "importVoucher": [
            {
                "totalCount": 5,
                "successVoucherCount": 1,
                "unSuccessVoucherCount": 4,
                "createdDate": "2023-07-14T07:23:47.9307587+00:00",
                "blobFileName": {
                    "fileName": "VoucherCameFromUploader1407202323074730462.csv",
                    "success": "https://assetmanagment.blob.core.windows.net/voucher-files/VoucherCameFromUploader1407202323074730462.csv",
                    "unSuccess": null
                }
            },
            {
                "totalCount": 0,
                "successVoucherCount": 0,
                "unSuccessVoucherCount": 0,
                "createdDate": "2023-07-14T07:22:32+00:00",
                "blobFileName": {
                    "fileName": "",
                    "success": "",
                    "unSuccess": ""
                }
            }
        ],
        "uploadStatus": "Complete",
        "earningType": "point",
        "earningValue": "89",
        "totalRedemptionLimit": 0,
        "redeemUrl": "",
        "termsAndCondition": "",
        "nxpoints": 0,
        "merchantId": [
            "MER000605"
        ],
        "discriminator": null,
        "createdDate": "2023-09-15T06:15:40.3978382Z",
        "updatedDate": "2023-09-15T06:15:40.3978383Z",
        "createdBy": null,
        "updatedBy": null,
        "templateCode": "*********",
        "generatedVouchers": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    "No. of Generated Vouchers ",
                    1,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Used Vouchers ",
                    0,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Un-used Vouchers ",
                    1
                ]
            },
            "_owner": null,
            "_store": {}
        },
        "isactive": "Active",
        "action": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "View Details",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-eye"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "View Details"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "/voucher",
                            "state": "VOT000196",
                            "className": "edit_btn",
                            "csvalue": "Edit",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-pen"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Edit"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "className": "edit_btn",
                            "csvalue": "Upload Voucher",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-upload"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Upload Voucher(s)"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "Generate File",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-file"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Generate File"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    false
                ]
            },
            "_owner": null,
            "_store": {}
        }
    },
    {
        "tags": "N/A",
        "voucherUsed": false,
        "isused": false,
        "id": null,
        "name": "***********",
        "code": "***********",
        "voucherValidityDate": {
            "startDate": "2023-07-13T00:00:00",
            "endDate": "2023-07-23T00:00:00"
        },
        "voucherVisibilityDate": "2023-07-13T00:00:00",
        "perPersonRedemption": 0,
        "perPersonRedemptionCount": 0,
        "totalRedemption": 0,
        "merchant": 0,
        "voucherGeneratedType": "Generated",
        "voucherType": 0,
        "title": "",
        "description": "asdfgh",
        "isActive": true,
        "internalTags": null,
        "numberOfVouchers": 3,
        "templateHitCounter": 3,
        "lastGenratedNumberOfVoucher": 9,
        "usedVoucherCount": 3,
        "merchantCode": [],
        "generatePath": "",
        "importVoucher": [
            {
                "totalCount": 0,
                "successVoucherCount": 0,
                "unSuccessVoucherCount": 0,
                "createdDate": "2023-07-13T09:34:24+00:00",
                "blobFileName": {
                    "fileName": "",
                    "success": "",
                    "unSuccess": ""
                }
            }
        ],
        "uploadStatus": "",
        "earningType": "point",
        "earningValue": "5",
        "totalRedemptionLimit": 0,
        "redeemUrl": "asdfgh",
        "termsAndCondition": "asdfgh",
        "nxpoints": 0,
        "merchantId": [
            "MER000605"
        ],
        "discriminator": null,
        "createdDate": "2023-09-15T06:15:40.3978384Z",
        "updatedDate": "2023-09-15T06:15:40.3978384Z",
        "createdBy": null,
        "updatedBy": null,
        "templateCode": "*********",
        "generatedVouchers": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    "No. of Generated Vouchers ",
                    9,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Used Vouchers ",
                    3,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Un-used Vouchers ",
                    6
                ]
            },
            "_owner": null,
            "_store": {}
        },
        "isactive": "Active",
        "action": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "View Details",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-eye"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "View Details"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "/voucher",
                            "state": "VOT000193",
                            "className": "edit_btn",
                            "csvalue": "Edit",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-pen"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Edit"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "className": "edit_btn",
                            "csvalue": "Generate Voucher",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-file"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Generate Voucher(s)"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "Generate File",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-file"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Generate File"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    false
                ]
            },
            "_owner": null,
            "_store": {}
        }
    },
    {
        "tags": "N/A",
        "voucherUsed": false,
        "isused": false,
        "id": null,
        "name": "***********",
        "code": "***********",
        "voucherValidityDate": {
            "startDate": "2023-07-13T00:00:00",
            "endDate": "2023-07-31T00:00:00"
        },
        "voucherVisibilityDate": "2023-07-31T00:00:00",
        "perPersonRedemption": 0,
        "perPersonRedemptionCount": 0,
        "totalRedemption": 0,
        "merchant": 0,
        "voucherGeneratedType": "Generated",
        "voucherType": 0,
        "title": "",
        "description": "",
        "isActive": true,
        "internalTags": null,
        "numberOfVouchers": 2,
        "templateHitCounter": 1,
        "lastGenratedNumberOfVoucher": 2,
        "usedVoucherCount": 0,
        "merchantCode": [],
        "generatePath": "",
        "importVoucher": [
            {
                "totalCount": 0,
                "successVoucherCount": 0,
                "unSuccessVoucherCount": 0,
                "createdDate": "2023-07-13T09:32:40+00:00",
                "blobFileName": {
                    "fileName": "",
                    "success": "",
                    "unSuccess": ""
                }
            }
        ],
        "uploadStatus": "",
        "earningType": "point",
        "earningValue": "5",
        "totalRedemptionLimit": 0,
        "redeemUrl": "",
        "termsAndCondition": "",
        "nxpoints": 0,
        "merchantId": [
            "MER000605"
        ],
        "discriminator": null,
        "createdDate": "2023-09-15T06:15:40.3978385Z",
        "updatedDate": "2023-09-15T06:15:40.3978386Z",
        "createdBy": null,
        "updatedBy": null,
        "templateCode": "*********",
        "generatedVouchers": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    "No. of Generated Vouchers ",
                    2,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Used Vouchers ",
                    0,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Un-used Vouchers ",
                    2
                ]
            },
            "_owner": null,
            "_store": {}
        },
        "isactive": "Active",
        "action": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "View Details",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-eye"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "View Details"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "/voucher",
                            "state": "VOT000192",
                            "className": "edit_btn",
                            "csvalue": "Edit",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-pen"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Edit"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "className": "edit_btn",
                            "csvalue": "Generate Voucher",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-file"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Generate Voucher(s)"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "Generate File",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-file"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Generate File"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    false
                ]
            },
            "_owner": null,
            "_store": {}
        }
    },
    {
        "tags": "N/A",
        "voucherUsed": false,
        "isused": false,
        "id": null,
        "name": "***********",
        "code": "***********",
        "voucherValidityDate": {
            "startDate": "2023-07-13T00:00:00",
            "endDate": "2023-07-13T00:00:00"
        },
        "voucherVisibilityDate": "2023-07-13T00:00:00",
        "perPersonRedemption": 0,
        "perPersonRedemptionCount": 0,
        "totalRedemption": 0,
        "merchant": 0,
        "voucherGeneratedType": "Generated",
        "voucherType": 0,
        "title": "",
        "description": "AESDFG",
        "isActive": false,
        "internalTags": null,
        "numberOfVouchers": 2,
        "templateHitCounter": 1,
        "lastGenratedNumberOfVoucher": 2,
        "usedVoucherCount": 0,
        "merchantCode": [],
        "generatePath": "",
        "importVoucher": [
            {
                "totalCount": 0,
                "successVoucherCount": 0,
                "unSuccessVoucherCount": 0,
                "createdDate": "2023-07-13T06:48:10+00:00",
                "blobFileName": {
                    "fileName": "",
                    "success": "",
                    "unSuccess": ""
                }
            }
        ],
        "uploadStatus": "",
        "earningType": "point",
        "earningValue": "6",
        "totalRedemptionLimit": 0,
        "redeemUrl": "ASDF",
        "termsAndCondition": "SDFGH",
        "nxpoints": 0,
        "merchantId": [
            "MER000605"
        ],
        "discriminator": null,
        "createdDate": "2023-09-15T06:15:40.3978387Z",
        "updatedDate": "2023-09-15T06:15:40.3978387Z",
        "createdBy": null,
        "updatedBy": null,
        "templateCode":"*********",
        "generatedVouchers": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    "No. of Generated Vouchers ",
                    2,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Used Vouchers ",
                    0,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Un-used Vouchers ",
                    2
                ]
            },
            "_owner": null,
            "_store": {}
        },
        "isactive": "Inactive",
        "action": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "View Details",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-eye"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "View Details"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "/voucher",
                            "state": "VOT000190",
                            "className": "edit_btn",
                            "csvalue": "Edit",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-pen"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Edit"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "className": "edit_btn",
                            "csvalue": "Generate Voucher",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-file"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Generate Voucher(s)"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "Generate File",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-file"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Generate File"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    false
                ]
            },
            "_owner": null,
            "_store": {}
        }
    },
    {
        "tags": "N/A",
        "voucherUsed": false,
        "isused": false,
        "id": null,
        "name": "***********",
        "code": "***********",
        "voucherValidityDate": {
            "startDate": "2023-07-11T00:00:00",
            "endDate": "2023-11-09T00:00:00"
        },
        "voucherVisibilityDate": "2023-07-12T00:00:00",
        "perPersonRedemption": 0,
        "perPersonRedemptionCount": 0,
        "totalRedemption": 0,
        "merchant": 0,
        "voucherGeneratedType": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    "Imported",
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    " ",
                    {
                        "type": "p",
                        "key": null,
                        "ref": null,
                        "props": {
                            "style": {
                                "color": "#ff6a00"
                            },
                            "children": [
                                "Total Last Imported Voucher Count ",
                                10
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": "p",
                        "key": null,
                        "ref": null,
                        "props": {
                            "style": {
                                "color": "green"
                            },
                            "children": [
                                "Last Succesfull Imported Voucher Count ",
                                0
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": "p",
                        "key": null,
                        "ref": null,
                        "props": {
                            "style": {
                                "color": "red"
                            },
                            "children": [
                                "Last Unsuccessful Imported Voucher Count ",
                                10
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    }
                ]
            },
            "_owner": null,
            "_store": {}
        },
        "voucherType": 0,
        "title": "",
        "description": "awesedfghbjnm,lkoijoygutfrcgvbn mklojuytyrdfcvb njmkliuytrdfxvbnjkuy7trdfxvbnjkiyutrfdc\n nbjkuhygtrdsxfswasxcghtfrdfxcb",
        "isActive": true,
        "internalTags": null,
        "numberOfVouchers": 1,
        "templateHitCounter": 0,
        "lastGenratedNumberOfVoucher": 9,
        "usedVoucherCount": 0,
        "merchantCode": [],
        "generatePath": "https://novusapi.azurewebsites.net/voucher-files/VoucherTemplate_VOT000182_0307202332093759019.csv",
        "importVoucher": [
            {
                "totalCount": 10,
                "successVoucherCount": 0,
                "unSuccessVoucherCount": 10,
                "createdDate": "2023-07-03T06:32:31.3845652+00:00",
                "blobFileName": {
                    "fileName": "VoucherCameFromUploader0307202332063122291.csv",
                    "success": "https://assetmanagment.blob.core.windows.net/voucher-files/VoucherCameFromUploader0307202332063122291.csv",
                    "unSuccess": null
                }
            },
            {
                "totalCount": 10,
                "successVoucherCount": 9,
                "unSuccessVoucherCount": 1,
                "createdDate": "2023-07-03T06:30:24.4343062+00:00",
                "blobFileName": {
                    "fileName": "VoucherCameFromUploader0307202330062207982.csv",
                    "success": "https://assetmanagment.blob.core.windows.net/voucher-files/VoucherCameFromUploader0307202330062207982.csv",
                    "unSuccess": null
                }
            },
            {
                "totalCount": 0,
                "successVoucherCount": 0,
                "unSuccessVoucherCount": 0,
                "createdDate": "2023-07-03T06:26:28+00:00",
                "blobFileName": {
                    "fileName": "",
                    "success": "",
                    "unSuccess": ""
                }
            }
        ],
        "uploadStatus": "Complete",
        "earningType": "point",
        "earningValue": "25",
        "totalRedemptionLimit": 0,
        "redeemUrl": "www.wwwabc.com",
        "termsAndCondition": "qwasdgrt",
        "nxpoints": 0,
        "merchantId": [
            "MER000605"
        ],
        "discriminator": null,
        "createdDate": "2023-09-15T06:15:40.3978388Z",
        "updatedDate": "2023-09-15T06:15:40.3978389Z",
        "createdBy": null,
        "updatedBy": null,
        "templateCode": "*********",
        "generatedVouchers": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    "No. of Generated Vouchers ",
                    9,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Used Vouchers ",
                    0,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Un-used Vouchers ",
                    9
                ]
            },
            "_owner": null,
            "_store": {}
        },
        "isactive": "Active",
        "action": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "View Details",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-eye"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "View Details"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "/voucher",
                            "state": "VOT000182",
                            "className": "edit_btn",
                            "csvalue": "Edit",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-pen"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Edit"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "className": "edit_btn",
                            "csvalue": "Upload Voucher",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-upload"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Upload Voucher(s)"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "Generate File",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-file"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Generate File"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "https://novusapi.azurewebsites.net/voucher-files/VoucherTemplate_VOT000182_0307202332093759019.csv",
                            "className": "edit_btn",
                            "csvalue": "Generate File",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-download"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Download File"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    }
                ]
            },
            "_owner": null,
            "_store": {}
        }
    },
    {
        "tags": "N/A",
        "voucherUsed": false,
        "isused": false,
        "id": null,
        "name": "***********",
        "code": "***********",
        "voucherValidityDate": {
            "startDate": "2023-07-12T00:00:00",
            "endDate": "2023-07-21T00:00:00"
        },
        "voucherVisibilityDate": "2023-07-12T00:00:00",
        "perPersonRedemption": 0,
        "perPersonRedemptionCount": 0,
        "totalRedemption": 0,
        "merchant": 0,
        "voucherGeneratedType": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    "Imported",
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    " ",
                    {
                        "type": "p",
                        "key": null,
                        "ref": null,
                        "props": {
                            "style": {
                                "color": "#ff6a00"
                            },
                            "children": [
                                "Total Last Imported Voucher Count ",
                                10
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": "p",
                        "key": null,
                        "ref": null,
                        "props": {
                            "style": {
                                "color": "green"
                            },
                            "children": [
                                "Last Succesfull Imported Voucher Count ",
                                10
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": "p",
                        "key": null,
                        "ref": null,
                        "props": {
                            "style": {
                                "color": "red"
                            },
                            "children": [
                                "Last Unsuccessful Imported Voucher Count ",
                                0
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    }
                ]
            },
            "_owner": null,
            "_store": {}
        },
        "voucherType": 0,
        "title": "",
        "description": "",
        "isActive": false,
        "internalTags": null,
        "numberOfVouchers": 1,
        "templateHitCounter": 0,
        "lastGenratedNumberOfVoucher": 11,
        "usedVoucherCount": 8,
        "merchantCode": [],
        "generatePath": "https://novusapi.azurewebsites.net/voucher-files/VoucherTemplate_VOT000183_0307202333095498647.csv",
        "importVoucher": [
            {
                "totalCount": 10,
                "successVoucherCount": 10,
                "unSuccessVoucherCount": 0,
                "createdDate": "2023-07-03T06:53:50.4427365+00:00",
                "blobFileName": {
                    "fileName": "VoucherCameFromUploader0307202353065025953.csv",
                    "success": "https://assetmanagment.blob.core.windows.net/voucher-files/VoucherCameFromUploader0307202353065025953.csv",
                    "unSuccess": null
                }
            },
            {
                "totalCount": 10,
                "successVoucherCount": 1,
                "unSuccessVoucherCount": 9,
                "createdDate": "2023-07-03T06:34:56.2056843+00:00",
                "blobFileName": {
                    "fileName": "VoucherCameFromUploader0307202334065593202.csv",
                    "success": "https://assetmanagment.blob.core.windows.net/voucher-files/VoucherCameFromUploader0307202334065593202.csv",
                    "unSuccess": null
                }
            },
            {
                "totalCount": 0,
                "successVoucherCount": 0,
                "unSuccessVoucherCount": 0,
                "createdDate": "2023-07-03T06:33:30+00:00",
                "blobFileName": {
                    "fileName": "",
                    "success": "",
                    "unSuccess": ""
                }
            }
        ],
        "uploadStatus": "Complete",
        "earningType": "point",
        "earningValue": "12",
        "totalRedemptionLimit": 0,
        "redeemUrl": "",
        "termsAndCondition": "",
        "nxpoints": 0,
        "merchantId": [
            "MER000605"
        ],
        "discriminator": null,
        "createdDate": "2023-09-15T06:15:40.397839Z",
        "updatedDate": "2023-09-15T06:15:40.397839Z",
        "createdBy": null,
        "updatedBy": null,
        "templateCode": "*********",
        "generatedVouchers": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    "No. of Generated Vouchers ",
                    11,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Used Vouchers ",
                    8,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Un-used Vouchers ",
                    3
                ]
            },
            "_owner": null,
            "_store": {}
        },
        "isactive": "Inactive",
        "action": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "View Details",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-eye"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "View Details"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "/voucher",
                            "state": "VOT000183",
                            "className": "edit_btn",
                            "csvalue": "Edit",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-pen"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Edit"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "className": "edit_btn",
                            "csvalue": "Upload Voucher",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-upload"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Upload Voucher(s)"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "Generate File",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-file"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Generate File"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "https://novusapi.azurewebsites.net/voucher-files/VoucherTemplate_VOT000183_0307202333095498647.csv",
                            "className": "edit_btn",
                            "csvalue": "Generate File",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-download"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Download File"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    }
                ]
            },
            "_owner": null,
            "_store": {}
        }
    },
    {
        "tags": "N/A",
        "voucherUsed": false,
        "isused": false,
        "id": null,
        "name": "***********",
        "code": "***********",
        "voucherValidityDate": {
            "startDate": "2023-07-11T00:00:00",
            "endDate": "2023-09-07T00:00:00"
        },
        "voucherVisibilityDate": "2023-07-11T00:00:00",
        "perPersonRedemption": 0,
        "perPersonRedemptionCount": 0,
        "totalRedemption": 0,
        "merchant": 0,
        "voucherGeneratedType": "Generated",
        "voucherType": 0,
        "title": "",
        "description": "",
        "isActive": true,
        "internalTags": null,
        "numberOfVouchers": 5,
        "templateHitCounter": 0,
        "lastGenratedNumberOfVoucher": 0,
        "usedVoucherCount": 0,
        "merchantCode": [],
        "generatePath": "",
        "importVoucher": [
            {
                "totalCount": 0,
                "successVoucherCount": 0,
                "unSuccessVoucherCount": 0,
                "createdDate": "2023-07-11T09:48:13+00:00",
                "blobFileName": {
                    "fileName": "",
                    "success": "",
                    "unSuccess": ""
                }
            }
        ],
        "uploadStatus": "",
        "earningType": "point",
        "earningValue": "12",
        "totalRedemptionLimit": 0,
        "redeemUrl": "",
        "termsAndCondition": "",
        "nxpoints": 0,
        "merchantId": [
            "MER000605"
        ],
        "discriminator": null,
        "createdDate": "2023-09-15T06:15:40.3978391Z",
        "updatedDate": "2023-09-15T06:15:40.3978392Z",
        "createdBy": null,
        "updatedBy": null,
        "templateCode": "*********",
        "generatedVouchers": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    "No. of Generated Vouchers ",
                    0,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Used Vouchers ",
                    0,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Un-used Vouchers ",
                    0
                ]
            },
            "_owner": null,
            "_store": {}
        },
        "isactive": "Active",
        "action": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "View Details",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-eye"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "View Details"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "/voucher",
                            "state": "VOT000187",
                            "className": "edit_btn",
                            "csvalue": "Edit",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-pen"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Edit"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "className": "edit_btn",
                            "csvalue": "Generate Voucher",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-file"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Generate Voucher(s)"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    false,
                    false
                ]
            },
            "_owner": null,
            "_store": {}
        }
    },
    {
        "tags": "N/A",
        "voucherUsed": false,
        "isused": false,
        "id": null,
        "name": "***********",
        "code": "***********",
        "voucherValidityDate": {
            "startDate": "2023-07-05T00:00:00",
            "endDate": "2023-07-16T00:00:00"
        },
        "voucherVisibilityDate": "2023-07-08T00:00:00",
        "perPersonRedemption": 1,
        "perPersonRedemptionCount": 0,
        "totalRedemption": 0,
        "merchant": 0,
        "voucherGeneratedType": "Generated",
        "voucherType": 1,
        "title": "",
        "description": "texting purpose",
        "isActive": true,
        "internalTags": null,
        "numberOfVouchers": 1,
        "templateHitCounter": 1,
        "lastGenratedNumberOfVoucher": 1,
        "usedVoucherCount": 0,
        "merchantCode": [],
        "generatePath": "https://novusapi.azurewebsites.net/voucher-files/VoucherTemplate_VOT000125_0507202303062404950.csv",
        "importVoucher": [
            {
                "totalCount": 0,
                "successVoucherCount": 0,
                "unSuccessVoucherCount": 0,
                "createdDate": "2023-06-23T08:41:18+00:00",
                "blobFileName": {
                    "fileName": "",
                    "success": "",
                    "unSuccess": ""
                }
            }
        ],
        "uploadStatus": "",
        "earningType": "currency",
        "earningValue": "1",
        "totalRedemptionLimit": 0,
        "redeemUrl": "",
        "termsAndCondition": "",
        "nxpoints": 0,
        "merchantId": [
            "MER000605"
        ],
        "discriminator": null,
        "createdDate": "2023-09-15T06:15:40.3978393Z",
        "updatedDate": "2023-09-15T06:15:40.3978393Z",
        "createdBy": null,
        "updatedBy": null,
        "templateCode": "*********",
        "generatedVouchers": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    "No. of Generated Vouchers ",
                    1,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Used Vouchers ",
                    0,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Un-used Vouchers ",
                    1
                ]
            },
            "_owner": null,
            "_store": {}
        },
        "isactive": "Active",
        "action": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "View Details",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-eye"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "View Details"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "/voucher",
                            "state": "VOT000125",
                            "className": "edit_btn",
                            "csvalue": "Edit",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-pen"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Edit"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "className": "edit_btn",
                            "csvalue": "Generate Voucher",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-file"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Generate Voucher(s)"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "Generate File",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-file"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Generate File"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "https://novusapi.azurewebsites.net/voucher-files/VoucherTemplate_VOT000125_0507202303062404950.csv",
                            "className": "edit_btn",
                            "csvalue": "Generate File",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-download"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Download File"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    }
                ]
            },
            "_owner": null,
            "_store": {}
        }
    },
    {
        "tags": "N/A",
        "voucherUsed": false,
        "isused": false,
        "id": null,
        "name": "***********",
        "code":"***********",
        "voucherValidityDate": {
            "startDate": "2023-07-03T00:00:00",
            "endDate": "2023-09-05T00:00:00"
        },
        "voucherVisibilityDate": "2023-07-03T00:00:00",
        "perPersonRedemption": 0,
        "perPersonRedemptionCount": 0,
        "totalRedemption": 0,
        "merchant": 0,
        "voucherGeneratedType": "Generated",
        "voucherType": 0,
        "title": "",
        "description": "text",
        "isActive": true,
        "internalTags": null,
        "numberOfVouchers": 2,
        "templateHitCounter": 6,
        "lastGenratedNumberOfVoucher": 12,
        "usedVoucherCount": 8,
        "merchantCode": [],
        "generatePath": "https://novusapi.azurewebsites.net/voucher-files/VoucherTemplate_VOT000179_3006202359092677461.csv",
        "importVoucher": [
            {
                "totalCount": 0,
                "successVoucherCount": 0,
                "unSuccessVoucherCount": 0,
                "createdDate": "2023-06-28T12:02:17+00:00",
                "blobFileName": {
                    "fileName": "",
                    "success": "",
                    "unSuccess": ""
                }
            }
        ],
        "uploadStatus": "",
        "earningType": "discount",
        "earningValue": "1",
        "totalRedemptionLimit": 0,
        "redeemUrl": "NA",
        "termsAndCondition": "NA",
        "nxpoints": 0,
        "merchantId": [
            "MER000605"
        ],
        "discriminator": null,
        "createdDate": "2023-09-15T06:15:40.3978394Z",
        "updatedDate": "2023-09-15T06:15:40.3978395Z",
        "createdBy": null,
        "updatedBy": null,
        "templateCode": "*********",
        "generatedVouchers": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    "No. of Generated Vouchers ",
                    12,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Used Vouchers ",
                    8,
                    {
                        "type": "br",
                        "key": null,
                        "ref": null,
                        "props": {},
                        "_owner": null,
                        "_store": {}
                    },
                    "No. of Un-used Vouchers ",
                    4
                ]
            },
            "_owner": null,
            "_store": {}
        },
        "isactive": "Active",
        "action": {
            "key": null,
            "ref": null,
            "props": {
                "children": [
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "View Details",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-eye"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "View Details"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "/voucher",
                            "state": "VOT000179",
                            "className": "edit_btn",
                            "csvalue": "Edit",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-pen"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Edit"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "className": "edit_btn",
                            "csvalue": "Generate Voucher",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-file"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Generate Voucher(s)"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "",
                            "state": {
                                "voucherCode": "***********"
                            },
                            "className": "edit_btn",
                            "csvalue": "Generate File",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-file"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Generate File"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    },
                    {
                        "type": {},
                        "key": null,
                        "ref": null,
                        "props": {
                            "to": "https://novusapi.azurewebsites.net/voucher-files/VoucherTemplate_VOT000179_3006202359092677461.csv",
                            "className": "edit_btn",
                            "csvalue": "Generate File",
                            "children": [
                                {
                                    "type": "i",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "fa-solid fa-download"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                },
                                {
                                    "type": "div",
                                    "key": null,
                                    "ref": null,
                                    "props": {
                                        "className": "show_div",
                                        "children": "Download File"
                                    },
                                    "_owner": null,
                                    "_store": {}
                                }
                            ]
                        },
                        "_owner": null,
                        "_store": {}
                    }
                ]
            },
            "_owner": null,
            "_store": {}
        }
    },
  

]
const IntroVoucherTemplate = ({ voucherTemplateDetail, clearVoucherTemplate }) => {
    const [formData, setFormData] = useState();
    const [showLogin, setShowLogin] = useState(false);
    const [voucherCode, setVoucherCode] = useState("");
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showModal, setModal] = useState(false);
    let exitOnLast=false
    const [loader, setLoader] = useState(true);
    const intro = introJs();
    const modalOpen = useSelector(state => state.intro.modalOpen);
     const navigate=useNavigate()
    // useEffect(() => {
    //     setLoader(true);
    //     clearVoucherTemplate();
    //     const token = JSON.parse(localStorage.getItem("token_gen"));
    //     voucherTemplateDetail(token.access_token)
    //         .then(() => {
    //             setLoader(false);
    //         })
    //         .catch((error) => {
    //             setLoader(false);
    //         });
    // }, []);
 let VoucherTemplate=staticData
    const handleUploadViewModal = (data) => {
        setShowUploadModal(true);
        setVoucherCode(data);
    };
    const handleViewModal = (data) => {
        setShowLogin(true);
        setVoucherCode(data);
    };
    const handleShowUploadModal = (isShow, title) => {
        setShowUploadModal(isShow);
        if (title === "Voucher are processing to get status refresh the page.") {
            swal({
                position: "center",
                icon: "success",
                title: title,
                showConfirmButton: false,
                timer: 5000,
            });
        } else {
            swal({
                position: "center",
                icon: "error",
                title: title,
                showConfirmButton: false,
                timer: 5000,
            });
        }
        // window.location.reload();
    };
    const buttons = document.querySelectorAll(".introjs-skipbutton");
    buttons.forEach(button => {
     button.addEventListener("click", () => {
       localStorage.setItem('firstLogin','')
     });
   });

    const handleCreateVoucher = async (code) => {
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const body = { code: code };
        let response = await GenerateVoucher(token.access_token, body);
        if (response.id) {
            swal({
                position: "center",
                icon: "success",
                title: "Refresh the page your voucher has been created successfully.",
                showConfirmButton: false,
                timer: 5000,
            });
        } else {
            swal({
                position: "center",
                icon: "error",
                title: response.message,
                showConfirmButton: false,
                timer: 5000,
            });
        }
    };
    const handleGenerateFile = async (code) => {
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const body = { code: code };
        let response = await DownloadVoucher(token.access_token, body);
        if (response.message === "Voucher are processing to get status refresh the page.") {
            swal({
                position: "center",
                icon: "success",
                title: "Your file is generating please refresh the page after some time.",
                showConfirmButton: false,
                timer: 5000,
            });
        } else {
            swal({
                position: "center",
                icon: "error",
                title: response.message,
                showConfirmButton: false,
                timer: 5000,
            });
        }
    };
 
    useEffect(() => {
        const tempData = staticData;
        if (tempData) {
            tempData?.map((val, ind) => {
                return (val.name = val.name);
            });
            tempData?.map((val, ind) => {
                // eslint-disable-next-line
                return (val.templateCode = val.code);
            });
            tempData?.map((val, ind) => {
                // eslint-disable-next-line
                return (val.generatedVouchers = (
                    <>
                        No. of Generated Vouchers {val.lastGenratedNumberOfVoucher}
                        <br />
                        No. of Used Vouchers {val.usedVoucherCount}
                        <br />
                        No. of Un-used Vouchers {val.lastGenratedNumberOfVoucher - val.usedVoucherCount}
                    </>
                ));
            });
            tempData?.map((val, ind) => {
                // eslint-disable-next-line
                return (val.totalRedemption = val.totalRedemption);
            });
            tempData?.map((val, ind) => {
                // eslint-disable-next-line
                return (val.tagline = val.tagline);
            });
            tempData?.map((val, ind) => {
                // eslint-disable-next-line
                return (val.voucherGeneratedType = val.voucherGeneratedType ? (
                    "Generated"
                ) : val?.importVoucher?.length ? (
                    <>
                        Imported
                        <br /> <p style={{ color: "#ff6a00" }}>Total Last Imported Voucher Count {val.importVoucher[0].totalCount}</p>
                        <p style={{ color: "green" }}>Last Succesfull Imported Voucher Count {val.importVoucher[0].successVoucherCount}</p>
                        <p style={{ color: "red" }}>Last Unsuccessful Imported Voucher Count {val.importVoucher[0].unSuccessVoucherCount}</p>
                    </>
                ) : (
                    <>
                        Imported
                        <br /> <p style={{ color: "#ff6a00" }}>Total Last Imported Voucher Count 0</p>
                        <p style={{ color: "green" }}>Last Succesfull Imported Voucher Count 0</p>
                        <p style={{ color: "red" }}>Last Unsuccessful Imported Voucher Count 0</p>
                    </>
                ));
            });
            tempData?.map((val, ind) => {
                // eslint-disable-next-line
                return (val.tags = "N/A");
            });
            tempData?.map((val, ind) => {
                // eslint-disable-next-line
                return (val.isactive = val.isActive === true ? "Active" : "Inactive");
            });

            tempData?.map((val, ind) => {
                return (val.action = (
                    <>
                        <Link to="" state={{ voucherCode: val.code }} className="edit_btn" csvalue="View Details" onClick={() => handleViewModal(val.code)}>
                            {/* <i className="fa-solid fa-eye"></i> View Details */}
                            <i className="fa-solid fa-eye"></i>
                            <div className="show_div">View Details</div>
                        </Link>
                        <Link to="/voucher" state={val.code} className="edit_btn" csvalue="Edit">
                            {/* <i className="fa-solid fa-pen"></i> Edit */}
                            <i className="fa-solid fa-pen"></i>
                            <div className="show_div">Edit</div>
                        </Link>
                        {val.voucherGeneratedType === "Generated" ? (
                            <Link to="" onClick={() => handleCreateVoucher(val.code)} className="edit_btn" csvalue="Generate Voucher">
                                {/* <i className="fa-solid fa-file"></i> Generate Voucher(s) */}
                                <i className="fa-solid fa-file"></i>
                                <div className="show_div">Generate Voucher(s)</div>
                            </Link>
                        ) : (
                            <Link to="" onClick={() => handleUploadViewModal(val.code)} className="edit_btn" csvalue="Upload Voucher">
                                {/* <i className="fa-solid fa-upload"></i> Upload Voucher(s) */}
                                <i className="fa-solid fa-upload"></i>
                                <div className="show_div">Upload Voucher(s)</div>
                            </Link>
                        )}
                        {val.lastGenratedNumberOfVoucher > 0 && (
                            <Link to="" state={{ voucherCode: val.code }} onClick={() => handleGenerateFile(val.code)} className="edit_btn" csvalue="Generate File">
                                {/* <i className="fa-solid fa-file"></i> Generate File */}
                                <i className="fa-solid fa-file"></i>
                                <div className="show_div">Generate File</div>
                            </Link>
                        )}
                        {val.generatePath != "" && (
                            <Link to={val.generatePath} className="edit_btn" csvalue="Generate File">
                                {/* <i className="fa-solid fa-file"></i> Generate File */}
                                <i className="fa-solid fa-download"></i>
                                <div className="show_div">Download File</div>
                            </Link>
                        )}
                    </>
                ));
            });
        }
 
        setFormData(tempData);
    }, [VoucherTemplate]);
    let complete=false
    useEffect(() => { 
     
        const handleLoad=()=>{ 
       // temp=1
 
         const steps = [
            {
                element: document.getElementById('voucher'),
                title: 'Create Vouchers Template',
                intro: "Craft templates for voucher campaigns to maintain branding consistency."
              },
             
              {
                element:document.getElementById('gift'),
                title: 'Gift Card ',
                intro: 'Provide gift cards as an integral component of your loyalty program for enhanced customer experiences.',
              
              }

         ]
         intro.setOptions({
             steps: steps,
             doneLabel:"Next",
             exitOnOverlayClick: false
                       });
         intro.start();
         } 
            setTimeout(()=>{
            handleLoad()
            },500)
       
            return () => {
                intro.exit();
            };
       }, []);

       intro.oncomplete(function(element) {
        complete=true
        setTimeout(()=>{
      if(!exitOnLast) navigate("/intro/gift-card")
        },600)
        
    });
   
    intro.onexit(() => {
        if(!complete){
            setModal(true)
            localStorage.setItem('firstLogin','')      

        } 
      });
      intro.onchange(function(element) {
        switch (this._currentStep){
            case 1:
              setTimeout(()=>{
                let cross=  document.getElementsByClassName("introjs-skipbutton")[0]
                cross?.addEventListener('click', function() {
                exitOnLast=true
                localStorage.setItem('firstLogin','')     
                });
              },600)  
    
              
                   break;
                 
           }
    
    });
    return (
        <>
            <Header name={"Voucher Template"} />
            
            {showModal && <ModalCard show={showModal}/>}

            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="dashboard__heading">
                                <div className="dashboard__headingLeft">
                                    <div className="dashboard__headingContent">
                                        <Link to="/voucher">
                                        <Button disabled={true} id="voucher" className="btn btn-primary btn-lg">Create Voucher Template</Button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard__customersCard">
                            <div className="dashboard__header">
                                <div className="dashboard__left">
                                    <h4 id='voucher_details'>Voucher Template Details</h4>
                                    {showLogin && <VoucherViewModal show={showLogin} close={() => setShowLogin(false)} voucherCode={showLogin && voucherCode} />}
                                    {showUploadModal && <UploadVoucherModal show={showUploadModal} close={() => setShowUploadModal(false)} voucherCode={showUploadModal && voucherCode}  />}
                                </div>
                            </div>
                            <div className="dashboard__body">
                                <Paginated data={formData ? formData : []} columns={COLUMNS} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => ({
    VoucherTemplate: state.VoucherTemplate,
});
const mapDispatchToProps = {
    voucherTemplateDetail,
    clearVoucherTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(IntroVoucherTemplate);
