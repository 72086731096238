import React from "react";
import { logo } from "../../Image/Index";
import { useLocation } from "react-router-dom";
import AuthenticationSliderLeft from "./common/AuthenticationSliderLeft";

function Thankyou() {
    const { state } = useLocation();

    return (
        <section className="section signUpPage">
            <div className="signUpPage__main">
                <AuthenticationSliderLeft />
                {state?.additionalUpdate  ? (
                     <div className="signUpPage__right">
                     <div className="signUpPage__heading">
                         <figure>
                             <img src={logo} alt="logo" />
                         </figure>
                         
                         <h1>Thank You for Coming again</h1>
                         <h4 className="thankyouHeading">
                             We have send again  a confirmation e-mail to: <span>{state?.emailID}</span> <br />
                             <strong>Click on the Activation Link</strong> to complete your sign in.
                         </h4>
                         <p>
                             <button type="button" onClick={(e) => (window.location.href = "mailto:example@email.com")} className="btn btn-primary">
                                 <i className="fa-solid fa-envelope"></i> Check Your Inbox
                             </button>
                         </p>
                     </div>
                 </div>
                ):(
                    <div className="signUpPage__right">
                    <div className="signUpPage__heading">
                        <figure>
                            <img src={logo} alt="logo" />
                        </figure>
                        
                        <h1>Thank You for Signing up!</h1>
                        <h4 className="thankyouHeading">
                            We just send you a confirmation e-mail to: <span>{state?.emailID}</span> <br />
                            <strong>Click on the Activation Link</strong> to complete your sign in.
                        </h4>
                        <p>
                            <button type="button" onClick={(e) => (window.location.href = "mailto:example@email.com")} className="btn btn-primary">
                                <i className="fa-solid fa-envelope"></i> Check Your Inbox
                            </button>
                        </p>
                    </div>
                </div>
                )}
              
            </div>
        </section>
    );
}

export default Thankyou;
