import React, { useState, useEffect, useMemo } from "react";
import Header from "../../layout/Header";
import { Link, useLocation } from "react-router-dom";
import { dashboardIconOne, payment_failed } from "../../../Image/Index";
import { decrypt } from "../../../utils/encodingdecoding";
import BarChartAnalyticalReport1 from "../../charts/BarChartAnalyticalReport2";
import { getUserJourneyReport } from "../../../actions/dashboard";
import BarChartAnalyticalReport3 from "../../charts/BarChartAnalyticalReport3";
import LineChartAnalyticalReport3 from "../../charts/LineChartAnalyticalReport3";
import { getBusinessGoalReport, getCampaignCodeReport, getRFMReport } from "../campaigns/services";
import SelectValidator from "../../partial/SelectValidator";
import { ValidatorForm } from "react-form-validator-core";
import { selectValueGetter } from "../../../utils/helper";

const CampaignReport = () => {
    const [selectedCategory, setSelectedCategory] = useState("");
    const [categoryData, setCategoryData] = useState("");
    const [selectedBusinessGoalData, setSelectedBusinessGoalData] = useState(null);

    const location = useLocation();
    const campaignId = location?.state?.campaignId;

    const [loader, setLoader] = useState(false);
    const [report, setReport] = useState([]);
    const [data, setData] = useState([]);
    const [response, setResponse] = useState({});
    const industry = decrypt(localStorage.getItem("schemaName"));
    const [option, setoption] = useState([]);
    const [options, setOptions] = useState([]);
    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => {
        fetch();
    }, []);

    const fetch = async () => {
        const tempOption = [];
        let tempToken = JSON.parse(localStorage.getItem("token_gen"));
        tempToken = tempToken?.access_token;
        await getBusinessGoalReport(tempToken, industry)
            .then((res) => {
                setReport(res.data);
            })
            .catch(() => {});
    };

    const fetch2 = async () => {
        setLoader(true);
        let tempToken = JSON.parse(localStorage.getItem("token_gen"));
        tempToken = tempToken?.access_token;
        await getRFMReport(tempToken)
            .then((res) => {
                setData(res.data);
                setFirstRender(false);
            })
            .catch(() => {});
        setLoader(false);
    };
    useEffect(() => {
        fetch2();
    }, []);

    useEffect(() => {
        if (campaignId) {
            onClick(campaignId);
        }
    }, [campaignId]);

    const onClick = async (code, businessGoal) => {
        if (code) {
            setCategoryData(code);
            setLoader(true);

            let tempToken = JSON.parse(localStorage.getItem("token_gen"));
            tempToken = tempToken?.access_token;

            await getCampaignCodeReport(tempToken, code)
                .then((res) => {
                    setResponse(res);
                    setLoader(false);
                    setSelectedCategory(res?.data?.businessGoal || "NA");
                })
                .catch(() => {
                    setLoader(false);
                });

            setTimeout(() => {
                window.scrollTo(0, document.body.scrollHeight);
            }, 100);
        } else {
        }
    };

    useEffect(() => {
        if (report) {
            const temp = report.map((goal) => ({
                value: goal?.businessGoal,
                label: goal?.businessGoal,
            }));
            setoption(temp);
        }
    }, [report]);

    const LineChartAnalyticalReport = useMemo(() => {
        return <LineChartAnalyticalReport3 predictedData={data} />;
    }, [data]);
    const handleCategoryChange = (selectedValue) => {
        setSelectedCategory(selectedValue);

        const selectedGoalData = report.find((item) => item.businessGoal === selectedValue.value);

        setSelectedBusinessGoalData(selectedGoalData);
        setResponse({});
        setCategoryData("");
    };

    useEffect(() => {
        const temp = [];
        if (selectedBusinessGoalData) {
            selectedBusinessGoalData.data.map((goal) => {
                temp.push({
                    value: goal?.code,
                    label: goal?.name,
                    code: goal?.code,
                });
            });
        } else {
            report?.forEach((item) => {
                item.data.map((goal) => {
                    temp.push({
                        value: goal?.code,
                        label: goal?.name,
                        code: goal?.code,
                    });
                });
            });
        }
        setOptions(temp);
    }, [selectedBusinessGoalData, report]);
    const onSubmit = () => {};
    useEffect(() => {
        const selectedCampaign = report.find((campaign) => {
            return campaign.data.some((goal) => goal.code === campaignId);
        });

        if (selectedCampaign) {
            setSelectedCategory(selectedCampaign.businessGoal);

            // Check if selectedCampaign has data and is an array before mapping
            if (selectedCampaign.data && Array.isArray(selectedCampaign.data)) {
                const selectedCampaignData = selectedCampaign.data.map((item) => {
                    setCategoryData(item.name);
                });
            }
        }
    }, [report, campaignId]);

    return (
        <>
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <Header name={"Campaign Report"} />
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__transactionsCard">
                        <div className="dashboard__header">
                            <div className="dashboard__left">
                                <h4 id="transction_list">RFM & Current Data Overview</h4>
                            </div>
                        </div>

                        <div className="dashboard__body">
                            <div className="customTable__body">
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>RFM Data</th>
                                                <th>Current Data</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <strong style={{ float: "left" }}>Total Campaign</strong>
                                                </td>
                                                <td>{firstRender ? "" : 0}</td>
                                                <td>{firstRender ? "" : data?.totalCampaign || 0}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong style={{ float: "left" }}>Total Customer</strong>
                                                </td>
                                                <td>{firstRender ? "" : 0}</td>
                                                <td>{firstRender ? "" : data?.totalCustomer || 0}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong style={{ float: "left" }}>Total Transaction Amount</strong>
                                                </td>
                                                <td>{firstRender ? "" : 0}</td>
                                                <td>{firstRender ? "" : data?.totalTransactionAmount || 0}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong style={{ float: "left" }}>Avg Transaction Amount</strong>
                                                </td>
                                                <td>{firstRender ? "" : 0}</td>
                                                <td>{firstRender ? "" : data?.avarageTransactionAmount || 0}</td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <strong style={{ float: "left" }}>Total Transaction</strong>
                                                </td>
                                                <td>{firstRender ? "" : 0}</td>
                                                <td>{firstRender ? "" : data?.totalTransaction || 0}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong style={{ float: "left" }}>Total Redeem Points</strong>
                                                </td>
                                                <td>{firstRender ? "" : 0}</td>
                                                <td>{firstRender ? "" : data?.totalRedeemPoints || 0}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong style={{ float: "left" }}>Redeption Rate</strong>
                                                </td>
                                                <td>{firstRender ? "" : 0}</td>
                                                <td>{firstRender ? "" : data?.redeptionRate || 0}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong style={{ float: "left" }}>Total Points</strong>
                                                </td>
                                                <td>{firstRender ? "" : 0}</td>
                                                <td>{firstRender ? "" : data?.totalPoints || 0}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="analyticalReport" style={{ paddingTop: "30px" }}>
                        <div className="analyticalReportChartAnalysisOne">
                            <div id="dashboard" className="container" style={{ width: "100%" }}>
                                <div className="analyticalReportChartAnalysisOne__topSection">
                                    <div className="analyticalReportChartAnalysisOne__heading">
                                        <h2>Data Overview</h2>
                                    </div>
                                    <div className="analyticalReportChartAnalysisOne__fullSection">
                                        <div className="analyticalReportChartAnalysisOne__box">
                                            <div className="analyticalReportChartAnalysisOne__header">
                                                <div className="analyticalReportChartAnalysisOne__left">
                                                    <div className="analyticalReportChartAnalysisOne__image">
                                                        <img src={dashboardIconOne} alt="" />
                                                    </div>
                                                    <div className="analyticalReportChartAnalysisOne__details">
                                                        <h3>Data</h3>
                                                    </div>
                                                </div>
                                              
                                            </div>
                                            <div className="analyticalReportChartAnalysisOne__chartSection">
                                            {LineChartAnalyticalReport}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="dashboard__customerViewMain" style={{ paddingTop: "30px" }}>
                        <div className="analyticalReportChartAnalysisOne__heading">
                            <h2>Campaign Report</h2>
                        </div>
                        <div className="dashboard__customerViewCard">
                            <ValidatorForm onSubmit={() => onSubmit()}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Business Goal</label>
                                            <SelectValidator placeholder="Select Business Goal" className="custom-ReactSelect bordered no____value" value={selectValueGetter(option, selectedCategory)} selected={selectedCategory} options={option} onChange={handleCategoryChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Campaign</label>
                                            <SelectValidator placeholder="Select A Campaign" className="custom-ReactSelect bordered no____value" value={categoryData ? selectValueGetter(options, categoryData) : []} selected={categoryData} options={options.length === 0 ? [{ label: "No Campaign Available", value: "" }] : options} onChange={(e) => onClick(e.code)} />
                                        </div>
                                    </div>
                                </div>
                            </ValidatorForm>

                            {response && Object.keys(response).length > 0 && (
                                <div className="dashboard__customerViewPointsTabs">
                                    <div className="dashboard__customerViewPoints">
                                        <div className="dashboard__campaignReportMainBox">
                                            <div className="dashboard__campaignReport">
                                                <div className="campaignReportTextBox">
                                                    <h4>Total Customer</h4>
                                                    <h3>{response?.data?.totalCustomer || 0}</h3>
                                                </div>
                                            </div>
                                            <div className="dashboard__campaignReport">
                                                <div className="campaignReportTextBox">
                                                    <h4>Total Transaction</h4>
                                                    <h3>{response?.data?.totalTransaction || 0}</h3>
                                                </div>
                                            </div>
                                            <div className="dashboard__campaignReport">
                                                <div className="campaignReportTextBox">
                                                    <h4>Total Transaction Amount</h4>
                                                    <h3>{response?.data?.totalTransactionAmount || 0}</h3>
                                                </div>
                                            </div>
                                            <div className="dashboard__campaignReport">
                                                <div className="campaignReportTextBox">
                                                    <h4>Average Transaction Amount</h4>
                                                    <h3>{response?.data?.avarageTransactionAmount || 0}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dashboard__campaignReportMainBoxx">
                                            <div className="dashboard__campaignReport">
                                                <div className="campaignReportTextBox">
                                                    <h4>Total Points</h4>
                                                    <h3>{response?.data?.totalPoints || 0}</h3>
                                                </div>
                                            </div>
                                            <div className="dashboard__campaignReport">
                                                <div className="campaignReportTextBox">
                                                    <h4>Total Redeem Points</h4>
                                                    <h3>{response?.data?.totalRedeemPoints || 0}</h3>
                                                </div>
                                            </div>
                                            <div className="dashboard__campaignReport">
                                                <div className="campaignReportTextBox">
                                                    <h4>Redemption Rate</h4>
                                                    <h3>{response?.data?.redeptionRate || 0}</h3>
                                                </div>
                                            </div>

                                            <div className="dashboard__campaignReport">
                                                <div className="campaignReportTextBox">
                                                    <h4>Active Redemption</h4>
                                                    <h3>{response?.data?.ActiveRedeemption || 0}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dashboard__campaignReportMainBoxx">
                                            <div className="dashboard__campaignReport">
                                                <div className="campaignReportTextBox">
                                                    <h4>Expire Redemption</h4>
                                                    <h3>{response?.data?.ExpireRedeemption || 0}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CampaignReport;
