import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { connect } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { logo, inside, outside, dashboard, dashboardHover, programs, programsHover, customers, customersHover, transaction, transactionHover, /*financialAccount, financialAccountHover,*/ settings, settingsHover, /*helpFAQs, helpFAQsHover,*/ logoutIcon, logoutIconHover, apiDocument, apiDocumentHover, uploadIcon, monitor, monitor_hover } from "../Image/Index";
import { logout } from "../actions/authAction";
import { clearCampaigns } from "../actions/campaigns";
import { clearCustomer } from "../actions/customer";
import { clearDashboard } from "../actions/dashboard";
import { clearSetting } from "../actions/setting";
import { clearTransaction } from "../actions/transaction";
import { clearBusinessGoal } from "../actions/businessGoal";
import { decrypt } from "../utils/encodingdecoding";
import introJs from "intro.js";
import "intro.js/introjs.css";
import { useSelector, useDispatch } from "react-redux";
import { modalCard, startTour } from "../actions/intro";

const IntroSidebar = (props) => {
    const { children, logout, clearCampaigns, clearCustomer, clearDashboard, clearSetting, clearTransaction, clearBusinessGoal } = props;
    const location = useLocation();
    const [toggle, setToggle] = useState(true);
    const [dropdownDocBtn, setDropdownDocBtn] = useState(false);
    const [activeBtn, setActiveBtn] = useState(false);
    const [dropdownSettingBtn, setDropdownSettingBtn] = useState(false);
    const [apiDocBtn, setApiDocBtn] = useState(false);
    const [menuItem, setMenuItem] = useState([]);
    const [menuTree, setMenuTree] = useState([]);
    const [isCardAttached, setIsCardAttached] = useState([]);
    let Dashboard = sessionStorage.getItem("dashboard");
    const intro = introJs();
    const profileDrop = useSelector((state) => state.intro.dropDownSetting);
    const documentDrop = useSelector((state) => state.intro.documentation);
    const dispatch = useDispatch();
    const [key, setKey] = useState(true);
    useEffect(() => {
        if (location.pathname.includes("customers-list")) {
            let tempCusPageIndex = JSON.parse(localStorage.getItem("CusPageIndex"));
            localStorage.setItem("CusPageIndex", JSON.stringify(tempCusPageIndex));
        } else {
            localStorage.setItem("CusPageIndex", JSON.stringify(1));
        }
        if (location.pathname.includes("transactions-list")) {
            let tempTransPageIndex = JSON.parse(localStorage.getItem("TransPageIndex"));
            localStorage.setItem("TransPageIndex", JSON.stringify(tempTransPageIndex));
        } else {
            localStorage.setItem("TransPageIndex", JSON.stringify(1));
        }

        let commTabKey = decrypt(localStorage.getItem("key"));
        let commTabCode = decrypt(localStorage.getItem("code"));

        if (!commTabCode && !commTabKey) {
            return;
        } else {
            localStorage.removeItem("key");
            localStorage.removeItem("code");
        }
    }, [location]);
    useEffect(() => {
        setMenuTree(menuItem);
    }, [menuItem]);

    useEffect(() => {
        let temp = decrypt(localStorage.getItem("isCardAttach"));
        setIsCardAttached(temp);
    }, []);

    useEffect(() => {
        setMenuItem(
            window.location.pathname == "/register" || window.location.pathname == "/contact-us-query"
                ? [
                      {
                          id: 1,
                          path: "/register",
                          name: "Registerd User",
                          iconOffHover: <img src={monitor} alt="" className="offHover" />,
                          iconOnHover: <img src={monitor_hover} alt="" className="onHover" />,
                          isChildVisible: false,
                          isActive: false,
                          dropdown: null,
                      },
                      {
                          id: 2,
                          path: "/contact-us-query",
                          name: "Contact-Us Query",
                          iconOffHover: <img src={uploadIcon} alt="" className="offHover" />,
                          iconOnHover: <img src={uploadIcon} alt="" className="onHover" />,
                          isChildVisible: false,
                          dropdown: null,
                          isActive: false,
                      },
                  ]
                : [
                      {
                          id: 1,
                          path: "/dashboard",
                          name: "Dashboard",
                          iconOffHover: <img src={monitor} alt="" className="offHover" />,
                          iconOnHover: <img src={monitor_hover} alt="" className="onHover" />,
                          isChildVisible: false,
                          isActive: false,
                          dropdown: null,
                          className: "dashboardinfo",
                          content: "this is dashboard",
                      },
                      {
                          id: 2,
                          path: "/analytical-report",
                          name: "RFM Data Analysis",
                          iconOffHover: <img src={uploadIcon} alt="" className="offHover" />,
                          iconOnHover: <img src={uploadIcon} alt="" className="onHover" />,
                          isChildVisible: false,
                          dropdown: null,
                          isActive: false,
                          className: "analytical",
                          content: "this is analyticla",
                      },
                      {
                          id: 3,
                          path: "/growth-prospect",
                          name: "Growth Prospect",
                          iconOffHover: <img src={dashboard} alt="" className="offHover" />,
                          iconOnHover: <img src={dashboardHover} alt="" className="onHover" />,
                          isChildVisible: false,
                          dropdown: null,
                          isActive: false,
                          className: "growth",
                          content: "this is growth ",
                      },
                      {
                          id: 4,
                          name: "Campaigns",
                          iconOffHover: <img src={programs} alt="" className="offHover" />,
                          iconOnHover: <img src={programsHover} alt="" className="onHover" />,
                          isChildVisible: localStorage.getItem("isChildVisible") === "true" ? true : false,
                          isActive: false,
                          dropdown: [
                              {
                                  path: "/my-campaigns",
                                  name: "My Campaigns",
                              },
                              {
                                  path: "/business-goals-selection",
                                  name: "Sample Campaigns",
                              },
                          ],
                          className: "campaignsinfos",
                          content: "this is campaigns",
                      },
                      {
                          id: 5,
                          path: "/customers-list",
                          name: "Customers",
                          iconOffHover: <img src={customers} alt="" className="offHover" />,
                          iconOnHover: <img src={customersHover} alt="" className="onHover" />,
                          isChildVisible: false,
                          dropdown: null,
                          isActive: false,
                          className: "customer_intro",
                          content: "this is customer",
                      },
                      {
                          id: 6,
                          path: "/transactions-list",
                          name: "Transaction",
                          iconOffHover: <img src={transaction} alt="" className="offHover" />,
                          iconOnHover: <img src={transactionHover} alt="" className="onHover" />,
                          isChildVisible: false,
                          dropdown: null,
                          isActive: false,
                          className: "teansctions",
                          content: "this is transctions",
                      },
                      {
                          id: 6,
                          path: "/voucher-template",
                          name: "Vouchers",
                          iconOffHover: <i className="fa fa-shopping-cart" style={{ fontSize: "1.2rem" }}></i>,

                          isChildVisible: false,
                          dropdown: null,
                          isActive: false,
                          className: "voucherp",
                          content: "this is voucher",
                      },
                      {
                          id: 6,
                          path: "/gift-card",
                          name: "Gift Card ",
                          iconOffHover: <i className="fas fa-gift" style={{ fontSize: "1.2rem" }}></i>,

                          isChildVisible: false,
                          dropdown: null,
                          isActive: false,
                          className: "gift",
                          content: "this is gift",
                      },
                  ]
        );
    }, [window.location.pathname]);

    useEffect(() => {
        if (location.pathname.includes("campaigns")) {
            localStorage.setItem("isChildVisible", true);
        } else {
            localStorage.setItem("isChildVisible", false);
        }
    }, [location]);

    const menuMainTrigger = () => {
        let array = [...menuTree];
        array.forEach((elem) => {
            if (toggle) {
                elem.isActive = false;
                setDropdownDocBtn(false);
                setDropdownSettingBtn(false);
            }
        });
        setMenuTree(array);
        setToggle(!toggle);
    };

    const childrenMenu = (id, Index, api_doc, path) => {
        location.pathname === path && window.location.reload();

        let array = [...menuTree];
        array.forEach((elem) => {
            if (elem.id === id && elem.dropdown && !toggle) {
                elem.isActive = false;
                elem.isChildVisible = true;
                setDropdownDocBtn(false);
                setActiveBtn(false);
                setApiDocBtn(false);
            } else if ((id === "btn" && toggle && Index === 2) || api_doc) {
                setDropdownDocBtn(true);
                setActiveBtn(true);
                elem.isChildVisible = false;
                setDropdownSettingBtn(false);
                api_doc ? setApiDocBtn(true) : setApiDocBtn(false);
            } else if (id === "btn" && toggle && Index === 1) {
                setDropdownSettingBtn(true);
                elem.isChildVisible = false;
                setDropdownDocBtn(false);
                setActiveBtn(true);
                setApiDocBtn(false);
            } else if (id === "btn" && Index === 1) {
                setDropdownSettingBtn(!dropdownSettingBtn);
                elem.isChildVisible = false;
                setDropdownDocBtn(false);
                setApiDocBtn(false);
            } else if ((id === "btn" && Index === 2) || api_doc) {
                setDropdownSettingBtn(false);
                elem.isChildVisible = false;
                setDropdownDocBtn(!dropdownDocBtn);
                api_doc ? setApiDocBtn(true) : setApiDocBtn(false);
            } else if (toggle === false) {
                setDropdownDocBtn(false);
                setActiveBtn(false);
                setApiDocBtn(false);
                elem.isChildVisible = false;
            }
        });
        setMenuTree(array);
    };

    const menuChildTrigger = async (id, Index, path) => {
        location.pathname === path && window.location.reload();

        let array = [...menuTree];
        await array.forEach((elem) => {
            if (elem.id === id && elem.dropdown) {
                elem.isActive = !elem.isActive;
                setDropdownDocBtn(false);
                setActiveBtn(false);
                setDropdownSettingBtn(false);
                setApiDocBtn(false);
            } else if (id === "btn" && Index === 2) {
                setDropdownDocBtn(!dropdownDocBtn);
                setActiveBtn(true);
                setDropdownSettingBtn(false);
                elem.isActive = false;
            } else if (id === "btn" && Index === 1) {
                setDropdownSettingBtn(!dropdownSettingBtn);
                setDropdownDocBtn(false);
                setActiveBtn(true);
                setApiDocBtn(false);
                elem.isActive = false;
            } else {
                setDropdownSettingBtn(false);
                setDropdownDocBtn(false);
                setActiveBtn(false);
                setApiDocBtn(false);
                elem.isChildVisible = false;
                elem.isActive = false;
            }
        });

        setMenuTree(array);
    };

    useEffect(() => {
        sessionStorage.setItem("dashboard", location.pathname.includes("register") ? "AD" : "UD");
    }, [location]);

    const handleLogout = () => {
        if (Dashboard === "UD") {
            if (localStorage.getItem("superAdmin")) {
                localStorage.removeItem("saasemail");
                localStorage.removeItem("superAdmin");
            }
            logout();
            clearCampaigns();
            clearCustomer();
            clearDashboard();
            clearSetting();
            clearTransaction();
            clearBusinessGoal();
            localStorage.removeItem("token_gen");

            sessionStorage.clear();
            localStorage.removeItem("pageIndex");
            localStorage.removeItem("token_gen");
            localStorage.removeItem("token_rare");
            localStorage.removeItem("profile");
            localStorage.removeItem("Id");
            localStorage.removeItem("clientid");
            localStorage.removeItem("schemaId");
            localStorage.removeItem("merchantcode");
            localStorage.removeItem("clientsecret");
            localStorage.removeItem("schemaName");
            localStorage.removeItem("email");
            localStorage.removeItem("isCardAttach");
            localStorage.removeItem("TransPageIndex");
            localStorage.removeItem("CusPageIndex");
            localStorage.removeItem("isChildVisible");
            localStorage.removeItem("loginKey");
            localStorage.removeItem("name");
            localStorage.removeItem("isLogin");
            localStorage.removeItem("moduleAccess");
        } else {
            localStorage.removeItem("saasemail");
            localStorage.removeItem("password");
            sessionStorage.setItem("dashboard", "UD");
        }
    };
    // useEffect(()=>{

    // },[props.dropdown])
    //     useEffect(() => {
    //         console.log({count});
    //         console.log({props});
    //        const handleLoad=()=>{
    //       // temp=1

    //         const steps = menuTree.map(item => ({
    //           element: `.${item.className}`,
    //          intro: `This is ${item.content}.`,
    //         }));
    //         intro.setOptions({
    //             steps: steps,
    //           });

    //         intro.start();
    //         }
    //      // if(!localStorage.getItem('intro')==0){
    //         handleLoad()
    //       //}

    //       }, []);

    //       intro.onchange(function(element) {
    //         console.log("eel",this._currentStep);
    //         switch (this._currentStep){
    //             case 1:
    //                    intro.setOptions({ 'nextLabel': 'Step1 Next' , 'prevLabel': 'Step1 Back'});
    //                    break;
    //              case 2:
    //                    intro.setOptions({ 'nextLabel': 'Step2 Next' , 'prevLabel': 'Step2 Back'});
    //                     break;
    //               case 3:
    //                 window.location.href= "/customers-list";
    //            }

    //         // else{
    //         //    window.location.href= "/customers-list";
    //         // }
    //    });

    return (
        <>
            <div className={`dashboard ${toggle ? "openSidebar" : ""}`}>
                <div className="dashboard__sidebar" id="sidebar">
                    <div className="dashboard__topSection">
                        <div className="dashboard__logoBox">
                            <img src={logo} alt="" />
                        </div>
                        <div className="dashboard__menuBox">
                            <span onClick={menuMainTrigger}>
                                <img src={inside} alt="" className="inside" />
                                <img src={outside} alt="" className="outside" />
                            </span>
                        </div>
                    </div>

                    <div className="dashboard__menuMain">
                        <div className="dashboard__menuTop">
                            <ul>
                                {menuTree.map((elem, index) => {
                                    const { id, name, iconOffHover, iconOnHover, dropdown, path, isChildVisible, isActive, className } = elem;
                                    return (
                                        <li key={`parentLi__${index}`} title={name}>
                                            {path ? (
                                                <div id={className}>
                                                    {!documentDrop && (
                                                        <NavLink to={path} className="link" style={{ pointerEvents: isCardAttached === "false" && "none" }} onClick={() => menuChildTrigger(id, null, path)}>
                                                            <figure className="icon">
                                                                {iconOffHover}
                                                                {iconOnHover}
                                                            </figure>
                                                            <span className="link_text">{name}</span>
                                                        </NavLink>
                                                    )}
                                                </div>
                                            ) : (
                                                <div id={className}>
                                                    <button type="button" className={isChildVisible ? "link active" : "link"} onClick={() => menuChildTrigger(id, null)}>
                                                        <figure className="icon">
                                                            {iconOffHover}
                                                            {iconOnHover}
                                                        </figure>
                                                        <span className="link_text">{name}</span>
                                                    </button>
                                                </div>
                                            )}
                                            {dropdown && isActive && (
                                                <ul className="childrenDropdown">
                                                    {dropdown.map((each, idx) => {
                                                        const { path, name } = each;
                                                        return (
                                                            <div id={className}>
                                                                <li key={`childLi__${id}__${idx}`} title={name}>
                                                                    <NavLink style={{ pointerEvents: isCardAttached === "false" && "none" }} onClick={() => childrenMenu(id, null, null, path)}>
                                                                        {name}
                                                                    </NavLink>
                                                                </li>
                                                            </div>
                                                        );
                                                    })}
                                                </ul>
                                            )}{" "}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>

                        <div className="dashboard__menuBottom">
                            <ul>
                                {Dashboard === "UD" && !location.pathname.includes("contact-us-query") && (
                                    <>
                                        <li>
                                            <button className={location.pathname.includes("change-password") || location.pathname.includes("/profile-settings") ? "link active" : "link"} disabled={isCardAttached === "false"} type="button" onClick={() => menuChildTrigger("btn", 1)}>
                                                <figure className="icon">
                                                    <img src={settings} alt="" className="offHover" />
                                                    <img src={settingsHover} alt="" className="onHover" />
                                                </figure>
                                                <span className="link_text">Settings</span>
                                            </button>
                                            {dropdownSettingBtn && activeBtn && !profileDrop && (
                                                <ul className="childrenDropdown">
                                                    {/* <li>
                                                        <NavLink to="/financial-account" onClick={() => childrenMenu("btn", 1)}>
                                                            Financial Account
                                                        </NavLink>
                                                    </li> */}
                                                    <li>
                                                        <NavLink to="/profile-settings/" id="profile_setting" onClick={() => childrenMenu("btn", 1)}>
                                                            Profile Settings
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink disabled={true} to="/change-password" id="change_pswd" onClick={() => childrenMenu("btn", 1)}>
                                                            Change Password
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink
                                                            to="/intro"
                                                            id=""
                                                            onClick={() => {
                                                                localStorage.setItem("firstLogin", "Y");
                                                                dispatch(modalCard(key + 1));
                                                                setKey(key + 1);
                                                            }}
                                                            state={{ data: key + 1 }}
                                                        >
                                                            Novus Loyalty's Tour
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/contact-us" onClick={() => childrenMenu("btn", 1)}>
                                                            Contact Us
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            )}
                                            {profileDrop && (
                                                <ul className="childrenDropdown">
                                                    <li>
                                                        <NavLink to="/financial-account" onClick={() => childrenMenu("btn", 1)}>
                                                            Financial Account
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/profile-settings/" id="profile_setting" onClick={() => childrenMenu("btn", 1)}>
                                                            Profile Settings
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/change-password" id="change_pswd" onClick={() => childrenMenu("btn", 1)}>
                                                            Change Password
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink
                                                            to="/upload-existing-data"
                                                            onClick={() => {
                                                                localStorage.setItem("firstLogin", "Y");
                                                                setKey(key + 1);
                                                                dispatch(modalCard(key + 1));
                                                            }}
                                                            state={{ data: key + 1 }}
                                                        >
                                                            Novus Loyalty's Tour
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/contact-us" onClick={() => childrenMenu("btn", 1)}>
                                                            Contact Us
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            )}
                                        </li>
                                        <li>
                                            <button id="intro_documentation" className={location.pathname.includes("api") ? "link active" : "link"} type="button" disabled={isCardAttached === "false"} onClick={() => menuChildTrigger("btn", 2)}>
                                                <figure className="icon">
                                                    <img src={apiDocument} alt="" className="offHover" />
                                                    <img src={apiDocumentHover} alt="" className="onHover" />
                                                </figure>
                                                <span className="link_text">Documentation</span>
                                            </button>
                                            {dropdownDocBtn && activeBtn && !documentDrop && (
                                                <ul className="childrenDropdown childrenDropdown_2">
                                                    <li>
                                                        <NavLink to="/generate-access-key-api" id="documentationm" onClick={() => childrenMenu("btn", 2)}>
                                                            Generate Access Key
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/customer-registration-api" onClick={() => childrenMenu("btn", 2)}>
                                                            Customers
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/transaction-api" onClick={() => childrenMenu("btn", 2)}>
                                                            Transactions
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/redemption-api" onClick={() => childrenMenu("btn", 2)}>
                                                            Redemption
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/help-details" id="help_FAQ" onClick={() => childrenMenu("btn", 2)}>
                                                            Help & FAQs
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            )}
                                            {documentDrop && (
                                                <ul className="childrenDropdown childrenDropdown_2">
                                                    <li>
                                                        <NavLink to="/generate-access-key-api" id="documentationm" onClick={() => childrenMenu("btn", 2)}>
                                                            Generate Access Key
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/customer-registration-api" onClick={() => childrenMenu("btn", 2)}>
                                                            Customers
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/transaction-api" onClick={() => childrenMenu("btn", 2)}>
                                                            Transactions
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/redemption-api" onClick={() => childrenMenu("btn", 2)}>
                                                            Redemption
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/help-details" id="help_FAQ" onClick={() => childrenMenu("btn", 2)}>
                                                            Help & FAQs
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            )}
                                        </li>
                                    </>
                                )}
                                <li title="Logout">
                                    <NavLink className="link" to={Dashboard === "UD" ? "/signin" : "/saas-admin"} aria-current="page" onClick={handleLogout}>
                                        <figure className="icon">
                                            <img src={logoutIcon} alt="" className="offHover" />
                                            <img src={logoutIconHover} alt="" className="onHover" />
                                        </figure>
                                        <span className="link_text">Logout</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="dashboard__mainPage">{children}</div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    // dropdown:state.dropDownSetting
});

export default connect(mapStateToProps, {
    logout,
    clearCampaigns,
    clearCustomer,
    clearDashboard,
    clearSetting,
    clearTransaction,
    clearBusinessGoal,
})(IntroSidebar);
