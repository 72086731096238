import { Link } from "react-router-dom";
export const COLUMN = [
    {
        Header: "Banner Name",
        Footer: "Banner Name",
        accessor: "uploadedSource",
        sticky: "left",
        // Cell: ({ value }) => {
        // 	return format(new Date(value), "dd/MM/yyyy");
        // },
    },
    {
        Header: "Image Link",
        Footer: "Image Link",
        accessor: "blobFilePath",
        Cell: ({ value }) => (
            <Link to={value}>
                <i className="fa-solid fa-download"></i>
            </Link>
        ),
    },
    {
        Header: "Delete",
        Footer: "Delete",
        accessor: "delete",
        // Cell: ({ value }) => (
        //     <Link to={value}>
        //     <i class="fa-solid fa-x"></i>
        //     </Link>
        // ),
      
        // Cell: ({ value }) => {
        // 	return format(new Date(value), "dd/MM/yyyy");
        // },
    },
];
