import moment from "moment";
export const getDate = (date, format) => {
    if(date && date!=="N/A"){
        try{
   let newDate=new Date(date).toISOString()
    let forMattedDate = moment(newDate).format(format);
    if (forMattedDate != "Invalid date") {
        return forMattedDate;
    } else {
        return "";
    }
}
catch(e){
}
}
};

export const selectValueGetter = (opts, val) => {
    // if (opts.find((item) => item.value === val)) return "";
    return opts?.find((item) => item.value === val || item.value?.toUpperCase().replace(/_/g, "") === val);
};

export const selectValueGetterBoolean = (opts, val) => {
    // if (opts.find((item) => item.value === val)) return "";
    return opts.find((item) => item.value === val);
};

export const getDropdownObject = (val, arr) => {
    if (!arr) return "";
    return arr.find((item) => item.value === val);
};

export const getMultiDropdownObject = (value, arr) => {
    if (!arr?.length) return "";
    // console.log(value?.map((val,index)=>{ return arr.find((item,ind) => item.value === val[ind])}))
    return value?.map((val) => {
        return arr.find((item) => item.value === val);
    });
};

export const parseNumeric = (str) => {
    return str.toString().replace(/[^0-9]/gi, "");
};

export const toNumberFormate = (str) => {
    if (!str) return 0;
    return str.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
};
export const getOperator = (arr, val) => {
    if (!arr) return "";
    return arr.find((item) => item.value === val);
};
export const parseNumericFloat = (str) => {
    return str.toString().match(/[+-]?([0-9]+([.][0-9]*)?|[.][0-9])/gi, "");
};
export const validateString = (str) => {
    const regex = /\S/;
    return regex.test(str);
};
export const extract10DigitNumber = (str) => {
    const regex = "^(?!\\d*(\\d)\\1{7,}|0123456789|1234567890)\\d{10,15}$";
    const match = str.match(regex);
    return match ? match[0] : null;
};

export const generateString = (length) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const validEmail = (mail) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!mail) return false;
    return mail.match(mailformat);
};
export const handleClick = () => {
    const currentDate = new Date();
    const dateInSixMonths = new Date(currentDate);
    dateInSixMonths.setMonth(currentDate.getMonth() + 6);
    const year = dateInSixMonths.getFullYear();
    const month = String(dateInSixMonths.getMonth() + 1).padStart(2, "0");
    const day = String(dateInSixMonths.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
};

export const titleCase=(str)=> {
    return str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
  }
