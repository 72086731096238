
import { Modal, Button, CloseButton } from "react-bootstrap";
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import {arrow_right, arrow_right_dark} from "../../Image/Index";
import {modalCard, startTour, showModalOnstart} from '../../actions/intro'
import { Link, useLocation, useNavigate } from 'react-router-dom';

const ModalIntro=(props)=>{
const [show, setShow]=useState(true)
const location = useLocation();
const modalRefresh = location.state;
const modalShowFIrst = useSelector((state) => state.intro.showModal);

const navigate=useNavigate()
const dispatch = useDispatch();
useEffect(()=>{
if(props.show){
    setShow(true)
}
if(modalRefresh?.data){
    setShow(true)
}

},[props.show])


const endTour=()=>{
    setShow(false)
    dispatch(showModalOnstart(modalShowFIrst+1))
   localStorage.setItem('firstLogin','')
}
const startTours=()=>{
    dispatch(modalCard(true))
    dispatch(startTour(3))
    setShow(false)
    localStorage.setItem('firstLogin','start')
}
const startCustomerTour=()=>{

navigate('/intro/profile-settings')

}
    return (
  

    <>    

      <Modal show={show} onHide={endTour} centered>
        <Modal.Header className="custom_header" closeButton></Modal.Header>
        <Modal.Body className="dashboard__transactionsModal">
          <h3 className="guides_nv">Welcome to Novus Loyalty</h3>
          <p className="p_nv">This powerful tool is designed to help businesses build and maintain strong customer relationships by rewarding loyal patrons</p>
          <div className="stour_mainbox">
            <div className="stour_box startt_tour_btn">
              <Button onClick={startTours}>
                {/* <p>Start Tour</p> */}
                <p>Program Journey</p>
                <figure>
                  <img src={arrow_right} alt="program journey" />
                </figure>
              </Button>
            </div>
            <div className="stour_box startt_tour_btn">
             
                <Button onClick={startCustomerTour}>
                  <p>Customer Journey</p>
                  <figure>
                    <img src={arrow_right_dark} alt="customer journey" />
                  </figure>
                </Button>
            </div>
            {/* <div className="stour_box">
              <Button onClick={endTour}>
                <figure>
                  <img src={end_tour} alt="end tour" />
                </figure>
                <p>Skip Tour</p>
              </Button>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
    
    
    )
}
export default ModalIntro