import React, { useState, useEffect, useMemo } from "react";
import Header from "../../layout/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { backIcon2 } from "../../../Image/Index";
import { getCampaignCodeReport } from "../campaigns/services";

const CampaignData = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const campaignId = location?.state?.campaignId;
    const [loader, setLoader] = useState(false);
    const [response, setResponse] = useState({});
    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => {
        if (campaignId) {
            onClick(campaignId);
        }
    }, [campaignId]);

    const onClick = async (code) => {
        setLoader(true);
        let tempToken = JSON.parse(localStorage.getItem("token_gen"));
        tempToken = tempToken?.access_token;
        await getCampaignCodeReport(tempToken, code)
            .then((res) => {
                setResponse(res);
                setFirstRender(false);
                setLoader(false);
            })
            .catch(() => {
                setLoader(false);
                setFirstRender(false);
            });
    };

    return (
        <>
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <Header name={"Campaign Report"} />
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__profileSettingsMain">
                        <div className="dashboard__heading">
                            <div className="dashboard__headingLeft">
                                <div className="dashboard__headingBackLink">
                                    <Link to="" onClick={() => navigate(-1)}>
                                        <img src={backIcon2} alt="" />
                                        <div className="dashboard__headingContent">
                                            <h3>Back</h3>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard__customerViewMain">
                        <div className="analyticalReportChartAnalysisOne__heading">
                            <h2>Campaign Report</h2>
                        </div>
                        <div className="dashboard__customerViewCard">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group cr_p">
                                        <label>Business Goal</label>
                                        <p>{firstRender ? "" : response?.data?.businessGoal || "Not Available"}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group cr_p">
                                        <label>Campaign</label>
                                        <p>{firstRender ? "" : response?.data?.camapaignName || "Not Available"}</p>
                                    </div>
                                </div>
                            </div>
                            {response && Object.keys(response).length > 0 && (
                                <div className="dashboard__customerViewPointsTabs">
                                    <div className="dashboard__customerViewPoints">
                                        <div className="dashboard__campaignReportMainBox">
                                            <div className="dashboard__campaignReport">
                                                <div className="campaignReportTextBox">
                                                    <h4>Total Customer</h4>
                                                    <h3>{response?.data?.totalCustomer || 0}</h3>
                                                </div>
                                            </div>
                                            <div className="dashboard__campaignReport">
                                                <div className="campaignReportTextBox">
                                                    <h4>Total Transaction</h4>
                                                    <h3>{response?.data?.totalTransaction || 0}</h3>
                                                </div>
                                            </div>
                                            <div className="dashboard__campaignReport">
                                                <div className="campaignReportTextBox">
                                                    <h4>Total Transaction Amount</h4>
                                                    <h3>{response?.data?.totalTransactionAmount || 0}</h3>
                                                </div>
                                            </div>
                                            <div className="dashboard__campaignReport">
                                                <div className="campaignReportTextBox">
                                                    <h4>Average Transaction Amount</h4>
                                                    <h3>{response?.data?.avarageTransactionAmount || 0}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dashboard__campaignReportMainBoxx">
                                            <div className="dashboard__campaignReport">
                                                <div className="campaignReportTextBox">
                                                    <h4>Total Points</h4>
                                                    <h3>{response?.data?.totalPoints || 0}</h3>
                                                </div>
                                            </div>
                                            <div className="dashboard__campaignReport">
                                                <div className="campaignReportTextBox">
                                                    <h4>Total Redeem Points</h4>
                                                    <h3>{response?.data?.totalRedeemPoints || 0}</h3>
                                                </div>
                                            </div>
                                            <div className="dashboard__campaignReport">
                                                <div className="campaignReportTextBox">
                                                    <h4>Redemption Rate</h4>
                                                    <h3>{response?.data?.redeptionRate || 0}</h3>
                                                </div>
                                            </div>
                                            <div className="dashboard__campaignReport">
                                                <div className="campaignReportTextBox">
                                                    <h4>Active Redemption</h4>
                                                    <h3>{response?.data?.ActiveRedeemption || 0}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dashboard__campaignReportMainBoxx">
                                            <div className="dashboard__campaignReport">
                                                <div className="campaignReportTextBox">
                                                    <h4>Expire Redemption</h4>
                                                    <h3>{response?.data?.ExpireRedeemption || 0}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CampaignData;
