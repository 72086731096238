import React from "react";
import { logo } from "../../Image/Index";
import { Link } from "react-router-dom";
import AuthenticationSliderLeft from "./common/AuthenticationSliderLeft";

function ThankyouResetPassword() {
    return (
        <section className="section signUpPage">
            <div className="signUpPage__main">
                <AuthenticationSliderLeft />
                <div className="signUpPage__right">
                    <div className="signUpPage__heading">
                        <figure>
                            <img src={logo} alt="logo" />
                        </figure>
                        <h1>Thank You</h1>
                        <h4>
                            Your Password has been changed successfully. Please <br />
                            <Link to="/signin">Click Here</Link> to Login.
                        </h4>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ThankyouResetPassword;
