import React, { useEffect, useLayoutEffect, useState } from "react";
import { giftCard, CountryRegions, giftCardUpdate, merchantGiftCard, getSelectedGiftCard, getSelectGiftCard } from "../../../actions/auth";
import Header from "../../layout/Header";
import swal from "sweetalert";
import Card from "./Card";
import { decrypt } from "../../../utils/encodingdecoding";
import { updateCatCache } from "../../../actions/setting";

const GiftCard1 = () => {
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [isSelected, setIsSelected] = useState([]);
    const [responseData, setResponseData] = useState([]);
    const [option, setOption] = useState()

    const [selectCountry, setSelectCountry] = useState({
        options: [],
        country: "",
        getSearchedCountry: [],
        allSelectCountry: "",
        countryToggle: true,
        searchCountryTerm: "",
        countryFlag: true,
    });
    const [categories, setCategories] = useState([]);
    const [card, setCard] = useState({
        getSearchedCard: [],
        cardFlag: false,
        allSelectCard: false,
    });

    const { options, country, getSearchedCountry, allSelectCountry, countryToggle, searchCountryTerm ,countryFlag} = selectCountry;
    const { getSearchedCard, cardFlag, allSelectCard } = card;

    const fetchSelectGiftCard = async () => {
        const tempOption = [];
        setLoader(true);
        try {
        
            const response = await getSelectGiftCard(decrypt(localStorage.getItem("merchantcode")));

            response.data.forEach(item => {
                if (item.giftcard_code && item.giftcard_code.length > 0) {
                    tempOption.push({ label: item.country, value: item.country });
                }
            });
    
            setOption(tempOption);
           
          
          
           
        } catch (error) {
           
        }
            if ( tempOption.length > 0) {
                 fetchCountryRegions(tempOption);
              }
              else {
                   fetchCountryRegions()
              }
        // setLoader(false);
    };

    const fetchCountryRegions = async (option) => {
      //  setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        let res = await CountryRegions(token?.access_token);
        const country = res?.map((val) => ({
            value: val.name,
            label: val.displayName,
            symbol: val.isoCurrencySymbol,
            isChecked: false,
        }));

        const matchedCountries = [];
        option?.forEach(opt => {
            const matchedCountry = country.find(c => c.value === opt.value);
            if (matchedCountry) {
                matchedCountries.push(matchedCountry);
            }
        });
        if (option && option.length > 0) {
            setSelectCountry({ ...selectCountry, getSearchedCountry: matchedCountries.slice(0, 10), options: country ,countryFlag: false});
            setLoader(false);
        }
        else {
            setSelectCountry({ ...selectCountry, getSearchedCountry: country.slice(0, 10), options: country ,countryFlag: false});
            setLoader(false);
        }

        
    };





    useLayoutEffect(() => {
        setCategories((prev) => {
            return prev.map((elem) => {
                elem.data = Array.isArray(data) && data?.filter((value) => value.category_code.split(",").includes(elem.name)).map((matchingValue) => matchingValue.id);
                return elem;
            });
        });
    }, [data]);

    const fetch = async (country) => {
        let tempArr = [];
        setLoader(true);
        try {
            let response = await giftCard(country);
            setData(response.data);
            !response?.data?.length && setLoader(false)
            response?.data.forEach((i) => {
                if (Array.isArray(i.category_code.split(","))) {
                    i.category_code.split(",").forEach((item) => {
                        if (!tempArr.includes(item) && item) {
                            tempArr.push(item);
                        }
                    });
                }
            });
            let dataArr = tempArr.map((item, ind) => {
                return { value: "CH" + ind, name: item, data: [], isChecked: false };
            });
            setCategories(dataArr);
        } catch (error) { }
        // setLoader(false);
    };

    useEffect(() => {
        const callAPI = async () => {
            await fetchSelectGiftCard();
            await fetch("selectAll");
        };
        callAPI();
    }, []);
    
    

    const fetchSelectedGiftCard = async (country) => {
        if (country) {
            setLoader(true);
            try {
                let response = await getSelectedGiftCard(country, decrypt(localStorage.getItem("merchantcode")));
                setResponseData(response)
                setIsSelected(response?.data?.giftcard_code ? response?.data?.giftcard_code : []);
            } catch (error) { }
            setLoader(false);
        }
    };

    useLayoutEffect(() => {
        data?.length && fetchSelectedGiftCard(country ? country : "selectAll");
    }, [data]);




  

    const handleCountrySearch = (e) => {
        let temp = [];
        if (e.target.value) {
            options?.filter((str) => {
                if (str.label.toLowerCase().includes(e.target.value.toLowerCase())) {
                    temp.push(str);
                }
            });
            setSelectCountry({ ...selectCountry, getSearchedCountry: temp, countryToggle: true, searchCountryTerm: e.target.value });
        } else {
            setSelectCountry({ ...selectCountry, getSearchedCountry: options, countryToggle: true, searchCountryTerm: e.target.value });
        }
    };

    const handleCardSearch = (e) => {
        let temp = [];
        if (e.target.value) {
            data?.filter((str) => {
                if (str.title.toLowerCase().includes(e.target.value.toLowerCase())) {
                    temp.push(str);
                }
            });
            setCard({ ...card, getSearchedCard: temp, cardFlag: true });
        } else {
            setCard({ ...card, getSearchedCard: temp, cardFlag: false });
        }
    };

    const handleCountryCheckbox = (e, value) => {
        if (value === "selectAll") {
            e.target.value && fetchSelectedGiftCard("");
            const allCountriesSelected = e.target.checked;
            fetch("selectAll");
            let temp = [];
            if (allCountriesSelected) {
                temp = getSearchedCountry.map((country) => country.value);
            }
            setSelectCountry((prevState) => ({
                ...prevState,
                getSearchedCountry: prevState.getSearchedCountry.map((ele) => ({
                    ...ele,
                    isChecked: allCountriesSelected,
                })),
                allSelectCountry: allCountriesSelected ? "checked" : "",
                country: value,
            }));
        } else {
            // e.target.value && fetchSelectedGiftCard(value);
            fetch(e.target.checked ? value : "selectAll");
            const updatedSearchCountry = getSearchedCountry.map((ele) => (ele.value === value ? { ...ele, isChecked: e.target.checked } : { ...ele, isChecked: false }));
            const isSelectAllChecked = updatedSearchCountry.every((ele) => ele.isChecked);
            let temp = [];
            updatedSearchCountry.length &&
                updatedSearchCountry.map((country) => {
                    if (country.isChecked === true) {
                        temp.push(country.value);
                    }
                });
            setSelectCountry((prevState) => ({
                ...prevState,
                getSearchedCountry: updatedSearchCountry,
                allSelectCountry: isSelectAllChecked ? "checked" : "",
                country: e.target.checked ? value : "selectAll",
            }));
        }
    };

    useEffect(() => {
        var length = 0;
        categories.map((val) => val.isChecked && length++);
        setCard((prevState) => ({
            ...prevState,
            allSelectCard: length === categories?.length && length !== 0 ? true : false,
        }));
    }, [categories]);

    useEffect(() => {
        const tempArr = [...categories];
        isSelected.length &&
            tempArr.map((values, i) => {
                var count = 0;
                values?.data?.map((val) => {
                    isSelected.map((element) => {
                        if (element.category === values.name && element.id === val) {
                            count++;
                        }
                    });
                });
                if (values.data.length == count) {
                    values.isChecked = true;
                } else {
                    values.isChecked = false;
                }
            });
        if (isSelected.length === 0)
            tempArr.map((values, i) => {
                values.isChecked = false;
            });
        setCategories(tempArr);
    }, [isSelected]);

    const handleCategory = (e, category) => {
        if (category === "selectAll") {
            const allCardsSelected = e.target.checked;
            setCard((prevState) => ({
                ...prevState,
                allSelectCard: allCardsSelected ? true : false,
            }));

            setCategories((prev) => {
                prev.map((i) => {
                    i.isChecked = allCardsSelected ? true : false;
                });
                return prev;
            });
            if (allCardsSelected) {
                let tempIsSelected = [];
                if (categories.length) {
                    categories.forEach((card) => {
                        card.data.forEach((ele) => {
                            tempIsSelected.push({ id: ele, category: card.name });
                        });
                    });
                }
                setIsSelected(tempIsSelected);
            } else {
        
                setIsSelected(responseData?.data?.giftcard_code ? responseData?.data?.giftcard_code : []);
            }
        } else {
            let tempChecked = e.target.checked;
            let tempSelectedId = [];
            setCategories((prevState) =>
                prevState.map((ele) => {
                    if (ele.name === category) {
                        return {
                            ...ele,
                            isChecked: tempChecked ? true : false,
                        };
                    }
                    return ele;
                })
            );

            if (tempChecked) {
                let temp = isSelected?.filter((ele) => {
                    if (ele.category !== category) {
                        return ele;
                    }
                });

                categories.map((cat) => {
                    if (cat.name === category) {
                        cat.data.map((ele) => {
                            tempSelectedId.push({ id: ele, category: cat.name });
                        });
                    }
                });
                setIsSelected([...temp, ...tempSelectedId]);
            } else {
                isSelected.forEach((select) => {
                    categories.forEach((cat) => {
                        if (cat.name !== category) {
                            cat.data.forEach((id) => {
                                if (select.id === id && select.category === cat.name) {
                                    tempSelectedId.push({ id: id, category: cat.name });
                                }
                            });
                        }
                    });
                });
                setIsSelected(tempSelectedId);
            }
        }
    };

    const handleSelect = async (id, type, category, fromSearch) => {
        let searchCat = [];
        let newObject;
        let index;
        if (fromSearch) {
            let updatedArray;
            category.forEach((cat) => {
                let temp = { category: cat, id: id };
                index = isSelected.findIndex((item) => item.id === id && item.category === cat);
                if (index === -1) {
                    searchCat.push(temp);
                } else {
                    updatedArray = isSelected?.filter((item) => item.id !== id);
                }
            });
            index === -1 ? setIsSelected([...isSelected, ...searchCat]) : setIsSelected(updatedArray);
        } else {
            newObject = { category: category, id: id };
            index = isSelected.findIndex((item) => item.id === newObject.id && item.category === newObject.category);
            if (index === -1) {
                setIsSelected([...isSelected, newObject]);
            } else {
                const updatedArray = isSelected?.filter((item) => item.id !== newObject.id || item.category !== newObject.category);
                setIsSelected(updatedArray);
            }
        }
    };
    const onSubmit = async () => {
        let token = JSON.parse(localStorage.getItem("token_gen"));
        let body = {};
        let merBody = {};
        const merchantCode = decrypt(localStorage.getItem("merchantcode"));
        setLoader(true);
        let temp = isSelected.map((ele) => {
            return ele.id;
        });
        body.giftcard_code = isSelected;
        body.merchant_code = merchantCode;
        body.country = country ? country : "selectAll";
        let response = await giftCardUpdate(body);

        let merchant_giftcode = [...new Set(temp)];
        merBody.merchant_code = merchantCode;
        merBody.giftcard_code = merchant_giftcode;
        merBody.country = country ? country : "selectAll";
        let merResponse = await merchantGiftCard(merBody);
        updateCatCache(token?.access_token)
        if (response?.message?.data === "Details updated successfully" && merResponse?.message?.message === "Giftcard List Updated Successfully") {
            swal({
                position: "center",
                icon: "success",
                title: response?.message?.data,
                showConfirmButton: false,
                timer: 5000,
            });
        } else if (response?.message?.message === "Giftcard List update successfully" && merResponse?.message?.message === "Giftcard List Updated Successfully") {
            swal({
                position: "center",
                icon: "success",
                title: response?.message?.message,
                showConfirmButton: false,
                timer: 5000,
            });
        }  else if(response?.message?.message === "Request failed with status code 500") {
            swal({
                position: "center",
                icon: "error",
                title: "Please add gift card list",
                showConfirmButton: false,
                timer: 5000,
            });
        }
        else {
            swal({
                position: "center",
                icon: "error",
                title: response?.message?.message,
                showConfirmButton: false,
                timer: 5000,
            });
        }
        setLoader(false);
    };

    return (
        <>
            <Header name={"Gift Card"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="dashboard__content">
                <div className="giftcard_mainbox">
                    <div className="gc_sidebar">
                        {/* <button id="country" className="open_country_box" onClick={() => setSelectCountry((prevState) => ({ ...prevState, countryToggle: !countryToggle, searchCountryTerm: "", getSearchedCountry: options, allSelectCountry: "" }))}> */}
                            <h3> Country</h3>
                        {/* </button> */}
                        <input onChange={handleCountrySearch} type="text" placeholder="Enter Country" value={searchCountryTerm} />
                        {countryToggle && (
                            <div className="form-group">
                                {getSearchedCountry?.length ? (
                                    <div className="select_all_countries">
                                        {/* <span>Select All</span>
                                        <input type="checkbox" value={allSelectCountry ? "" : "global"} checked={allSelectCountry === "checked"} onChange={(e) => handleCountryCheckbox(e, "selectAll")} /> */}
                                    </div>
                                ) : (
                                    ""
                                )}
                                {!countryFlag && (
                                <div className="country-select-form-box">
                                    {getSearchedCountry?.length ? (
                                        getSearchedCountry
                                            ?.filter((ele, i) => searchCountryTerm !== "" || i < 10)
                                            .map((ele, i) => (
                                                <ul key={i}>
                                                    <li>{ele.label}</li>
                                                    <input type="checkbox" value={ele.isChecked ? "" : ele.value} name="isChecked" checked={ele.isChecked} onChange={(e) => handleCountryCheckbox(e, ele.value)} />
                                                </ul>
                                            ))
                                    ) : (
                                        <p>Not found</p>
                                    )}
                                    {!searchCountryTerm && <p>Search More Countries..........</p>}
                                </div>
                                )}
                            </div>
                        )}
                    </div>
                    { data && data.length > 0 ?
                                                    (
                                                        <div className="gc_contentbox">
                                                        <div className="gc_content_pbox">
                                                            <input type="text" id="gift_card" onChange={handleCardSearch} placeholder="Search Gift Cards/Vouchers" />
                                                            {cardFlag ? (
                                                                getSearchedCard?.length ? (
                                                                    <>
                                                                        <div className="campaignSelection__boxes">
                                                                            <div className="gc_inmainbox">
                                                                                {getSearchedCard.map(
                                                                                    (value, i) =>
                                                                                        Array.isArray(value.category_code.split(",")) &&
                                                                                        value.category_code.length && (
                                                                                            <div key={i} className="gc_innbox">
                                                                                                <Card item={value} getSearchedCountry={getSearchedCountry} handleSelect={handleSelect} isSelected={isSelected} value={value.category_code.split(",")} fromSearch={true} />
                                                                                            </div>
                                                                                        )
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row" style={{ paddingBottom: "20px" }}>
                                                                            <div className="col-sm-12  col-md-12 col-lg-12 col-xl-12">
                                                                                <div className="button-section" style={{ display: "flex", justifyContent: "flex-end" }}>
                                                                                    <button type="submit" onClick={onSubmit} className="btn btn-primary" >
                                                                                        Update
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <span>Not found</span>
                                                                )
                                                            ) : (
                                                                <div>
                                                                    <div className="select_all_giftcard">
                                                                        <div>Select All</div> <input type="checkbox" checked={allSelectCard} onChange={(e) => handleCategory(e, "selectAll")} />
                                                                    </div>
                                                                    <div className="accordion" id="accordionExample">
                                                                        {categories && categories?.length > 0 ? (
                                
                                
                                                                            <div className="accordion-item">
                                
                                                                                <h2 className="accordion-header" id="staticAccordion">
                                                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#staticCollapse" aria-expanded="false" aria-controls="staticCollapse">
                                                                                        Selected Gift Card ({isSelected.length})
                                                                                    </button>
                                                                                    {/* <input type="checkbox" /> */}
                                                                                </h2>
                                                                                <div id="staticCollapse" className="accordion-collapse collapse" aria-labelledby="staticAccordion">
                                                                                    <div className="accordion-body">
                                                                                        {isSelected?.length > 0 ? (
                                                                                            <div className="campaignSelection__boxes">
                                                                                                <div className="gc_inmainbox">
                                                                                                    {isSelected?.map((selectedItem, index) => {
                                
                                                                                                        const matchingData = data?.filter(value =>
                                                                                                            value.id.split(",").includes(selectedItem.id)
                                                                                                        );
                                                                                                        return (
                                                                                                            matchingData.map((item, i) => (
                                                                                                                
                                                                                                                <div key={i} className="gc_innbox">
                                                                                                                
                                                                                                                    <Card item={item} getSearchedCountry={getSearchedCountry} handleSelect={handleSelect} isSelected={isSelected} value={selectedItem.category} fromSearch={false} />
                                                                                                                </div>
                                                                                                            ))
                                                                                                        );
                                                                                                    })}
                                                                                                </div>
                                                                                            </div>
                                                                                        ) :  <div><p>No gift card selected yet</p></div>}
                                                                                    </div>
                                
                                                                                </div>
                                                                            </div>
                                
                                
                                
                                                                        ) : (
                                                                            null
                                                                        )}
                                
                                                                        {categories &&
                                                                            categories?.map((category, i) => (
                                                                                <div className="accordion-item" key={i}>
                                                                                    {category.data.length > 0 && (
                                                                                        <h2 className="accordion-header" id={i}>
                                                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${category.value}`} aria-expanded="false" aria-controls={category.value}>
                                                                                                {category.name + "  (" + category.data.length + ")"}
                                                                                            </button>
                                                                                            <input type="checkbox" name={category.value} value={category.value} checked={category.isChecked} onChange={(e) => handleCategory(e, category.name)} />
                                                                                        </h2>
                                                                                    )}
                                                                                    <div id={category.value} className="accordion-collapse collapse" aria-labelledby={`#${i}`} data-bs-parent="#accordionExample">
                                                                                        <div className="accordion-body">
                                                                                            <div className="campaignSelection__boxes">
                                                                                                <div className="gc_inmainbox">
                                                                                                    {data.length && data?.map(
                                                                                                        (value, i) =>
                                                                                                            Array.isArray(value.category_code.split(",")) &&
                                                                                                            value.category_code.length &&
                                                                                                            value.category_code.split(",").map(
                                                                                                                (item, i) =>
                                                                                                                    item == category.name && (
                                                                                                                        <div key={i} className="gc_innbox">
                                                                                                                            <Card item={value} getSearchedCountry={getSearchedCountry} handleSelect={handleSelect} isSelected={isSelected} value={item} fromSearch={false} />
                                                                                                                        </div>
                                                                                                                    )
                                                                                                            )
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                
                                                                    </div>
                                
                                
                                
                                                                    {loader ? (
                                                                        ""
                                                                    ) : (
                                                                        <div className="row" style={{ paddingBottom: "20px" }}>
                                                                            <div className="col-sm-12  col-md-12 col-lg-12 col-xl-12">
                                                                                <div className="button-section" style={{ display: "flex", justifyContent: "flex-end" }}>
                                                                                    <button type="submit" onClick={onSubmit} className="btn btn-primary" >
                                                                                        Update
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    ):(
                                                        <div className="gc_contentbox">
                                                        <div className="gc_content_pbox">
                                                            <h3>Please select a country</h3>
                                                            </div></div>
                                                    )}
                 
                </div>
            </div>
        </>
    );
};

export default GiftCard1;
