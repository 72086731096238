import React, { useState, useEffect } from "react";
import Header from "../../layout/Header";
import { Tabs, Tab } from "react-bootstrap";
import { profileImage } from "../../../Image/Index";
import { Link } from "react-router-dom";
import { Paginated } from "./Paginated";
// import { COLUMNS } from "./column";
import data from "./data.json";
import TransactionViewModal from "../transactionsList/TransactionViewModal";
import { getFinancialAccount, clearSetting } from "../../../actions/setting";
import { connect } from "react-redux";

const FinancialAccount = ({ getFinancialAccount, clearSetting, Setting }) => {
    const [Data, setData] = useState([]);
    const [showLogin, setShowLogin] = useState(false);
    const [loader, setLoader] = useState(false);
    const [formData, setFormData] = useState({
        limit: "",
        balance: "",
        used: "",
        code: "",
        name: "",
    });
    const [date, setDate] = useState({
        startDate: "0",
        endDate: "0",
        daysDiff: "",
        allDates: "",
    });
    const [transactionId, setTransactionId] = useState("");

    useEffect(() => {
        fetch();
        handleSetData();
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        setFormData({
            limit: Setting.limit ? Setting.limit : "0",
            balance: Setting.balance ? Setting.balance : "0",
            used: Setting.used ? Setting.used : "0",
            code: Setting?.data?.code,
            name: Setting?.data?.name,
        });
    }, [Setting]);

    const handleSetData = () => {
        const temp = data;
        temp?.map((val, i) => {
            return val.transactionId;
            // (val.transactionId = (
            //     <Link id={val.transactionId} to="/customer-view" state={{ customerCode: val.customerCode }} onClick={() => setShowLogin(true)}>
            //         {val.transactionId}
            //     </Link>
            // ));
        });
        temp?.map((val, i) => {
            return val.customerCode;
            // (val.customerCode = (
            //     <Link id={val.customerCode} to="/customer-view" state={{ customerCode: val.customercode }}>
            //         {val.customerCode}
            //     </Link>
            // ));
        });
        setData(temp);
    };

    const handleViewModal = (data) => {
        setShowLogin(true);
        setTransactionId(data);
    };

    const fetch = async () => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const id = localStorage.getItem("Id");
        await clearSetting();
        await getFinancialAccount(token?.access_token, id);
        setLoader(false);
    };
    const COLUMNS = [
        {
            Header: "Value",
            Footer: "Value",
            accessor: "value",
            disableFilters: true,
            sticky: "left",
        },
        {
            Header: "Date",
            Footer: "Date",
            accessor: "transactionProcessedDate",
            sticky: "left",
        },
        {
            Header: "Transaction ID",
            Footer: "Transaction Id",
            accessor: "transactionId",
            Cell: ({ value }) => {
                return (
                    <Link to="" state={{ customerCode: value }} onClick={() => handleViewModal(value)}>
                        {value}
                    </Link>
                );
            },
        },
        {
            Header: "Customer code",
            Footer: "Customer code",
            accessor: "customerCode",
            Cell: ({ value }) => {
                return (
                    <Link to="/customer-view" state={{ customerCode: value }}>
                        {value}
                    </Link>
                );
            },
        },
    ];

    const { limit, balance, used, code, name } = formData;
    return (
        <>
            <Header name={"Financial Account"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__financialAccountMain">
                        <div className="dashboard__financialAccountCard">
                            <div className="dashboard__financialAccountProfile">
                                <div className="dashboard__financialAccountImage">
                                    <img src={profileImage} alt="" />
                                </div>
                                <div className="dashboard__financialAccountDetails">
                                    <div className="dashboard__financialAccountTop">
                                        <h2>Financial Details</h2>
                                        <TransactionViewModal show={showLogin} close={() => setShowLogin(false)} transactionId={showLogin && transactionId} />
                                    </div>
                                    <div className="dashboard__financialAccountBottom">
                                        <div className="dashboard__financialAccountLeft">
                                            <ul>
                                                <li>Name: {name}</li>
                                                <li>Merchant code: {code}</li>
                                            </ul>
                                        </div>
                                        {/* <div className="dashboard__financialAccountRight">
											<ul>
												<li>Merchant ID: 754823614</li>
												<li>Customer Code: CUS1245896321</li>
												<li>UID: XXXXXXXX1455</li>
											</ul>
										</div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard__financialAccountCard">
                            <div className="dashboard__financialAccountPointsTabs">
                                <Tabs id="financialAccountPointsTabs">
                                    <Tab eventKey="Points" title="Points">
                                        <div className="dashboard__financialAccountPoints">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="dashboard__financialAccountHeading">
                                                        <h3>Total Value</h3>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="dashboard__financialAccountBox">
                                                        <h4>Total Limit</h4>
                                                        <h2>{limit}</h2>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="dashboard__financialAccountBox">
                                                        <h4>Total Spent</h4>
                                                        <h2>{used}</h2>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="dashboard__financialAccountBox">
                                                        <h4>Total Balance</h4>
                                                        <h2>{balance}</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <Paginated columns={COLUMNS} data={Data} setData={setData} date={date} setDate={setDate} handleSetData={handleSetData} />
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => ({
    Setting: state.Setting,
});

const mapDispatchToProps = {
    getFinancialAccount,
    clearSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancialAccount);
