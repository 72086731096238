import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ValidatorForm } from "react-form-validator-core";
import { Token_Generator, signup, getIndustry } from "../../actions/auth";
import { logo, eyeSeen, eyeUnseen, eyeSeenFocus, eyeUnseenFocus } from "../../Image/Index";
import TextValidator from "../partial/TextValidator";
import SelectValidator from "../partial/SelectValidator";
import PasswordValidator from "../partial/PasswordValidator";
import AuthenticationSliderLeft from "./common/AuthenticationSliderLeft";
import axios from "../../utils/axios";
import swal from "sweetalert";

import ReCAPTCHA from "react-google-recaptcha";

const SignUp = () => {
    const [geoInfo, setGeoInfo] = useState();
    const formRef = useRef();
    const recaptchaRef = useRef();
    const [recaptchaFilled, setRecaptchaFilled] = useState(false);
    const [recaptchaValueData, setRecaptchaValueData] = useState()
    const [isRevealPassword, setIsRevealPassword] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState()
    const [accessToken, setAccessToken] = useState("");
    const [isConfirmPassword, setIsConfirmPassword] = useState(false);
    // const [passwordLabel, setPasswordLabel] = useState();
    const [passwordFocus, setPasswordFocus] = useState(false);
    const [confirmFocus, setConfirmFocus] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        Name: "",
        emailID: "",
        contactNumber: "",
        newPassword: "",
        confirmPassword: "",
        selectedTypeId: "",
        companyName: "",
        detailAboutProject: "",
        tAndC: false,
        error: false,
        options: [],
        needSelectedTypeId: "",
        needOptions: [
            {
                value: "Launch a new loyalty program",
                label: "Launch a new loyalty program",
            },
            {
                value: "Replace existing loyalty program",
                label: "Replace existing loyalty program",
            },
            {
                value: "Need more information on loyalty setup",
                label: "Need more information on loyalty setup",
            },
        ],
    });

    const [finalData, setFinalData] = useState({});
    const [loader, setLoader] = useState(false);

    const invalidDomains = ["mailinator", "cwmxc", "horsgit", "maildrop", "explodemail", "stresspc", "anonaddy"];

    const { Name, emailID, contactNumber, newPassword, confirmPassword, selectedTypeId, companyName, detailAboutProject, options, needSelectedTypeId, needOptions, tAndC, error } = formData;
    const onChange = (e) => setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });

    useEffect(() => {
        ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
            if (value !== newPassword) {
                return false;
            }
            return true;
        });
        // eslint-disable-next-line
    }, [confirmPassword, newPassword]);

    useEffect(() => {
        const validateEmail = (email) => {
            const domainPattern = invalidDomains.join("|");
            const regexPattern = `^(?!.*@(${domainPattern})\\b)([a-zA-Z0-9]+([._-][a-zA-Z0-9]+)*)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$`;

            const regex = new RegExp(regexPattern);
            if (regex.test(email)) {
                return true;
            } else {
                return false;
            }
            // return email.match(/^(?!.*@(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        };
        ValidatorForm.addValidationRule("validEmail", (value) => {
            if (validateEmail(value)) {
                return true;
            }
            return false;
        });
    }, [emailID]);

    useEffect(() => {
        const validateName = (name) => {
            return /^[A-Za-z\s]*$/.test(name);
        };
        ValidatorForm.addValidationRule("validName", (value) => {
            if (validateName(value)) {
                return true;
            }
            return false;
        });
    }, [Name]);

    useEffect(() => {
        const validateName = (name) => {
            return /^[A-Za-z\s&,$,%,*,@,!,.,,,]*$/.test(name);
        };
        ValidatorForm.addValidationRule("validNameData", (value) => {
            if (validateName(value)) {
                return true;
            }
            return false;
        });
    }, [detailAboutProject]);

    useEffect(() => {
        ValidatorForm.addValidationRule("minimumlength", (value) => {
            if (value.length > 0 && value.length < 50) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule("maximumlength", (value) => {
            if (value.length > 500) {
                return false;
            }
            return true;
        });
    }, [detailAboutProject])

    useEffect(() => {
        const tempOption = [];
        const response = token_gen();
        response.then((res) => {
            const industry = getIndustry(res);
            industry.then((res) => {
                res.length &&
                    res?.map((val) => {
                        return tempOption.push({
                            value: val?.name,
                            label: val?.name,
                        });
                    });
                setFormData({ ...formData, ...{ options: tempOption } });
            });
        });
    }, []); // eslint-disable-line

    useEffect(() => {
        getGeoInfo();
    }, []);

    const getGeoInfo = () => {
        axios
            .get("https://ipapi.co/json/")
            .then((response) => {
                let data = response.data;
                setGeoInfo(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        let data = [];
        data.push({ name: Name, companyname: companyName, contactNumber: contactNumber, projectdetails: detailAboutProject, email: emailID, need: needSelectedTypeId.value, industry: selectedTypeId.value, passwordhash: newPassword, EmailConfirmed: "", ip: geoInfo?.ip, country: geoInfo?.country_name });
        setFinalData(data[0]);
    }, [formData, geoInfo]); // eslint-disable-line

    const onSelectChange = (e) => setFormData({ ...formData, selectedTypeId: { ...e } });
    const onNeedSelectChange = (e) => setFormData({ ...formData, needSelectedTypeId: { ...e } });

    const handleCheckbox = (e) => setFormData({ ...formData, tAndC: e.target.checked });
    const token_gen = async () => {
        const token = await Token_Generator();
        setAccessToken(token?.access_token);
        return token?.access_token;
    };

    const onSubmit = async (e) => {
        // const recaptchaValue = await recaptchaRef.current.getValue()
        e.preventDefault();
        if (formData?.newPassword !== formData?.confirmPassword) {
            swal({
                title: " Oops!",
                text: "Please match new password and confirm password",
                icon: "warning",
                button: "oh no!",
                timer: 5000,
            });
        } else {
            if (!tAndC) {
                setFormData({ ...formData, error: true });
            } else if (!recaptchaFilled) {
                setRecaptchaValueData("Please confirm you're not a robot.")

            }
            else {
                if (newPassword !== confirmPassword) {
                    setFormData({ ...formData, error: true });
                }
                {
                    setLoader(true);
                    const response = await signup(accessToken, finalData);
                    if (response.message === "User Created") {
                        setLoader(false);
                        navigate("/thankyou", { state: { emailID: emailID } });
                    }
                    else if (response.message === "User Updated") {
                        setLoader(false);
                        navigate("/thankyou", { state: { emailID: emailID, additionalUpdate: "YourAdditionalData" } });
                    }
                    else if (response.message === "User Already Exist") {
                        swal({
                            title: " Oops!",
                            text: "User Already Exist",
                            icon: "warning",
                            button: "oh no!",
                            timer: 5000,
                        });

                        setLoader(false);
                    } else {
                        swal({
                            title: " Oops!",
                            text: " Something went wrong please try again.",
                            icon: "error",
                            button: "oh no!",
                            timer: 5000,
                        });

                        setLoader(false);
                    }
                }
            }
        }
    };
    const handleChange = value => {
        // console.log(recaptchaRef.current.getValue())
    };

    const asyncScriptOnLoad = () => {
        console.log("scriptLoad - reCaptcha Ref-", recaptchaRef);
    };

    const handleLinkClick = (e) => {
        e.preventDefault();
        window.open(e.target.href, "_blank");
    };
    const onRecaptchaChange = (value) => {
        setRecaptchaValueData(null);
        if (value) {
            setRecaptchaFilled(true);
        }
    };


    return (
        <section className="section signUpPage">
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="signUpPage__main">
                <AuthenticationSliderLeft />
                <div className="signUpPage__right">
                    <div className="signUpPage__heading">
                        <figure>
                            <img src={logo} alt="logo" />
                        </figure>
                        <h1>Sign Up for your 30-day free trial</h1>
                        <h4>All-In-One Loyalty Rewards Solution for SMBs to Enterprises</h4>
                        {/* <p>
                            <span>
                                <i className="fa-solid fa-check"></i> No credit card required
                            </span>
                        </p> */}
                    </div>
                    <div className="signUpPage__form">
                        <ValidatorForm className="signUpPage__formSection" ref={formRef} autoComplete="off" autoCapitalize="off" onSubmit={(e) => onSubmit(e)}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <TextValidator className="form-control" placeholder="Name" name="Name" maxLength="50" value={Name} onChange={onChange} validators={["required", "trim", "validName"]} errorMessages={["Please enter your name", "Enter valid Name", "Enter valid Name"]} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <TextValidator className="form-control" placeholder="Email" name="emailID" maxLength="50" value={emailID} type="email" onChange={onChange} validators={["required", "validEmail"]} errorMessages={["Please enter your email", "Enter valid email"]} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group withIcon">
                                        <span className="passwordToggle">{passwordFocus ? <img className="onFocus" title={isRevealPassword ? "Hide password" : "Show password"} src={isRevealPassword ? "/Images/eyeUnseenFocus.png" : "/Images/eyeSeenFocus.png"} alt="eye" onClick={() => setIsRevealPassword((prevState) => !prevState)} /> : <img  title={isRevealPassword ? "Hide password" : "Show password"} src={isRevealPassword ? "/Images/eyeUnseen.png" : "/Images/eyeSeen.png"} alt="eye" onClick={() => setIsRevealPassword((prevState) => !prevState)} />}</span>
                                        <PasswordValidator placeholder="New Password" className="form-control" name="newPassword" minLength="6" maxLength="16" value={newPassword} type={isRevealPassword ? "text" : "password"} onChange={onChange} validators={["required", "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,16}$"]} errorMessages={["Please enter password.", "condition"]}  />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group withIcon">
                                        <span className="passwordToggle">{confirmFocus ? <img className="onFocus" title={isConfirmPassword ? "Hide password" : "Show password"} src={isConfirmPassword ? "/Images/eyeUnseenFocus.png" : "/Images/eyeSeenFocus.png"} alt="eye" onClick={() => setIsConfirmPassword((prevState) => !prevState)} /> : <img title={isConfirmPassword ? "Hide password" : "Show password"} src={isConfirmPassword ? "/Images/eyeUnseen.png" : "/Images/eyeSeen.png"} alt="eye" onClick={() => setIsConfirmPassword((prevState) => !prevState)} />}</span>
                                        <PasswordValidator placeholder="Confirm Password" className="form-control" name="confirmPassword" maxLength="16" value={confirmPassword} type={isConfirmPassword ? "text" : "password"} onChange={onChange} validators={["required", "isPasswordMatch"]} errorMessages={[" Please enter confirm password.", "Password Mismatch."]}  />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <TextValidator className="form-control" placeholder="Contact Number" name="contactNumber" maxLength="50" value={contactNumber} onChange={onChange} validators={["required", "matchRegexp:^(?!\\d*(\\d)\\1{7,}|0123456789|1234567890)\\d{10,15}$"]} errorMessages={["Please enter your contact number", "Please enter a valid number"]} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <SelectValidator placeholder="Select Industry" className="custom-ReactSelect bordered" name="category" value={selectedTypeId} selected={selectedTypeId} options={options} onChange={onSelectChange} validators={["required"]} errorMessages={["Please enter your industry"]} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <SelectValidator placeholder="What you need" className="custom-ReactSelect bordered" name="category" value={needSelectedTypeId} selected={needSelectedTypeId} options={needOptions} onChange={onNeedSelectChange} validators={["required"]} errorMessages={["Please select an option"]} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <TextValidator placeholder="Company Name" className="form-control" name="companyName" value={companyName} validators={["required", "trim", "validNameData"]} errorMessages={["Please enter your company name", "Enter valid company name", "Enter valid company name"]} onChange={onChange} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <TextValidator placeholder="Details about your project" className="form-control" name="detailAboutProject" value={detailAboutProject} onChange={onChange} validators={["required", "validNameData", "minimumlength", "maximumlength"]} errorMessages={["Please enter details about your project", "Enter valid description", "Minimum length should be 50 character", "Maximum length should be 500 character"]} />
                                        {/* <textarea  placeholder="Details about your project" className="form-control" ></textarea> */}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <ReCAPTCHA
                                           
                                            theme="light"
                                            ref={recaptchaRef}
                                            sitekey={process.env.REACT_APP_GOOGLE_KEY}
                                            onChange={onRecaptchaChange}
                                        />
                                        {recaptchaValueData && <div style={{ color: "red" }}>
                                            {recaptchaValueData}</div>}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        {/* <p>
                                            <input type="checkbox" onChange={handleCheckbox} /> I've read and agreed to the{" "}
                                            <Link to="/terms-and-conditions" onClick={handleLinkClick}>
                                                Terms and Conditions
                                            </Link>
                                        </p> */}
                                        <label htmlFor="signup_terms" className="signup_checklabel">
                                            <input type="checkbox" onChange={handleCheckbox} id="signup_terms" />
                                            <p>I've read and agreed to the {" "}<Link to="/terms-and-conditions" onClick={handleLinkClick}>
                                                Terms and Conditions
                                            </Link></p>
                                        </label>
                                        {error && !tAndC && <span className="textValidatorError">Please agree to T&C</span>}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary btn-lg w-100">
                                            Continue
                                        </button>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <p>
                                            Already have an account? <Link to="/signin">Sign In</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </ValidatorForm>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SignUp;
