import React, { useState } from "react";
import CardViewModal from "./CardViewModal";
import { Link } from "react-router-dom";
import getSymbolFromCurrency from "currency-symbol-map";

const Card = ({ item, getSearchedCountry, handleSelect, isSelected, value, fromSearch }) => {
    const [state, setState] = useState({
        showModal: false,
        code: "",
        currencySymbol: "",
    });
    const { showModal, code, currencySymbol } = state;

    const handleViewModal = (item, country) => {

        let temp = "";
        getSearchedCountry.filter((itemm) => {
        
                if (itemm.value === item?.countries[0]?.code) {
                    temp = getSymbolFromCurrency(itemm.symbol);
                    return temp;
                }
           
        });
        setState((prevState) => ({
            ...prevState,
            showModal: true,
            code: item,
            currencySymbol: temp,
        }));
    };

    const isSelectedCard = (array, object, fromSearch) => {
        let exists;
        if (fromSearch) {
            exists = array?.some((value) => value.id === object.id);
        } else {
            exists = array?.some((value) => value.id === object.id && value.category === object.category);
        }
        if (exists) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div className="">
            <div className={isSelected && isSelectedCard(isSelected, { category: value, id: item.id }, fromSearch) ? "giftcard_voucher_box selected_voucher" : "giftcard_voucher_box"}>
                <div className="gc_imagebox">
                    <img src={item?.image} alt="" />
                    <div className="gc_overlay">
                        <div className="gc_overlay_box">
                            <ul>
                                <li>
                                    <Link to="" onClick={() => handleViewModal(item, item.country)}>
                                        View Details
                                    </Link>
                                </li>

                                <li>
                                    <Link to="" onClick={() => handleSelect(item.id, isSelected && isSelected?.includes(item?.id) ? "Unselect" : "Select", value, fromSearch)}>
                                        {isSelected && isSelectedCard(isSelected, { category: value, id: item.id }, fromSearch) ? "Unselect" : "Select"}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row giftcard_voucher_details">
                    <div className="giftcard_voucher_title">
                        <h5>{item?.title}</h5>
                    </div>
                </div>
            </div>

            {showModal && <CardViewModal show={showModal} close={() => setState((prev) => ({ ...prev, showModal: false }))} code={code} currencySymbol={currencySymbol} />}
        </div>
    );
};

export default Card;
