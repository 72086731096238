import React from "react";

const HeadingSection = () => {
    return (
        <>
            <div className="dashboard__apiPlaygroundDetails">
                <h4>Use Case</h4>
                <p>This API is used to get a new SSO token for the Paytm user after its expiry. The refresh token received in the response of Validate OTP API is used to get a new SSO token.</p>
                <div className="table-responsive">
                    <table className="table table-bordered w-100">
                        <thead>
                            <tr>
                                <th>ATTRIBUTE</th>
                                <th>VALUE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Authorization string mandatory</td>
                                <td>This is a base64 encoded string of “clientId:clientSecret”</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="dashboard__apiPlaygroundDetails">
                <h4>Request Attributes</h4>
                <p>Content Type : JSON</p>
                <h4>Body</h4>
                <div className="table-responsive">
                    <table className="table table-bordered w-100">
                        <thead>
                            <tr>
                                <th>ATTRIBUTE</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>"client_id"</td>
                                <td>client_id</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>"grant_type"</td>
                                <td>client_credentials</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>"client_secret"</td>
                                <td>client_secret</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="dashboard__apiPlaygroundDetails">
                <h4>Response Attributes</h4>
                <p>API Content Type: JSON</p>
                <h4>Success Response</h4>
                <div className="table-responsive" style={{ margin: "0 0 30px" }}>
                    <table className="table table-bordered w-100">
                        <thead>
                            <tr>
                                <th>ATTRIBUTE</th>
                                <th className="td_left">VALUE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>accessToken</td>
                                <td>Received SSO token of the user Example: eyJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiZGlyIn0..xxxxxxxxxxx.9iHTtWbCZ0I6qbn2sUnyz5siw1fqbmtEnFMFE7nSIX-yrwCkiGfAC6QmPr9q-tw8LMPOh5-3UXRbpeVZEupQd3wNyaArWybRX2HAxJDRD8mxJ_wxzJM6GZ1ov4O3EIsx2Y_Zr0aHCd3VbnTjRUnlVdxXJPFG8QZs0b_2TVdoAX3_QjZS8_dwcmIWoH8ebDzOIs7MJacETfMtyFGAo8Xc0LjznToUWvTsTbIXQoF1yB0.1fZFAYJVsY61BTv2htLcXQ8800</td>
                            </tr>
                            <tr>
                                <td>tokenType string</td>
                                <td className="td_left">Possible Values: BEARER</td>
                            </tr>
                            <tr>
                                <td>expiresIn string</td>
                                <td className="td_left">Expiry timer of the SSO token Example: 1594011297000</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h4>Failure Response</h4>
                <div className="table-responsive" style={{ margin: "0 0 30px" }}>
                    <table className="table table-bordered w-100">
                        <thead>
                            <tr>
                                <th>ATTRIBUTE</th>
                                <th>VALUE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>error string</td>
                                <td>This is the error corresponding to a particular error description and is returned to the merchant. The different error codes corresponding to this API are mentioned below.</td>
                            </tr>
                            <tr>
                                <td>error_description string</td>
                                <td>This parameter is the error_description which contains information about the failure result.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="alert alert-primary" role="alert" style={{ margin: "0" }}>
                    <p style={{ margin: "0" }}>
                        <strong>Note:</strong>&nbsp;If Authorization header is incorrect then the response will not come and you will face HTTP Status code "401"
                    </p>
                </div>
            </div>
            <div className="dashboard__apiPlaygroundDetails">
                <h4>Response Codes & Messages</h4>
                <div className="table-responsive">
                    <table className="table table-bordered w-100">
                        <thead>
                            <tr>
                                <th>ResponseCode</th>
                                <th>Status</th>
                                <th>Message</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>200</td>
                                <td>SUCCESS</td>
                                <td>OK</td>
                            </tr>
                            <tr>
                                <td>400</td>
                                <td>FAILURE</td>
                                <td>Invalid_client</td>
                            </tr>

                            <tr>
                                <td>404</td>
                                <td>FAILURE</td>
                                <td>Not Found</td>
                            </tr>
                            <tr>
                                <td>429</td>
                                <td>FAILURE</td>
                                <td>Too Many Requests</td>
                            </tr>
                            <tr>
                                <td>500</td>
                                <td>FAILURE</td>
                                <td>Internal Server Error</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default HeadingSection;
