import React from "react";
import Header from "../../layout/Header";
import { Link } from "react-router-dom";

const UploadExistingData = () => {
    return (
        <>
            <Header name={"Upload Historical Data"} />
            <div className="uploadingExistingData">
                <div className="container">
                    <div className="uploadingExistingData__main">
                        <div className="uploadingExistingData__left">
                            <img src={"/Images/uploadExistingDataImg.png"} alt="" />
                        </div>
                        <div className="uploadingExistingData__right">
                            <div className="uploadingExistingData__content justify-content-center">
                                <div className="uploadingExistingData__heading">
                                    <h1>Do you want to upload historical data?</h1>
                                    <h4>Uploading Historical data helps us in providing detailed reports and show how you are performing in comparison to the industry average.</h4>
                                </div>
                                <div className="uploadingExistingData__uploadBox">
                                    <div className="uploadingExistingData__uploadBoxBottom">
                                        <Link to="/uploading-existing-datas" state={{ from: "upload" }}>
                                            <button type="button" className="btn btn-primary">
                                                <i className="fa-solid fa-file-arrow-up"></i>
                                                Upload Data
                                            </button>
                                        </Link>
                                        <Link to="/analytical-report" state={{ prevPath: "/uploading-existing-datas" }} className="btn btn-outline-primary">
                                            <i className="fa-regular fa-file-lines"></i>
                                            Check Sample Report
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="uploadingExistingData__buttonSection">
                                <div className="uploadingExistingData__buttonSectionLeft">
                                    <Link to="/business-goals-selection" state={{ path: "/upload-existing-data" }}>
                                        Skip
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UploadExistingData;
