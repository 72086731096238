import { CLEAR_CUSTOMER_LIST, GET_CUSTOMER_LIST,SEARCH_CUSTOMER_LIST } from "../actions/type";

const initialState = {
    data: [],
};

const Customer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_CUSTOMER_LIST:
            return {
                ...state,
                data: payload,
            };
            case SEARCH_CUSTOMER_LIST:
                return {
                    ...state,
                    data: payload,
                };
        case CLEAR_CUSTOMER_LIST:
            return {
                ...state,
                data: [],
            };
        default:
            return state;
    }
};
export default Customer;
