import React, { useEffect, useState } from 'react'
import Header from '../../layout/Header'
import EmailPaginated from './EmailPaginated';
import { EMAILCOLUMNS } from './EmailColumn';
import { emailReport } from '../../../actions/auth';
import { ValidatorForm } from 'react-form-validator-core';
import SelectValidator from '../../partial/SelectValidator';
import { selectValueGetter } from '../../../utils/helper';

function EmailReport() {

    const [loader, setLoader] = useState(false);
    const [typeData, setTypeData] = useState(false)
    const [selectPurpose, setSelectPurpose] = useState(null)
    const [formData, setFormData] = useState([]);
    const [purposeData, setPurposeData] = useState([]);
    const [response, setResponse] = useState()

    const updateData = () => {
        let temp = [];
        setLoader(true)
        const token = JSON.parse(localStorage.getItem("token_gen"));
        emailReport(token?.access_token, typeData).then((res) => {
            setResponse(res?.data)
            res?.data?.map((item) => temp.push({ label: "Select", value: "" }, { label: item.purpose, value: item.purpose }));
            let jsonObject = temp.map(JSON.stringify);
            let uniqueSet = new Set(jsonObject);
            let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
            setPurposeData(uniqueArray);
            setLoader(false)
        });
    }

    useEffect(() => {
        updateData()
    }, [])

    useEffect(() => {
        const tempData = response
        tempData?.map((val, ind) => {
            return (val.purpose = val.purpose);
        });
        tempData?.map((val, ind) => {
            return (val.fileName = val.fileName);
        });
        tempData?.map((val, ind) => {
            return (val.totalEmails = val.totalEmails);
        });
        tempData?.map((val, ind) => {
            return (val.totalSuccess = val.totalSuccess);
        });
        tempData?.map((val, ind) => {
            return (val.totalPending = val.totalPending);
        });
        tempData?.map((val, ind) => {
            return (val.totalFail = Math.abs(val.totalFail));
        });
        setFormData(tempData)
    }, [response]);

    const handleSelect = async (selectedOption) => {
        setSelectPurpose(selectedOption.value)
        setTypeData(true)
        try {
            setLoader(true);
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const res = await emailReport(token?.access_token, selectedOption.value, true);
            setResponse(res.data);
            setLoader(false);
        } catch (error) {
            setLoader(false);
        }
    };
    
    return (
        <>
            <Header name={"Email Report"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="dashboard__customersCard">
                            <div className="dashboard__header">
                                <div className="dashboard__left">
                                    <h4 id="voucher_details">Report</h4>
                                </div>
                            </div>
                            <div className="dashboard__body">
                                <ValidatorForm >
                                    <div className="form-group col-md-4">
                                        <label> Select Purpose</label>
                                        <SelectValidator
                                            options={purposeData}
                                            onChange={handleSelect}
                                            value={selectValueGetter(purposeData, selectPurpose)}
                                        />
                                    </div>
                                </ValidatorForm>
                                <EmailPaginated data={formData ? formData : []} columns={EMAILCOLUMNS} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmailReport