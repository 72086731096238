import React, { useEffect, useState } from "react";
import Header from "../../layout/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import AccordionButton from "./userJourneyPartials/AccordionButton";
import { getBusinessGoal, clearBusinessGoal } from "../../../actions/businessGoal";
import { clearCampaigns } from "../../../actions/campaigns";
import { connect } from "react-redux";
import { decrypt } from "../../../utils/encodingdecoding";
import { backIcon2 } from "../../../Image/Index";
import { element } from "prop-types";

const BusinessGoalsSelection = ({ getBusinessGoal, clearBusinessGoal, BusinessGoal, clearCampaigns }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeKey, setActiveKey] = useState("-1");
    const [loader, setLoader] = useState(false);
    const [state, setState] = useState({
        tags: [],
        disable: true,
        data: [],
        goals: [],
    });
    const { tags, disable, goals, data } = state;

    useEffect(() => {
        fetch();
    }, []); //eslint-disable-line

    useEffect(() => {
        setState({ ...state, data: BusinessGoal });
    }, [BusinessGoal]); //eslint-disable-line

    useEffect(() => {
        const industry = decrypt(localStorage.getItem("schemaName"));
        data.forEach((item) => {
            if (item.industry === industry) {
                setState({ ...state, goals: item.goals });
            }
        });
    }, [data]); //eslint-disable-line

    const fetch = async () => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        await clearBusinessGoal();
        await getBusinessGoal(token?.access_token);
        setLoader(false);
    };

    const handleChange = (e, tag) => {
        let tempTag = `business goal:${tag.businessgoal.toLowerCase()}`;
        tempTag = tempTag.replace(/-/g, " ");
        if (tags.includes(tempTag)) {
            let temp = tags.filter((item) => item !== tempTag);
            setState({ ...state, tags: temp, disable: temp.length <= 0 ? true : false });
        } else {
            let temp = tag.businessgoal;
            let businessGoal = temp.replace(/-/g, " ");
            setState({ ...state, tags: [...tags, `business goal:${businessGoal.toLowerCase()}`], disable: false });
        }
    };

    const handleClick = async () => {
        await clearCampaigns();
        navigate("/sample-campaigns", { state: tags });
    };
    const handleSkipClick = async () => {
        let tags = [];
        let goal;
        goals.map((element) => {
            goal = element.businessgoal.replace(/-/g, " ");
            return tags.push(`business goal:${goal.toLowerCase()}`);
        });
        await clearCampaigns();
        navigate("/sample-campaigns", { state: [] });
    };

    return (
        <>
            <Header name={"Business Goal Selections"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="businessGoalsSelection">
                <div className="businessGoalsSelection__topSection">
                    <div className="container">
                        <div className="businessGoalsSelection__leftSection">
                            {location?.state?.prevPath && (
                                <div className="dashboard__headingBackLink dashboard__headingBackLink2">
                                    <Link to="" onClick={() => navigate(-1)}>
                                        <img style={{ width: "18px" }} src={backIcon2} alt="" />
                                        <span>Back</span>
                                    </Link>
                                </div>
                            )}
                            <h4 id="business_goals">Select your Business Goal</h4>
                        </div>
                    </div>
                </div>
                <div className="businessGoalsSelection__bottomSection">
                    <div className="container">
                        {Array.isArray(goals) ? (
                            <Accordion className="businessGoalsSelection__accordion" onSelect={(e) => setActiveKey(e)}>
                                {goals &&
                                    goals.map((item, index) => (
                                        <Card key={index} className="businessGoalsSelection__accordionItem">
                                            <Card.Header className="businessGoalsSelection__accordionHeading">
                                                <div className={index === Number(activeKey) ? "businessGoalsSelection__accordionButton active" : "businessGoalsSelection__accordionButton"}>
                                                    <input type="checkbox" value={index} onChange={(e) => handleChange(e, item)} />
                                                    {item.businessgoal}
                                                    <AccordionButton eventKey={index.toString()}>
                                                        <i className="fa-solid fa-chevron-right"></i>
                                                    </AccordionButton>
                                                </div>
                                            </Card.Header>
                                            <Accordion.Collapse className="businessGoalsSelection__accordionCollapse" eventKey={index.toString()}>
                                                <Card.Body className="businessGoalsSelection__accordionBody">{item.description}</Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    ))}
                            </Accordion>
                        ) : (
                            <h6>There is no business goal</h6>
                        )}
                        <div className="businessGoalsSelection__buttonSection">
                            <div className="businessGoalsSelection__buttonSectionLeft">
                                <Link onClick={handleSkipClick}>Skip</Link>
                            </div>
                            <div className="businessGoalsSelection__buttonSectionRight">
                                <Link to="/contact-us" className="btn btn-outline-primary">
                                    Contact Novus Support
                                    <i className="fa-solid fa-comment-dots"></i>
                                </Link>
                                <button className="btn btn-primary" id="select_camp" disabled={disable ? true : false} onClick={handleClick}>
                                    Select Camapigns
                                    <i className="fa-solid fa-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    BusinessGoal: state.BusinessGoal.data,
    Campaigns: state.Campaigns.data,
});
const mapDispatchToProps = {
    getBusinessGoal,
    clearBusinessGoal,
    clearCampaigns,
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessGoalsSelection);
