import { CLEAR_VOUCHER_TEMPLATE, VOUCHER_TEMPLATE_DETAIL } from "../actions/type";

const initialState = {
    dataList: [],
    show: true,
};

const VoucherTemplate = (state = initialState, action) => {
    const { type, payload, show } = action;
    switch (type) {
        case VOUCHER_TEMPLATE_DETAIL:
            return {
                ...state,
                dataList: payload,
                show: show,
            };
        case CLEAR_VOUCHER_TEMPLATE:
            return {
                dataList: [],
                show: true,
            };
        default:
            return state;
    }
};
export default VoucherTemplate;
