import { SHOW_ALERT_MODEL, CLEAR_ALERT_MODAL } from "../actions/type";
const initialState = {
    alertModal: {},
    loader:false
};

const alertModal = (state = initialState, action) => {
    const { type, payload } = action;


    switch (type) {
        case SHOW_ALERT_MODEL:
            return { alertModal: payload, };
        case CLEAR_ALERT_MODAL:
            return { alertModal: {} };
        default:
            return state;
    }
};
export default alertModal;
