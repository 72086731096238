import React from "react";
import { Link } from "react-router-dom";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

const AccordionButton = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => {});
    return <Link onClick={decoratedOnClick}>{children}</Link>;
};

export default AccordionButton;
