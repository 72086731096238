import { AUTH,LOGOUT } from "../actions/type";
const initialState = {
    authData: {},
};

const authReducer=(state=initialState,action)=>{
    const { type, payload } = action;
    switch(type){
        case AUTH:
            localStorage.setItem('profile',JSON.stringify({...payload}))
            return {...state,authData:payload};
        case LOGOUT:
            return {...state,authData:{}};
        default:
            return state;
    }
}
export default authReducer;
