import { GET_UPLOAD_FILE_DETAILS, CLEAR_UPLOAD_FILE_DETAILS }  from "../actions/type";

const initialState = {
    data: [],
};

const UploadFileData = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_UPLOAD_FILE_DETAILS:
            return {
                ...state,
                data: payload,
            };
        case CLEAR_UPLOAD_FILE_DETAILS:
            return {
                ...state,
                data: [],
            };

        default:
            return state;
    }
};
export default UploadFileData;
