import React, { useEffect, useRef, useState } from "react";
import { ValidatorForm } from "react-form-validator-core";
import { Link, useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import Header from "../layout/Header";
import { backIcon2 } from "../../Image/Index";
import TextAreaValidator from "../partial/TextAreaValidator";
import TextValidator from "../partial/TextValidator";
import SelectValidator from "../partial/SelectValidator";
import { getDate, parseNumeric } from "../../utils/helper";
import { decrypt } from "../../utils/encodingdecoding";
import { selectValueGetter } from "../../utils/helper";
import moment from "moment";
import { createVoucherTemplate, getVoucherEnquiry, updateVoucherTemplate } from "../pages/campaigns/services";
import { deleteVoucherImageData, getVouchersImage } from "../../actions/auth";
import { vouchersImage } from "../../actions/setting";

const Voucher = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [voucherCode, setVoucherCode] = useState();
    const [voucherName, setVoucherName] = useState();
    const [responseImages, setResponseImages] = useState();
    const [loader, setLoader] = useState(false);
    const [bannerSize, setBannerSize] = useState(false);
    const [selectedBanner, setSelectedBanner] = useState(null);
    const imageInputRefBanner = useRef();
    const location = useLocation();
    const navigate = useNavigate();
    const [options] = useState([
        {
            value: "currency",
            label: "Currency",
        },
        {
            value: "discount",
            label: "Discount",
        },
        {
            value: "point",
            label: "Point",
        },
    ]);
    const [state, setState] = useState({
        name: "",
        code: "",
        voucherValidityDate: {
            startDate: "",
            endDate: "",
        },
        voucherVisibilityDate: "",
        voucherValueType: "Pre Loaded Value",
        redeemUrl: "",
        termsAndCondition: "",
        perPersonRedemption: 0,
        perPersonRedemptionCount: 0,
        totalRedemption: 0,
        merchant: 0,
        voucherGeneratedType: 0,
        voucherType: 0,
        title: "",
        description: "",
        isActive: 0,
        internalTags: [],
        numberOfVouchers: 1,
        templateHitCounter: 0,
        lastGenratedNumberOfVoucher: 0,
        usedVoucherCount: 0,
        merchantCode: [decrypt(localStorage.getItem("merchantcode"))],
        generatePath: "",
        importVoucher: [
            {
                totalCount: 0,
                successVoucherCount: 0,
                unSuccessVoucherCount: 0,
                createdDate: getDate(new Date()),
                blobFileName: {
                    fileName: "",
                    success: "",
                    unSuccess: "",
                },
            },
        ],
        uploadStatus: "",

        earningType: "",
        earningValue: "",
        nxpoints: 0,
        totalRedemptionLimit: 0,
        merchantId: [decrypt(localStorage.getItem("merchantcode"))],
        tags: [],
    });

    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const [voucherVisibileError, setVoucherVisibileError] = useState(false);
    const [createdDate, setCreatedDate] = useState();
    const { code, name, voucherValidityDate, title, perPersonRedemption, perPersonRedemptionCount, isActive, tags, voucherGeneratedType, numberOfVouchers, voucherVisibilityDate, voucherType, totalRedemption, earningType, earningValue, description, nxpoints, redeemUrl, termsAndCondition, totalRedemptionLimit } = state;
    const { startDate, endDate } = voucherValidityDate;

    useEffect(() => {
        if (location.state) {
            setLoader(true);
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const response = getVoucherEnquiry(token?.access_token, location.state);
            response
                .then((res) => {
                    const { code, name, voucherValidityDate, title, perPersonRedemption, perPersonRedemptionCount, isActive, voucherGeneratedType, numberOfVouchers, voucherVisibilityDate, voucherType, totalRedemption, earningType, earningValue, description, nxpoints, redeemUrl, termsAndCondition, totalRedemptionLimit, createdDate } = res.data;
                    setResponseImages(res.data.imageUpload);
                    setState((prevState) => ({
                        ...prevState,
                        code: code,
                        name: name,
                        voucherValidityDate: {
                            ...prevState.voucherValidityDate,
                            startDate: voucherValidityDate.startDate,
                            endDate: voucherValidityDate.endDate,
                        },

                        isActive: isActive,
                        voucherGeneratedType: voucherGeneratedType,
                        numberOfVouchers: numberOfVouchers,
                        voucherVisibilityDate: voucherVisibilityDate,

                        earningType: earningType,
                        earningValue: earningValue,
                        description: description,
                        // nxpoints: nxpoints,
                        redeemUrl: redeemUrl,
                        termsAndCondition: termsAndCondition,
                        // totalRedemptionLimit: totalRedemptionLimit,
                    }));
                    setCreatedDate(getDate(createdDate, "YYYY-MM-DD"));
                    setLoader(false);
                })
                .catch((err) => {
                    setLoader(false);
                });
            setLoader(false);
        }
    }, [location]);

    useEffect(() => {
        let tempEndDate = moment(endDate).diff(moment(startDate), "days");
        if (tempEndDate < 0) {
            setEndDateError(true);
        } else {
            setEndDateError(false);
        }
    }, [startDate, endDate]);

    useEffect(() => {
        let tempVisibilityDate = moment(endDate).diff(moment(voucherVisibilityDate), "days");
        let tempStartDate = moment(voucherVisibilityDate).diff(moment(startDate), "days");

        if (tempStartDate < 0 || tempVisibilityDate < 0) {
            setVoucherVisibileError(true);
        } else {
            setVoucherVisibileError(false);
        }
    }, [endDate, voucherVisibilityDate, startDate]);

    useEffect(() => {
        if (location.state) {
            let tempStartDate = moment(startDate).diff(moment(createdDate), "days");
            // tempStartDate < 0 ? setStartDateError(true) : setStartDateError(false);
        }
    }, [startDate]);

    const handleToggle = (e) => {
        setState({ ...state, [e.target.name]: Number(e.target.checked) });
    };

    const onChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };
    const handleDateTime = (e) => {
        setState((prevState) => ({
            ...prevState,
            voucherValidityDate: {
                ...prevState.voucherValidityDate,
                [e.target.name]: e.target.value,
            },
        }));
    };
    const onSelectChange = (e) => {
        let temp = e.value;
        setState({ ...state, earningType: temp });
    };

    const handleReset = () => {
        setState({
            name: "",
            code: "",
            voucherValidityDate: {
                startDate: "",
                endDate: "",
            },
            voucherVisibilityDate: "",
            redeemUrl: "",
            termsAndCondition: "",
            perPersonRedemption: 0,
            perPersonRedemptionCount: 0,
            totalRedemption: 0,
            merchant: 0,
            voucherGeneratedType: 0,
            voucherType: 0,
            title: "",
            description: "",
            isActive: 0,
            internalTags: [],
            numberOfVouchers: 1,
            templateHitCounter: 0,
            lastGenratedNumberOfVoucher: 0,
            usedVoucherCount: 0,
            merchantCode: [decrypt(localStorage.getItem("merchantcode"))],
            generatePath: "",
            importVoucher: [
                {
                    totalCount: 0,
                    successVoucherCount: 0,
                    unSuccessVoucherCount: 0,
                    createdDate: getDate(new Date()),
                    blobFileName: {
                        fileName: "",
                        success: "",
                        unSuccess: "",
                    },
                },
            ],
            uploadStatus: "",
            earningType: "",
            earningValue: "",
            nxpoints: 0,
            totalRedemptionLimit: 0,
            merchantId: [decrypt(localStorage.getItem("merchantcode"))],
            tags: [],
        });
    };

    const onSubmit = async () => {
        if (endDateError === false && voucherVisibileError === false && startDateError === false) {
            setLoader(true);
            const token = JSON.parse(localStorage.getItem("token_gen"));

            if (location.state) {
                let temp = location.state;

                const response = await updateVoucherTemplate(token?.access_token, temp, state);
                setVoucherCode(temp);
                setVoucherName(state.name);
                if (response?.message === "Record Updated Successfully.") {
                    swal({
                        position: "center",
                        icon: "success",
                        title: 'Voucher Template Updated Successfully',
                        showConfirmButton: false,
                        timer: 2000,
                    });
                } else {
                    swal({
                        position: "center",
                        icon: "error",
                        title: response?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }
            } else {
                const response = await createVoucherTemplate(token?.access_token, state);
                setVoucherCode(response?.data?.code);
                setVoucherName(response?.data?.name);
                if (response?.message === "Record Created Successfully.") {
                    swal({
                        position: "center",
                        icon: "success",
                        title:'Voucher Template Created Successfully' ,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    setState({
                        name: "",
                        code: "",
                        voucherValidityDate: {
                            startDate: "",
                            endDate: "",
                        },
                        voucherVisibilityDate: "",
                        redeemUrl: "",
                        termsAndCondition: "",
                        perPersonRedemption: 0,
                        perPersonRedemptionCount: 0,
                        totalRedemption: 0,
                        merchant: 0,
                        voucherGeneratedType: 0,
                        voucherType: 0,
                        title: "",
                        description: "",
                        isActive: 0,
                        internalTags: [],
                        numberOfVouchers: 1,
                        templateHitCounter: 0,
                        lastGenratedNumberOfVoucher: 0,
                        usedVoucherCount: 0,
                        merchantCode: [decrypt(localStorage.getItem("merchantcode"))],
                        generatePath: "",
                        importVoucher: [
                            {
                                totalCount: 0,
                                successVoucherCount: 0,
                                unSuccessVoucherCount: 0,
                                createdDate: getDate(new Date()),
                                blobFileName: {
                                    fileName: "",
                                    success: "",
                                    unSuccess: "",
                                },
                            },
                        ],
                        uploadStatus: "",
                        earningType: "",
                        earningValue: "",
                        nxpoints: 0,
                        totalRedemptionLimit: 0,
                        merchantId: [decrypt(localStorage.getItem("merchantcode"))],
                        tags: [],
                    });
                } else {
                    swal({
                        position: "center",
                        icon: "error",
                        title: response?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }
            }
            setLoader(false);
        }
    };

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("token_gen"));
        if (voucherCode ) {
            if (!bannerSize) {
                vouchersImage(token?.access_token, voucherCode, selectedBanner);
            }

            setTimeout(() => {
                navigate("/voucher-template");
            }, 2000);
        }
    }, [voucherCode, bannerSize, selectedBanner]);

    const handleFileBanner = (e) => {
        if (e.target.files[0] && e.target.files[0]?.bannerSize > 1 * 1024 * 1024) {
            setBannerSize(true);
        } else {
            const filedata = e.target.files[0];
            setSelectedBanner(filedata);
            setBannerSize(false);
        }
    };

    // const fetch = (e) => {
    //     if (location.state) {
    //         setLoader(true);
    //         let token = JSON.parse(localStorage.getItem("token_gen"));
    //         const id = localStorage.getItem("Id");
    //         getVouchersImage(token?.access_token, id, location.state)
    //             .then((res) => {
    //                 setResponseImages(res.data);
    //                 setLoader(false);
    //             })
    //             .catch((err) => {
    //                 setLoader(false);
    //             });
    //     }
    // };
    // useEffect(() => {
    //     fetch();
    // }, [location]);

    const handleShowDialog = () => {
        setIsOpen(!isOpen);
        window.scrollTo(0, 0);
    };

    const handleDelete = async (voucherTemplateCode) => {

        setLoader(true);
        let token = JSON.parse(localStorage.getItem("token_gen"));
        deleteVoucherImageData(token?.access_token, voucherTemplateCode)
            .then((Setting) => {
                swal({
                    position: "center",
                    icon: "success",
                    title: Setting.message,
                    showConfirmButton: false,
                    timer: 2000,
                });
            
                setTimeout(() => {
                    navigate("/voucher-template");
                }, 2000);
                setLoader(false);
            })
            .catch((err) => {
                swal({
                    position: "center",
                    icon: "error",
                    title: err.message,
                    showConfirmButton: false,
                    timer: 2000,
                });
            });
    };

    return (
        <>
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <Header name={"Voucher Template"} />
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__profileSettingsMain">
                        <div className="dashboard__heading">
                            <div className="dashboard__headingLeft">
                                <div className="dashboard__headingBackLink">
                                    <Link to="" onClick={() => navigate(-1)}>
                                        <img src={backIcon2} alt="" />
                                        <div className="dashboard__headingContent">
                                            <h3>Voucher Template</h3>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard__profileSettingsBox">
                            <ValidatorForm className="customForm customForm_2" onSubmit={onSubmit}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <div className="form-group">
                                                <label>
                                                    Name of the Voucher Template<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <TextValidator maxLength="50" className="form-control" name="name" value={name} onChange={(e) => onChange(e)} validators={["required"]} errorMessages={["Please enter name"]} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>
                                                Voucher Validity Start Date & Time<span style={{ color: "red" }}>*</span>
                                            </label>
                                            {location.state ? <TextValidator className="form-control" name="startDate" type="date" value={getDate(startDate, "YYYY-MM-DD")} onChange={handleDateTime} validators={["required"]} errorMessages={["Please enter startDate & Time"]} /> : <TextValidator className="form-control" name="startDate" type="date" value={getDate(startDate, "YYYY-MM-DD")} min={getDate(new Date(), "YYYY-MM-DD")} onChange={handleDateTime} validators={["required"]} errorMessages={["Please enter startDate & Time"]} />}
                                            {startDateError && <span style={{ color: "red" }}>Start date should not be less than {getDate(createdDate, "DD-MM-YYYY")}</span>}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>
                                                Voucher Validity End Date & Time<span style={{ color: "red" }}>*</span>
                                            </label>
                                            <TextValidator className="form-control" name="endDate" type="date" value={getDate(endDate, "YYYY-MM-DD")} onChange={handleDateTime} validators={["required"]} errorMessages={["Please enter endDate & Time"]} />
                                            {endDateError && <span style={{ color: "red" }}>End date should be greater than start date</span>}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row voucher_box_row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>Tagline of the Voucher Template</label>
                                            <TextValidator className="form-control" name="title" value={title} onChange={(e) => onChange(e)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="person" style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                            <p className="voucher" style={{ display: "flex", width: "100%", marginBottom: "0" }}>
                                                Per Person Redemption
                                            </p>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id="toggleSwitch" name="perPersonRedemption" checked={perPersonRedemption} onChange={handleToggle} style={{ height: "25px", width: "60px" }} />
                                            </div>
                                        </div>
                                    </div>
                                    {perPersonRedemption === 1 && (
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                            <div className="form-group withIcon">
                                                <label>Per Person Redemption Count</label>
                                                <TextValidator
                                                    className="form-control"
                                                    name="perPersonRedemptionCount"
                                                    value={perPersonRedemptionCount}
                                                    onChange={(e) =>
                                                        setState({
                                                            ...state,
                                                            perPersonRedemptionCount: Number(parseNumeric(e.target.value)),
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div> */}
                                <div className="row voucher_box_row" style={{ padding: "20px 0" }}>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>
                                                Voucher Visibile From Date & Time<span style={{ color: "red" }}>*</span>
                                            </label>
                                            <TextValidator className="form-control" name="voucherVisibilityDate" value={getDate(voucherVisibilityDate, "YYYY-MM-DD")} type="date" onChange={(e) => onChange(e)} validators={["required"]} errorMessages={["Please enter voucher template visible date"]} />
                                            {voucherVisibileError && <span style={{ color: "red" }}> Voucher visibility date should be in between voucher validity start & end date </span>}
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="person" style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                            <p className="voucher" style={{ display: "flex", width: "100%", marginBottom: "0", color: "black" }}>
                                                Voucher Generated Type
                                            </p>
                                            {/* <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id="toggleSwitch" name="voucherGeneratedType" checked={voucherGeneratedType} onChange={handleToggle} style={{ height: "25px", width: "60px" }} />
                                            </div> */}
                                            <div className="">
                                                <label className="toggle">
                                                    <input type="checkbox" name="voucherGeneratedType" checked={voucherGeneratedType} onChange={handleToggle} disabled={location.state ? true : false} />
                                                    <span className="slider"></span>
                                                    <span className="labels" data-on="Generated" data-off="Imported"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {voucherGeneratedType === 1 && (
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                            <div className="form-group withIcon">
                                                <label>Generate No Of Vouchers At One Time</label>
                                                <TextValidator
                                                    className="form-control"
                                                    name="numberOfVouchers"
                                                    value={numberOfVouchers}
                                                    validators={["required"]}
                                                    maxLength="7"
                                                    errorMessages={["Please enter generate number of vouchers at one time"]}
                                                    onChange={(e) =>
                                                        setState({
                                                            ...state,
                                                            numberOfVouchers: Number(parseNumeric(e.target.value)),
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* <div className="row voucher_box_row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>Voucher Visibile From Date & Time<span style={{color:"red"}}>*</span></label>
                                            <TextValidator className="form-control" name="voucherVisibilityDate" value={getDate(voucherVisibilityDate, "YYYY-MM-DD")} type="date" onChange={(e) => onChange(e)} validators={["required"]} errorMessages={["Please enter voucher template visible date"]} />
                                            {voucherVisibileError && <span style={{ color: "red" }}> Voucher visibility date should be in between voucher validity start & end date </span>}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="person" style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                            <p className="voucher" style={{ display: "flex", width: "100%", marginBottom: "0" }}>
                                                Voucher Type
                                            </p>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id="toggleSwitch" name="voucherType" checked={voucherType} onChange={handleToggle} style={{ height: "25px", width: "60px" }} />
                                            </div>
                                        </div>
                                    </div>
                                    {voucherType === 1 && (
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                            <div className="form-group withIcon">
                                                <label>Total Redemption</label>
                                                <TextValidator
                                                    className="form-control"
                                                    name="totalRedemption"
                                                    value={totalRedemption}
                                                    validators={["required"]}
                                                    errorMessages={["Please enter total redemption"]}
                                                    onChange={(e) =>
                                                        setState({
                                                            ...state,
                                                            totalRedemption: Number(parseNumeric(e.target.value)),
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div> */}

                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <div className="form-group">
                                                <label>
                                                    Earning Type<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <SelectValidator placeholder="Select Type" className="custom-ReactSelect bordered" name="earningType" value={selectValueGetter(options, earningType) || ""} options={options} validators={["required"]} errorMessages={["Please enter earning type"]} onChange={onSelectChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>
                                                Earning Value<span style={{ color: "red" }}>*</span>
                                            </label>
                                            <TextValidator
                                                className="form-control"
                                                name="earningValue"
                                                value={earningValue}
                                                validators={["required"]}
                                                errorMessages={["Please enter earning value"]}
                                                maxLength="7"
                                                onChange={(e) =>
                                                    setState({
                                                        ...state,
                                                        earningValue: parseNumeric(e.target.value),
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>Description of the Voucher Template</label>
                                            <TextAreaValidator className="form-control" maxLength="500" name="description" value={description} onChange={(e) => onChange(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row voucher_box_row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="person" style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                            <p className="voucher" style={{ display: "flex", width: "100%", marginBottom: "0", color: "black" }}>
                                                Status
                                            </p>
                                            {/* <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id="toggleSwitch" name="isActive" checked={isActive} onChange={handleToggle} style={{ height: "25px", width: "60px" }} />
                                            </div> */}

                                            <div className="">
                                                <label className="toggle">
                                                    <input type="checkbox" name="isActive" checked={isActive} onChange={handleToggle} />
                                                    <span className="slider"></span>
                                                    <span className="labels" data-on="Active" data-off="Inactive"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>Redeem URL</label>
                                            <TextValidator className="form-control" name="redeemUrl" maxLength="500" value={redeemUrl} onChange={(e) => onChange(e)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>Terms And Conditions</label>
                                            <TextValidator className="form-control" name="termsAndCondition" value={termsAndCondition} onChange={(e) => onChange(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        {responseImages ? (
                                            <div className="form-group withIcon">
                                                <label>
                                                    Upload Image<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input className="form-control" type="file" ref={imageInputRefBanner} accept={"image/png, image/gif, image/jpeg, image/jpg"} onChange={handleFileBanner} />
                                                {bannerSize && <span style={{ color: "red" }}>file size not more than 1 MB</span>}
                                            </div>
                                        ) : (
                                            <div className="form-group withIcon">
                                                <label>
                                                    Upload Image<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input className="form-control" type="file" ref={imageInputRefBanner} accept={"image/png, image/gif, image/jpeg, image/jpg"} onChange={handleFileBanner} />
                                                {bannerSize && <span style={{ color: "red" }}>file size not more than 1 MB</span>}
                                            </div>
                                        )}

                                        {/* {responseImages ? (
                                            <img src={responseImages.imageUrl} />
                                        ) : null} */}
                                    </div>
                                    {responseImages ? (
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 v_imagemainbox">
                                            <div className="v_imagebox">
                                                <img className="small" src={responseImages} style={{ cursor: "pointer" }} onClick={handleShowDialog} width={50} />
                                                <Link onClick={() => handleDelete(code)}>
                                                    {" "}
                                                    <i className="fa fa-times-circle"></i>
                                                </Link>
                                            </div>

                                            {isOpen && (
                                                <dialog className="dialog" open onClick={handleShowDialog}>
                                                    <button>X</button>
                                                    <img className="image" src={responseImages} onClick={handleShowDialog} />
                                                </dialog>
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="row">
                                    <div className="col-sm-12  col-md-12 col-lg-12 col-xl-12">
                                        <div className="button-section">
                                            <button type="submit" className="btn btn-primary">
                                                Submit
                                            </button>
                                            <button type="button" className="btn btn-danger" onClick={handleReset}>
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </ValidatorForm>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Voucher;
