import React, { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import { getDate } from "../utils/helper";
// import { getCampaigns } from "./campaigns/services";
import { getSampleCampaigns, clearCampaigns } from "../actions/campaigns";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import introJs from "intro.js";
import "intro.js/introjs.css";
import ModalCard from "../components/layout/ModalCard";
import { useSelector, useDispatch } from "react-redux";
import Page from "../components/pages/Pagination";
import ProgramsCard from "../components/partial/ProgramsCard";
import request from "../components/pages/campaigns/reqbody.json";
import sampleBody from "./sampleBody.json";
import { useNavigate } from "react-router-dom";
const SampleCampaign = ({ getSampleCampaigns, clearCampaigns, Campaigns, createsampledata, clearcreatesample }) => {
    const location = useLocation();
    const [data, setData] = useState();
    const [tagData, setTagData] = useState();
    // eslint-disable-next-line
    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [hasNextPage, setHasNextPage] = useState(false);
    const [showmodal, setShowModal] = useState(false);
    // eslint-disable-next-line
    const [hasPreviousPage, setHasPreviousPage] = useState(false);
    // eslint-disable-next-line
    const [total, setTotal] = useState(0);
    const [loader, setLoader] = useState(false);
    const [count, setCount] = useState(0);
    const pageSize = 6;
    const [showButton, setShowButton] = useState(false);
    const pageCount = Math.ceil(total / pageSize);
    const intro = introJs();
    const modalShow = useSelector((state) => state.intro.modalOpen);
    let exitOnLast = false;
    useEffect(() => {
        campaign();
        // eslint-disable-next-line
    }, [tagData]);
    useEffect(() => {
        if (Campaigns?.data.length <= 0 && count < 1) {
            setCount(count + 1);
        }
    }, [Campaigns?.data]); // eslint-disable-line

    useEffect(() => {
        fetch();
        return () => {
            intro.exit();
        };
    }, []);

    useEffect(() => {
        taggedSCamp();
    }, [Campaigns?.data]); // eslint-disable-line

    const fetch = async () => {
        setLoader(true);
        await clearCampaigns();
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const id = localStorage.getItem("Id");
        let industry = localStorage.getItem("schemaName");
        setLoader(false);
        // clearCampaigns();
    };

    const taggedSCamp = () => {
        let tempTagData = [];
        let temp = location?.state?.length ? location?.state : location?.state?.tags;
        Campaigns.data.forEach((item) => {
            temp?.length &&
                temp?.forEach((tag) => {
                    item?.internalTags?.forEach((e) => {
                        if (tag === e) {
                            tempTagData.push(item);
                        }
                    });
                });
        });
        if (tempTagData?.length == 0 && temp?.length == undefined) {
            setTagData(Campaigns?.data);
        } else {
            setTagData(tempTagData);
        }
    };

    const campaign = async () => {
        setHasNextPage(tagData?.length >= pageSize);
        setHasPreviousPage(currentPage > 0);
        setTotal(tagData?.length);
        if (tagData?.length > 0) {
            setShowButton(true);
        } else {
            setShowButton(false);
            // setData([]);
        }
    };
    let complete = false;
    useEffect(() => {
        setData(sampleBody);

        const handleLoad = () => {
            const steps = [
                {
                    element: document.getElementById("edit_cmp"),
                    title: "View Campaign",
                    intro: "View existing campaigns to align them with your business goals. ",
                },
                {
                    element: document.getElementById("create_cmp"),
                    title: "Create Campaign  ",
                    intro: "Design brand-new loyalty campaigns using various earning rules. ",
                },
            ];
            intro.setOptions({
                steps: steps,
                exitOnOverlayClick: false,
                doneLabel: "Next",
                tooltipClass: "customTooltip",
            });
            intro.start();
        };
        setTimeout(() => {
            handleLoad();
        }, 500);
        return () => {
            intro.exit();
        };
    }, []);
    intro.oncomplete(function (element) {
        complete = true;
        setTimeout(() => {
            if (!exitOnLast) navigate("/intro/my-campaigns");
        }, 700);
    });
    intro.onexit(() => {
        if (!complete) {
            localStorage.setItem("firstLogin", "");
            setShowModal(true);
        }
    });
    // const updateSampleCmp = async () => {
    //     setHasNextPage(Campaigns?.data.length >= pageSize);
    //     setHasPreviousPage(currentPage > 0);
    //     setTotal(Campaigns?.data.length);
    //     if (Campaigns?.data.length > 0) {
    //         setData(Campaigns?.data.slice(-5).reverse());
    //     } else {
    //         setData([]);
    //     }
    // };

    // const pageCount = Math.ceil(total / pageSize);

    intro.onchange(function (element) {
        switch (this._currentStep) {
            case 0:
                document.getElementById('edit_cmp')?.classList.add('zoom_in')
                break;
            case 1:
                setTimeout(() => {
                    let cross = document.getElementsByClassName("introjs-skipbutton")[0];
                    cross?.addEventListener("click", function () {
                        exitOnLast = true;
                        localStorage.setItem("firstLogin", "");
                        setShowModal(true);
                    });
                }, 600);
                document.getElementById('create_cmp')?.classList.add('zoom_in')
                break;
        }
    });
    return (
        <>
            {/* <LogoutTimer/> */}
            <Header name={"Sample Campaigns"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            {showmodal && <ModalCard show={showmodal}/>}
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="dashboard__heading">
                                <div className="dashboard__headingLeft">
                                    <div className="dashboard__headingContent">
                                        <h3 id="sample_comp">Sample Campaigns</h3>
                                        <p>Set how your customers will earn points</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {data?.length > 0 ? (
                            data.map((item, index) => (
                                <div className="col-sm-12 col-md-6 col-lg-4 sc_boxed" key={item.code + index}>
                                    <ProgramsCard introSampleCamp={"introSampleCamp"} id={item.code} data={item} index={currentPage * 6 + index} heading={item.name} startDate={getDate(item.startDateTime, "MM-DD-YYYY")} endDate={getDate(item.endDateTime, "MM-DD-YYYY")} text={item?.description} campaignCode={item.code} sample={true} tags={location.state} firstData={index === 0 ? true : false} />
                                </div>
                            ))
                        ) : (
                            <h4>There is no sample campaign to show</h4>
                        )}
                        {showButton && (
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="dashboard__paginationBox">
                                    <Page currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} hasNextPage={hasNextPage} hasPreviousPage={hasPreviousPage} value={5} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    Campaigns: state.Campaigns,
});
const mapDispatchToProps = {
    getSampleCampaigns,
    clearCampaigns,
};

export default connect(mapStateToProps, mapDispatchToProps)(SampleCampaign);
