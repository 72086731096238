import React, { useEffect } from "react";
import { novus_logo_white } from "../../Image/Index";
import $ from "jquery";

const TermAndCondition = () => {
    useEffect(() => {
        if ($(window).width() >= 300) {
            $(window).scroll(function () {
                var scroll = $(window).scrollTop();

                if (scroll >= 10) {
                    $(".navbar").addClass("sticky");
                } else {
                    $(".navbar").removeClass("sticky");
                }
            });
        }

        $(".platform").click(function () {
            $(".platformlist").slideToggle("slow");
        });
        $(".programtypes").click(function () {
            $(".programtypeslist").slideToggle("slow");
        });
        $(".footersolution").click(function () {
            $(".footersolutionlist").slideToggle("slow");
        });
        $(".resources").click(function () {
            $(".resourceslist").slideToggle("slow");
        });
        $(".overview").click(function () {
            $(".overviewlist").slideToggle("slow");
        });

        function disableScroll() {
            window.addEventListener("DOMMouseScroll", preventDefault, false);
            window.onwheel = preventDefault;
            window.onmousewheel = document.onmousewheel = preventDefault;
            window.ontouchmove = preventDefault;
            document.onkeydown = preventDefaultForScrollKeys;
        }

        function enableScroll() {
            window.removeEventListener && window.removeEventListener("DOMMouseScroll", preventDefault, false);
            window.onmousewheel = document.onmousewheel = null;
            window.onwheel = null;
            window.ontouchmove = null;
            document.onkeydown = null;
        }

        function preventDefault(a) {
            a = a || window.event;
            a.preventDefault && a.preventDefault();
            a.returnValue = false;
        }

        function preventDefaultForScrollKeys(a) {
            var keys = {
                37: true, // left arrow key
                38: true, // up arrow key
                39: true, // right arrow key
                40: true, // down arrow key
            };
            if (keys[a.keyCode]) {
                return preventDefault(a);
            } else {
                return;
            }
        }

        function getScrollBarWidth() {
            var c = document.createElement("p");
            c.style.width = "100%";
            c.style.height = "200px";
            var d = document.createElement("div");
            d.style.position = "absolute";
            d.style.top = "0px";
            d.style.left = "0px";
            d.style.visibility = "hidden";
            d.style.width = "200px";
            d.style.height = "150px";
            d.style.overflow = "hidden";
            d.appendChild(c);
            document.body.appendChild(d);
            var b = c.offsetWidth;
            d.style.overflow = "scroll";
            var a = c.offsetWidth;

            if (b == a) {
                a = d.clientWidth;
            }

            document.body.removeChild(d);
            return b - a;
        }

        $(".menuBtn a").click(function (a) {
            a.preventDefault();
            $(this).toggleClass("is-active");

            if ($(this).hasClass("is-active")) {
                disableScroll();
                $(".menuWrapper").addClass("showMenu");
                $("body").addClass("nav-active");
                $("body, #header").addClass("lockScroll").css("marginRight", getScrollBarWidth());
            } else {
                enableScroll();
                $(".menuWrapper").removeClass("showMenu");
                $("body").removeClass("nav-active");
                $("body, #header").removeClass("lockScroll").css("marginRight", 0);
            }
        });
        $(".closeMenu").click(function (a) {
            a.preventDefault();
            $(".menuWrapper").removeClass("showMenu");
            $("body, #header").removeClass("lockScroll").css("marginRight", 0);
        });

        if ($(window).width() < 768) {
            $(".toggle-menu").click(function () {
                $(this).next(".dropdown").slideToggle("fast");
                $(".dropdown").not($(this).next()).slideUp("fast");
                return false;
            });
        }
    }, []);

    return (
        <>
            <nav className="navbar" role="navigation" aria-label="main navigation">
                <div className="container">
                    <div className="navbar-brand">
                        <a className="navbar-item" href="https://www.novusloyalty.com/">
                            <span className="is-sr-only">
                                <div className="nl_logo nl_logo_white"></div>
                            </span>
                        </a>
                        <a href="#" role="button" className="navbar-burger burger is-transparent" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </a>
                    </div>

                    <div className="navbar-menu">
                        <div className="navbar-end navigation_box navigation_box_home">
                            <ul>
                                <li>
                                    <a className="navbar-item" href="#">
                                        Platform <i className="arrow down"></i>
                                    </a>
                                    <ul className="customDropDown">
                                        <li>
                                            <a href="https://www.novusloyalty.com/customer-loyalty-program-engine">
                                                Loyalty Engine <i className="arrow right"></i>
                                            </a>
                                            <ul className="customDropDownSub">
                                                <li>
                                                    <a href="https://www.novusloyalty.com/customer-loyalty-campaign-management">Campaign Management</a>
                                                </li>
                                                <li>
                                                    <a href="https://www.novusloyalty.com/loyalty-program-software-rule-engine">Rule Engine</a>
                                                </li>
                                                <li>
                                                    <a href="https://www.novusloyalty.com/loyalty-program-reporting">BI Reporting</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="https://www.novusloyalty.com/loyalty-rewards-marketplace">Rewards Marketplace</a>
                                        </li>
                                        <li>
                                            <a href="https://www.novusloyalty.com/loyalty-program-gamification-rewards">Gamification</a>
                                        </li>
                                        <li>
                                            <a href="https://www.novusloyalty.com/loyalty-program-offers-management">Offers Management</a>
                                        </li>
                                        <li>
                                            <a href="https://www.novusloyalty.com/loyalty-program-giftcard-management">Gift Card Management</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a className="navbar-item" href="https://www.novusloyalty.com/loyalty-rewards-program">
                                        Program Types <i className="arrow down"></i>
                                    </a>
                                    <ul className="customDropDown">
                                        <li>
                                            <a href="https://www.novusloyalty.com/loyalty-points-program">Points</a>
                                        </li>
                                        <li>
                                            <a href="https://www.novusloyalty.com/tiered-loyalty-programs">Tiers</a>
                                        </li>
                                        <li>
                                            <a href="https://www.novusloyalty.com/digital-wallet-loyalty-solution">Digital Wallets</a>
                                        </li>
                                        <li>
                                            <a href="https://www.novusloyalty.com/cashback-loyalty-rewards-program">Cashback &amp; Discounts</a>
                                        </li>
                                        <li>
                                            <a href="https://www.novusloyalty.com/referral-loyalty-programs">Referrals</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a className="navbar-item" href="https://www.novusloyalty.com/loyalty-program-software-solution">
                                        Solution
                                    </a>
                                </li>
                                <li>
                                    <a className="navbar-item" href="https://www.novusloyalty.com/loyalty-program-pricing">
                                        Pricing
                                    </a>
                                </li>
                                <li>
                                    <a className="navbar-item" href="#">
                                        Resources <i className="arrow down"></i>
                                    </a>
                                    <ul className="customDropDown">
                                        <li>
                                            <a href="https://www.novusloyalty.com/clients-testimonials">Clients</a>
                                        </li>
                                        <li>
                                            <a href="https://www.novusloyalty.com/case-studies">Case Studies</a>
                                        </li>
                                        <li>
                                            <a href="https://www.novusloyalty.com/loyalty-blogs">Blogs</a>
                                        </li>
                                        <li>
                                            <a href="https://www.novusloyalty.com/loyalty-solution-partner-program">Partners</a>
                                        </li>
                                        <li>
                                            <a href="https://www.novusloyalty.com/loyalty-partner-integrations">Integrations</a>
                                        </li>
                                        <li>
                                            <a href="https://www.novusloyalty.com/business-loyalty-value-calculator">Loyalty Value Calculator</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a className="navbar-item bookdemo" href="https://www.novusloyalty.com/start-a-free-trial#starter">
                                        Start a Free Trial
                                    </a>
                                </li>
                                <div className="bookdemomobbox">
                                    <ul>
                                        <li>
                                            <a className="navbar-item bookdemo" href="https://www.novusloyalty.com/start-a-free-trial#starter">
                                                Free Trial
                                            </a>
                                        </li>
                                        <li className="noPadding tablet menuBtn new_btn">
                                            <a href="javascript:void(0);" className="noAfter">
                                                <span></span>
                                                <span className="cross"></span>
                                                <span></span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <div className="menuWrapper">
                <div className="menuInner">
                    <div className="circle"></div>
                    <div className="navContainer">
                        <ul>
                            <li className="hasDropDown">
                                <a href="javascript:void(0)" className="toggle-menu">
                                    Platform
                                </a>
                                <ul className="dropdown">
                                    <li>
                                        <a href="https://www.novusloyalty.com/customer-loyalty-program-engine">Loyalty Engine</a>
                                    </li>
                                    <li>
                                        <a href="https://www.novusloyalty.com/customer-loyalty-campaign-management">Campaign Management</a>
                                    </li>
                                    <li>
                                        <a href="https://www.novusloyalty.com/loyalty-program-software-rule-engine">Rule Engine</a>
                                    </li>
                                    <li>
                                        <a href="https://www.novusloyalty.com/loyalty-program-offers-management">Offers Management</a>
                                    </li>
                                    <li>
                                        <a href="https://www.novusloyalty.com/loyalty-rewards-marketplace">Rewards Marketplace</a>
                                    </li>
                                    <li>
                                        <a href="https://www.novusloyalty.com/loyalty-program-reporting">BI Reporting</a>
                                    </li>
                                    <li>
                                        <a href="https://www.novusloyalty.com/loyalty-program-gamification-rewards">Gamification</a>
                                    </li>
                                    <li>
                                        <a href="https://www.novusloyalty.com/loyalty-program-giftcard-management">Gift Card Management</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="hasDropDown">
                                <a href="javascript:void(0)" className="toggle-menu">
                                    Program Types
                                </a>
                                <ul className="dropdown">
                                    <li>
                                        <a href="https://www.novusloyalty.com/loyalty-points-program">Points</a>
                                    </li>
                                    <li>
                                        <a href="https://www.novusloyalty.com/tiered-loyalty-programs">Tiers</a>
                                    </li>
                                    <li>
                                        <a href="https://www.novusloyalty.com/digital-wallet-loyalty-solution">Digital Wallets</a>
                                    </li>
                                    <li>
                                        <a href="https://www.novusloyalty.com/cashback-loyalty-rewards-program">Cashback &amp; Discounts</a>
                                    </li>
                                    <li>
                                        <a href="https://www.novusloyalty.com/referral-loyalty-programs">Referrals</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="hasDropDown">
                                <a href="javascript:void(0)" className="toggle-menu">
                                    Solution
                                </a>
                                <ul className="dropdown">
                                    <li>
                                        <a href="https://www.novusloyalty.com/loyalty-program-software-solution#retail">Retail Loyalty</a>
                                    </li>
                                    <li>
                                        <a href="https://www.novusloyalty.com/loyalty-program-software-solution#banking">BFSI Loyalty</a>
                                    </li>
                                    <li>
                                        <a href="https://www.novusloyalty.com/loyalty-program-software-solution#employee">Employee Loyalty</a>
                                    </li>
                                    <li>
                                        <a href="https://www.novusloyalty.com/loyalty-program-software-solution#travel">Coalition Loyalty</a>
                                    </li>
                                    <li>
                                        <a href="https://www.novusloyalty.com/loyalty-program-software-solution#travel">Travel Loyalty</a>
                                    </li>
                                    <li>
                                        <a href="https://www.novusloyalty.com/loyalty-program-software-solution#FB">Food & Beverages</a>
                                    </li>
                                    <li>
                                        <a href="https://www.novusloyalty.com/loyalty-program-software-solution#ecommerce">Ecommerce</a>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <a className="navbar-item" href="https://www.novusloyalty.com/loyalty-program-pricing">
                                    Pricing
                                </a>
                            </li>

                            <li className="hasDropDown">
                                <a href="javascript:void(0)" className="toggle-menu">
                                    Resources
                                </a>
                                <ul className="dropdown adjustUpSide">
                                    <li>
                                        <a href="https://www.novusloyalty.com/clients-testimonials">Clients</a>
                                    </li>
                                    <li>
                                        <a href="https://www.novusloyalty.com/case-studies">Case Studies</a>
                                    </li>
                                    <li>
                                        <a href="https://www.novusloyalty.com/loyalty-blogs">Blogs</a>
                                    </li>
                                    <li>
                                        <a href="https://www.novusloyalty.com/loyalty-solution-partner-program">Partners</a>
                                    </li>
                                    <li>
                                        <a href="https://www.novusloyalty.com/loyalty-partner-integrations">Integrations</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="https://www.novusloyalty.com/contact-us">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="tncmainbox">
                <div className="container-fluid nvsbanner">
                    <div className="container nvs_banner_container">
                        <div className="row">
                            <div className="col">
                                <h1>Terms &amp; Conditions</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid smallspacing ttbg">
                    <div className="container">
                        <div className="wid900">
                            <p>The terms and conditions explain the nature and scope of the relationship between Clavax Technologies Private Limited here in referred to as "CTPL” or "Novus Loyalty" (as owner and management for the program) and a member of CTPL’s loyalty program and who is issued Novus Loyalty membership. The terms and conditions cover limitations and exclusions on the liability of CTPL or Novus Loyalty and Novus Loyalty Partners.</p>
                        </div>
                    </div>
                </div>

                <div className="container-fluid smallspacing ppbg">
                    <div className="container">
                        <div className="wid900 tncbox">
                            <ol className="termsAndCondition__list">
                                <li>
                                    <span></span>
                                    <h3>Definitions</h3>
                                    <h5>In these terms and conditions, unless the context specifies otherwise:</h5>
                                    <ol>
                                        <li>"CTPL" shall mean Clavax Technologies Private Limited a company incorporated under the Indian Companies Act, 1956 and having its principal place of business at 727, Udyog Vihar Phase V, Gurgaon, Haryana, India - 122016 and such expression shall, unless it be repugnant to the subject or context thereof, include its successors, assigns, representatives and transferees.</li>
                                        <li>"NOVUS LOYALTY Membership Account/ NOVUS LOYALTY Membership” means a unique account created for every NOVUS LOYALTY member during his (online / offline) enrolment into the NOVUS LOYALTY program. NOVUS LOYALTY Membership account is where NOVUS LOYALTY Points will be accumulated for accrual and / or redemption.</li>
                                        <li>"NOVUS LOYALTY Member(s)” means a NOVUS LOYALTY customer, who is enrolled as a member of NOVUS LOYALTY holding a valid NOVUS LOYALTY card/membership number. Membership to the program is governed by these rules set by CTPL for this program which are subject to change.</li>
                                        <li> "NOVUS LOYALTY Card” means a NOVUS LOYALTY multi partner loyalty program card issued to a NOVUS LOYALTY Member by CTPL where a unique membership number is given to every NOVUS LOYALTY member.</li>
                                        <li> “NOVUS LOYALTY Number” means a 10-digit mobile number provided by NOVUS LOYALTY member during the time of enrolment or afterwards for registration or linking of his NOVUS LOYALTY membership account for accrual and / or redemption of NOVUS LOYALTY points.</li>
                                        <li> "NOVUS LOYALTY Point(s)" is the unit currency of the program. NOVUS LOYALTY Members are awarded NOVUS LOYALTY Points for earn activities carried out by the NOVUS LOYALTY Member within the NOVUS LOYALTY network.</li>
                                        <li> "NOVUS LOYALTY Network" means all the NOVUS LOYALTY Partners.</li>
                                        <li> "NOVUS LOYALTY Partner" means any or all the present or future entities, partners, shops, departmental stores, airlines, hotels, restaurants, car rental companies and other participating establishments in the program whether with online or offline presence, in agreement with CTPL, where members can accumulate NOVUS LOYALTY points for purchase of goods and services.</li>
                                        <li>"NOVUS LOYALTY Rewards" means the rewards as enlisted by the rewards supplier in the NOVUS LOYALTY Rewards Catalog as communicated from time to time.</li>
                                        <li> "NOVUS LOYALTY Rewards Catalog" means the periodical rewards catalog issued and maintained by CTPL or by its vendors for the program, containing the NOVUS LOYALTY Rewards currently available in the program and the number of NOVUS LOYALTY Points required for them.</li>
                                        <li> "Program” means the NOVUS LOYALTY multi partner loyalty program, managed and operated by CTPL offering benefits, facilities or arrangements to NOVUS LOYALTY Members by reason of their membership into NOVUS LOYALTY program, whereby NOVUS LOYALTY points are accumulated on every Earn Activity made by NOVUS LOYALTY Members within NOVUS LOYALTY network and such NOVUS LOYALTY points may be used by the NOVUS LOYALTY Member for redemption of NOVUS LOYALTY Points through the NOVUS LOYALTY Rewards Catalog and Redemption at NOVUS LOYALTY Partners.</li>
                                        <li> "Redemption" means any activity, which results in exchange of NOVUS LOYALTY Points by a NOVUS LOYALTY Member for NOVUS LOYALTY Rewards at NOVUS LOYALTY Rewards Catalog or at NOVUS LOYALTY Partner</li>
                                        <li>“Promotional Activities” shall include, but will not be limited to the promotion, and / or marketing of the loyalty program, loyalty points, loyalty Benefits, through television, press magazine, direct mailers, direct solicitation, take-ones, displays, hoardings, web-based activities, mobile device solutions, and / or any other means that may be made available to promote, but will not limited to NOVUS LOYALTY, its Partners, its Promotion and Media Partners, its Vendors.</li>
                                        <li>"Supplier" shall mean an independent third party responsible for the purchase and sale of the NOVUS LOYALTY Rewards to the NOVUS LOYALTY Members.</li>
                                        <li>"Working Days” means the period from Monday to Friday.</li>
                                        <li>“Password / PIN” means a unique code created by NOVUS LOYALTY member to log into his NOVUS LOYALTY account or to authenticate his transaction during redemption of NOVUS LOYALTY points.</li>
                                    </ol>
                                </li>
                                <li>
                                    <h3>General Conditions</h3>
                                    <ol>
                                        <li>
                                            The NOVUS LOYALTY Member agrees that by using the NOVUS LOYALTY Card or by entering his/her NOVUS LOYALTY number at{" "}
                                            <a href="https://www.novusloyalty.com" target="_blank">
                                                {" "}
                                                www.NovusLoyalty.com{" "}
                                            </a>{" "}
                                            or at any NOVUS LOYALTY Partner online or offline, he/she is deemed to have read and understood the terms and conditions of the Program and agrees to be contacted by the NOVUS LOYALTY customer service team or its telemarketer for the purpose of its promotional activities (SMS, Email, Social campaigns, etc.), its insurance business and confirms that he/she is bound by these terms and conditions and any changes to it from time to time and such other terms as specified from CTPL from time to time.{" "}
                                        </li>
                                        <li>Membership into NOVUS LOYALTY Program is voluntary. </li>
                                        <li>
                                            NOVUS LOYALTY reserves the right to modify these Terms and Conditions and such changes shall be deemed effective immediately upon posting of the modified Terms and Conditions on&nbsp;
                                            <a href="https://www.novusloyalty.com" target="_blank">
                                                {" "}
                                                www.NovusLoyalty.com{" "}
                                            </a>
                                            . Members’ continued holding of the NOVUS LOYALTY Membership Account following the posting of changes on{" "}
                                            <a href="https://www.novusloyalty.com" target="_blank">
                                                {" "}
                                                www.NovusLoyalty.com{" "}
                                            </a>{" "}
                                            will indicate their acceptance of changes.{" "}
                                        </li>
                                        <li>NOVUS LOYALTY membership is valid for lifetime of the NOVUS LOYALTY Member unless terminated in accordance with these terms and conditions. </li>
                                        <li>NOVUS LOYALTY Membership Account is not a payment medium or payment instrument. </li>
                                        <li>NOVUS LOYALTY Membership is non-transferable, except as specified in this terms and conditions. NOVUS LOYALTY Points are non-negotiable, nonredeemable by cash and cannot be gifted, awarded or rewarded to any other person than the NOVUS LOYALTY Member himself. </li>
                                        <li>NOVUS LOYALTY Members will be personally liable for any and all costs, taxes, charges, claims or liabilities of whatever nature arising from the provision or availability of benefits, facilities or arrangements provided or made available to a NOVUS LOYALTY Member, by NOVUS LOYALTY Partners, as a result of his/her membership in the Program. </li>
                                        <li>The NOVUS LOYALTY membership account at all times remains the property of CTPL, which reserves the right at any time in its absolute discretion and without giving notice to such member to deactivate the NOVUS LOYALTY Membership. The NOVUS LOYALTY Member must return or destroy his/her NOVUS LOYALTY card (physical / virtual) without delay whenever requested by CTPL and in such circumstances, cannot redeem the NOVUS LOYALTY Points, which will automatically expire. </li>
                                        <li>CTPL will endeavor to ensure that the services, benefits, facilities and arrangements as expressed or advertised by the NOVUS LOYALTY Partners will be available to the NOVUS LOYALTY Members. However, CTPL will not be liable for any loss or damage, whether direct or indirect, arising from the provision or non-provision whether whole or part, of any such services, benefits, facilities or any other arrangements by the NOVUS LOYALTY Partners. </li>
                                        <li> When a NOVUS LOYALTY Member seeks to use or obtain any of the services, benefits, facilities and arrangements as offered by the NOVUS LOYALTY Partner, the provisions of such services, benefits, facilities or arrangements will be subject to the respective terms and conditions of the NOVUS LOYALTY Partner, being the provider of the said benefits, facilities and arrangements. </li>
                                        <li> CTPL shall not be liable for any loss or damage, whether direct or indirect, resulting from termination or change of the Program or any of its facilities, benefits or arrangements which are made available to the NOVUS LOYALTY Members, including without limitation, NOVUS LOYALTY Partner's withdrawal or the withdrawal or limiting of any such services, benefits or facilities. </li>
                                        <li> The Program of NOVUS LOYALTY may be modified at the sole discretion of CTPL from time to time without intimation to the NOVUS LOYALTY Member. </li>
                                        <li> CTPL shall not be liable in the event it fails to fulfill any of its obligations under this terms and conditions due to flood, earthquake, storm, cyclone or any other acts of god of similar nature, war, strike, lockout, or governmental or judicial or quasi-judicial policies/intervention/direction /prohibition or change in law or for any other reasons beyond its control </li>
                                        <li> A waiver of any of the terms and conditions by CTPL shall not be deemed to be a continuing waiver but shall apply solely to the instances to which the waiver is directed. </li>
                                        <li>
                                            {" "}
                                            A NOVUS LOYALTY Card shall be accepted only at locations identified in the list provided to the NOVUS LOYALTY Members at{" "}
                                            <a href="https://www.novusloyalty.com" target="_blank">
                                                {" "}
                                                www.NovusLoyalty.com{" "}
                                            </a>
                                            . CTPL can at any time without intimation to the NOVUS LOYALTY Members add and / or delete NOVUS LOYALTY Partners from such list. The same shall be updated on{" "}
                                            <a href="https://www.novusloyalty.com" target="_blank">
                                                {" "}
                                                www.NovusLoyalty.com{" "}
                                            </a>{" "}
                                        </li>
                                        <li> If there are any change in the contact details (mobile number/ email id/ address) of a NOVUS LOYALTY member from what has been registered with NOVUS LOYALTY, the member must update the respective contact details with NOVUS LOYALTY immediately either through the NOVUS LOYALTY website, Mobile application, contact center or any other channels to avoid any misuse of NOVUS LOYALTY Points. CTPL shall not be liable for any loss of points or other relevant damages incurred by the member if necessary details are not updated with NOVUS LOYALTY on time. </li>
                                        <li> All NOVUS LOYALTY Point dispute complaints older than 30 Working Days from the date of the transaction will not be entertained. The NOVUS LOYALTY Member must provide proof of transaction as required to register any NOVUS LOYALTY Point dispute. </li>
                                        <li> The NOVUS LOYALTY Member acknowledges and understands that NOVUS LOYALTY is merely an intermediary and does not assume any liability or acquire any right, title or interest in the Rewards. NOVUS LOYALTY is only engaged in the management of the Rewards displayed in the NOVUS LOYALTY Rewards Catalog; however, all the Rewards are offered by the Vendors and Partners to the Members directly </li>
                                    </ol>
                                </li>
                                <li>
                                    <h3>Enrolment in the Program</h3>
                                    <ol>
                                        <li>You represent that you are at least 18 years of age, You represent and warrant that You have the right, authority and capacity to enter into this Agreement and to abide by all the T&amp;Cs as part of this Agreement. Finally, you shall not impersonate any person or entity, or falsely state or otherwise misrepresent Your identity, age or affiliation with any person or entity. You are responsible to share correct communication details with NOVUS LOYALTY at the time of enrolling into the Program. </li>
                                        <li>Prospective NOVUS LOYALTY Members can enroll into the Program at selected NOVUS LOYALTY Partner outlets, using Partner platforms (online / offline). NOVUS LOYALTY Partners may also enroll prospective NOVUS LOYALTY Members to the Program. Enrolment of prospective members into the NOVUS LOYALTY program can be done only via NOVUS LOYALTY Partners. </li>
                                        <li>NOVUS LOYALTY shall reserve the right to issue the NOVUS LOYALTY Membership Account only to those customers, including customers of Partner, who fulfil NOVUS LOYALTY’s eligibility criteria for issuance of the NOVUS LOYALTY membership Account and fulfil / comply with the requirements as may be specified by NOVUS LOYALTY. NOVUS LOYALTY shall be under no obligation to issue a NOVUS LOYALTY Membership Account to Partner’s customers who do not comply with the agreed eligibility criterion. </li>
                                        <li>Each NOVUS LOYALTY Member can have a maximum of one NOVUS LOYALTY Card at any point in time, unless otherwise specified. If a NOVUS LOYALTY Member is found to have multiple memberships in the Program, NOVUS LOYALTY may choose to restrict any of the NOVUS LOYALTY Membership. </li>
                                        <li>If a NOVUS LOYALTY Member holds more than one NOVUS LOYALTY Card, it is advisable that the NOVUS LOYALTY Member calls ‘NOVUS LOYALTY Contact Center’ requesting an account aggregation. NOVUS LOYALTY on such request shall cancel that card(s) which the NOVUS LOYALTY Member does not want to retain and transfer the NOVUS LOYALTY Points, earned if any, into a single NOVUS LOYALTY Membership Account </li>
                                    </ol>
                                </li>
                                <li>
                                    <h3>NOVUS LOYALTY Communication</h3>
                                    <ol>
                                        <li> It is the responsibility of the NOVUS LOYALTY member to provide accurate communication information like Name, mobile number, Email ID, Address, etc. to NOVUS LOYALTY. The member can contact the NOVUS LOYALTY call center or log into NOVUS LOYALTY website to request / update the change of their contact information. </li>
                                        <li> NOVUS LOYALTY may send alerts or promotional messages to the registered mobile phone number or email ID provided by You while registering with NOVUS LOYALTY or on any contact information subsequently provided by You to NOVUS LOYALTY, or via e-mail or social campaign or push notifications.&nbsp; Communications will not be limited to transactional message or promotional messages by NOVUS LOYALTY Partners only. </li>
                                        <li> NOVUS LOYALTY will make best efforts to provide alerts via SMS/E-mail/Telemarketing/Push notifications/Social Media and it shall be deemed that You shall have received the information sent from NOVUS LOYALTY as an alert on the registered mobile phone number or e-mail id provided by You. NOVUS LOYALTY shall not be under any obligation to confirm the authenticity of the person(s) receiving the alert. You cannot hold NOVUS LOYALTY liable for non-availability of the SMS/email alert/push notifications service in any manner whatsoever. </li>
                                        <li> The communication provided by NOVUS LOYALTY is an additional facility provided for your convenience and that it may be susceptible to error, omission and/or inaccuracy. In the event that You observe any error in the information provided, NOVUS LOYALTY shall be immediately informed about the same by You and NOVUS LOYALTY will make best possible efforts to rectify the error as early as possible. You shall not hold NOVUS LOYALTY liable for any loss, damages, claim, expense including legal cost that may be incurred/suffered by You. </li>
                                        <li> You will indemnify and hold harmless NOVUS LOYALTY and the SMS/e-mail/telemarketing service provider including its officials from any damages, claims, demands, proceedings, losses, costs, charges and expenses whatsoever including legal charges and attorney’s fees which NOVUS LOYALTY or the SMS/e-mail service provider may at any time incur, sustain, suffer or be subjected to as a consequence of, or arising out of, any of the following: (i) misuse by You or improper or fraudulent information provided by You; (ii) incorrect number or a number that belongs to an unrelated third party provided by You. </li>
                                    </ol>
                                </li>
                                <li>
                                    <h3>Use of NOVUS LOYALTY Card and NOVUS LOYALTY Membership</h3>
                                    <ol>
                                        <li>By using his/her NOVUS LOYALTY Card and/or stating his/her NOVUS LOYALTY number to CTPL and/or any NOVUS LOYALTY Partners, a NOVUS LOYALTY Member grants his/her consent to CTPL for creating, storing, maintaining and updating the NOVUS LOYALTY Member’s data. Such data shall include membership data (like name, address, telephone number, mobile number, email address, date of birth, areas of interest, preferences, etc. as provided by the NOVUS LOYALTY Member), usage data (including without limitation, transactions and NOVUS LOYALTY Points accrued at various NOVUS LOYALTY Partners, etc.), and data concerning contacts with CTPL (including without limitation, enquiry regarding membership) for the purpose of providing relevant information and customer services to the NOVUS LOYALTY Member in connection with the Program. All data relating to the NOVUS LOYALTY Members shall be protected and used by CTPL in the manner indicated in CTPL’s Privacy Policy.</li>
                                        <li>If any NOVUS LOYALTY Member loses the NOVUS LOYALTY card, the NOVUS LOYALTY member must inform ‘NOVUS LOYALTY Contact Center’ immediately. Till such time the loss has been reported to CTPL, CTPL shall not be liable for any losses incurred by misuse of such NOVUS LOYALTY Card. A new NOVUS LOYALTY Card will be issued to such NOVUS LOYALTY Member on request after blocking and cancellation of the old NOVUS LOYALTY Card and after proper verification by NOVUS LOYALTY. The new NOVUS LOYALTY Card will indicate a new NOVUS LOYALTY Card number; however, all other NOVUS LOYALTY Membership Account details and the NOVUS LOYALTY Points will continue to be retained as is.</li>
                                    </ol>
                                </li>
                                <li>
                                    <h3>Accrual of NOVUS LOYALTY Points</h3>
                                    <ol>
                                        <li>NOVUS LOYALTY Members can accrue NOVUS LOYALTY Points earned by them through a transaction with a NOVUS LOYALTY Partner for the purchase of goods or services paid by cash, credit/debit card, cheque, online payment, standing instruction or any other means of payment agreed upon by the NOVUS LOYALTY Partner.</li>
                                        <li>
                                            NOVUS LOYALTY Members will also be awarded NOVUS LOYALTY Points for non-transactional activities which include goodwill points as well as bonus points. Bonus points would be communicated from time to time through{" "}
                                            <a href="https://www.novusloyalty.com" target="_blank">
                                                {" "}
                                                www.NovusLoyalty.com{" "}
                                            </a>{" "}
                                            or through any other means of communication as decided by NOVUS LOYALTY from time to time.
                                        </li>
                                        <li>
                                            NOVUS LOYALTY Points awarded may vary between different NOVUS LOYALTY Partners depending on the arrangement with the NOVUS LOYALTY Partner. Details of all NOVUS LOYALTY Points will be communicated to the NOVUS LOYALTY Members through{" "}
                                            <a href="https://www.novusloyalty.com" target="_blank">
                                                {" "}
                                                www.NovusLoyalty.com{" "}
                                            </a>{" "}
                                            or through any other means of communication as decided from time to time.
                                        </li>
                                        <li>To accrue NOVUS LOYALTY Points, a NOVUS LOYALTY Member must present his/her NOVUS LOYALTY Card or NOVUS LOYALTY number at the time of completing the Earn Activity. If a NOVUS LOYALTY Member fails to present the NOVUS LOYALTY card or NOVUS LOYALTY number when visiting or on availing such services, this may result in the applicable NOVUS LOYALTY points not being accrued by the NOVUS LOYALTY Member and in such instance NOVUS LOYALTY or the NOVUS LOYALTY Partner shall not be responsible.</li>
                                        <li>
                                            The method of earning NOVUS LOYALTY Points from NOVUS LOYALTY Partners is subject to change from time to time. Such changes will be communicated through{" "}
                                            <a href="https://www.novusloyalty.com" target="_blank">
                                                {" "}
                                                www.NovusLoyalty.com{" "}
                                            </a>{" "}
                                            and through any other means of communication as decided by CTPL from time to time.
                                        </li>
                                        <li>NOVUS LOYALTY Partners may change any of the terms and conditions of their business at any time without notice to the NOVUS LOYALTY Member. NOVUS LOYALTY is not responsible for informing NOVUS LOYALTY Members of any such changes. NOVUS LOYALTY has no liability for the acts and omissions of the NOVUS LOYALTY Partners.</li>
                                        <li>All NOVUS LOYALTY Members are required to obtain and preserve for a period of 30 Working Days appropriate documentation for all transactions done with NOVUS LOYALTY Partners that earn NOVUS LOYALTY Points. It is the NOVUS LOYALTY Member's responsibility to ensure that the Electronic Data Collection generated transaction slip is accurate.</li>
                                    </ol>
                                </li>
                                <li>
                                    <h3>NOVUS LOYALTY Points calculation</h3>
                                    <ol>
                                        <li>NOVUS LOYALTY Points accrued through transactions at NOVUS LOYALTY Partners shall be credited to member's account within 30 working days. </li>
                                        <li>NOVUS LOYALTY Points accrued through any other earn activity shall be credited to NOVUS LOYALTY Member's account within such period as is mentioned for such earn activity. </li>
                                        <li>NOVUS LOYALTY Point's will not be credited back in the NOVUS LOYALTY Membership Account in case the NOVUS LOYALTY Member decides to return the goods and services, forfeited and unused services or Goods or services acquired by using NOVUS LOYALTY Points </li>
                                        <li>NOVUS LOYALTY Points are accrued in whole. NOVUS LOYALTY Members will not be awarded NOVUS LOYALTY Points in fractions. </li>
                                        <li>If NOVUS LOYALTY Members participate in Earn Activities at more than one NOVUS LOYALTY Partner, subject to the consent of the NOVUS LOYALTY partners, the NOVUS LOYALTY Members are eligible for multiple memberships in the Program and the NOVUS LOYALTY points will be credited to the respective NOVUS LOYALTY membership accounts. </li>
                                        <li>Complaints on NOVUS LOYALTY Points not credited to account from any NOVUS LOYALTY Partner will be entertained only if the date of transaction is not older than 30 working days. The NOVUS LOYALTY Member must provide proof of transaction as required by CTPL to register any NOVUS LOYALTY Point dispute. </li>
                                        <li>The respective NOVUS LOYALTY Partners are responsible for the NOVUS LOYALTY Points issued under this Program and if any of them goes into liquidation or non-payment for these points or any similar event, CTPL shall not be liable to honor NOVUS LOYALTY Points which have been credited to the NOVUS LOYALTY Membership account and are yet to be redeemed, but have not been fully funded by the NOVUS LOYALTY Partner as on that date. In such an event, such NOVUS LOYALTY Points will be removed from the NOVUS LOYALTY Membership Account without prior intimation. </li>
                                    </ol>
                                </li>
                                <li>
                                    <h3>Transfer of NOVUS LOYALTY Points </h3>
                                    <ol>
                                        <li>At no time can NOVUS LOYALTY points be credited to or NOVUS LOYALTY rewards request be issued to, purchased by, or sold to or otherwise transferred to any person other than the NOVUS LOYALTY Member. Any such NOVUS LOYALTY points or requests for NOVUS LOYALTY rewards are void. Any person who commits such act is liable for damages, litigation and related costs to NOVUS LOYALTY, which reserves the right, to terminate the NOVUS LOYALTY membership of the transferor or remove such person from the NOVUS LOYALTY Network or deactivate such transferor’s NOVUS LOYALTY Membership Account. </li>
                                    </ol>
                                </li>
                                <li>
                                    <h3> NOVUS LOYALTY Point statement, Program updates &amp; notices </h3>
                                    <ol>
                                        <li> NOVUS LOYALTY Members can call the ‘NOVUS LOYALTY Contact Center’ numbers or register on www.Novus Loyalty.com in the "My Transaction” page for obtaining a summary of their recent transaction and obtaining their NOVUS LOYALTY Point balance.</li>
                                        <li>
                                            {" "}
                                            Any notice, information, intimation, change, modification, alteration of the NOVUS LOYALTY Rewards Catalog shall be deemed to have been given to the NOVUS LOYALTY Member forthwith upon updating&nbsp;{" "}
                                            <a href="https://www.novusloyalty.com" target="_blank">
                                                {" "}
                                                www.NovusLoyalty.com{" "}
                                            </a>
                                            &nbsp;of such changes, modification, alteration or notice on.{" "}
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <h3>Redemption of NOVUS LOYALTY Points</h3>
                                    <ol>
                                        <li>NOVUS LOYALTY Points can either be redeemed at the NOVUS LOYALTY Rewards Catalog or at any of the NOVUS LOYALTY Network Partners, subject to the NOVUS LOYALTY Member providing the required credentials (i.e. NOVUS LOYALTY Member information, Password, NOVUS LOYALTY Number, etc.)</li>
                                        <li>NOVUS LOYALTY Rewards Catalogue is available at www.Novus Loyalty.com (NOVUS LOYALTY web-site) and captures the products and services which are available as NOVUS LOYALTY Rewards against NOVUS LOYALTY Points accumulated by a NOVUS LOYALTY Member. Redemption on NOVUS LOYALTY Rewards Catalogue can be done by approaching the ‘NOVUS LOYALTY Contact Center’ or through logging in at www.Novus Loyalty.com</li>
                                        <li>All products and services featured in the NOVUS LOYALTY Rewards Catalogue are subject to availability and Seller(s) warranties and conditions at the time of claiming the NOVUS LOYALTY Rewards. In case any product or service selected by a NOVUS LOYALTY Member is not available, NOVUS LOYALTY Members may proceed to choose / select from the other available products and services from the NOVUS LOYALTY Rewards Catalogue.</li>
                                        <li>On receipt of the NOVUS LOYALTY Rewards request by ‘NOVUS LOYALTY Contact Center’ or www.Novus Loyalty.com or visiting selected online and offline NOVUS LOYALTY Network Partners, the specified number of NOVUS LOYALTY Points will automatically be deducted from the accumulated NOVUS LOYALTY Points of the NOVUS LOYALTY Members account.</li>
                                        <li>All requests issued for NOVUS LOYALTY Rewards are subject to any statutory regulations, rules and restrictions, if any and the NOVUS LOYALTY Member agrees to abide by such statutory regulations, rules and restrictions.</li>
                                        <li>NOVUS LOYALTY Rewards have no monetary refund value.</li>
                                        <li>For the purpose of redemption, the NOVUS LOYALTY Member will directly enter a sale and purchase relationship with the concerned NOVUS LOYALTY Network Partner(s)/ Seller(s) of the NOVUS LOYALTY Rewards. NOVUS LOYALTY only facilitates redemption of NOVUS LOYALTY points.</li>
                                        <li>Redeemed Rewards are not exchangeable for other rewards or refundable (except as stated under clause No. 16 of these Terms &amp; Conditions).</li>
                                    </ol>
                                </li>
                                <li>
                                    <h3>Termination of NOVUS LOYALTY membership</h3>
                                    <ol>
                                        <li>A NOVUS LOYALTY Member may terminate his/her NOVUS LOYALTY membership by calling ‘NOVUS LOYALTY Contact Center’ and requesting for termination. The NOVUS LOYALTY Member shall provide the required credentials vis-a-vis customer information already provided by the NOVUS LOYALTY Member.</li>
                                        <li>Misuse of NOVUS LOYALTY card or the membership benefits may result in termination of the membership or withdrawal of benefits at the sole discretion of CTPL.</li>
                                        <li>Disqualification of a NOVUS LOYALTY Member, arising out of his/her misconduct, fraud and misuse of NOVUS LOYALTY Rewards or acts in a manner inconsistent with any central/state/local laws, statutes or ordinances, may result in immediate termination of NOVUS LOYALTY membership and cancellation of all NOVUS LOYALTY Points and NOVUS LOYALTY Rewards. Any NOVUS LOYALTY Member so disqualified shall not be entitled to redeem his NOVUS LOYALTY Points after such disqualification.</li>
                                        <li>Upon the death of a NOVUS LOYALTY Member, his/her NOVUS LOYALTY membership account will be closed, and all outstanding NOVUS LOYALTY Points will expire.</li>
                                    </ol>
                                </li>
                                <li>
                                    <h3>Miscellaneous</h3>
                                    <ol>
                                        <li>Settlement of disputes: Terms and conditions, set herein, shall be governed by the laws of India only. All disputes arising under the terms and conditions shall be attempted to be resolved through negotiations. Only in case, such negotiations are unsuccessful, the Member shall resort to legal recourse, which shall be subject to the exclusive jurisdiction of the competent courts in New Delhi only. All claims and disputes arising under the terms and conditions should be notified to CTPL within 45 working days from the event date after which no NOVUS LOYALTY Member shall have any claim against CTPL.</li>
                                        <li>CTPL or its Parent Company shall have the right to transfer, assign and sell in any manner, in whole or in part, the Program to any third party of its choice without reference or intimation to the NOVUS LOYALTY Members.</li>
                                        <li>NOVUS LOYALTY membership enrollment and eligibility, earning NOVUS LOYALTY Points, claiming NOVUS LOYALTY Rewards, availability of NOVUS LOYALTY Rewards and redemption are subject to the terms and conditions herein and are subject to any applicable laws and regulations.</li>
                                        <li>CTPL reserves the right to terminate the Program, by giving 30 working days’ notice to all NOVUS LOYALTY Members.</li>
                                        <li>Upon termination or withdrawal of the Program, all NOVUS LOYALTY Members will be given 30 working days’ time within which they may use the NOVUS LOYALTY Points in their NOVUS LOYALTY Membership Account to request for NOVUS LOYALTY Rewards, after which the NOVUS LOYALTY Points will be purged or cancelled.</li>
                                        <li>NOVUS LOYALTY and NOVUS LOYALTY Partners shall not be liable to any NOVUS LOYALTY Member or his/her nominee or companion, for any indirect or consequential loss, damage or expense of any kind whatsoever, arising out of or in connection with NOVUS LOYALTY, including the functioning of the helpline and/or www.Novus Loyalty.com, and/or provision or the refusal to provide any benefits, whether such loss, damage or expense is caused by the negligence or otherwise, and whether CTPL and/or any of its NOVUS LOYALTY Partners have any control over the circumstances giving rise to the claim or not.</li>
                                        <li>Every NOVUS LOYALTY member acknowledges, represents and warrants that he is the sole and exclusive owner of his NOVUS LOYALTY Membership account and the only authorized user of his NOVUS LOYALTY account's credentials. NOVUS LOYALTY member also accepts the sole responsibility to keep his NOVUS LOYALTY card, NOVUS LOYALTY number; password, account details confidential. Member is solely responsible for all the orders placed and other activities in the account (like profile changes, address change, etc.)</li>
                                        <li>NOVUS LOYALTY will not ask for NOVUS LOYALTY Member’s password and other details through any E-mail, Call, SMS or Social Network, etc. Please beware of spam mails and phishing calls and do not share your NOVUS LOYALTY Membership Account details with mail id / SMS’s / any other source which appear to be coming from unauthorized source.</li>
                                        <li>NOVUS LOYALTY customer can also delink the linked NOVUS LOYALTY number from the NOVUS LOYALTY account. </li>
                                    </ol>
                                </li>
                                <li>
                                    <h3>Redemption terms and conditions</h3>
                                    <ol>
                                        <li>
                                            <h4>Definitions</h4>
                                            <ul>
                                                <li>“Compliant” means any grievance, claims, issues, queries etc. raised by any NOVUS LOYALTY Member in connection with a Reward or their experience in using Reward Catalogue (including for reasons including but not limited to delay in receiving the reward, receipt of damaged/defective reward, wrong delivery, reward quality related issues, etc.</li>
                                                <li>“Order delivery TAT” means time taken to deliver Rewards to the NOVUS LOYALTY Member from the date of receipt of an order from the NOVUS LOYALTY Member and timelines for the same as given in the product delivery section</li>
                                                <li>“Rewards” shall mean such products and services made available to the NOVUS LOYALTY Member/s</li>
                                                <li>“Reward Catalogue” shall mean the catalogue wherein the NOVUS LOYALTY Member shall have the option to redeem their NOVUS LOYALTY Points at NOVUS LOYALTY Web-site or at NOVUS LOYALTY Contact Centre</li>
                                                <li>"Redemption" means any activity, which results in exchange of NOVUS LOYALTY Points by a NOVUS LOYALTY Member for NOVUS LOYALTY Rewards at NOVUS LOYALTY Rewards Catalogue or at NOVUS LOYALTY Partner Networks</li>
                                                <li>“Seller(s)” shall mean any independent third party responsible to manage the Rewards catalogue and the sale of the Rewards to NOVUS LOYALTY Members.&nbsp; All redemption orders &amp; services are managed by the Seller(s).</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h4>NOVUS LOYALTY Rewards catalogue terms and conditions</h4>
                                            <ul>
                                                <li>The NOVUS LOYALTY Rewards Catalogue facilitates the online sale and purchase of merchandise and services ("Services") offered by Seller(s) / registered merchants / vendors / service providers ("Seller(s)") all terms are offered by and agreed to be between NOVUS LOYALTY Member and Seller(s) alone. The terms include without limitation price, shipping costs, payment methods, payment terms, date, period and mode of delivery, warranties related to products and services and after sales services related to products and services. NOVUS LOYALTY does not have any control or does not determine or advise or in any way involve itself in the offering or acceptance of such commercial/contractual terms between the NOVUS LOYALTY Member/s and Seller/s.</li>
                                                <li>Availability of the Rewards and NOVUS LOYALTY Points required for Redemption in NOVUS LOYALTY Rewards Catalogue are subject to change without prior notice.</li>
                                                <li>All Rewards sent will be as per the description mentioned in the NOVUS LOYALTY Rewards Catalogue</li>
                                                <li>Selection of Rewards offered for Redemption in Rewards Catalogue is and will be at the sole discretion of NOVUS LOYALTY or its Seller(s) and is liable to change without prior notice.</li>
                                                <li>NOVUS LOYALTY will not be held responsible where any Seller(s) of rewards withdraws, cancels, becomes non- functional, alters or amends those rewards or any terms attached thereto.</li>
                                                <li>NOVUS LOYALTY is not responsible for changes to, or discontinuance of, any Seller(s) or Seller(s) product or service, or if any Seller(s) withdraws from this program or for any effect on or devaluation of rewards caused by such change, discontinuance, or withdrawal. NOVUS LOYALTY Members may not rely upon the continued availability of any rewards or award level.</li>
                                                <li>NOVUS LOYALTY does not accept liability for damage to persons or property whatsoever caused in relation to Rewards redeemed by NOVUS LOYALTY Members in the Program.</li>
                                                <li>Rewards image displayed on the Rewards Catalogue is merely indicative and subject to change as per availability and is not final.</li>
                                                <li>NOVUS LOYALTY/Seller(s) reserves the right to refuse to process any Transactions whether by using NOVUS LOYALTY Points by NOVUS LOYALTY Members with a prior history of questionable charges including without limitation breach of any agreements by NOVUS LOYALTY Member with NOVUS LOYALTY or breach/violation of any law or breach of any NOVUS LOYALTY / Seller policy.</li>
                                                <li>NOVUS LOYALTY Members acknowledge that NOVUS LOYALTY will not be liable for any damages, interests or claims etc. resulting from not processing a Transaction/Transaction Price or any delay in processing a Transaction / Transaction Price which is beyond the control of NOVUS LOYALTY.</li>
                                                <li>All Valid Credit / Debit/ Cash Card/ and other payment instruments are processed using a Credit Card payment gateway or appropriate payment system infrastructure and the same will also be governed by the terms and conditions agreed to between the NOVUS LOYALTY Member and his/her respective Issuing Bank and payment instrument issuing company.</li>
                                                <li>All Online Bank Transfers from Valid Bank Accounts are processed using the&nbsp;gateway provided by the respective Issuing Bank which supports Payment Facility to provide these services to the NOVUS LOYALTY Members. All such Online Bank Transfers on Payment Facility are also governed by the terms and conditions agreed to between NOVUS LOYALTY Member and his/her respective Issuing Bank.</li>
                                            </ul>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <h3>Warranties / Guarantee </h3>
                                    <ol>
                                        <li>All warranties and guarantees on the NOVUS LOYALTY Rewards are the sole responsibility of the Seller(s) (except in some cases where the warranty or guarantee may be from the manufacturer(s)) providing the NOVUS LOYALTY Rewards. CTPL shall not be liable or responsible for any defect or deficiency in the NOVUS LOYALTY Rewards, whatsoever. All guarantees and warranties on the NOVUS LOYALTY Rewards shall be transferred as is from the manufacturer or the Seller(s).</li>
                                        <li>NOVUS LOYALTY Members must contact the respective Seller(s) / Manufacturer (in case of manufacturer warranty) in the event of any defect or deficiency in the NOVUS LOYALTY Rewards redeemed. Alternatively, within the warranty period, the NOVUS LOYALTY Member may choose to contact ‘NOVUS LOYALTY Contact Center’ for any defect or deficiency in the NOVUS LOYALTY Rewards. In such event, NOVUS LOYALTY will purely act as a complaint routing agent. NOVUS LOYALTY will not be liable or responsible for the products and services offered through the NOVUS LOYALTY Rewards Catalogue and NOVUS LOYALTY gives no warranty or representation either express or implied with respect to type, quality or fitness of goods acquired or their suitability for any purpose provided by the Supplier under this Program, whatsoever.</li>
                                        <li>NOVUS LOYALTY Rewards in the form of gift vouchers or vouchers featured in the NOVUS LOYALTY Rewards Catalogue always carry a validity period, post which these vouchers will not be honored by the Seller(s) or issuer of gift voucher. All gift vouchers will be subject to their respective terms and conditions of the Seller(s). Kindly note that Gift vouchers should be utilized within expiry dates mentioned on the voucher. Gift Vouchers will not be revalidated under any circumstances</li>
                                        <li>In case of non-receipt of the warranty card /Invoice (comprising of Seller’s warranty) for any NOVUS LOYALTY Reward, the NOVUS LOYALTY Member must contact NOVUS LOYALTY Contact Center immediately and inform of non-receipt of rewards.</li>
                                    </ol>
                                </li>
                                <li>
                                    <h3>Product Delivery</h3>
                                    <ol>
                                        <li>
                                            Seller(s) to ensure that delivery of NOVUS LOYALTY rewards is fulfilled within,
                                            <ul>
                                                <li>15 days (for products dispatched through courier services like DTDC/ DHL / Bluedart etc.)</li>
                                                <li>30 days (for orders dispatched through Speed Post / India Post)</li>
                                                <li>30 days (for white goods and kitchen appliances dispatched through road transport) of the receipt of the order unless the delay is due to conditions beyond the control of the Seller(s) or unless otherwise specified.</li>
                                            </ul>
                                        </li>
                                        <li>Redemption orders from NOVUS LOYALTY Members once given to CTPL cannot be cancelled.</li>
                                        <li>NOVUS LOYALTY Rewards once delivered cannot be returned or cancelled by a NOVUS LOYALTY member unless there is a defect in the NOVUS LOYALTY Reward(s).</li>
                                        <li>For Rewards delivered through electronic mediums such as email/SMS, cannot be cancelled once placed.</li>
                                        <li>Reward redemption for air travel. Holiday packages and hotel bookings can be cancelled as per the travel operator’s policy and guidelines.</li>
                                        <li>For all Redemptions on www.NOVUS LOYALTY.com, please ensure your contact details are valid and accurate. If not, please update your NOVUS LOYALTY Membership Account by logging in to www.NOVUS LOYALTY.com or calling ‘NOVUS LOYALTY Contact Center’.</li>
                                        <li>If a NOVUS LOYALTY Member has not given the correct address or is not available at the mentioned address at the time of delivery, the NOVUS LOYALTY Reward will be delivered by the Seller(s) to the person who is available at the given address by the member. NOVUS LOYALTY shall not be held responsible and does not take any liability in such instance(s).</li>
                                        <li>If NOVUS LOYALTY does not receive any complaint of non-receipt of NOVUS LOYALTY Reward within twenty (20) working days from the date of delivery of the NOVUS LOYALTY Reward(s) it shall be deemed that the NOVUS LOYALTY Member has received the NOVUS LOYALTY Reward.</li>
                                        <li>Delivery of the NOVUS LOYALTY Reward to the opted office address or apartment address will be done at the reception or the front desk, as the case may be. Such delivery of NOVUS LOYALTY Rewards would be at the sole risk of the NOVUS LOYALTY Member.</li>
                                        <li> On Redemption, the specific number of NOVUS LOYALTY Points would automatically be removed from accumulated NOVUS LOYALTY Points in the NOVUS LOYALTY Member's account.</li>
                                        <li> NOVUS LOYALTY Points are redeemable only against rewards, vouchers or services as featured in NOVUS LOYALTY Rewards Catalogue as updated from time to time, as well as within the NOVUS LOYALTY Partner Network/s. Information supplied by a NOVUS LOYALTY Member on the Redemption of certain NOVUS LOYALTY Rewards may be used by NOVUS LOYALTY or the Seller(s) for the purposes of fulfilling the Redemption request.</li>
                                        <li> Order confirmation mails &amp; / SMS will be sent for all orders placed through NOVUS LOYALTY Contact center, NOVUS LOYALTY Portal and NOVUS LOYALTY Mobile App.</li>
                                        <li> For all web orders, ensure shipping address, Mobile number &amp; email address is checked before the order is placed on NOVUS LOYALTY Portal and NOVUS LOYALTY Mobile App. This information can be viewed under the “My account” section</li>
                                        <li> Email / SMS update will be sent to the NOVUS LOYALTY Members after placing a redemption order at the NOVUS LOYALTY Rewards Catalogue. This Email / SMS will be sent to the registered Email ID / mobile number provided by the NOVUS LOYALTY Member. NOVUS LOYALTY will not be responsible for any blockage, restriction or regulation imposed by regulators, leading to inability of communication or delay in communication of any updates or information, whatsoever.</li>
                                        <li> NOVUS LOYALTY reserves the right to change or cancel the Program, substitute or change the Rewards without prior notice. NOVUS LOYALTY can suspend or terminate the Program any time it deems necessary. Redemption claims submitted prior to the effective date of the change or cancellation of the Program will still be honored within a 30-day time limit.</li>
                                        <li> Fraud and abuse relating to redemption of NOVUS LOYALTY points in the Program will result in the forfeiture of the points as well as termination of the NOVUS LOYALTY Member account.</li>
                                        <li> Any taxes or other liabilities or charges payable to the government or any other authority or any Seller(s) which may arise or accrue to a NOVUS LOYALTY Member by redemption as aforesaid or otherwise as a result of the Program shall be to the sole account of the NOVUS LOYALTY Member.</li>
                                        <li> In the event of a dispute between NOVUS LOYALTY and a NOVUS LOYALTY Member on the NOVUS LOYALTY Reward will be subject arbitration in Gurgaon as per the Indian Arbitration and Conciliation Act, 1996. The number of Arbitrators shall be three, where each Party will have the right to appoint one (1) Arbitrator and the two (2) Arbitrators will appoint the third Arbitrator. The language to be used in the arbitral proceedings will be English</li>
                                        <li> All logos and trademarks on the NOVUS LOYALTY Rewards Catalogue and on NOVUS LOYALTY Rewards are owned by the respective companies.</li>
                                        <li> The NOVUS LOYALTY Member acknowledges and agrees that NOVUS LOYALTY shall not be liable or responsible for the Rewards offered through the NOVUS LOYALTY Rewards Program. All Rewards are offered to the Members directly by the Seller(s).</li>
                                        <li> NOVUS LOYALTY shall not be held liable for any damage caused to the NOVUS LOYALTY Reward while in transit from the Seller(s) location to the NOVUS LOYALTY Members’ location. All complaints regarding the same shall fall within the terms cited in clause 13 herein above (Warranty / Guarantee).</li>
                                    </ol>
                                </li>
                                <li>
                                    <h3>Return / Exchange Policy</h3>
                                    <p>If the NOVUS LOYALTY redemption order delivered to the NOVUS LOYALTY member is damaged, or defective, or is different from what was ordered from the Seller(s), product can be returned as per the return policy. To get a defective item replaced, please follow the following steps:</p>
                                    <ol>
                                        <li>NOVUS LOYALTY members can log their complaint for the Reward received on NOVUS LOYALTY web-site or contact NOVUS LOYALTY contact center, within 7 days from the date of delivery of the Reward.</li>
                                        <li>
                                            The Seller(s) will communicate to the NOVUS LOYALTY Member with further resolution steps which may include:
                                            <ul>
                                                <li>Organize the brand service team to resolve the complaint</li>
                                                <li>In some instances, NOVUS LOYALTY member, may need to visit the brand service center</li>
                                                <li>The Seller(s) may organize a courier pick up of the defective/damaged product and reship a replacement.</li>
                                            </ul>
                                        </li>
                                        <li>Return of a product will be accepted by the Seller(s) only if it is unused and is with the original packing along with accessories / warranty cards / user manuals / tags / labels if any for branded products only.</li>
                                        <li>In case Seller(s) of the product is unable to replace the damaged / defective product due to non-availability of stock or the product is returned to origin due to courier non-serviceability issues, NOVUS LOYALTY points will be refunded to the member’s account.</li>
                                    </ol>
                                </li>
                                <li>
                                    <h3>Refund Policy</h3>
                                    <ol>
                                        <li>In case of refunds, the NOVUS LOYALTY points consumed for that specific order would be reversed back to the NOVUS LOYALTY account.</li>
                                        <li>Refund shall be conditional and shall be with recourse available to NOVUS LOYALTY in case of any misuse by NOVUS LOYALTY Member.</li>
                                        <li>Refund shall be subject to NOVUS LOYALTY Member complying with Policies.</li>
                                        <li>Refunds for any cancelled / returned orders would be processed within seven working days</li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bigspacing nlgradbg nvfooter">
                <div className="container wcolor">
                    <div className="row">
                        <div className="col-lg-8 col-md-6">
                            <img src="https://www.novusloyalty.com/img/novus_loyalty_footer_logo_white.png" loading="lazy" className="footer_logo_hide_on_mob" />
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-5 footer_row">
                        <div className="col">
                            <h5>Platform</h5>
                            <h5 className="footer_header platform">Platform</h5>
                            <ul className="footer_link_list platformlist">
                                <li>
                                    <a href="https://www.novusloyalty.com/customer-loyalty-campaign-management">Campaign Management</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/loyalty-rewards-marketplace">Rewards Marketplace</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/customer-loyalty-program-engine">Loyalty Engine</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/loyalty-program-software-rule-engine">Rule Engine</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/loyalty-program-reporting">BI Reporting</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/loyalty-program-gamification-rewards">Gamification</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/loyalty-program-offers-management">Offers Management</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/loyalty-program-giftcard-management">Gift Card Management</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col">
                            <h5>Program Types</h5>
                            <h5 className="footer_header programtypes">Program Types</h5>
                            <ul className="footer_link_list programtypeslist">
                                <li>
                                    <a href="https://www.novusloyalty.com/loyalty-points-program">Points</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/tiered-loyalty-programs">Tiers</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/digital-wallet-loyalty-solution">Digital Wallets</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/cashback-loyalty-rewards-program">Cashback &amp; Discounts</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/referral-loyalty-programs">Referrals</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col">
                            <h5>Solution</h5>
                            <h5 className="footer_header footersolution">Solution</h5>
                            <ul className="footer_link_list footersolutionlist">
                                <li>
                                    <a href="https://www.novusloyalty.com/bank-loyalty-rewards-program">Bank Loyalty</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/ecommerce-rewards-program-software">Ecommerce Loyalty</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/retail-loyalty-program-provider">Retail Loyalty</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/restaurant-loyalty-program-platform">Restaurant Loyalty</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/healthcare-loyalty-software">Healthcare Loyalty</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/employee-rewards-platform">Employee Loyalty</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/travel-loyalty-software-solution">Travel Loyalty</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/automotive-loyalty-software-solution">Automotive Loyalty</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col">
                            <h5>Resources</h5>
                            <h5 className="footer_header resources">Resources</h5>
                            <ul className="footer_link_list resourceslist">
                                <li>
                                    <a href="https://www.novusloyalty.com/clients-testimonials">Clients &amp; Testimonials</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/case-studies">Case Studies</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/loyalty-blogs">Blogs</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/loyalty-solution-partner-program">Partners</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/loyalty-partner-integrations">Integrations</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col">
                            <h5>Overview</h5>
                            <h5 className="footer_header overview">Overview</h5>
                            <ul className="footer_link_list overviewlist">
                                <li>
                                    <a href="https://www.novusloyalty.com/loyalty-program-pricing">Pricing</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/how-it-works">How it works</a>
                                </li>
                                <li>
                                    <a href="https://www.novusloyalty.com/contact-us">Contact Us</a>
                                </li>
                            </ul>
                            <a href="https://seal.gtisec.com/85DpokRDn458k74kufrkjibh" target="_blank" className="pci_dss mt-20 hideonmob"></a>
                            {/* <div className="linkedinfollow">
                    <script src="https://platform.linkedin.com/in.js" type="text/javascript"> lang: en_US</script>
		            <script type="IN/FollowCompany" data-id="70545347" data-counter="bottom"></script>
                </div> */}
                            {/* <a href="https://twitter.com/novusloyalty?ref_src=twsrc%5Etfw" className="twitter-follow-button" data-show-count="false">Follow @novusloyalty</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> */}
                        </div>
                        <div className="col showonmobonly">
                            <a href="javascript:void(0)" target="_blank" className="linkedin hideonmob"></a>
                            <a href="https://seal.gtisec.com/85DpokRDn458k74kufrkjibh" target="_blank" className="pci_dss"></a>
                        </div>
                    </div>
                    <div className="nffooterlinks">
                        <ul>
                            <li>
                                <a href="https://www.novusloyalty.com/contact-us">Contact Us</a>
                            </li>
                            <li>
                                <a href="https://www.novusloyalty.com/sitemap-index.xml">Sitemap</a>
                            </li>
                            <li>
                                <a href="https://www.novusloyalty.com/privacy-policy">Privacy Policy</a>
                            </li>
                            <li>
                                <a href="https://www.novusloyalty.com/terms-and-conditions">Terms &amp; Conditions</a>
                            </li>
                        </ul>
                    </div>
                    <div className="nfcopyright">
                        <ul>
                            <li>
                                <figure>
                                    <img src={novus_logo_white} alt="logo" loading="lazy" width="80" />
                                </figure>
                            </li>
                            <li>&copy; 2023</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="footer_copyright nlgradbg hideonnewfooter">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <p>&copy; 2023 Novus Loyalty. All Rights Reserved</p>
                        </div>
                        <div className="col-md-7">
                            <ul className="footer_copyright_links">
                                <li>
                                    <a href="https://www.novusloyalty.com/terms-and-conditions">Terms &amp; Conditions</a>
                                </li>
                                <li>|</li>
                                <li>
                                    <a href="https://www.novusloyalty.com/privacy-policy">Privacy Policy</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TermAndCondition;
