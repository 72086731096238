import React from "react";
import Header from "../components/layout/Header";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import introJs from "intro.js";
import "intro.js/introjs.css";
import { useSelector, useDispatch } from "react-redux";
import { startTour } from "../actions/intro";
import { useLocation, useNavigate } from "react-router-dom";
import ModalCard from "../components/layout/ModalCard";
import ModalIntro from "../components/layout/ModalView";
let exitOnLast = 0;
const IntroStart = () => {
    const [showFirst, setShowFirst] = useState(true);
    const location = useLocation();
    let modalRefresh = location?.state?.data;
    if (!modalRefresh) modalRefresh = 1;
    const intro = introJs();
    const modalShow = useSelector((state) => state.intro.modalOpen);
    const startTours = useSelector((state) => state.intro.startTours);
    const count = useSelector((state) => state.intro.countFirst);
    const modalShowFIrst = useSelector((state) => state.intro.showModal);

    
    const dispatch = useDispatch();
    let complete = 0;
    const [showModal, setShowModal] = useState(0);
    useEffect(() => {
        if (localStorage.getItem("firstLogin") === "Y") {
            setShowFirst(showFirst + 1);
        }
        const handleLoad = () => {
            // temp=1

            const steps = [
                {
                    element: document.getElementById("upload_data"),
                    title: "Upload Data ",
                    intro: "Import your customer and transaction data to kickstart your loyalty program setup ",
                },
                {
                    element: document.getElementById("skip"),
                    title: "Skip",
                    intro: "If you don’t have Historical data, skip a step and move forward in the process.",
                },
            ];
            intro.setOptions({
                steps: steps,
                exitOnOverlayClick: false,
                doneLabel: "Next",
                tooltipClass: "customTooltip",
            });

            intro.start();
        };
        if (modalShow && localStorage.getItem("firstLogin") === "start") {
            complete = false;
            dispatch(startTour(false));
            setTimeout(() => {
                handleLoad();
            }, 500);
        }
        return () => {
            intro.exit();
        };
    }, [modalShow, startTours, modalRefresh,count]);

    intro.oncomplete(function (element) {
        complete = true;
        setTimeout(() => {
            if (exitOnLast !== 2) {
                localStorage.setItem("firstLogin", "Y");
                window.location.href = "/intro/dashboard";
            }
        }, 700);
    });
    intro.onexit((exit) => {
        if (!complete) {
            exitOnLast = 0;
            //   localStorage.setItem('firstLogin','')
            //   setShowFirst(false)
            // setShowModal(true)
        } else {
        }
    });

    intro.onchange(function (element) {
        switch (this._currentStep) {
            case 0:
                setTimeout(() => {
                    let cross1 = document.getElementsByClassName("introjs-skipbutton")[0];
                    cross1?.addEventListener("click", function () {
                        setShowModal(showModal+1);
                        localStorage.setItem("firstLogin", "");
                    });
                },400);
                break;
            case 1:
                let cross = document.getElementsByClassName("introjs-skipbutton")[0];
                 cross?.addEventListener("click", function () {
                    exitOnLast = 2;
                    setShowModal(showModal+1);
                    localStorage.setItem("firstLogin", "");
                });
                
                break;
        }
    });
    useEffect(()=>{
      if(modalShowFIrst)  setShowModal(showModal+1)
      else setShowModal(false)
    },[modalShowFIrst])

    return (
        <>
            {showModal  && <ModalCard show={showModal} />}
            <Header name={"Upload Historical Data"} />
            <div className="uploadingExistingData">
                <div className="container">
                    <div className="uploadingExistingData__main">
                        <div className="uploadingExistingData__left">
                            <img src={"/Images/uploadExistingDataImg.png"} alt="" />
                        </div>
                        <div className="uploadingExistingData__right">
                            <div className="uploadingExistingData__content justify-content-center">
                                <div className="uploadingExistingData__heading">
                                    <h1>Do you want to upload historical data?</h1>
                                    <h4>Uploading Historical data helps us in providing detailed reports and show how you are performing in comparison to the industry average.</h4>
                                </div>
                                <div className="uploadingExistingData__uploadBox">
                                    <div className="uploadingExistingData__uploadBoxBottom">
                                        <Link>
                                            <button type="button" disabled={true} id="upload_data" className="btn btn-primary" style={{}}>
                                                <i className="fa-solid fa-file-arrow-up"></i>
                                                Upload Data
                                            </button>
                                        </Link>
                                        <Link className="btn btn-outline-primary">
                                            <i className="fa-regular fa-file-lines"></i>
                                            Check Sample Report
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="uploadingExistingData__buttonSection">
                                <div className="uploadingExistingData__buttonSectionLeft">
                                    <Link id="skip"  disabled>
                                        Skip
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalIntro show={showFirst} />
        </>
    );
};

export default IntroStart;
