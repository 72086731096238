import { useEffect, useState } from "react";
import introJs from "intro.js";
import "intro.js/introjs.css";
import {earn_reward_points, header_main, home_section_left, home_section_right, customer_campagins} from '../Image/Index'
import { useNavigate } from "react-router-dom";

import ModalCard from "../components/layout/ModalCard";


let complete=false
let exitOnLast=false

const IntroCustomerCampins=()=>{
const [show,setShow]=useState(false)
    const intro=introJs()
const navigate=useNavigate()

    useEffect(() => { 
      localStorage.setItem('firstLogin', 'customer')
        const handleLoad=()=>{ 
         const steps = [
            {
                element: document.getElementById('rewards'),
                title: 'Campaigns',
                intro: "Have a look at the ongoing campaigns where you can earn rewards.  "
              },
          
              
             
         ]
         intro.setOptions({
             steps: steps,
            exitOnOverlayClick: false,
             doneLabel:"Next",
             tooltipClass: 'customTooltip'
           });
         intro.start();
         } 
         setTimeout(()=>{
            handleLoad()
         },500)
         return () => {
            intro.exit();
        }; 
        
       }, []);
       intro.oncomplete(function(element) {
        complete=true
        setTimeout(()=>{
            if(!exitOnLast) navigate("/intro/customer/history")
        },700)


    });
    intro.onexit(() => {
        if(!complete) {
            localStorage.setItem('firstLogin','')  
           setShow(true)
            }
            
        
      });
      intro.onchange(function(element) {
        switch (this._currentStep){
            case 0:
              setTimeout(()=>{
                let cross=  document.getElementsByClassName("introjs-skipbutton")[0]
                cross?.addEventListener('click', function() {
                exitOnLast=true
                localStorage.setItem('firstLogin','')   
                setShow(true)  
                });
              },600)  
      
              
                   break;
                 
           }
      
      });
    return(<>
    {show && <ModalCard show={show} customer={true}/>}

    <div className="cj_home_one">
      <div className="cj_home_one_header">
        <img src={header_main} alt=""/>
      </div>
      <div className="cj_home_one_body">
        <div className="cj_home_one_body_left">
          <img src={home_section_left} alt=""/>
        </div>
        <div className="cj_home_one_body_right">
          <img src={home_section_right} alt=""/>
        </div>
      </div>
      <div className="cj_home_one_big_body">
        <img id='rewards' src={customer_campagins} alt=""/>
      </div>
    </div>

    {/* <img id='rewards' src={customer_campagins} style={{height:'800px',width:'1300px'}} ></img> */}
    
    
    </>)
}
export default IntroCustomerCampins