import { Link } from "react-router-dom";
import { getDate } from "../../../../utils/helper";

export const COLUMNS = [
    {
        Header: "File Type",
        Footer: "File Type",
        accessor: "fileType",
        sticky: "left",
    },
    {
        Header: "Total Records",
        Footer: "Total Records",
        accessor: "totalRecords",
        disableFilters: true,
        sticky: "left",
    },

    {
        Header: "Record Process Count",
        Footer: "Record Process Count",
        accessor: "recordProcessCount",
    },
    {
        Header: "Uploaded Date",
        Footer: "Uploaded Date",
        accessor: "uploadedDate",
        Cell: ({ value }) => getDate(value, "MMM-DD-YYYY"),
    },

    {
        Header: "Download",
        Footer: "Download",
        accessor: "blobFilePath",
        Cell: ({ value }) => <Link to={value}><i className="fa-solid fa-download"></i></Link>,
    },
];
