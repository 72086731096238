import React, { Fragment } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import { payment_done } from "../../Image/Index";
class SuccessPayment extends React.Component {
    render() {
        return (
            <>
                <Header name={"Payment Success"} />

                <div className="dashboard__content">
                    <div className="container-fluid">
                        <div className="payment_status psuccess">
                            <img src={payment_done} width={60}/>
                            <h2>Thank you for choosing us!</h2>
                            <p>Your payment for the subscription is complete. Enjoy Seamless access to Novus Loyalty. We appreciate your choice!</p>
                        </div>
                        {/* <p>
                        We appreciate your business! If you have any questions, please email
                        <Link to='javascript:void(0)'
                            onClick={() => window.location = 'mailto:info@novusloyalty.com'}>
                            {` info@novusloyalty.com`}
                        </Link>

                    </p> */}
                    </div>
                </div>
            </>



        );
    }
}

export default SuccessPayment;
