import React, { useState, useEffect } from "react";
import Header from "../../layout/Header";
import { backIcon2 } from "../../../Image/Index";
import { Link, useNavigate } from "react-router-dom";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../../partial/TextValidator";
import SelectValidator from "../../partial/SelectValidator";
import { getMerchantWallet, getWalletSummary } from "../campaigns/services";
import Page from "../Pagination";
import { getDate, selectValueGetter } from "../../../utils/helper";
import { Tab, Tabs } from "react-bootstrap";
import { CSVLink } from "react-csv";
import TransactionViewModal from "../transactionsList/TransactionViewModal";
import { loadStripe } from "@stripe/stripe-js";
import { createPayment } from "../../../actions/service";
import { decrypt } from "../../../utils/encodingdecoding";

const WalletReport = () => {
    let navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [show, setShow] = useState(false);
    const [state, setState] = useState({
        wallet: " ",
        pointWalletSummary: "",
        totalLength: 0,
        amount: "",
        transactionId: "",
        selectedTypeId: { label: "Point", value: "point" },
        options: [
            { label: "Point", value: "point" },
            // { label: "Voucher", value: "voucher" },
        ],
    });

    const { wallet, pointWalletSummary, totalLength, amount, transactionId, selectedTypeId, options } = state;
    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = 10;
    const pageCount = Math.ceil(totalLength / pageSize);
    useEffect(() => {
        const fetchWallet = async () => {
            setLoader(true);
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const id = localStorage.getItem("Id");
            let response = await getMerchantWallet(token?.access_token, id);
            setState((prev) => ({
                ...prev,
                wallet: response.data,
            }));
            setLoader(false);
        };
        fetchWallet();
    }, []);

    useEffect(() => {
        const fetchWalletSummary = async () => {
            setLoader(true);
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const id = localStorage.getItem("Id");
            let response = await getWalletSummary(token?.access_token, id);
            let temp = [];
            response?.financialStatementFixPointWallet?.filter((ele) => {
                if (ele?.earnedValue < 0) {
                    temp.push({
                        earnedValue: ele?.earnedValue,
                        earnedDate: ele?.earnedDate,
                        earnedTransactionId: ele?.earnedTransactionId,
                        customercode: ele?.customercode,
                    });
                }
            });
            setState((prev) => ({
                ...prev,
                pointWalletSummary: temp.length ? temp : [],
                totalLength: temp?.length,
            }));
            setLoader(false);
        };
        fetchWalletSummary();
    }, []);

    const handleChange = (e) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, "");
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const onSelectChange = (e) => setState({ ...state, selectedTypeId: { ...e } });

    const handleViewModal = (id) => {
        setShow(true);
        setState((prev) => ({
            ...prev,
            transactionId: id,
        }));
    };

    const onSubmit = async () => {
        setLoader(true);
        let tempAmount = amount * 100;
        // payment integration
        const stripe = await loadStripe(process.env.REACT_APP_SECRAT_KEY);

        const body = {
            name: selectedTypeId.value,
            unit_amount: tempAmount,
            email: decrypt(localStorage.getItem("email")),
        };
        const response = await createPayment(body);
        const session = await response.json();
        setLoader(false);
        const result = stripe.redirectToCheckout({
            sessionId: session.id,
        });

        if (result.error) {
            console.log(result.error);
        }
    };

    return (
        <>
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <Header name={"Wallet Report"} />
            <div className="dashboard__content">
                {show && <TransactionViewModal show={show} close={() => setShow(false)} transactionId={show && transactionId} />}
                <div className="container-fluid">
                    <div className="dashboard__customerViewMain">
                        <div className="dashboard__heading">
                            <div className="dashboard__headingLeft">
                                <div className="dashboard__headingBackLink">
                                    <Link to="" onClick={() => navigate(-1)}>
                                        <img src={backIcon2} alt="" />
                                    </Link>
                                </div>
                                <div className="dashboard__headingContent">
                                    <h3>Back</h3>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard__customerViewCard">
                            <div className="dashboard__customerViewProfile">
                                <div className="dashboard__customerViewDetails">
                                    <div className="dashboard__customerViewTop">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="dashboard__customerViewHeading">
                                                    <ValidatorForm className="signUpPage__formSection" onSubmit={onSubmit}>
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <SelectValidator className="" name="choice" value={selectValueGetter(options, selectedTypeId.value)} options={options} onChange={onSelectChange} validators={["required"]} errorMessages={["Please enter your industry"]} />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <TextValidator className="form-control" placeholder="Amount" name="amount" value={amount} maxLength="6" validators={["required", "matchRegexp:^[1-9][0-9]*$"]} errorMessages={["Amount is required.", "Please enter a valid amount (greater than zero)"]} onChange={handleChange} />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <button type="submit" className="btn btn-primary">
                                                                    Recharge Amount
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </ValidatorForm>
                                                </div>
                                            </div>
                                        </div>
                                        {amount && (
                                            <h2>
                                                Total points you earn : <b>{amount * 4}</b>
                                            </h2>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard__customerViewCard">
                            <div className="dashboard__customerViewPointsTabs">
                                <Tabs id="customerViewPointsTabs">
                                    <Tab eventKey="Wallet" title="Wallet">
                                        <div className="dashboard__customerViewPoints">
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="dashboard__customerViewBox m-0">
                                                        <h4>Total Limit</h4>
                                                        <h2>{wallet?.pointLimit}</h2>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="dashboard__customerViewBox m-0">
                                                        <h4>Total Spend</h4>
                                                        <h2>{wallet?.totalSpent}</h2>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="dashboard__customerViewBox m-0">
                                                        <h4>Total Balance</h4>
                                                        <h2>{wallet?.pointBalance}</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Finance Summary" title="Finance Summary">
                                        <div className="table-responsive">
                                            <table className="table table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Value</th>
                                                        <th>Date</th>
                                                        <th>Transaction ID</th>
                                                        <th>Customer Code</th>
                                                    </tr>
                                                </thead>
                                                {
                                                    <tbody>
                                                        {pointWalletSummary && pointWalletSummary?.length > 0
                                                            ? pointWalletSummary.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((statement, i) => (
                                                                  <tr key={i}>
                                                                      <td>{statement.earnedValue}</td>
                                                                      <td>{getDate(statement.earnedDate, "DD-MM-YYYY")}</td>

                                                                      <td>
                                                                          <Link to="" state={{ transactionId: statement.customercode, from: "WalletReport" }} onClick={() => handleViewModal(statement.earnedTransactionId)}>
                                                                              {statement.earnedTransactionId}
                                                                          </Link>
                                                                      </td>

                                                                      <td>
                                                                          <Link to={`/customer-view/${statement.customercode}`} state={{ from: "WalletReport" }}>
                                                                              {statement.customercode}
                                                                          </Link>
                                                                      </td>
                                                                  </tr>
                                                              ))
                                                            : null}
                                                    </tbody>
                                                }
                                                {pointWalletSummary && pointWalletSummary?.length <= 0 && (
                                                    <tfoot>
                                                        <tr>
                                                            <td colSpan="4">
                                                                <h6 className="text-center">No Record Found</h6>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                )}
                                            </table>
                                        </div>
                                        {pointWalletSummary && pointWalletSummary?.length > 0 && (
                                            <div>
                                                <div className="dashboard__paginationBox">
                                                    <Page currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} value={5} />
                                                </div>
                                                <div>
                                                    <CSVLink className="dashboard__downloadButton" data={pointWalletSummary} filename={"financial-summary.csv"}>
                                                        <button className="btn btn-primary ">Download</button>
                                                    </CSVLink>
                                                </div>
                                            </div>
                                        )}
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WalletReport;
