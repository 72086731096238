export const COLUMNS = [
    {
        Header: "Name",
        Footer: "Name",
        accessor: "name",
        disableFilters: true,
        sticky: "left",
    },

    {
        Header: "Email",
        Footer: "Email",
        accessor: "email",
    },

    {
        Header: "Contact Number",
        Footer: "Contact Number",
        accessor: "contactnumber",
    },

    {
        Header: "Date",
        Footer: "Date",
        accessor: "Created",
    },
    {
        Header: "Description",
        Footer: "Description",
        accessor: "projectdetails",
    },

    {
        Header: "Country",
        Footer: "Country",
        accessor: "country",
    },
    {
        Header: "ip",
        Footer: "ip",
        accessor: "ip",
    },
];
