import { useEffect, useState } from "react";
import introJs from "intro.js";
import "intro.js/introjs.css";
import { gift_cards_layout, header_main, home_section_left, home_section_right, in_store_redemtion_button, redemption_box} from '../Image/Index'
import { useNavigate } from "react-router-dom";

import ModalCard from "../components/layout/ModalCard";


let complete = false
let exitOnLast = false

const IntroReddem = () => {
    const [show, setShow] = useState(false)
    const intro = introJs()
    const navigate = useNavigate()

    useEffect(() => {
        localStorage.setItem('firstLogin', 'customer')
        const handleLoad = () => {
            const steps = [
                {
                    element: document.getElementById('giftCards'),
                    title: 'Redeem',
                    intro: "Exchange your earned rewards for gift cards as part of your loyalty program benefits."
                },


            ]
            intro.setOptions({
                steps: steps,
                exitOnOverlayClick: false,
                doneLabel: "Next",
                tooltipClass: 'customTooltip'
            });
            intro.start();
        }
        setTimeout(() => {
            handleLoad()
        }, 500)
        return () => {
            intro.exit();
        };

    }, []);
    intro.oncomplete(function (element) {
        complete = true
        setTimeout(() => {
            if (!exitOnLast) navigate("/intro/customer/store")
        }, 700)


    });
    intro.onexit(() => {
        if (!complete) {
            localStorage.setItem('firstLogin', '')
            setShow(true)
        }

    });
    intro.onchange(function (element) {
        switch (this._currentStep) {
            case 0:
                setTimeout(() => {
                    let cross = document.getElementsByClassName("introjs-skipbutton")[0]
                   cross?.addEventListener('click', function () {
                        exitOnLast = true
                        localStorage.setItem('firstLogin', '')
                        setShow(true)
                    });
                }, 600)


                break;

        }

    });
    return (<>
        {show && <ModalCard show={show} customer={true}/>}
        
        {/* <img  src={redeem_back} style={{ height: '300px', width: '1600px' }} ></img> */}

        <div className="cj_home_one">
            <div className="cj_home_one_header">
                <img src={header_main} alt=""/>
            </div>
            <div className="cj_home_one_body">
                <div className="cj_home_one_body_left cj_home_one_body_left_with_button">
                <img src={home_section_left} alt=""/>
                <div className="rewards_button">
                    <img src={in_store_redemtion_button}/>
                </div>
                </div>
                <div className="cj_home_one_body_right">
                <img src={home_section_right} alt=""/>
                </div>
            </div>
            <div id="giftCards"className="cj_home_one_big_body">
                <img src={gift_cards_layout} alt=""/>
            </div>
            
        </div>

        {/* <img id='rewards' src={earn_reward_points} style={{ height: '400px', width: '300px' }} ></img> */}


    </>)
}
export default IntroReddem