// modals alerts
export const SHOW_ALERT_MODEL = "SHOW_ALERT_MODEL";
export const CLEAR_ALERT_MODAL = "CLEAR_ALERT_MODAL";

export const DASHBOARD = "DASHBOARD";
export const CLEAR_DASHBOARD = "CLEAR_DASHBOARD";
export const PAGE_CHANGED = "PAGE_CHANGED";
export const PAGE_SIZE_CHANGED = "PAGE_SIZE_CHANGED";
export const PAGE_SORT_CHANGED = "PAGE_SORT_CHANGED";
export const PAGE_FILTER_CHANGED = "PAGE_FILTER_CHANGED";
export const TOTAL_COUNT_CHANGED = "TOTAL_COUNT_CHANGED";

export const NEW_REFERRAL_CAMPANING = "NEW_REFERRAL_CAMPANING";

export const GET_FINANCIAL_ACCOUNT = "GET_FINANCIAL_ACCOUNT";

export const CLEAR_SETTING = "CLEAR_SETTING";

export const GET_PROFILE_SETTING = "GET_PROFILE_SETTING";
export const UPDATE_PROFILE_SETTING = "UPDATE_PROFILE_SETTING";

export const GET_TRANSACTION_LIST = "GET_TRANSACTION_LIST";
export const CLEAR_TRANSACTION_LIST = "CLEAR_TRANSACTION_LIST";
export const SEARCH_TRANSACTION_LIST = "SEARCH_TRANSACTION_LIST";

export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST";
export const CLEAR_CUSTOMER_LIST = "CLEAR_CUSTOMER_LIST";
export const SEARCH_CUSTOMER_LIST = "SEARCH_CUSTOMER_LIST";

export const GET_CAMPAIGNS = "GET_CAMPAIGNS";
export const GET_CAMPAIGN = "GET_CAMPAIGN";
export const GET_SAMPLE_CAMPAIGNS = "GET_SAMPLE_CAMPAIGNS";
export const CLEAR_CAMPAIGNS = "CLEAR_CAMPAIGNS";
export const CREATE_SAMPLE_CAMPAIGNS = "CREATE_SAMPLE_CAMPAIGNS";
export const CLEAR_SAMPLE_CAMPAIGNS = "CLEAR_SAMPLE_CAMPAIGNS";
export const UPDATE_SAMPLE_CAMPAIGN = "UPDATE_SAMPLE_CAMPAIGN";

export const SHOW_SITE_LOADER = "SHOW_SITE_LOADER";
export const HIDE_SITE_LOADER = "HIDE_SITE_LOADER";

export const LOAD_TOKEN = "LOAD_TOKEN";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT = "LOGOUT";
export const AUTH = "AUTH";

export const GET_BUSINESS_GOAL = "GET_BUSINESS_GOAL";
export const CLEAR_BUSINESS_GOAL = "CLEAR_BUSINESS_GOAL";

export const GET_UPLOAD_FILE_DETAILS = "GET_UPLOAD_FILE_DETAILS";
export const CLEAR_UPLOAD_FILE_DETAILS = "CLEAR_UPLOAD_FILE_DETAILS";

export const GET_COUNTRY = "GET_COUNTRY";
export const CLEAR_COUNTRY = "CLEAR_COUNTRY";

export const VOUCHER_TEMPLATE_DETAIL = "VOUCHER_TEMPLATE_DETAIL";
export const CLEAR_VOUCHER_TEMPLATE = "CLEAR_VOUCHER_TEMPLATE";
export const PROFILE_DROPDOWN = "PROFILE_DROPDOWN";
export const INTRO_DOCUMENTATION = "INTRO_DOCUMENTATION";
export const INTROMODAL = "INTROMODAL";
export const START_TOUR = "START_TOUR";
export const COUNT_FIRST = "COUNT_FIRST";
export const SHOW_MODAL = "SHOW_MODAL";
export const PAYMENT_MODAL = "PAYMENT_MODAL";
