import React, { useState, useEffect } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
// import { GlobalFilter } from "./GlobalFilter";

const Paginated = ({ columns, data }) => {
    const { getTableProps, getTableBodyProps, rows, headerGroups, page, nextPage, previousPage, canPreviousPage, canNextPage, pageOptions, state, gotoPage, setPageSize, prepareRow, setGlobalFilter } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        usePagination
    );
    const { globalFilter, pageIndex, pageSize } = state;

    const handlePageIndex = (event) => {
        if (event.target.value !== "") {
            const number = event.target.value ? Number(event.target.value) - 1 : 0;
            gotoPage(number);
        }
    };

    return (
        <div className="customTable">
            <div className="customTable__header">
                <div className="customTable__left">
                    <div className="customTable__showingRecords">
                        <div className="form-group">
                            <label>Show Records:</label>
                            <select className="form-control selectArrow" value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
                                {[10, 25, 50, 100].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="customTable__right">
                    {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
                </div>
            </div>
            <div className="customTable__body">
                <div className="table-responsive">
                    <table className="table table-striped table-bordered" {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        {data?.length ? (
                            <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return <td {...cell.getCellProps()}>{cell.render("Cell") ? cell.render("Cell") : "Null"}</td>;
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan={9}>
                                        <p style={{ textAlign: "center", margin: 0 }}>No data found</p>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
            <div className="customTable__footer">
                <div className="customTable__left">
                    <div className="customTable__totalTransactions">
                        <p>
                        Total Communication: <span>{rows.length}</span>
                        </p>
                    </div>
                </div>
                <div className="customTable__right">
                    <div className="customTable__top">
                        <div className="customTable__totalPages">
                            <p>
                                Page
                                <select className="form-control selectArrow" value={pageIndex + 1} onChange={handlePageIndex}>
                                    {pageOptions?.length ? (
                                        pageOptions.map((pageIndex) => (
                                            <option key={pageIndex} value={pageIndex + 1}>
                                                {pageIndex + 1}
                                            </option>
                                        ))
                                    ) : (
                                        <option key={0} value={0}>
                                            {0}
                                        </option>
                                    )}
                                </select>
                                of <span>{pageOptions.length}</span>
                            </p>
                        </div>
                    </div>
                    <div className="customTable__bottom customTable__bottom__2">
                        <div className="customTable__buttons">
                            {/* <span className="btn btn-outline-primary" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
								{"First"}
							</span> */}
                            <span className="btn btn-outline-primary" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {"Previous"}
                            </span>
                            <span className="btn btn-outline-primary" onClick={() => nextPage()} disabled={!canNextPage}>
                                {"Next"}
                            </span>
                            {/* <span className="btn btn-outline-primary" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
								{"Last"}
							</span> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Paginated;
