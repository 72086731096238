import React, { useEffect } from "react";
import { notFoundImg } from "../../Image/Index";
import { Link, Navigate } from "react-router-dom";

const NotFound = () => {
    useEffect(() => {
        document.querySelector("body").classList = "";
        document.querySelector("body").classList.add("pageNotFound");
    }, []);

    const temp = JSON.parse(sessionStorage.getItem("token"));
    if (!temp || temp === null || undefined) {
        return <Navigate to="/signin" />;
    }
    return (
        <div className="dashboard__content">
            <div className="container-fluid">
                <div className="dashboard__notFoundBox">
                    <figure>
                        <img src={notFoundImg} alt="" />
                    </figure>
                    <h3>Oops. That page doesn't exist.</h3>
                    <Link className="btn btn-primary" to="/dashboard">
                        Go To Dashboard
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
