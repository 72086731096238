import { Link } from "react-router-dom";

const handleLink = (link) => {
    const width = window.screen.width / 1.5;
    const height = window.screen.height / 1.5;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;
    window.open(link, "", `width=${width},height=${height},left=${left},top=${top}`);
};

export const COLUMNS = [
    {
        Header: "Date ",
        Footer: "Date",
        accessor: "createdAt",
        disableFilters: true,
        sticky: "left",
    },
    {
        Header: "Customer Name",
        Footer: "Customer Name",
        accessor: "customername",
    },
    {
        Header: "Review Link",
        Footer: "Review Link",
        accessor: "link",
        Cell: ({ value }) => <Link onClick={() => handleLink(value)}>{value}</Link>,
    },
    {
        Header: "Remarks",
        Footer: "Remarks",
        accessor: "remarkId",
        // Cell: ({}) => <input placeholder="remarks" />,
        Cell: ({ value }) => (value?.remark ? <>{value?.remark}</> : <Link to={`/customer-review?remark=${value?.id}`}>Add Remarks</Link>),
    },
    {
        Header: "Action",
        Footer: "Action",
        accessor: "action",
        Cell: ({ value }) => (
            <>
    
                {value?.other === "" ? (
                    <>
                        <Link to={`/customer-review?value=${value?.link ? value?.link : ""}&status=true&id=${value?.id}&mobile=${value?.mobile}`}>Approved</Link>
                        <Link to={`/customer-review?value=${value?.link ? value?.link : ""}&status=false&id=${value?.id}&mobile=${value?.mobile}`}>Rejected</Link>
                    </>
                ) : value?.status === true ? (
                    <Link style={{ pointerEvents: "none", color: "black" }} to={`/customer-review?value=${value?.link ? value?.link : ""}&status=true&id=${value?.id}&mobile=${value?.mobile}`}>
                        Approved
                    </Link>
                ) : (
                    value?.status === false && (
                        <Link style={{ pointerEvents: "none", color: "black" }} to={`/customer-review?value=${value?.link ? value?.link : ""}&status=false&id=${value?.id}&mobile=${value?.mobile}`}>
                            Rejected
                        </Link>
                    )
                )}
            </>
        ),
    },
];
