import React, { useEffect, useState, useRef } from "react";
import Header from "../../layout/Header";
import TextValidator from "../../partial/TextValidator";
import { ValidatorForm } from "react-form-validator-core";
import { Link, useNavigate } from "react-router-dom";
import { backIcon2 } from "../../../Image/Index";
import { merchantLogo } from "../../../actions/setting";
import swal from "sweetalert";
import { getMerchantAndLogo } from "../../../actions/auth";
import { COLUMNS } from "./columnn";
import { Upload } from "./upload";

const UploadLogo = () => {
    const imageInputRef = useRef();
    const formRef = useRef();
    let navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [brandName, setBrandName] = useState("");
    const [size, setSIze] = useState(false);

    const onChange = (e) => {
        setBrandName(e.target.value);
    };
    const handleFile = (e) => {
        if (e.target.files[0] && e.target.files[0]?.size > 1 * 1024 * 1024) {
            setSIze(true);
        } else {
            const filedata = e.target.files[0];
            setSelectedFile(filedata);
            setSIze(false);
        }
    };
    const handleCancelData = () => {
        setSelectedFile(null);
        setBrandName("");
    };

    const Submit = async (e) => {
        e.preventDefault();
        let token = JSON.parse(localStorage.getItem("token_gen"));
        setLoader(true);
        (await !size) &&
            merchantLogo(token?.access_token, brandName, selectedFile)
                .then((res) => {
                    swal({
                        position: "center",
                        icon: "success",
                        title: res.message,
                        showConfirmButton: false,
                        timer: 10000,
                    });
                    setSelectedFile(null);
                    fetchData();
                    setBrandName("");
                })
                .catch((err) => {
                    swal({
                        position: "center",
                        icon: "error",
                        title: err.message,
                        showConfirmButton: false,
                        timer: 10000,
                    });
                });
        imageInputRef.current.value = "";

        setLoader(false);
    };
    const fetchData = async () => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const id = localStorage.getItem("Id");
        const response = await getMerchantAndLogo(token?.access_token, id);
        setLoader(false);
        setData(response.data);
    };
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Header name={"Upload Logo"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__profileSettingsMain">
                        <div className="dashboard__heading">
                            <div className="dashboard__headingLeft">
                                <div className="dashboard__headingBackLink">
                                    <Link to="" onClick={() => navigate(-1)}>
                                        <img src={backIcon2} alt="" />
                                    </Link>
                                </div>
                                <div className="dashboard__headingContent">
                                    <h3>Upload Logo</h3>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard__profileSettingsBox">
                            <ValidatorForm className="customForm" ref={formRef} onSubmit={(e) => Submit(e)}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>Logo</label>
                                            <input className="form-control" type="file" ref={imageInputRef} accept={"image/png, image/gif, image/jpeg, image/jpg"} required onChange={handleFile} />
                                            {size && <span style={{ color: "red" }}>file size not more than 1 MB</span>}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>Brand Name</label>
                                            <TextValidator placeholder="Brand Name" className="form-control" name="emailID" validators={["required"]} errorMessages={[" Please enter brands name."]} value={brandName} onChange={onChange} />
                                        </div>
                                    </div>
                                    <div>
                                        <p style={{ color: "black", fontSize: "13px" }}>
                                            {" "}
                                            <span style={{ color: "red" }}>*</span>only one logo can up uploded,Newly uploded logo will replace old
                                        </p>
                                    </div>
                                    <h6 style={{ color: "black", fontSize: "18px" }}>Uploded Logo</h6>
                                    {data && (
                                        <div>
                                            <Upload columns={COLUMNS} data={data} />
                                        </div>
                                    )}
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div className="button-section">
                                            <button type="button" className="btn btn-danger" onClick={handleCancelData}>
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn btn-primary">
                                                Upload
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </ValidatorForm>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UploadLogo;
