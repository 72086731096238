import React, { useState, useEffect } from "react";
import { Modal, Button, CloseButton } from "react-bootstrap";
import { giftCardView } from "../../../actions/auth";
import parse from "html-react-parser";

const CardViewModal = (props) => {
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState();
    const [vendors, setVendors] = useState();
 

    // useEffect(() => {
    //     if (props.show) {
    //         setLoader(true);
    //         const response = giftCardView(props.code);
    //         response
    //             .then((res) => {
    //                 setData(res.data);
    //                 setVendors(res.data.vendors);
    //                 setLoader(false);
    //             })
    //             .catch((err) => {
    //                 setLoader(false);
    //             });
    //     } else setLoader(false);
    // }, [props.show, props.code]);

    return (
        <>
            <Modal show={props.show} onHide={props.close} size="lg" centered>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: "20px" }}>Gift Card Details</Modal.Title>
                    <CloseButton onClick={props.close} variant="white" />
                </Modal.Header>
                <Modal.Body className="dashboard__transactionsModal" style={{ position: "relative", minHeight: "85px" }}>
                    {loader ? (
                        <div className="mainPreloaderMain" style={{ position: "absolute" }}>
                            <div className="mainPreloader">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    ) : (
                        <div className="dashboard__transactionsModalProfile">
                            <div className="dashboard__transactionsModalDetails">
                                <div className="dashboard__transactionsModalBottom">
                                    <div className="gc_modal_box">
                                        <div className="gc_title">
                                            <h5>
                                                Title: <span>{props?.code?.title}</span>
                                            </h5>
                                        </div>
                                        <div className="gc_amount">
                                            <h5>Amount:</h5>
                                            <span className="gc_amount_box">
                                                {props?.code?.vendors?.length > 0 &&
                                                    props?.code?.vendors.map((ele) => {
                                                        return ele.denominations.map((ele, i) => {
                                                            return (
                                                                <span key={i} className="gc_amount_single">
                                                                    <span className="" title={ele.fix_value}>
                                                                        <span>{props.currencySymbol}</span>
                                                                        {ele.fix_value}
                                                                    </span>
                                                                </span>
                                                            );
                                                        });
                                                    })}
                                            </span>
                                        </div>
                                        <div className="">
                                            <h5>Terms and Conditions:</h5>{" "}
                                            {props?.code?.vendors?.length > 0 &&
                                                props?.code?.vendors.map((ele, i) => {
                                                    return <span key={i}>{parse(ele?.tnc)}</span>;
                                                })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.close}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CardViewModal;
