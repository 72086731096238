import React from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";

export const Upload = ({ columns, data }) => {
    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        usePagination
    );

    return (
        <div className="customTable">
            {/* <div className="customTable__header">
				<div className="customTable__left">
					<div className="customTable__showingRecords">
						<label>Show Records:</label>
						<select className="form-control" value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
							{[10, 25, 50, 100].map((pageSize) => (
								<option key={pageSize} value={pageSize}>
									{pageSize}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="customTable__right">
					<CustomFilter filter={globalFilter} setFilter={setGlobalFilter} />
				</div>
            </div> */}

            <div className="customTable__body">
                <div className="table-responsive">
                    <table className="table table-striped table-bordered" {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        {Array.isArray(data) ? (
                            <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan={4}>
                                        <p style={{ textAlign: "center", margin: 0 }}>No data found</p>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </div>
    );
};
