import React, { useEffect, useState } from "react";
import Header from "../layout/Header";
import { COLUMNS } from "./allVoucherColumn";
import AllVoucherPaginated from "./AllVoucherPaginated";
import { ValidatorForm } from "react-form-validator-core";
import SelectValidator from "../partial/SelectValidator";
import { getDate, selectValueGetter } from "../../utils/helper";
import { RedeemVoucher, getVoucherTemplateStatusByCode, getVouchersWallet, transactionRegistration } from "../pages/campaigns/services";
import swal from "sweetalert";
import { decrypt } from "../../utils/encodingdecoding";

const AllVouchers = () => {
    const [loader, setLoader] = useState(false);
    const [response, setResponse] = useState()
    const [valueselect, setValueSelect] = useState("all");
    const [option] = useState([
        { label: "All Vouchers", value: "all" },
        { label: "Active Vouchers", value: "Active" },
        { label: "Expired Vouchers", value: "InActive" },
        { label: "Redeemed Vouchers", value: "Redeemed" },
    ]);

    const [filterOption, setFilterOption] = useState("all");
    const [data, setData] = useState([])

    const unPeople = (e) => {
        setValueSelect(e.value);
        setFilterOption(e.value);
    };

    const fetch = async () => {
        setLoader(true)
        const id = decrypt(localStorage.getItem("merchantcode"))
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const res = await getVouchersWallet(token?.access_token, id);
        setResponse(res.data)
        setLoader(false)
    };

    useEffect(() => {
        fetch();
    }, []);

    const onSubmit = () => { };

    const handleVoucher = async (value) => {
        setLoader(true)
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const body = {
            customercode: value?.customerCode,
        };
        let voucherStatusResponse = await getVoucherTemplateStatusByCode(token?.access_token, body);
        setLoader(false)
    };

    const handleRedeemtion = async (value) => {
        const id = localStorage.getItem("Id");
        setLoader(true);
        let tempToken = JSON.parse(localStorage.getItem("token_gen"));
        tempToken = tempToken?.access_token;
        let currentDate = new Date();
        let formattedDate = getDate(currentDate, "YYYY/MM/DD")
        let body = {}
        body.customer = {
            customercode: value?.customerCode,
        };
        body.redeemDetail = {
            walletType: "voucher",
            values: value?.voucherCode,
            transactionType: "redeem",
            transactionDate: formattedDate,
            VoucherTemplateCode: value?.voucherTemplateCode
        };
        var temp = {
            customer: {
                customercode: value.customerCode
            },
            transactionDetail: {
                vouchertemplatecode: value?.voucherTemplateCode,
                value: value?.earnedValue
            },
            schemacode: "TSC000049"
        }
        await RedeemVoucher(tempToken, body).then((res) => {
            if (res.status === 400 || res.status === 500) {
                setLoader(false);
                swal({
                    position: "center",
                    icon: "error",
                    title: res.message,
                    showConfirmButton: false,
                    timer: 5000,
                });
            } else {
                handleVoucher(value).then((resp) => {
                    setLoader(false);
                    swal({
                        position: "center",
                        icon: "success",
                        title: "Voucher Redeemed Successfully",
                        showConfirmButton: false,
                        timer: 5000,
                    });
                    value?.voucherType.toLowerCase() == "discount" && transactionRegistration(tempToken, JSON.stringify(temp))
                }).catch(() => {

                    setLoader(false);
                });
                // CustomerWallets(tempToken, { "customercode": value.customercode }).then((res) => {
                //     console.log(res.data)

                // })



            }
        });
        const merchantcode = decrypt(localStorage.getItem("merchantcode"))
        const res = await getVouchersWallet(tempToken, merchantcode)
        setResponse(res.data)

        setLoader(false);
    };



    useEffect(() => {

        const temp = response;

        if (temp) {
            temp?.map((val, i) => {
                return (val.customerCode = val.customercode);
            });
            temp?.map((val, i) => {
                return (val.vouchercode = val.voucherCode);
            });
            temp?.map((val, ind) => {
                return (val.expiredate = getDate(val.expiryDate, "DD-MMM-YYYY"));
            });
            temp?.map((val, ind) => {
                return (val.value = val.earnedValue)
            });
            temp?.map((val, ind) => {
                return (val.action = <button style={{ textTransform: "capitalize" }} className={val.voucherUsed ? "badge bg-warning" : "badge bg-success"} onClick={() => handleRedeemtion(val)} disabled={val?.voucherUsed} >{val?.voucherUsed ? "redeemed" : "redeem"}</button>);
            });
            const currentDate = new Date();
            const filteredData =
                filterOption === "all"
                    ? temp
                    : temp.filter((val) => {
                        if (filterOption === "Redeemed") {
                            return val.voucherUsed === true;
                        } else if (filterOption === "InActive") {
                            return new Date(val.expiryDate) < currentDate;
                        }
                        else if (filterOption === "Active") {
                            return val.voucherUsed === false;
                        }
                        return false;
                    });
            filteredData?.reverse()
            setData(filteredData);
        }
    }, [filterOption, response]);

    return (
        <>
            <Header name={"Vouchers"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__transactionsCard">
                        <div className="dashboard__header">
                            <div className="dashboard__left">
                                <h4 id='transction_list'>All Vouchers List</h4>
                            </div>
                        </div>
                        <div className="dashboard__body">
                            <div className="dashboard__heading" style={{ justifyContent: "end", marginBottom: "0" }}>
                                <div className="dashboard__headingRight">
                                    <div className="form-group">
                                        <ValidatorForm onSubmit={onSubmit}>
                                            <SelectValidator placeholder="Select" className="" name="choice" value={selectValueGetter(option, valueselect)} options={option} onChange={unPeople} />
                                        </ValidatorForm>
                                    </div>
                                </div>
                            </div>
                            <AllVoucherPaginated data={data} columns={COLUMNS} loader={loader} setLoader={setLoader} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AllVouchers


