import { GET_FINANCIAL_ACCOUNT, GET_PROFILE_SETTING, UPDATE_PROFILE_SETTING, CLEAR_SETTING } from "../actions/type";

const initialState = {
    companyname: "",
    contactnumber: "",
    currency: null,
    email: "",
    industry: "",
    msg: "",
    name: "",
    need: "",
    projectdetails: "",
    rewardRatio: "",
    timeZone: "",
    message: "",
    ListData: null,
    errors: null,
    recordCount: 0,
    statusCode: 0,
    totalCount: 0,
    data: {},
    cardStatus: undefined,
    moduleAccess: [],
    packageNameList: [],
    plan: "",
};

const Setting = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case CLEAR_SETTING:
            return {
                ...state,
                companyname: "",
                contactnumber: "",
                currency: null,
                email: "",
                industry: "",
                msg: "",
                name: "",
                need: "",
                projectdetails: "",
                rewardRatio: "",
                timeZone: "",
                message: "",
                ListData: null,
                errors: null,
                recordCount: 0,
                statusCode: 0,
                totalCount: 0,
                data: {},
                cardStatus: "",
                moduleAccess: [],
                packageNameList: [],
                plan: "",
            };
        case GET_PROFILE_SETTING:
            return {
                ...state,
                companyname: payload.companyname,
                contactnumber: payload.contactnumber,
                currency: payload.currency,
                email: payload.email,
                industry: payload.industry,
                msg: payload.msg,
                name: payload.name,
                need: payload.need,
                projectdetails: payload.projectdetails,
                rewardRatio: payload.rewardRatio,
                timeZone: payload.timeZone,
                cardStatus: payload.cardStatus,
                moduleAccess: payload?.moduleAccess||[],
                packageNameList: payload?.packageNameList||[],
                plan: payload.plan,
            };
        case UPDATE_PROFILE_SETTING:
            return {
                ...state,
                message: payload.message,
            };
        case GET_FINANCIAL_ACCOUNT:
            return {
                ...state,
                ListData: payload.ListData,
                errors: payload.errors,
                message: payload.message,
                recordCount: payload.recordCount,
                statusCode: payload.statusCode,
                totalCount: payload.totalCount,
                data: payload,
            };
        default:
            return state;
    }
};
export default Setting;
