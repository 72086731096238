import React, { useState, useEffect } from "react";
import { Internal_Token } from "../actions/auth";

function RefreshInternalToken() {
	// eslint-disable-next-line
	const [token, setToken] = useState(null);

	const fetchToken = async () => {
		let body = {
			clientid: localStorage.getItem("clientid"),
			grant_type: "client_credentials",
			clientsecret: localStorage.getItem("clientsecret"),
		};
		const response = await Internal_Token(body);

		localStorage.setItem("token_gen", JSON.stringify(response));
		setToken(response);
	};

	useEffect(() => {
		const intervalId = setInterval(() => {
			fetchToken();
		}, 58 * 60 * 1000);

		return () => clearInterval(intervalId);
	}, []);

	return <></>;
}
export default RefreshInternalToken;
