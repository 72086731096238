import React, { Fragment } from "react";
import Header from "./Header";
import { payment_failed } from "../../Image/Index";
class CanclePayment extends React.Component {
    render() {
        return (
            <>
                <Header name={"Payment Failed"} />
                <div className="dashboard__content">
                    <div className="container-fluid">
                        <div className="payment_status pfailed">
                            <img src={payment_failed} width={60}/>
                            <h2>Payment Failed</h2>
                            <p>Oops! It seems there was an issue with payment for your subscription. Please check the following:</p>
                            <ul>
                                <li>	Ensure your payment details are correct. </li>
                                <li> 	Verify that your payment method has sufficient funds.</li>
                                <li>	Double-check that your card is not expired.</li>
                            </ul>
                            <p>If you continue to experience problems, please contact Novus support team for assistance.</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default CanclePayment;
