import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { decrypt } from "../../utils/encodingdecoding";

const LineChartAnalyticalReport1 = ({ data, title, amount, predictedData, histPredData, growthAvailable }) => {
    const [formData, setFormData] = useState({
        labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October"],
        datasets: [
            {
                label: "Historical",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: true,
                tension: 0.4,
                backgroundColor: "rgb(224, 109, 178, 0.35)",
                borderColor: "#e06db2",
                borderWidth: 2,
                pointStyle: "none",
                pointRadius: 8,
                pointBorderWidth: 2,
                pointBorderColor: "#e06db2",
                pointBackgroundColor: "#ffffff",
                pointHoverRadius: 8,
                pointHoverBorderWidth: 2,
                pointHoverBackgroundColor: "#ffffff",
            },
            {
                label: "Growth Prospect",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: true,
                tension: 0.4,
                backgroundColor: "rgb(45, 142, 219, 0.35)",
                borderColor: "#2d8edb",
                borderWidth: 2,
                pointStyle: "circle",
                pointRadius: 8,
                pointBorderWidth: 2,
                pointBorderColor: "#2d8edb",
                pointBackgroundColor: "#ffffff",
                pointHoverRadius: 8,
                pointHoverBorderWidth: 2,
                pointHoverBackgroundColor: "#ffffff",
            },
            {
                label: "Predicted",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: true,
                tension: 0.4,
                backgroundColor: "rgb(224, 202, 225, 1)",
                borderColor: "#e58cc3",
                borderWidth: 2,
                pointStyle: "none",
                pointRadius: 8,
                pointBorderWidth: 2,
                pointBorderColor: "#e58cc3",
                pointBackgroundColor: "#ffffff",
                pointHoverRadius: 8,
                pointHoverBorderWidth: 2,
                pointHoverBackgroundColor: "#ffffff",
            },
            {
                label: "Growth Available",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: true,
                tension: 0.4,
                // backgroundColor: "rgb(144, 147, 139, 1)",
                borderColor: "#767b6b",
                borderWidth: 2,
                pointStyle: "circle",
                pointRadius: 8,
                pointBorderWidth: 2,
                pointBorderColor: "#767b6b",
                pointBackgroundColor: "#ffffff",
                pointHoverRadius: 8,
                pointHoverBorderWidth: 2,
                pointHoverBackgroundColor: "#ffffff",
            },
        ],
    });

    const options = {
        indexAxis: "x",
        element: {
            legend: {
                position: "top",
            },
        },
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };
    // useEffect(() => {
    // 	data.length && setFormData({
    // 		...formData,
    // 		datasets: formData?.datasets.map((obj, ind) => {
    // 			if (ind == 1) return { ...obj, label: title, data: data.length > 9? data : data[0] };
    // 			return obj;
    // 		}),
    // 	});
    // }, [title])

    useEffect(() => {
        data?.length &&
            setFormData({
                ...formData,
                datasets: formData?.datasets.map((obj, ind) => {
                    if (ind == 1) return { ...obj, data: data.length > 9 ? data : data[0] };
                    return obj;
                }),
            });
    }, [data]);
    useEffect(() => {
        setFormData({
            ...formData,
            datasets: formData?.datasets.map((obj, ind) => {
                if (ind == 0 && histPredData?.length) return { ...obj, data: histPredData };
                if (ind == 2 && predictedData?.length) return { ...obj, data: predictedData?.length > 9 ? predictedData : predictedData[0] };
                if (ind == 3 && growthAvailable?.length) return { ...obj, data: growthAvailable?.length > 9 ? growthAvailable : growthAvailable[0] };
                return obj;
            }),
        });
    }, [predictedData, histPredData, growthAvailable]);

    return (
        <>
            <div className="analyticalReportChartAnalysisTwo__lineChartReport lineChartReport__">
                &nbsp;
                <Line data={formData} options={options} />
            </div>
        </>
    );
};

export default LineChartAnalyticalReport1;
