import { PROFILE_DROPDOWN, INTRO_DOCUMENTATION, INTROMODAL, START_TOUR,COUNT_FIRST,SHOW_MODAL ,PAYMENT_MODAL} from "../actions/type";
const initialState = {
    dropDownSetting: false,
    documentation: false,
    modalOpen: false,
    startTours: false,
    countFirst:0,
    showModal:0,
    paymentMoadal:0
};


const intro = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case PROFILE_DROPDOWN:

            return { ...state, dropDownSetting: payload };
        case INTRO_DOCUMENTATION:
            return { ...state, documentation: payload };
        case INTROMODAL:
            return { ...state, modalOpen: payload };
        case START_TOUR:
            return { ...state, startTours: payload }
            case COUNT_FIRST:
                return { ...state, countFirst: payload }
              case SHOW_MODAL:
                return { ...state, showModal: payload }
        case PAYMENT_MODAL:
            return { ...state, paymentMoadal: payload }
        default:
            return state;
    }
};
export default intro;
