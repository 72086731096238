import React, { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import { Link, useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { backIcon2 } from "../Image/Index";
import { element } from "prop-types";
import introJs from "intro.js";
import "intro.js/introjs.css";
import ModalCard from "../components/layout/ModalCard";
import AccordionButton from "../components/pages/userJourney/userJourneyPartials/AccordionButton";
const IntroBusinessGoalsSelection = () => {
    const navigate = useNavigate();
    const [activeKey, setActiveKey] = useState("-1");
    const [state, setState] = useState({
        data: [],
        goals: [],
    });
    let exitOnLast = false;
    const [show, setshow] = useState(false);
    const intro = introJs();

    const { goals, data } = state;

    let staticData = [
        {
            industry: "E-commerce",
            goals: [
                {
                    businessgoal: "Brand Awareness",
                    description: "Grab potential customers when they become aware of your business through various rewards programs such as loyalty points, new deals & offers, and gift cards",
                },
                {
                    businessgoal: "Gain Consideration",
                    description: "Customers typically research and compare products, prices, and reviews on the website or through other sources. At this stage give great deals and offers on various products to engage customers.",
                },
                {
                    businessgoal: "Improve Conversions",
                    description: "Offer Valuable and appealing loyalty programs to your customers. Rewards can include discounts on future purchases, free shipping, exclusive access to products, or new deals, cashback and many more.",
                },
                {
                    businessgoal: "Post-Purchase Activities",
                    description: "Keep engaging with your customers after the purchase as well. This is an excellent opportunity for your business to provide personalized communication to the customer, including information about related products or promotions, as well as asking for feedback on the purchase experience.",
                },
                {
                    businessgoal: "Increase Retention",
                    description: "Focus on retaining customers through various strategies such as customer loyalty programs, personalized product recommendations, and special deals and promotions.",
                },
                {
                    businessgoal: "Build brand Advocacy",
                    description: "Incentivize your customers to become brand advocates and share their positive experiences with others through social media, reviews, and word-of-mouth recommendations.",
                },
            ],
        },
        {
            industry: "Transportation",
            goals: [
                {
                    businessgoal: "Research and Booking",
                    description: "This stage involves the customer researching and selecting a transportation service, such as a taxi or a ride-hailing app, and making a booking. This stage must include some exciting offers and best suited pricing option.",
                },
                {
                    businessgoal: "Departure",
                    description: "This stage involves the customer traveling to the transportation pick-up point, such as a taxi stand or a designated pick-up location for a ride-hailing app. Try to offer points for ride booking and various redemption options to make ride affordable",
                },
                {
                    businessgoal: "In-transit experience",
                    description: "This stage involves the customer traveling to their destination and experiencing the transportation service. This can include interactions with the driver or other passengers, as well as any amenities and deals provided by the service.",
                },
                {
                    businessgoal: "Arrival and post-trip experience",
                    description: "This stage involves the customer arriving at their destination and completing any necessary payment or feedback for the transportation service. Offer various discounts to customers for their next trip to encourage them choose your services again.",
                },
            ],
        },
        {
            industry: "Travel",
            goals: [
                {
                    businessgoal: "Inspiration",
                    description: "At this stage, customers become inspired to travel through various channels such as advertising, social media, or word-of-mouth referrals.",
                },
                {
                    businessgoal: "Research",
                    description: "Provide customers with better pricing options and great deals (Once inspired, customers begin researching different travel options, comparing features, pricing, and reading reviews. This includes researching different destinations, hotels, airlines, and travel packages, as well as visiting their websites)",
                },
                {
                    businessgoal: "Planning",
                    description: "After researching, the customer may engage with a travel agent or an online booking platform to plan their trip. This stage require sharing exclusive deals to make their travelling cheap and convenient.",
                },
                {
                    businessgoal: "Booking",
                    description: "Once the customer has made a decision, they will book their trip through a travel agent or an online booking platform that provides them with best deals. This stage requires a straightforward and transparent booking process, with an efficient checkout process.",
                },
                {
                    businessgoal: "Pre-trip",
                    description: "Before the trip, customers may require ongoing support and access to travel information. This includes travel guildes, documents, hotels tie-up, special rewards etc.",
                },
                {
                    businessgoal: "On-trip",
                    description: "During the trip, customers may require additional support and access to local information. This includes transportation, accommodation, tours, and activities.",
                },
                {
                    businessgoal: "Post-trip",
                    description: "After the trip, customers may require additional support and access to feedback and reviews. This includes feedback on the travel experience. Share points and incentive for such activities.",
                },
                {
                    businessgoal: "Loyalty",
                    description: "The final stage is where satisfied customers become loyal to the travel provider and recommend it to others through word-of-mouth referrals, social media, or online reviews.",
                },
            ],
        },
        {
            industry: "Retail",
            goals: [
                {
                    businessgoal: "Awareness",
                    description: "Push customer for signup with rewards program(At this stage, potential customers become aware of retail stores and brands through various channels such as advertising, referrals, or social media).",
                },
                {
                    businessgoal: "Research",
                    description: "Provide customers with better pricing options and great deals (Once aware, customers begin researching different products, comparing features, and pricing, and reading reviews. This includes researching different retailers, brands, and products, as well as visiting their websites.)",
                },
                {
                    businessgoal: "Consideration",
                    description: "After researching, the customer may visit a store or engage to get more information about products. This stage is crucial for the retailer to provide best deals on products and build customer loyalty.",
                },
                {
                    businessgoal: "Purchase",
                    description: "Once the customer has made a decision, they will make a purchase, either in-store or online. This stage requires a straightforward and transparent purchase process, with different heart-winning payments options and delivery process.",
                },
                {
                    businessgoal: "Post-Purchase",
                    description: "After the purchase, customers may require ongoing support and access to related product information and various deals and offers.",
                },
                {
                    businessgoal: "Loyalty",
                    description: "The final stage is where satisfied customers become loyal to the retailer and recommend it to others through word-of-mouth referrals, social media, or online reviews.",
                },
            ],
        },
        {
            industry: "Healthcare",
            goals: [
                {
                    businessgoal: "Brand Awareness",
                    description: "Improve patient outcomes when they become aware of your healthcare services through various loyalty and reward programs.",
                },
                {
                    businessgoal: "Gain Consideration",
                    description: "Patients research different healthcare providers, compare features, and pricing, and read reviews. Offer them discounts on healthcare services, personalized healthcare plans, or even access to exclusive events or services.",
                },
                {
                    businessgoal: "Treatment Stage",
                    description: "Once the patient has made a decision, they will receive treatment, which may include consultations, tests, procedures, or medication. You need to provide them with excellent offers, rewards, and service to keep them engaged with your brands.",
                },
                {
                    businessgoal: "Follow-up",
                    description: "After treatment, provide customers with personalized communication, discounted check-ups, free health plans, or medication. Keep customers engaged with various customer loyalty programs.",
                },
                {
                    businessgoal: "Loyalty",
                    description: "Provide a positive experience, and attract & retain patients to drive long-term success and referrals through word-of-mouth marketing. Encourage loyalty with Personalized communication and special promotions to patients.",
                },
            ],
        },
        {
            industry: "Automotive",
            goals: [
                {
                    businessgoal: "Brand Awareness",
                    description: "Grab potential customers when they become aware of your business through various service rewards and loyalty programs for old or new vehicles.",
                },
                {
                    businessgoal: "Gain Consideration",
                    description: "Customers typically research and compare products, prices, and reviews on the website or through other sources. Provide incentives for vehicle upgrades with the dealership, such as discounts or special financing rates to influence their purchasing decision",
                },
                {
                    businessgoal: "Increase Purchases",
                    description: "Offer Valuable and appealing loyalty programs to your customers. Rewards can include discounts on future services and maintenance, including exclusive access to new deals, and many more.",
                },
                {
                    businessgoal: "Take the Ownership",
                    description: "Provide ongoing support to the customer with personalized communication, offers, and discounts on maintenance services, repairs, and accessories. This is a crucial stage for building customer loyalty and retention.",
                },
                {
                    businessgoal: "Build Advocacy",
                    description: "Push your satisfied customers to become brand advocates by offering them special offers and deals to increase word-of-mouth referrals, and online reviews.",
                },
            ],
        },
        {
            industry: "RealEstate",
            goals: [
                {
                    businessgoal: "Awareness",
                    description: "Push customer for signup with rewards program(At this stage, potential customers become aware of retail stores and brands through various channels such as advertising, referrals, or social media)",
                },
                {
                    businessgoal: "Research",
                    description: "Provide customers with better pricing options and great deals (Once aware, customers begin researching different products, comparing features, and pricing, and reading reviews. This includes researching different retailers, brands, and products, as well as visiting their websites.) ",
                },
                {
                    businessgoal: "Consideration",
                    description: "After researching, the customer may visit a store or engage to get more information about products. This stage is crucial for the retailer to provide best deals on products and build customer loyalty",
                },
                {
                    businessgoal: "Purchase",
                    description: "Once the customer has made a decision, they will make a purchase, either in-store or online. This stage requires a straightforward and transparent purchase process, with different heart-winning payments options and delivery process",
                },
                {
                    businessgoal: "Post-Purchase",
                    description: "After the purchase, customers may require ongoing support and access to related product information and various deals and offers. ",
                },
                {
                    businessgoal: "Loyalty",
                    description: "The final stage is where satisfied customers become loyal to the retailer and recommend it to others through word-of-mouth referrals, social media, or online reviews.",
                },
            ],
        },
        {
            industry: "Restaurant",
            goals: [
                {
                    businessgoal: "Awareness and Discovery",
                    description: "Push for a visit or online ordering with exclusive offers(This stage involves the customer becoming aware of a restaurant through advertising, online reviews, word-of-mouth, or other means)",
                },
                {
                    businessgoal: "Research and Booking",
                    description: "Offer best pricing and great deals. This stage involves the customer researching the restaurant online, reading reviews, checking menus, and booking a table either online, via phone or through an app.",
                },
                {
                    businessgoal: "Ordering",
                    description: "This stage involves the customer ordering food and drinks from the server, who may make recommendations or answer any questions the customer has.",
                },
                {
                    businessgoal: "Dining Experience",
                    description: "This stage involves the customer enjoying their meal and any additional experiences the restaurant may offer, such as live music, wine tastings or dessert courses.",
                },
                {
                    businessgoal: "Payment and Feedback",
                    description: "This stage involves the customer settling their bill and providing feedback on their experience, either by completing a survey, leaving a review or talking to the server or manager.",
                },
            ],
        },
        {
            industry: "FinancialServices",
            goals: [
                {
                    businessgoal: "Brand Awareness",
                    description: "Grab the potential customers when they become aware of your business through various rewards programs such as loyalty points, new deals & offers and gift cards.",
                },
                {
                    businessgoal: "Gain Consideration",
                    description: "Once aware, customers begin researching different financial services, comparing features, pricing, and reading reviews. At this stage give great deals and offers on various credit cards and services to engage customers.",
                },
                {
                    businessgoal: "Increase Purchases",
                    description: "Before opening a bank account, getting a loan, or applying for a credit card customer always looks for great deals and offers. This stage requires various rewards and incentives to keep customers engaged.",
                },
                {
                    businessgoal: "Take the Ownership",
                    description: "Provide customers with new offers and deals after the purchase to retail them for long.",
                },
                {
                    businessgoal: "Build Loyalty",
                    description: "Convert your customers into brand advocates to increase the word-of-mouth referrals, and online reviews.",
                },
            ],
        },
    ];
    useEffect(() => {
        setState({ ...state, data: staticData });
    }, []); //eslint-disable-line

    useEffect(() => {
        data.map((item) => {
            if (item.industry === "E-commerce") {
                setState({ ...state, goals: item.goals });
            }
        });
    }, [data]); //eslint-disable-line

    let complete = false;

    useEffect(() => {
        const handleLoad = () => {
            const steps = [
                {
                    element: document.getElementById("businessGoal"),
                    title: "Awareness  ",
                    intro: "Leverage the Brand Awareness feature to craft loyalty campaigns focused on raising awareness of your brand. ",
                },
                {
                    element: document.getElementById("select_camp"),
                    title: "Select Campaign  ",
                    intro: "Choose from your existing campaigns to make necessary updates efficiently. ",
                },
            ];
            intro.setOptions({
                steps: steps,
                doneLabel: "Next",
                exitOnOverlayClick: false,
                tooltipClass: "customTooltip",
            });

            intro.start();
        };
        setTimeout(() => {
            handleLoad();
        }, 500);
        return () => {
            intro.exit();
        };
    }, []);
    intro.oncomplete(function (element) {
        complete = true;
        setTimeout(() => {
            if (!exitOnLast) {
                navigate("/intro/sample-campaigns");
            }
        }, 700);
    });
    intro.onexit(() => {
        if (!complete) {
            localStorage.setItem("firstLogin", "");
            setshow(true);
        }
    });
    intro.onchange(function (element) {
        switch (this._currentStep) {
            case 1:
                setTimeout(() => {
                    let cross = document.getElementsByClassName("introjs-skipbutton")[0];
                    cross?.addEventListener("click", function () {
                        exitOnLast = true;
                        localStorage.setItem("firstLogin", "");
                        setshow(true);
                    });
                    
                }, 600);

                break;
        }
    });
    return (
        <>
            <Header name={"Business Goal Selections"} />

            {show && <ModalCard show={show} />}
            <div className="businessGoalsSelection">
                <div className="businessGoalsSelection__topSection">
                    <div className="container">
                        <div className="businessGoalsSelection__leftSection">
                            <div className="dashboard__headingBackLink dashboard__headingBackLink2">
                                <Link>
                                    <img style={{ width: "18px" }} src={backIcon2} alt="" />
                                    <span>Back</span>
                                </Link>
                            </div>
                            <h4 id="business_goals">Select your Business Goal</h4>
                        </div>
                    </div>
                </div>
                <div className="businessGoalsSelection__bottomSection">
                    <div className="container">
                        <Accordion className="businessGoalsSelection__accordion">
                            {goals &&
                                goals.map((item, index) => (
                                    <Card id={index === 0 ? "businessGoal" : ""} key={index} className="businessGoalsSelection__accordionItem">
                                        <Card.Header className="businessGoalsSelection__accordionHeading">
                                            <div className={index === Number(activeKey) ? "businessGoalsSelection__accordionButton active" : "businessGoalsSelection__accordionButton"}>
                                                <input type="checkbox" value={index} checked={index === 0 ? "checked" : ""} />
                                                {item.businessgoal}
                                                <AccordionButton eventKey={index.toString()} disabled>
                                                    <i className="fa-solid fa-chevron-right"></i>
                                                </AccordionButton>
                                            </div>
                                        </Card.Header>
                                        <Accordion.Collapse className="businessGoalsSelection__accordionCollapse" eventKey={index.toString()}>
                                            <Card.Body className="businessGoalsSelection__accordionBody"></Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                ))}
                        </Accordion>

                        <div className="businessGoalsSelection__buttonSection">
                            <div className="businessGoalsSelection__buttonSectionLeft">
                                <Link>Skip</Link>
                            </div>
                            <div className="businessGoalsSelection__buttonSectionRight">
                                <Link to="/contact-us" className="btn btn-outline-primary">
                                    Contact Novus Support
                                    <i className="fa-solid fa-comment-dots"></i>
                                </Link>
                                <button className="btn btn-primary" id="select_camp" disabled={true}>
                                    Select Camapigns
                                    <i className="fa-solid fa-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IntroBusinessGoalsSelection;
