import CryptoJS from "crypto-js";

export const encrypt = (args) => {
  try {
    if (args) {
      const ciphertext = CryptoJS.AES.encrypt(args, process.env.REACT_APP_SECRETKEY).toString();
      return encodeURIComponent(ciphertext);
    }
    return null;
  } catch (error) {
    // console.error('Encryption error:', error);
    return null;
  }
};

export const decrypt = (args) => {
  try {
    let plaintext = '';
    if (args) {
      const ciphertext = decodeURIComponent(args); // Remove decodeURIComponent if not URL-encoded
      const bytes = CryptoJS.AES.decrypt(ciphertext, process.env.REACT_APP_SECRETKEY);
      // Check if decryption was successful
      if (bytes.sigBytes > 0) {
        plaintext = bytes.toString(CryptoJS.enc.Utf8);
      } else {
        plaintext = '';
        // Handle decryption failure, e.g., throw an error or return an appropriate value
        // console.error('Decryption failed');
      }
    }
    return plaintext;
  } catch (error) {
    // console.error('Encryption error:', error);
    return null;
  }
};