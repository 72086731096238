import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../actions/authAction";
import { clearCampaigns } from "../actions/campaigns";
import { clearCustomer } from "../actions/customer";
import { clearDashboard } from "../actions/dashboard";
import { clearSetting } from "../actions/setting";
import { clearTransaction } from "../actions/transaction";
import { connect } from "react-redux";
import Swal from "sweetalert2";

const ItedlTimer = ({ logout, clearCampaigns, clearCustomer, clearDashboard, clearSetting, clearTransaction }) => {
    const minutes = 10; // Enter how many minutes you want

    const [lastActive, setLastActive] = useState(new Date());
    const logoutTimeout = minutes * 60 * 1000;
    const navigate = useNavigate();

    useEffect(() => {
        const channel = new BroadcastChannel("last-active-channel");

        channel.onmessage = (event) => {
            setLastActive(new Date(event.data));
        };

        return () => {
            channel.close();
        };
    }, []);

    useEffect(() => {
        const handleActivity = () => {
            const currentTime = new Date().getTime();
            setLastActive(currentTime);

            const channel = new BroadcastChannel("last-active-channel");
            channel.postMessage(currentTime);
        };

        window.addEventListener("click", handleActivity);
        window.addEventListener("keypress", handleActivity);
        window.addEventListener("mousemove", handleActivity);

        return () => {
            window.removeEventListener("click", handleActivity);
            window.removeEventListener("keypress", handleActivity);
            window.removeEventListener("mousemove", handleActivity);
        };
    }, []);

    useEffect(() => {
        let timeoutId;

        const checkLogoutTimeout = () => {
            const now = new Date();
            const elapsed = now - lastActive;

            if (elapsed >= logoutTimeout) {
                  Swal.fire({
                    icon: 'warning',
                    title: "Need Assistance?",
                    text:"Are you experiencing any issues with Novus Loyalty Features or need help? We're here to assist you ",
                    confirmButtonText: 'Live Chat',
                    cancelButtonText: 'Contact Us',
                    confirmButtonColor: "#2056a5",
                    cancelButtonColor:"#2056a5",
                    showCancelButton: true,
                    showCloseButton: true
                  }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = "/";
                    } else if (
                      result.dismiss === Swal.DismissReason.cancel
                    ) {
                        window.location.href = "/contact-us";
                    }
                  })
            } else {
                timeoutId = setTimeout(checkLogoutTimeout, logoutTimeout - elapsed);
            }
        };


        timeoutId = setTimeout(checkLogoutTimeout, logoutTimeout);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [lastActive, logoutTimeout, navigate, logout]);

    return null; 
};

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
});

export default connect(mapStateToProps, {
    logout,
    clearCampaigns,
    clearCustomer,
    clearDashboard,
    clearSetting,
    clearTransaction,
})(ItedlTimer);
