import React from "react";

const HeadingSection = () => {
    return (
        <>
            <div className="dashboard__apiPlaygroundDetails">
                <h4>Use Case</h4>
                <p>This API is used for customer registration.</p>
                <div className="table-responsive">
                    <table className="table table-bordered w-100">
                        <thead>
                            <tr>
                                <th>ATTRIBUTE</th>
                                <th>VALUE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Authorization string mandatory</td>
                                <td>This is a base64 encoded string of “clientId:clientSecret”</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="dashboard__apiPlaygroundDetails">
                <h4>Request Attributes</h4>
                <p>Content Type : JSON</p>
                <h4>Body</h4>
                <div className="table-responsive">
                    <table className="table table-bordered w-100">
                        <thead>
                            <tr>
                                <th>ATTRIBUTE</th>
                                <th>VALUE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>"mobile"</td>
                                <td>Registered Number of Customer</td>
                            </tr>
                            <tr>
                                <td>"name"</td>
                                <td>Name of the customer</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="dashboard__apiPlaygroundDetails">
                <h4>Response Attributes</h4>
                <p>API Content Type: JSON</p>
                <h4>Success Response</h4>
                <div className="table-responsive" style={{ margin: "0 0 30px" }}>
                    <table className="table table-bordered w-100">
                        <thead>
                            <tr>
                                <th>ATTRIBUTE</th>
                                <th>VALUE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>"name"</td>
                                <td>Name of Customer</td>
                            </tr>
                            <tr>
                                <td>"mobile"</td>
                                <td>Mobile Number</td>
                            </tr>
                            <tr>
                                <td>"firstvisit"</td>
                                <td>Customer ever logged in</td>
                            </tr>
                            <tr>
                                <td>"discriminator"</td>
                                <td>Customer</td>
                            </tr>
                            <tr>
                                <td>"customercode"</td>
                                <td>Customer Code Uniquely System Generated</td>
                            </tr>
                            <tr>
                                <td>"merchantId"</td>
                                <td>Merchant ID</td>
                            </tr>
                            <tr>
                                <td>"totalCount"</td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>"message"</td>
                                <td>Request Proceed Successfully</td>
                            </tr>
                            <tr>
                                <td>"statusCode"</td>
                                <td>200</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h4>Failure Response</h4>
                <div className="table-responsive" style={{ margin: "0 0 30px" }}>
                    <table className="table table-bordered w-100">
                        <thead>
                            <tr>
                                <th>ATTRIBUTE</th>
                                <th>VALUE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>error string</td>
                                <td>This is the error corresponding to a particular error description and is returned to the merchant. The different error codes corresponding to this API are mentioned below.</td>
                            </tr>
                            <tr>
                                <td>error_description string</td>
                                <td>This parameter is the error_description which contains information about the failure result.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="alert alert-primary" role="alert" style={{ margin: "0" }}>
                    <p style={{ margin: "0" }}>
                        <strong>Note:</strong>&nbsp;If Authorization header is incorrect then the response will not come and you will face HTTP Status code "401"
                    </p>
                </div>
            </div>
            <div className="dashboard__apiPlaygroundDetails">
                <h4>Response Codes & Messages</h4>
                <div className="table-responsive">
                    <table className="table table-bordered w-100">
                        <thead>
                            <tr>
                                <th>ResponseCode</th>
                                <th>Status</th>
                                <th>Message</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>200</td>
                                <td>SUCCESS</td>
                                <td>OK</td>
                            </tr>
                            <tr>
                                <td>400</td>
                                <td>FAILURE</td>
                                <td>Invalid_client</td>
                            </tr>
                            <tr>
                                <td>401</td>
                                <td>FAILURE</td>
                                <td>Unauthorized Access</td>
                            </tr>

                            <tr>
                                <td>404</td>
                                <td>FAILURE</td>
                                <td>Not Found</td>
                            </tr>
                            <tr>
                                <td>409</td>
                                <td>FAILURE</td>
                                <td>Customer Already Exists</td>
                            </tr>
                            <tr>
                                <td>415</td>
                                <td>FAILURE</td>
                                <td>Unsupporetd Media Type</td>
                            </tr>
                            <tr>
                                <td>500</td>
                                <td>FAILURE</td>
                                <td>Internal Server Error</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default HeadingSection;
