import React, { useRef, useState, useEffect } from "react";
// import { "images/logo", "images/eyeSeen", "images/eyeUnseen", "images/eyeSeenFocus", "images/eyeUnseenFocus", "images/envelope" } from "../../Image/Index";
import { ValidatorForm } from "react-form-validator-core";
import { useNavigate } from "react-router-dom";
import { resetPassword, Token_Generator } from "../../actions/auth";
import PasswordValidator from "../partial/PasswordValidator";
import TextValidator from "../partial/TextValidator";
import AuthenticationSliderLeft from "./common/AuthenticationSliderLeft";
import swal from "sweetalert";

function ResetPassword() {
    const formRef = useRef();
    const navigate = useNavigate();

    const [isRevealPassword, setIsRevealPassword] = useState(false);
    const [isConfirmPassword, setIsConfirmPassword] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);
    const [confirmFocus, setConfirmFocus] = useState(false);

    const [formData, setFormData] = useState({
        password: "",
        email: "",
        EmailConfirmed: "",
        cnfPassword: "",
    });
    const [body, setBody] = useState({
        PasswordHash: "",
        email: "",
        EmailConfirmed: "",
    });
    const [loader, setLoader] = useState(false);
    const { password, email, EmailConfirmed, cnfPassword } = formData;
    useEffect(() => {
        setFormData({ ...formData, ...{ EmailConfirmed: window.location.href.split("code=")[1] } });
        // eslint-disable-next-line
    }, [window.location.href.split("code=")[1]]);

    useEffect(() => {
        ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
            if (value !== password) {
                return false;
            }
            return true;
        });
        // eslint-disable-next-line
    }, [cnfPassword, password]);

    useEffect(() => {
        const validateEmail = (email) => {
            // eslint-disable-next-line
            return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        };
        ValidatorForm.addValidationRule("validEmail", (value) => {
            if (validateEmail(value)) {
                return true;
            }
            return false;
        });
    }, [email]);

    useEffect(() => {
        setBody({
            passwordUpdateRequest: EmailConfirmed,
            PasswordHash: password,
            email: email,
        });
        // eslint-disable-next-line
    }, [formData]);

    const onChange = (e) => setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });
    const onSubmit = async () => {
        setLoader(true);
        const token = await Token_Generator();
        // const token = JSON.parse(localStorage.getItem("token_gen"));
        const response = await resetPassword(token?.access_token, body);
        if (response.message === "Your Password is Sucessfully Updated") {
            setLoader(false);
            navigate("/reset-thankyou");
        } else {
            swal({
                title: " Oops!",
                text: response.message,
                icon: "error",
                button: "oh no!",
                timer: 5000,
            });
            setLoader(false);
        }

        // navigate("/reset-password");
        // let response = await forgotePassword(token?.access_token,formData);
        // return response
    };

    return (
        <section className="section signUpPage">
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="signUpPage__main">
                <AuthenticationSliderLeft />
                <div className="signUpPage__right">
                    <div className="signUpPage__heading">
                        <figure>
                        <img src={"/Images/logo.png"} alt="logo" />
                        </figure>
                        <h1>Reset Password</h1>
                        <p>Resetting your password is easy! As per your request, the password for your Novus Loyalty account has to be reset.Please enter your existing Email ID and choose the new password.</p>
                        <p>If you didn't request this, you can ignore this or let us know. Your password doesn't change until you create a new password.</p>
                    </div>
                    <div className="signUpPage__form">
                        <ValidatorForm className="signUpPage__formSection"  ref={formRef} onSubmit={(e) => onSubmit(e)}>
                            <div className="form-group withIcon--forgetPassword">
                                <TextValidator validators={["required", "validEmail"]} errorMessages={["Please enter your email", "Enter valid email"]} placeholder="Enter Email ID" className="form-control" name="email" maxLength="50" value={email} type="text" onChange={onChange} autoComplete="new-email"/>
                                <span className="passwordToggle">
                                    <img src={"images/envelope"} alt="" />
                                </span>
                            </div>
                            <div className="form-group withIcon">
                                <span className="passwordToggle">{passwordFocus ? <img className="onFocus" title={isRevealPassword ? "Hide password" : "Show password"} src={isRevealPassword ? "/Images/eyeUnseenFocus.png" : "/Images/eyeSeenFocus.png"} alt="eye" onClick={() => setIsRevealPassword((prevState) => !prevState)} /> : <img title={isRevealPassword ? "Hide password" : "Show password"} src={isRevealPassword ? "/Images/eyeUnseen.png" : "/Images/eyeSeen.png"} alt="eye" onClick={() => setIsRevealPassword((prevState) => !prevState)} />}</span>
                                <PasswordValidator placeholder="New Password" className="form-control" name="password" minLength="6" maxLength="16" value={password} autoComplete="new-password" type={isRevealPassword ? "text" : "password"} onChange={onChange} validators={["required", "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,16}$"]} errorMessages={["Please enter password.", "condition"]} />
                            </div>
                            <div className="form-group withIcon">
                                <span className="passwordToggle">{confirmFocus ? <img className="onFocus" title={isConfirmPassword ? "Hide password" : "Show password"} src={isConfirmPassword ? "/Images/eyeUnseenFocus.png" : "/Images/eyeSeenFocus.png"} alt="eye" onClick={() => setIsConfirmPassword((prevState) => !prevState)} /> : <img title={isConfirmPassword ? "Hide password" : "Show password"} src={isConfirmPassword ? "/Images/eyeUnseen.png" : "/Images/eyeSeen.png"} alt="eye" onClick={() => setIsConfirmPassword((prevState) => !prevState)} />}</span>
                                <PasswordValidator placeholder="Confirm Password" className="form-control" name="cnfPassword" minLength="6" maxLength="16" value={cnfPassword} type={isConfirmPassword ? "text" : "password"} onChange={onChange} validators={["required", "isPasswordMatch"]} errorMessages={[" Please enter confirm password.", "Password Mismatch."]}  />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary btn-lg w-100">
                                    Reset Password
                                </button>
                            </div>
                        </ValidatorForm>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ResetPassword;
