import React, { useState, useEffect } from "react";
import Header from "../components/layout/Header";
import { connect } from "react-redux";
import { getDate } from "../utils/helper";
import { Link, useNavigate } from "react-router-dom";
import { importIcon } from "../Image/Index";
import { saveAs } from "file-saver";
import { getTransaction, clearTransaction } from "../actions/transaction";
import introJs from "intro.js";
import "intro.js/introjs.css";
import ModalCard from "../components/layout/ModalCard";
import { useSelector } from "react-redux";
import { COLUMNS } from "../components/pages/transactionsList/column";
import Paginated from "../components/pages/transactionsList/Paginated";
import TransactionViewModal from "../components/pages/transactionsList/TransactionViewModal";
const IntroTransactionsList = ({ getTransaction, Transaction, clearTransaction }) => {
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [showLogin, setShowLogin] = useState(false);
    const [show, setshow] = useState(false);
    const navigate = useNavigate();
    let exitOnLast = false;
    const [transactionId, setTransactionId] = useState("");
    const intro = introJs();
    const modalOpen = useSelector((state) => state.intro.modalOpen);

    useEffect(() => {
        let tempPageIndex = JSON.parse(localStorage.getItem("TransPageIndex"));
        if (tempPageIndex === 1) {
            fetch();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // setLoader(true);
        const ShortData = async () => {
            const tempData = Transaction?.data?.transactions;
            (await tempData) &&
                tempData.sort(function (a, b) {
                    return new Date(b.transactionProcessedDate) - new Date(a.transactionProcessedDate);
                });

            // tempData?.map((val, ind) => { return (val.customercode = <Link to="/customer-view" state={{ customerCode: val.customer.customercode }}>{val.customer.customercode}</Link>) });
            if (tempData) {
                tempData?.map((val, ind) => {
                    return (val.customercode = val.customer.customercode);
                });
                tempData?.map((val, ind) => {
                    // eslint-disable-next-line
                    return (val.transactionId = val.transactionId);
                });
                tempData?.map((val, ind) => {
                    return (val.transactionProcessedDate = getDate(val.transactionProcessedDate, "DD-MMM-YYYY"));
                });
                // tempData?.map((val, ind) => { return (val.action = <Link to="/" state={{ transactionId: val.customer.customercode }} data-bs-toggle="modal" data-bs-target="#financialAccountPointsTabsModal">View</Link>) });
                tempData?.map((val, ind) => {
                    return (val.action = (
                        <Link to="" state={{ transactionId: val.customer.customercode }} onClick={() => handleViewModal(val.transactionId)}>
                            <i className="fa-solid fa-eye"></i> View Details
                        </Link>
                    ));
                });
                setData([]);
            }
        };
        ShortData();

        // if (tempData.length > 0) setLoader(false);
    }, [Transaction]);

    const fetch = async () => {
        setLoader(true);
        await clearTransaction();
        // const token = await Token_Generator();
        const token = JSON.parse(localStorage.getItem("token_gen"));
        let id = localStorage.getItem("Id");
        // await getTransaction(token.access_token, id, 1, 500);
        // const response = await transaction(token.access_token, id);
        // return response;
        setLoader(false);
    };
    const handleViewModal = (data) => {
        setShowLogin(true);
        setTransactionId(data);
    };
    // eslint-disable-next-line
    const handleExport = () => {
        let tempData = data;
        const replacer = (key, value) => (value === null ? "" : value);
        const header = Object.keys(tempData[0]);
        let csv = tempData.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(","));
        csv.unshift(header.join(","));
        csv = csv.join("\r\n");

        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, "transaction.csv");
    };
    useEffect(() => {
        setLoader(true);
    }, []);
    let complete = false;
    useEffect(() => {
        const handleLoad = () => {
            // temp=1

            const steps = [
                // {
                //     element: document.getElementById('upload_transction'),
                //     title: 'Upload Transaction Data ',
                //     intro: "Enhance your loyalty program's insights by importing transaction data."
                // },
                {
                    element: document.getElementById("teansctions"),
                    title: "Transaction ",
                    intro: "Keep track of customer transactions and activities related to the loyalty program",
                },
                {
                    element: document.getElementById("voucherp"),
                    title: "Vouchers",
                    intro: "Establish and oversee voucher campaigns to encourage customer participation",
                },
            ];
            intro.setOptions({
                steps: steps,
                exitOnOverlayClick: false,
                doneLabel: "Next",
            });

            intro.start();
        };
        setTimeout(() => {
            handleLoad();
        }, 500);
        return () => {
            intro.exit();
        };
    }, []);

    intro.oncomplete(function (element) {
        complete = true;
        setTimeout(() => {
            if (!exitOnLast) {
                navigate("/intro/voucher-template");
                //     window.location.href = "/intro/voucher-template";
            }
        }, 700);
    });
    intro.onexit(() => {
        if (!complete) {
            setshow(true);
            localStorage.setItem("firstLogin", "");
        }
    });
    intro.onchange(function (element) {
        switch (this._currentStep) {
            case 1:
                setTimeout(() => {
                    let cross = document.getElementsByClassName("introjs-skipbutton")[0];
                    cross &&
                        cross?.addEventListener("click", function () {
                            setshow(true);
                            exitOnLast = true;
                            localStorage.setItem("firstLogin", "");
                        });
                }, 850);
                break;
        }
    });
    return (
        <>
            <Header name={"Transaction"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            {show && <ModalCard show={show}/>}
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__transactionsCard">
                        <div className="dashboard__header">
                            <div className="dashboard__left">
                                <h4>Transaction Data</h4>
                                {showLogin && <TransactionViewModal show={showLogin} close={() => setShowLogin(false)} transactionId={showLogin && transactionId} />}
                            </div>
                            <div className="dashboard__right">
                                <Link disabled id="upload_transction" className="btn btn-default">
                                    <img src={importIcon} alt="" /> Upload Transaction
                                </Link>
                            </div>
                        </div>
                        <div className="dashboard__body">
                            <p>No existing Transaction data.</p>
                            <p>You can add the data by uploading a CSV file containing the details.</p>
                        </div>
                    </div>

                    <div className="dashboard__transactionsCard">
                        <div className="dashboard__header">
                            <div className="dashboard__left">
                                <h4 id="transction_list">Transaction List</h4>
                            </div>
                        </div>
                        <table className="table table-striped table-bordered" role="table">
                            <thead>
                                <tr role="row">
                                    <th colSpan="1" role="columnheader">
                                        Customer Code
                                    </th>
                                    <th colSpan="1" role="columnheader">
                                        Transaction ID
                                    </th>
                                    <th colSpan="1" role="columnheader">
                                        Date
                                    </th>
                                    <th colSpan="1" role="columnheader">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody role="rowgroup">
                                <tr role="row">
                                    <td role="cell">****************</td>
                                    <td role="cell">****************</td>
                                    <td role="cell">****************</td>
                                    <td role="cell">
                                        <a href="/transactions-list">
                                            <i className="fa-solid fa-eye"></i> View Details
                                        </a>
                                    </td>
                                </tr>
                                <tr role="row">
                                    <td role="cell">****************</td>
                                    <td role="cell">****************</td>
                                    <td role="cell">****************</td>
                                    <td role="cell">
                                        <a href="/transactions-list">
                                            <i className="fa-solid fa-eye"></i> View Details
                                        </a>
                                    </td>
                                </tr>
                                <tr role="row">
                                    <td role="cell">****************</td>
                                    <td role="cell">****************</td>
                                    <td role="cell">****************</td>
                                    <td role="cell">
                                        <a href="/transactions-list">
                                            <i className="fa-solid fa-eye"></i> View Details
                                        </a>
                                    </td>
                                </tr>
                                <tr role="row">
                                    <td role="cell">****************</td>
                                    <td role="cell">****************</td>
                                    <td role="cell">****************</td>
                                    <td role="cell">
                                        <a href="/transactions-list">
                                            <i className="fa-solid fa-eye"></i> View Details
                                        </a>
                                    </td>
                                </tr>
                                <tr role="row">
                                    <td role="cell">****************</td>
                                    <td role="cell">****************</td>
                                    <td role="cell">****************</td>
                                    <td role="cell">
                                        <a href="/transactions-list">
                                            <i className="fa-solid fa-eye"></i> View Details
                                        </a>
                                    </td>
                                </tr>
                                <tr role="row">
                                    <td role="cell">****************</td>
                                    <td role="cell">****************</td>
                                    <td role="cell">****************</td>
                                    <td role="cell">
                                        <a href="/transactions-list">
                                            <i className="fa-solid fa-eye"></i> View Details
                                        </a>
                                    </td>
                                </tr>
                                <tr role="row">
                                    <td role="cell">****************</td>
                                    <td role="cell">****************</td>
                                    <td role="cell">****************</td>
                                    <td role="cell">
                                        <a href="/transactions-list">
                                            <i className="fa-solid fa-eye"></i> View Details
                                        </a>
                                    </td>
                                </tr>
                                <tr role="row">
                                    <td role="cell">****************</td>
                                    <td role="cell">****************</td>
                                    <td role="cell">****************</td>
                                    <td role="cell">
                                        <a href="/transactions-list">
                                            <i className="fa-solid fa-eye"></i> View Details
                                        </a>
                                    </td>
                                </tr>
                                <tr role="row">
                                    <td role="cell">****************</td>
                                    <td role="cell">****************</td>
                                    <td role="cell">****************</td>
                                    <td role="cell">
                                        <a href="/transactions-list">
                                            <i className="fa-solid fa-eye"></i> View Details
                                        </a>
                                    </td>
                                </tr>
                                <tr role="row">
                                    <td role="cell">****************</td>
                                    <td role="cell">****************</td>
                                    <td role="cell">****************</td>
                                    <td role="cell">
                                        <a href="/transactions-list">
                                            <i className="fa-solid fa-eye"></i> View Details
                                        </a>
                                    </td>
                                </tr>
                                <tr role="row">
                                    <td role="cell">****************</td>
                                    <td role="cell">****************</td>
                                    <td role="cell">****************</td>
                                    <td role="cell">
                                        <a href="/transactions-list">
                                            <i className="fa-solid fa-eye"></i> View Details
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    Transaction: state.Transaction,
    modalOpen: state.intro.modalOpen,
});
const mapDispatchToProps = {
    getTransaction,
    clearTransaction,
};

export default connect(mapStateToProps, mapDispatchToProps)(IntroTransactionsList);
