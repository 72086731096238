import React, { useState, useEffect } from "react";
import { Modal, Button, CloseButton } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import SelectValidator from "../partial/SelectValidator";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../partial/TextValidator";
import { GenerateVoucher } from "../../actions/auth";
import swal from "sweetalert";

const VoucherGenerateModal = (props) => {
    const [formData, setFormData] = useState({
        value: "",
        quantity: "",
        options: [],
    });
    const [loader, setLoader] = useState(false);
    const { value, quantity, options } = formData;
    const { generateData, setShowGenModal } = props;

    useEffect(() => {
        const tempOption = [];
        const tempValue = generateData.voucherValue.split(",");
        tempValue?.map((val) => {
            if (val) {
                return tempOption.push({
                    value: val,
                    label: val,
                });
            }
        });
        setFormData({ ...formData, ...{ options: tempOption } });
    }, []);

    const handleChange = (e) => {
        setFormData((prev) => ({ ...prev, quantity: e.target.value }));
    };

    const onSelectChange = (e) => {
        setFormData({ ...formData, value: { ...e } });
    };

    const onSubmit = async () => {
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const body = { code: generateData.code, value: parseInt(formData.value.value), generateVoucher: parseInt(formData.quantity) };
        setLoader(true);
        let response = await GenerateVoucher(token?.access_token, body);
        setLoader(false);
        if (response.id) {
            setShowGenModal(false);
            swal({
                position: "center",
                icon: "success",
                title: "Refresh the page your voucher has been created successfully.",
                showConfirmButton: false,
                timer: 5000,
            });
        } else {
            setShowGenModal(false);
            swal({
                position: "center",
                icon: "error",
                title: response.message,
                showConfirmButton: false,
                timer: 5000,
            });
        }
    };

    return (
        <>
            <Modal show={props.show} onHide={props.close} size="lg" centered>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: "20px" }}>File Generate</Modal.Title>
                    <CloseButton onClick={props.close} variant="white" />
                </Modal.Header>
                <Modal.Body className="dashboard__transactionsModal" style={{ position: "relative", minHeight: "85px" }}>
                    {loader ? (
                        <div className="mainPreloaderMain" style={{ position: "absolute" }}>
                            <div className="mainPreloader">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    ) : (
                        <div className="dashboard__transactionsModalProfile">
                            <div className="dashboard__transactionsModalDetails">
                                <div className="dashboard__transactionsModalBottom">
                                    <ValidatorForm onSubmit={onSubmit}>
                                        <ul>
                                            <li>
                                                <label>Select Denomination value</label>
                                                <SelectValidator placeholder="Select" className="custom-ReactSelect bordered" name="value" value={value} selected={value} options={options} onChange={onSelectChange} validators={["required"]} errorMessages={["Please enter your industry"]} />
                                            </li>
                                            <li>
                                                <label>Number of Generate Voucher:</label>
                                                <TextValidator
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    maxLength="10"
                                                    value={quantity}
                                                    onChange={(e) => {
                                                        const inputValue = e.target.value.replace(/[^0-9]/g, "");
                                                        handleChange({ target: { value: inputValue } });
                                                    }}
                                                    placeholder=""
                                                    validators={["required"]}
                                                    errorMessages={["Required"]}
                                                    className="form-control"
                                                    name="quantity"
                                                />
                                            </li>
                                        </ul>
                                    </ValidatorForm>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onSubmit} disabled={formData.quantity <= 0 || formData.value === ""}>
                        Submit
                    </Button>
                    {/* <Button onClick={props.close}>Close</Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default VoucherGenerateModal;
