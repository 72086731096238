import { Link } from "react-router-dom";

export const COLUMNS = [
   
    {
        Header: "Name",
        Footer: "Name",
        accessor: "name",
        disableFilters: true,
        sticky: "left",
    },

    {
        Header: "Email",
        Footer: "Email",
        accessor: "email",
    },
    {
        Header: "Contact Number",
        Footer: "Contact Number",
        accessor: "mobile",
    },
  
    {
        Header: "Earned Value",
        Footer: "Earned Value",
        accessor: "earnedValue",
    },
    {
        Header: "Available Value",
        Footer: "Available Value",
        accessor: "availableValue",
    },
    {
        Header: "Redeem Value",
        Footer: "Redeem Value",
        accessor: "redeemValue",
    },
    
    // {
    //     Header: "Update Date",
    //     Footer: "Update Date",
    //     accessor: "updatedDate",
    // },

    {
        Header: "Action",
        Footer: "Action",
        accessor: "action",
        Cell: ({ value }) => (
            <Link
                to={{
                    pathname: `/customer-view/${value}`,
                    state: { customerCode: value },
                }}
            >
                <i className="fa-solid fa-eye"></i> View Details
            </Link>
        ),
    },
];
