import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

const BarChartAnalyticalReport1 = ({data}) => {
	const [Data, setData] = useState({
		labels: ["18-20", "21-30", "31-40", "41-50", "51-60", "61-70", "71-80", "81-90"],
		datasets: [
			{
				label: "Transaction Count ",
				data: [0, 0, 0, 0, 0, 0, 0, 0],
				fill: true,
				tension: 0.4,
				backgroundColor: "#2d8edb",
			},
		],
	});
	
	useEffect(() => {
		setData({ ...Data, datasets: [{ ...Data.datasets[0], data }] });
	},[data])

	const Options = {
		indexAxis: "x",
		element: {
			legend: {
				position: "top",
			},
		},
		responsive: true,
		scales: {
			y: {
				beginAtZero: true,
			},
		},
	};

	return (
		<>
			<div className="analyticalReportChartAnalysisTwo__barChartReport">
				&nbsp;
				<Bar data={Data} options={Options} />
			</div>
		</>
	);
};

export default BarChartAnalyticalReport1;
