import { getNewAuthToken, isTokenExpired } from "../utils";
import { baseURL } from "../utils/url";
import { CLEAR_TRANSACTION_LIST, GET_TRANSACTION_LIST, SEARCH_TRANSACTION_LIST} from "./type";

export const getTransaction = (token, id, offset, limit) => async (dispatch) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `transaction?id=${id}&offset=${offset}&limit=${limit}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // body: JSON.stringify(data),
            // id: data,
        });
        fetchResponse
            .json()
            .then((res) => {
                if (res) {
                    dispatch({
                        type: GET_TRANSACTION_LIST,
                        payload: res,
                    });
                    return res;
                } else {
                    return null;
                }
            })
            .catch(() => {
                return null;
            });
    } catch (error) {
        return error;
    }
};

export const searchTransaction = (token,value) => async (dispatch) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `searchTransaction?value=${value}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        fetchResponse
            .json()
            .then((res) => {
                if (res) {
                    dispatch({
                        type: SEARCH_TRANSACTION_LIST,
                        payload: {totalCount: res?.totalCount, transactions: res?.data},
                    });
                    return {totalCount: res?.totalCount, custtransactionsomers: res?.data};
                } else {
                    return null;
                }
            })
            .catch(() => {
                return null;
            });
    } catch (error) {
        return error;
    }
};

export const clearTransaction = () => async (dispatch) => {
    dispatch({ type: CLEAR_TRANSACTION_LIST });
};
