import React, { useState, useEffect } from "react";
import Header from "../components/layout/Header";
import { Link, useNavigate } from "react-router-dom";
import { importIcon } from "../Image/Index";
import introJs from "intro.js";
import "intro.js/introjs.css";
import ModalCard from "../components/layout/ModalCard";
import { COLUMNS } from "../components/pages/customersList/column";
import Paginated from "../components/pages/customersList/Paginated";

const IntroCustomersList = () => {
    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    let exitOnLast = false;
    const intro = introJs();

    let tempData = [
        { action: "*******", createdAt: "*******", customercode: "*******", id: "*******", mobile: "*******", name: "*******" },
        { action: "*******", createdAt: "*******", customercode: "*******", id: "*******", mobile: "*******", name: "*******" },
        { action: "*******", createdAt: "*******", customercode: "*******", id: "*******", mobile: "*******", name: "*******" },
        { action: "*******", createdAt: "*******", customercode: "*******", id: "*******", mobile: "*******", name: "*******" },
        { action: "*******", createdAt: "*******", customercode: "*******", id: "*******", mobile: "*******", name: "*******" },
        { action: "*******", createdAt: "*******", customercode: "*******", id: "*******", mobile: "*******", name: "*******" },
        { action: "*******", createdAt: "*******", customercode: "*******", id: "*******", mobile: "*******", name: "*******" },
        { action: "*******", createdAt: "*******", customercode: "*******", id: "*******", mobile: "*******", name: "*******" },
    ];

    useEffect(() => {
        setData(tempData);
    }, []);

    let complete = false;
    useEffect(() => {
        const handleLoad = () => {
            const steps = [
                {
                    element: document.getElementById("customer_intro"),
                    title: "Customers ",
                    intro: " Oversee and track customer interactions and engagement with your loyalty program.",
                },
            ];
            intro.setOptions({
                steps: steps,
                exitOnOverlayClick: false,
                doneLabel: "Next",
                tooltipClass: "customTooltip",
            });

            intro.start();
        };
        setTimeout(() => {
            handleLoad();
        }, 500);
        return () => {
            intro.exit();
        };
    }, []);
    intro.oncomplete(function (element) {
        complete = true;

        setTimeout(() => {
            if (!exitOnLast) {
                navigate("/intro/transactions-list");
            }
        }, 700);
    });
    intro.onexit(() => {
        if (!complete) {
            localStorage.setItem("firstLogin", "");
            setShow(true);
        }
    });
    intro.onchange(function (element) {
        switch (this._currentStep) {
            case 0:
                setTimeout(() => {
                    let cross = document.getElementsByClassName("introjs-skipbutton")[0];
                   cross && cross?.addEventListener("click", function () {
                        exitOnLast = true;
                        setShow(true);
                        localStorage.setItem("firstLogin", "");
                    });
                }, 950);

                break;
        }
    });
    return (
        <>
            <Header name={"Customer"} />
            {show && <ModalCard  show={show}/>}
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__customersCard">
                        <div className="dashboard__header">
                            <div className="dashboard__left">
                                <h4>Customer Data</h4>
                            </div>
                            <div className="dashboard__right">
                                <Link id="upload_customer" className="btn btn-default">
                                    <img src={importIcon} alt="" /> Upload Customer
                                </Link>
                            </div>
                        </div>
                        <div className="dashboard__body">
                            <p>No existing customer data.</p>
                            <p>You can add the data by uploading a CSV file containing the details.</p>
                        </div>
                    </div>
                    <div className="dashboard__customersCard">
                        <div className="dashboard__header">
                            <div className="dashboard__left">
                                <h4 id="customer_list">Customer List</h4>
                            </div>
                        </div>
                        <div className="dashboard__body">
                            <Paginated data={data} columns={COLUMNS} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IntroCustomersList;
