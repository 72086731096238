import React, { useEffect } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import moment from "moment";

export const Paginated = ({ columns, data, setData, setDate, date, handleSetData }) => {
    useEffect(() => {
        setData(data);
        // eslint-disable-next-line
    }, [data]);

    const { startDate, endDate, daysDiff, allDates } = date;

    const handleChange = (e) => {
        setDate({ ...date, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        let temp = moment(endDate).diff(moment(startDate), "days");
        setDate({ ...date, daysDiff: temp });
        // eslint-disable-next-line
    }, [endDate, startDate]);

    useEffect(() => {
        let temp = [];
        if (startDate && endDate) {
            for (var i = 0; i <= daysDiff; i++) {
                var currentDate = moment(startDate).add(i, "days");
                temp.push(currentDate.format("DD/MM/YYYY"));
            }
        }
        setDate({ ...date, allDates: temp });
        // eslint-disable-next-line
    }, [daysDiff]);

    const handleFilter = () => {
        const filteredData = data.filter((item) => {
            return allDates.includes(item.transactionProcessedDate);
        });
        if (!startDate && !endDate) {
            handleSetData();
        } else {
            setData(filteredData);
        }
    };

    const { getTableProps, getTableBodyProps, headerGroups, page, nextPage, previousPage, canPreviousPage, canNextPage, pageOptions, state, gotoPage, setPageSize, prepareRow } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        usePagination
    );

    const { pageIndex, pageSize } = state;

    const handlePageIndex = (event) => {
        if (event.target.value !== "") {
            const number = event.target.value ? Number(event.target.value) - 1 : 0;
            gotoPage(number);
        }
    };

    return (
        <div className="customTable">
            {/* <div className="customTable__header">
				<div className="customTable__left">
					<div className="customTable__showingRecords">
						<label>Show Records:</label>
						<select className="form-control" value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
							{[10, 25, 50, 100].map((pageSize) => (
								<option key={pageSize} value={pageSize}>
									{pageSize}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="customTable__right">
					<CustomFilter filter={globalFilter} setFilter={setGlobalFilter} />
				</div>
            </div> */}
            <div className="row">
                <div className="col-lg-3">
                    <div className="customTable__showingRecords">
                        <div className="form-group">
                            <label>Show Records:</label>
                            <select className="form-control selectArrow" value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
                                {[10, 25, 50, 100].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="customTable__searchFilter">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>Start Date</label>
                                    <input type="date" name="startDate" className="form-control" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>End Date</label>
                                    <input type="date" name="endDate" className="form-control" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    {/* <label>&nbsp;</label> */}
                                    <button type="button" className="btn btn-primary" onClick={handleFilter}>
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="customTable__body">
                <div className="table-responsive">
                    <table className="table table-striped table-bordered" {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        {Array.isArray(data) ? (
                            <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan={4}>
                                        <p style={{ textAlign: "center", margin: 0 }}>No data found</p>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
            <div className="customTable__footer">
                <div className="customTable__left">
                    <div className="customTable__totalTransactions">
                        <p>
                            Total Records: <span>{data.length}</span>
                        </p>
                    </div>
                </div>
                <div className="customTable__right">
                    <div className="customTable__top">
                        <div className="customTable__totalPages">
                            <p>
                                Page
                                <select className="form-control selectArrow" value={pageIndex + 1} onChange={handlePageIndex}>
                                    {Array.isArray(pageOptions) ? (
                                        pageOptions.map((pageIndex) => (
                                            <option key={pageIndex} value={pageIndex + 1}>
                                                {pageIndex + 1}
                                            </option>
                                        ))
                                    ) : (
                                        <option key={0} value={0}>
                                            {0}
                                        </option>
                                    )}
                                </select>
                                of <span>{pageOptions.length}</span>
                            </p>
                        </div>
                    </div>
                    <div className="customTable__bottom">
                        <div className="customTable__buttons">
                            {/* <span className="btn btn-outline-primary" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
								{"First"}
							</span> */}
                            <span className="btn btn-outline-primary" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {"Previous"}
                            </span>
                            <span className="btn btn-outline-primary" onClick={() => nextPage()} disabled={!canNextPage}>
                                {"Next"}
                            </span>
                            {/* <span className="btn btn-outline-primary" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
								{"Last"}
							</span> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
