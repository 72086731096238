import React, { useState } from "react";
import Header from "../../layout/Header";
import CampaignDetailsTab from "./CampaignDetailsTab";
import EarningRulesTab from "./referral/EarningRulesTab";
import { backIcon2 } from "../../../Image/Index";
import { Link, useNavigate } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
// const ThemeContext = createContext(null);
import { decrypt } from "../../../utils/encodingdecoding";

const NewReferralCampaign = () => {
	let navigate = useNavigate();
	const [key, setKey] = useState("Campaign Details");

	const [formateData, SetFormateData] = useState({
		version: "15",
		code: "IBM-CAM000059",
		coalitionCampaignCode: null,
		coalitionProgramCode: null,
		status: true,
		priority: 999,
		name: "",
		description: null,
		timeZone: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
		isTest: false,
		isVisibile: true,
		startDateTime: "",
		endDateTime: "",
		visibilityDateTime: "0001-01-01T00:00:00",
		offerDetail: {
			offerCode: "",
			title: "Referal Campaign",
			programCode: null,
			internalLink: null,
			externalLink: null,
			imageLink: null,
			mobileImageLink: null,
			logoImageLink: null,
			description: null,
			redemptionProcess: null,
			escalationMatrix: null,
			termAndCondition: null,
			purchaseable: {
				isPurchaseable: false,
				isActivated: false,
				price: 0,
				validityPeriod: 86400,
				validityPeriodType: 0,
				isImported: false,
				voucherTemplate: null,
			},
			longDescription: null,
		},
		tags: [],
		internalTags: [],
		earningRule: {
			earningRuleEvalutionType: 2,
			rules: [
				{
					infixRule: [
						{
							id: "transactionType-3",
							entityName: "",
							field: "",
							format: null,
							jsonPath: "$.Transaction[?(@.TransactionType==Transaction)]",
							operator: "",
							type: "",
							value: "Transaction",
							data: {
								class: "Transaction",
							},
						},
					],
					prefixRule: {
						condition: "AND",
						rules: [
							{
								id: "transactionType-3",
								field: "transactionType-3",
								type: "string",
								input: "text",
								operator: "equal",
								value: "Transaction",
								data: {
									class: "Transaction",
								},
							},
						],
						valid: true,
					},
					data: [
						{
							RedeemType: "Fix-Point",
							Value: 0,
							isEvery: false,
							RewardRatio: 0,
							EarnigWho: "",
							Sponsors: [
								{
									Type: "Merchant",
									Code: decrypt(localStorage.getItem("merchantcode")),
									Percent: 100,
								},
							],
							redeemRule: {
								redeemRuleBy: false,
								afterSpecificTransaction: false,
							},
							expirationRule: {
								afterDuration: "",
								afterSpecificNumber: 0,
								onAfter: true,
							},
						},
					],
					function: [
						{
							infixRule: [],
							prefixRule: null,
							duration: null,
						},
					],
					dbFunctionRule: [
						{
							sqlRule: null,
							infixRule: [],
							prefixRule: null,
						},
					],
				},
			],
		},
		InfixQualifyingRules: [],
		PrefixQualifyingRules: [],
		merchantTransactionSchema: {
			schemaId: decrypt(localStorage.getItem("schemaId")),
			schemaName: decrypt(localStorage.getItem("schemaName")).replace(/\s/g, ""),
			merchants: [decrypt(localStorage.getItem("merchantcode"))],
		},
		processingMode: 0,
		approvalStatus: 1,
		restrictedPrograms: [],
		campaignGlobalLimit: {
			isGlobalCampaignLimit: false,
			globalLimits: [
				{
					walletKey: "point",
					walletName: "point",
					maxLimit: 0,
					isDailyLimit: false,
					dailyLimit: 0,
					limitConsumed: 0,
					limitRemained: 0,
					liveConsumptionStatus: null,
				},
				{
					walletKey: "discount",
					walletName: "discount",
					maxLimit: 0,
					isDailyLimit: false,
					dailyLimit: 0,
					limitConsumed: 0,
					limitRemained: 0,
					liveConsumptionStatus: null,
				},
				{
					walletKey: "currency",
					walletName: "currency",
					maxLimit: 0,
					isDailyLimit: false,
					dailyLimit: 0,
					limitConsumed: 0,
					limitRemained: 0,
					liveConsumptionStatus: null,
				},
			],
		},
		campaignCustomerLimit: {
			isCustomerLimit: false,
			customerLimits: [
				{
					walletKey: "point",
					walletName: "point",
					maxLimit: 0,
					timePeriod: "2",
					limit: 0,
				},
				{
					walletKey: "discount",
					walletName: "discount",
					maxLimit: 0,
					timePeriod: "2",
					limit: 0,
				},
				{
					walletKey: "currency",
					walletName: "currency",
					maxLimit: 0,
					timePeriod: "2",
					limit: 0,
				},
			],
		},
		selfMadeCampaign: false,
		taglastcheck: null,
		type: "",
		Range: 0,
		Tiers: null,
		Membership: null,
		merchantId: [decrypt(localStorage.getItem("merchantcode"))],
	});

	const [error, setError] = useState({
		errName: false,
		errNameMsg: "Please enter campaign name",
		errStartDate: false,
		errStartDateMsg: "Please select start date",
		errEndDate: false,
		errEndDateMsg: "Please select end date",
	});
	const { errName, errStartDate, errEndDate } = error;

	let tempName = false,
		tempStartDate = false,
		tempEndDate = false;

	const onChange = (e) => {
		if (e === false || e === true) {
			SetFormateData({ ...formateData, ...{ status: e } });
		} else {
			SetFormateData({ ...formateData, ...{ [e.target.name]: e.target.value } });
		}
		if (formateData.name !== "") {
			tempName = false;
		}
		if (formateData.startDateTime !== "") {
			tempStartDate = false;
		}
		if (formateData.endDateTime !== "") {
			tempEndDate = false;
		}
		setError({ ...error, errName: tempName ? true : false, errEndDate: tempEndDate ? true : false, errStartDate: tempStartDate ? true : false });
	};

	const handleTabChange = () => {
		if (formateData.name === "") {
			tempName = true;
		}
		if (formateData.startDateTime === "") {
			tempStartDate = true;
		}
		if (formateData.endDateTime === "") {
			tempEndDate = true;
		}

		setError({ ...error, errName: tempName ? true : false, errEndDate: tempEndDate ? true : false, errStartDate: tempStartDate ? true : false });
	};

	// const handleToggle = (e) => {
	// 	SetFormateData({ ...formateData,  ...{ status: e }})
	// };

	return (
		<>
			<Header name={"Create New Referral Program"} />
			<div className="dashboard__content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="dashboard__heading">
								<div className="dashboard__headingLeft">
									<div className="dashboard__headingBackLink">
										<Link to="" onClick={() => navigate(-1)}>
											<img src={backIcon2} alt="" />
											<div className="dashboard__headingContent">
										<h3>Create new campaign</h3>
									
									</div>
										</Link>
									</div>
									<div className="dashboard__headingContent">
									
										<p>Set how your customers will earn points</p>
									</div>
								</div>
							</div>
							<div className="dashboard__tabSection">
								<Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} onClick={handleTabChange}>
									<Tab eventKey="Campaign Details" title="Campaign Details">
										<CampaignDetailsTab onChange={(e) => onChange(e)} formateData={formateData} error={error} />
									</Tab>
									<Tab eventKey="Earning Rules" title="Earning Rules" disabled={formateData.name && formateData.endDateTime && formateData.startDateTime ? false : true}>
										<EarningRulesTab onChange={(e) => onChange(e)} formateData={formateData} />
									</Tab>
								</Tabs>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	// auth: state,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewReferralCampaign);
