import React from "react";
import { ValidatorComponent } from "react-form-validator-core";

class PasswordValidator extends ValidatorComponent {
    errorText() {
        const { isValid, value, errorMessages } = this.state;

        if (isValid) {
            return null;
        }

        let passwordUpper = "Upper Case Letter (A,G,T,Y etc.)";
        let passwordNumber = "Number (1,2,4,6 etc.)";
        let passwordLower = "Lower Case Letter (t,r,q,f etc.)";
        let passwordSpecial = "Special Character (!,@,#,$ etc.)";
        let minPassword = "Password must be between 6 to 16 character ";

        return (
            <>
                {errorMessages.includes("condition", 1) ? (
                    value && value != null ? (
                        <div className="textValidatorError">
                            <p>Your password should contain at least one:</p>
                            <ul>
                                <li>{value.match("[A-Z]") ? <span className="passwordAdded">{passwordUpper}</span> : <span style={{ color: "red" }}>{passwordUpper}</span>}</li>
                                <li>{value.match("[0-9]") ? <span className="passwordAdded">{passwordNumber}</span> : <span style={{ color: "red" }}>{passwordNumber}</span>}</li>
                                <li>{value.match("[a-z]") ? <span className="passwordAdded">{passwordLower}</span> : <span style={{ color: "red" }}>{passwordLower}</span>}</li>
                                <li>{value.match("[#?!@$%^&*-]") ? <span className="passwordAdded">{passwordSpecial}</span> : <span style={{ color: "red" }}>{passwordSpecial}</span>}</li>
                                <li>{value.length > 5 && value.length < 17 ? <span className="passwordAdded">{minPassword}</span> : <span style={{ color: "red" }}>{minPassword}</span>}</li>
                            </ul>
                        </div>
                    ) : (
                        <span className="textValidatorError">{this.getErrorMessage()}</span>
                    )
                ) : (
                    <span className="textValidatorError">{this.getErrorMessage()}</span>
                )}
            </>
        );
    }

    renderValidatorComponent() {
        const { type, errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;

        return (
            <>
                <input
                    type={type || "text"}
                    {...rest}
                    ref={(r) => {
                        this.input = r;
                    }}
                />
                {this.errorText()}
            </>
        );
    }
}

export default PasswordValidator;
