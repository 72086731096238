import { GET_CAMPAIGNS, GET_CAMPAIGN, CLEAR_CAMPAIGNS, GET_SAMPLE_CAMPAIGNS, CREATE_SAMPLE_CAMPAIGNS, UPDATE_SAMPLE_CAMPAIGN } from "../actions/type";

const initialState = {
    data: [],
    res: {},
    tag: [],
    no_cmp: false,
    show: true,
};

const getCampaigns = (state = initialState, action) => {
    const { type, payload, show } = action;
    switch (type) {
        case CLEAR_CAMPAIGNS:
            return {
                ...state,
                data: [],
                res: {},
                tag: [],
                show: true,
            };
        case GET_CAMPAIGNS:
            if (payload === "no_cmp") {
                return {
                    ...state,
                    no_cmp: true,
                };
            }
            return {
                ...state,
                data: payload,
            };
        case GET_CAMPAIGN:
            return {
                ...state,
                data: [payload],
            };
        case GET_SAMPLE_CAMPAIGNS:
            return {
                ...state,
                data: payload,
                show: show,
            };
        case CREATE_SAMPLE_CAMPAIGNS:
            return {
                ...state,
                res: payload,
            };
        case UPDATE_SAMPLE_CAMPAIGN:
            return {
                ...state,
                data: payload,
            };
        default:
            return state;
    }
};
export default getCampaigns;
