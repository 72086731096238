import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

const BarChartAnalyticalReportGrowth = ({ goalData, withoutGoal, title, backgroundColor }) => {
    const [Data, setData] = useState({
        labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        datasets: [
            {
                label: " ",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: true,
                tension: 0.4,
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            // {
            //     label: "Transaction Count2 ",
            //     data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            //     fill: true,
            //     tension: 0.4,
            //     backgroundColor: "rgba(53, 162, 235, 0.5)",
            // },
        ],
    });

    useEffect(() => {
        // setData({ ...Data, datasets: !goalData ? [{ ...Data.datasets[0], data: withoutGoal }] : [{ ...Data.datasets[1], data: goalData }] });
        setData({
            ...Data,
            datasets: Data?.datasets.map((obj, ind) => {
                if (!goalData) return { ...obj, data: withoutGoal, label: title, backgroundColor: backgroundColor };
                if (goalData) return { ...obj, data: goalData, label: title, backgroundColor: backgroundColor };
                return obj;
            }),
        });
    }, [withoutGoal, goalData]);

    const options = {
        indexAxis: "x",
        element: {
            legend: {
                position: "top",
            },
        },
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <>
            <div className="analyticalReportChartAnalysisOne__barChartReport">
                <Bar data={Data} options={options} />
            </div>
        </>
    );
};

export default BarChartAnalyticalReportGrowth;
