import { baseURL } from "../utils/url";

export const createPayment = async (body) => {
	try {
		const fetchResponse = await fetch(baseURL + "payment_method_configurations", {
			method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify(body)
		});
		return await fetchResponse;
	} catch (error) {
		return error;
	}
};