import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { decrypt } from "../../utils/encodingdecoding";

const LineChartAnalyticalReport3 = ({ data, predictedData }) => {
  const [formData, setFormData] = useState({
    labels: [
      "Total Transaction Amount",
      "Avg Transaction Amount",
      "Total Customer",
      "Total Transaction",
      "Total Redeem Points",
      "Total Points",
      "Redeption Rate",
      "Total Campaign",
    ],
    datasets: [
      {
        label: "RFM Data",
        data: [4000, 300, 150, 2000, 200, 3000, 40, 200],
        fill: true,
        tension: 0.4,
        backgroundColor: "rgb(224, 109, 178, 0.35)",
        borderColor: "#e06db2",
        borderWidth: 2,
        pointStyle: "none",
        pointRadius: 8,
        pointBorderWidth: 2,
        pointBorderColor: "#e06db2",
        pointBackgroundColor: "#ffffff",
        pointHoverRadius: 8,
        pointHoverBorderWidth: 2,
        pointHoverBackgroundColor: "#ffffff",
      },
      {
        label: "Current Data",
        data: [0, 0, 0, 0, 0, 0, 0, 0],
        fill: true,
        tension: 0.4,
        backgroundColor: "rgb(45, 142, 219, 0.35)",
        borderColor: "#2d8edb",
        borderWidth: 2,
        pointStyle: "circle",
        pointRadius: 8,
        pointBorderWidth: 2,
        pointBorderColor: "#2d8edb",
        pointBackgroundColor: "#ffffff",
        pointHoverRadius: 8,
        pointHoverBorderWidth: 2,
        pointHoverBackgroundColor: "#ffffff",
      },
    ],
  });

  const options = {
    indexAxis: "x",
    element: {
      legend: {
        position: "top",
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  useEffect(() => {
    data?.length &&
      setFormData({
        ...formData,
        datasets: formData?.datasets.map((obj, ind) => {
          if (ind === 1) return { ...obj, data: data.length > 9 ? data : data[0] };
          return obj;
        }),
      });
  }, [data]);

  useEffect(() => {
    setFormData({
      ...formData,
      datasets: formData?.datasets.map((obj, ind) => {
        if (ind === 1 && predictedData) {
          obj.data = [
            predictedData.totalTransactionAmount,
            predictedData.avarageTransactionAmount,
            predictedData.totalCustomer,
            predictedData.totalTransaction,
            predictedData.totalRedeemPoints,
            predictedData.totalPoints,
            predictedData.redeptionRate,
            predictedData.totalCampaign,
          ];
        }
        return obj;
      }),
    });
  }, [predictedData]);

  return (
    <>
      <div className="analyticalReportChartAnalysisTwo__lineChartReport lineChartReport__">
        &nbsp;
        <Line data={formData} options={options} />
      </div>
    </>
  );
};

export default LineChartAnalyticalReport3;
