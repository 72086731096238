// import $ from "jquery";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { decrypt } from "../../utils/encodingdecoding";
import { novus_logo } from "../../Image/Index";
import { getMerchantNotificationAlert } from "../pages/campaigns/services";
import { getDate } from "../../utils/helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Token_Generator } from "../../actions/auth";
// import { notifications, profilePic, settings, settingsHover, helpFAQs, helpFAQsHover, logoutIcon, logoutIconHover } from "../../Image/Index";
const Header = (props) => {
    const [name, setName] = useState();
    const [data, setData] = useState()
    const [datas, setDatas] = useState()

    useEffect(() => {
        if (localStorage.getItem("name")) {
            let tempName = decrypt(localStorage.getItem("name"));
            tempName = `${tempName[0].toUpperCase()}${tempName.slice(1)}`;
            setName(tempName);
        }
    }, []);
    const temp = localStorage.getItem("email");
    // const administratorBoxTrigger = () => {
    //     $(".administratorBox").toggleClass("show");
    //     $(".notificationsBox").removeClass("show");
    // };

    // const notificationsBoxTrigger = () => {
    //     $(".notificationsBox").toggleClass("show");
    //     $(".administratorBox").removeClass("show");
    // };

    // const logout = () => {
    // sessionStorage.clear();
    // localStorage.clear();
    // };

    // const container = document.getElementById("root");

    // const themeDark = () => {
    //     const dashboardThemeDark = document.getElementsByClassName("dashboard__themeDark");
    //     const dashboardThemeLight = document.getElementsByClassName("dashboard__themeLight");

    //     container.classList.add("themeDark");

    //     dashboardThemeDark.classList.add("show");
    //     dashboardThemeLight.classList.add("hide");
    // };

    // const themeLight = () => {
    //     const dashboardThemeDark = document.getElementsByClassName("dashboard__themeDark");
    //     const dashboardThemeLight = document.getElementsByClassName("dashboard__themeLight");

    //     container.classList.remove("themeDark");

    //     dashboardThemeDark.classList.add("hide");
    //     dashboardThemeLight.classList.add("show");
    // };
    const names = props.name || "";
    const fetchNotificationAlert = async () => {
        const token = await Token_Generator();

        await getMerchantNotificationAlert(token?.access_token)
            .then((res) => {
                const merchant = res.data.merchant;
                if (merchant.status === true) {
                    const currentDateTime = new Date();
                    const currentDate = getDate(currentDateTime, "DD/MM/YYYY:HH:mm")
                    const startDateTime = merchant.startDateTime;
                    const endDateTime = merchant.endDateTime;
                    if (currentDate >= startDateTime && currentDate <= endDateTime) {
                        setData(merchant.tital)
                        setDatas(merchant.discription)
                        const CustomToast = ({ closeToast }) => (
                            <div>
                                <strong>{merchant.tital}</strong> <br />
                                {merchant.discription}
                            </div>
                        );

                        toast(<CustomToast />, { autoClose: false });


                    }
                }
            })
            .catch((error) => {
             
            });



    };
    useEffect(() => {
        fetchNotificationAlert()
    }, [])
    return (
        <div className="dashboard__headerSection">
            {!name && !temp && (
                <Link to="/">
                    <figure>
                        <img src={novus_logo} alt="logo" width="120" loading="lazy" style={{ marginTop: "10px", marginLeft: "10px", paddingLeft: "15px" }} />
                    </figure>
                </Link>
            )}

            <div className="dashboard__heading">
                <h3>{props.name}</h3>
            </div>
            {name && ( // Use a conditional to show the welcome message only if 'name' has a value
                <div className="dashboard__username">

                    <h5>
                        Welcome {""}
                        <Link to="/profile-settings?p01">
                            <span>{name}</span>
                        </Link>
                    </h5>
                </div>
            )}
            {/* <div className="dashboard__themeButton">
				<Link to="" className="dashboard__themeDark" onClick={themeDark}>
					<i className="fa-solid fa-moon"></i>
				</Link>
				<Link to="" className="dashboard__themeLight" onClick={themeLight}>
					<i className="fa-solid fa-sun"></i>
				</Link>
			</div> */}
            {/* <div className="dashboard__rightSection">
                <ul>
                    <li>
                        <div className="notifications active" onClick={notificationsBoxTrigger}>
                            <img src={notifications} alt="" />
                        </div>
                        <div className="notificationsBox">
                            <div className="notificationsBox__header">
                                <div className="notificationsBox__left">
                                    <h4>Notifications</h4>
                                </div>
                                <div className="notificationsBox__right">
                                    <span className="badge bg-light-primary rounded-pill">5 New</span>
                                </div>
                            </div>
                            <div className="notificationsBox__notifications">
                                <ul>
                                    <li>
                                        <Link to="/notification-details">
                                            <div className="notificationsBox__img">
                                                <img src={profilePic} alt="" />
                                            </div>
                                            <div className="notificationsBox__content">
                                                <h5>Congratulation Sam winner!</h5>
                                                <p>Won the monthly best seller badge.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/notification-details">
                                            <div className="notificationsBox__img">
                                                <img src={profilePic} alt="" />
                                            </div>
                                            <div className="notificationsBox__content">
                                                <h5>Congratulation Sam winner!</h5>
                                                <p>Won the monthly best seller badge.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/notification-details">
                                            <div className="notificationsBox__img">
                                                <img src={profilePic} alt="" />
                                            </div>
                                            <div className="notificationsBox__content">
                                                <h5>Congratulation Sam winner!</h5>
                                                <p>Won the monthly best seller badge.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/notification-details">
                                            <div className="notificationsBox__img">
                                                <img src={profilePic} alt="" />
                                            </div>
                                            <div className="notificationsBox__content">
                                                <h5>Congratulation Sam winner!</h5>
                                                <p>Won the monthly best seller badge.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/notification-details">
                                            <div className="notificationsBox__img">
                                                <img src={profilePic} alt="" />
                                            </div>
                                            <div className="notificationsBox__content">
                                                <h5>Congratulation Sam winner!</h5>
                                                <p>Won the monthly best seller badge.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/notification-details">
                                            <div className="notificationsBox__img">
                                                <img src={profilePic} alt="" />
                                            </div>
                                            <div className="notificationsBox__content">
                                                <h5>Congratulation Sam winner!</h5>
                                                <p>Won the monthly best seller badge.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/notification-details">
                                            <div className="notificationsBox__img">
                                                <img src={profilePic} alt="" />
                                            </div>
                                            <div className="notificationsBox__content">
                                                <h5>Congratulation Sam winner!</h5>
                                                <p>Won the monthly best seller badge.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/notification-details">
                                            <div className="notificationsBox__img">
                                                <img src={profilePic} alt="" />
                                            </div>
                                            <div className="notificationsBox__content">
                                                <h5>Congratulation Sam winner!</h5>
                                                <p>Won the monthly best seller badge.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/notification-details">
                                            <div className="notificationsBox__img">
                                                <img src={profilePic} alt="" />
                                            </div>
                                            <div className="notificationsBox__content">
                                                <h5>Congratulation Sam winner!</h5>
                                                <p>Won the monthly best seller badge.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/notification-details">
                                            <div className="notificationsBox__img">
                                                <img src={profilePic} alt="" />
                                            </div>
                                            <div className="notificationsBox__content">
                                                <h5>Congratulation Sam winner!</h5>
                                                <p>Won the monthly best seller badge.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/notification-details">
                                            <div className="notificationsBox__img">
                                                <img src={profilePic} alt="" />
                                            </div>
                                            <div className="notificationsBox__content">
                                                <h5>Congratulation Sam winner!</h5>
                                                <p>Won the monthly best seller badge.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/notification-details">
                                            <div className="notificationsBox__img">
                                                <img src={profilePic} alt="" />
                                            </div>
                                            <div className="notificationsBox__content">
                                                <h5>Congratulation Sam winner!</h5>
                                                <p>Won the monthly best seller badge.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/notification-details">
                                            <div className="notificationsBox__img">
                                                <img src={profilePic} alt="" />
                                            </div>
                                            <div className="notificationsBox__content">
                                                <h5>Congratulation Sam winner!</h5>
                                                <p>Won the monthly best seller badge.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/notification-details">
                                            <div className="notificationsBox__img">
                                                <img src={profilePic} alt="" />
                                            </div>
                                            <div className="notificationsBox__content">
                                                <h5>Congratulation Sam winner!</h5>
                                                <p>Won the monthly best seller badge.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/notification-details">
                                            <div className="notificationsBox__img">
                                                <img src={profilePic} alt="" />
                                            </div>
                                            <div className="notificationsBox__content">
                                                <h5>Congratulation Sam winner!</h5>
                                                <p>Won the monthly best seller badge.</p>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="notificationsBox__footer">
                                <Link to="/notifications" className="btn btn-primary d-block w-100">
                                    Read all notifications
                                </Link>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="administrator">
                            <span className="administrator__currentProfilePic" onClick={administratorBoxTrigger}>
                                <img src={profilePic} alt="" />
                            </span>
                            <div className="administrator__updateProfilePic">
                                <input className="administrator__input" type="file" accept={"image/*"} />
                                <span className="administrator__icon">
                                    <i className="fa-solid fa-upload"></i>
                                </span>
                            </div>
                        </div>
                        <div className="administratorBox">
                            <ul>
                                <li>
                                    <Link className="link" to="/settings" aria-current="page">
                                        <figure className="icon">
                                            <img src={settings} alt="" className="offHover" />
                                            <img src={settingsHover} alt="" className="onHover" />
                                        </figure>
                                        <span className="link_text">Settings</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="link" to="/help-faqs" aria-current="page">
                                        <figure className="icon">
                                            <img src={helpFAQs} alt="" className="offHover" />
                                            <img src={helpFAQsHover} alt="" className="onHover" />
                                        </figure>
                                        <span className="link_text">Help & FAQs</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="link" to="/signin" aria-current="page" onClick={logout}>
                                        <figure className="icon">
                                            <img src={logoutIcon} alt="" className="offHover" />
                                            <img src={logoutIconHover} alt="" className="onHover" />
                                        </figure>
                                        <span className="link_text">Logout</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div> */}
            <ToastContainer autoClose={false} />
        </div>
    );
};

export default Header;
