import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import Chart from "chart.js/auto";

const options = {
	element: {
		legend: {
			position: "left",
		},
	},
	responsive: true,
};

const DoughnutChartDashboard = ({Total_Male,Total_female,Others}) => {
	const Data = {
		labels: ["Male", "Female", "Others"],
		datasets: [
			{
				data: [Total_Male?Total_Male:0, Total_female?Total_female:0, Others?Others:0],
				backgroundColor: ["#2d8edb", "#e06db2", "#d2d3d3"],
				borderColor: ["#2d8edb", "#e06db2", "#d2d3d3"],
				borderWidth: 1,
				borderRadius: [0, 0],
			},
		],
	};

	return (
		<div className="analyticalReportChartAnalysisTwo__doughnutChartReport">
			<Doughnut data={Data} options={options} width={50} height={50} />
		</div>
	);
};

export default DoughnutChartDashboard;
