import React, { useEffect, useMemo, useState, memo } from "react";
import Header from "../../layout/Header";
import CampaignDetailsTab from "./CampaignDetailsTab";
import EditEarningRulesTabLoyalty from "./loyalty/EditEarningRulesTabLoyalty";
import { backIcon2 } from "../../../Image/Index";
import { Link, useNavigate } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import CommunicationFlowTab from "./CommunicationFlowTab";
import { decrypt } from "../../../utils/encodingdecoding";
import { getCampaign, clearCampaigns } from "../../../actions/campaigns";
import introJs from "intro.js";
import "intro.js/introjs.css";
import { getDate, handleClick } from "../../../utils/helper";
const EditLoyaltyCampaign = ({ clearCampaigns, getCampaign, Campaigns }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { data, type, sampleFor } = location?.state || {};
    const [key, setKey] = useState("Campaign Details");
    const [loader, setLoader] = useState(true);
    const [formPage, setFormPage] = useState();

    const [formData, SetFormData] = useState({
        version: "15",
        code: "",
        coalitionCampaignCode: null,
        coalitionProgramCode: null,
        status: true,
        priority: 999,
        name: "",
        businessGoal: "",
        description: null,
        timeZone: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
        isTest: false,
        isVisibile: true,
        startDateTime: "2022-08-24T00:00:00",
        endDateTime: "2023-12-31T00:00:00",
        visibilityDateTime: "0001-01-01T00:00:00",
        offerDetail: {
            offerCode: "",
            title: "Referral Campaign",
            programCode: null,
            internalLink: null,
            externalLink: null,
            imageLink: null,
            mobileImageLink: null,
            logoImageLink: null,
            description: null,
            redemptionProcess: null,
            escalationMatrix: null,
            termAndCondition: null,
            purchaseable: {
                isPurchaseable: false,
                isActivated: false,
                price: 0,
                validityPeriod: 86400,
                validityPeriodType: 0,
                isImported: false,
                voucherTemplate: null,
            },
            longDescription: null,
        },
        tags: [],
        internalTags: [],
        earningRule: {
            earningRuleEvalutionType: 2,
            rules: [
                {
                    infixRule: [
                        {
                            entityName: "",
                            field: "",
                            jsonPath: "",
                            op: "",
                            value: [],
                            type: "",
                            format: null,
                        },
                    ],
                    prefixRule: {
                        condition: "AND",
                        rules: [
                            {
                                id: "",
                                field: "",
                                type: "",
                                input: "",
                                operator: "",
                                value: "",
                                data: {
                                    class: "Transaction",
                                },
                            },
                        ],
                        valid: true,
                    },
                    data: [
                        {
                            RedeemType: "",
                            Value: 0,
                            isEvery: false,
                            RewardRatio: 0,
                            EarnigWho: "Customer",
                            Sponsors: [
                                {
                                    Type: "Merchant",
                                    Code: decrypt(localStorage.getItem("merchantcode")),
                                    Percent: 100,
                                },
                            ],
                            redeemRule: {
                                redeemRuleBy: false,
                                afterSpecificTransaction: false,
                            },
                            expirationRule: {
                                onAfter: true,
                                afterSpecificNumber: 0,
                                afterDuration: "",
                            },
                        },
                    ],
                    function: [
                        {
                            infixRule: [],
                            prefixRule: null,
                            duration: null,
                        },
                    ],
                    dbFunctionRule: [
                        {
                            sqlRule: null,
                            infixRule: [],
                            prefixRule: null,
                        },
                    ],
                },
            ],
        },
        InfixQualifyingRules: [],
        PrefixQualifyingRules: [],
        merchantTransactionSchema: {
            schemaId: decrypt(localStorage.getItem("schemaId")),
            schemaName: data?.merchantTransactionSchema?.schemaName,
            merchants: [decrypt(localStorage.getItem("merchantcode"))],
        },
        processingMode: 0,
        approvalStatus: 1,
        restrictedPrograms: [],
        campaignGlobalLimit: {
            isGlobalCampaignLimit: false,
            globalLimits: [
                {
                    walletKey: "point",
                    walletName: "point",
                    maxLimit: 0,
                    isDailyLimit: false,
                    dailyLimit: 0,
                    limitConsumed: 0,
                    limitRemained: 0,
                    liveConsumptionStatus: null,
                },
                {
                    walletKey: "discount",
                    walletName: "discount",
                    maxLimit: 0,
                    isDailyLimit: false,
                    dailyLimit: 0,
                    limitConsumed: 0,
                    limitRemained: 0,
                    liveConsumptionStatus: null,
                },
                {
                    walletKey: "currency",
                    walletName: "currency",
                    maxLimit: 0,
                    isDailyLimit: false,
                    dailyLimit: 0,
                    limitConsumed: 0,
                    limitRemained: 0,
                    liveConsumptionStatus: null,
                },
            ],
        },
        campaignCustomerLimit: {
            isCustomerLimit: false,
            customerLimits: [
                {
                    walletKey: "point",
                    walletName: "point",
                    maxLimit: 0,
                    timePeriod: "2",
                    limit: 0,
                },
                {
                    walletKey: "discount",
                    walletName: "discount",
                    maxLimit: 0,
                    timePeriod: "2",
                    limit: 0,
                },
                {
                    walletKey: "currency",
                    walletName: "currency",
                    maxLimit: 0,
                    timePeriod: "2",
                    limit: 0,
                },
            ],
        },
        selfMadeCampaign: false,
        taglastcheck: null,
        type: "",
        Range: 0,
        Tiers: null,
        Membership: null,
        merchantId: [decrypt(localStorage.getItem("merchantcode"))],
    });

    const intro = introJs();
    const [error, setError] = useState({
        errName: false,
        errNameMsg: "Please enter campaign name",
        errBusiness: false,
        errBusinessMsg: "Please select business goal",
        errStartDate: false,
        errStartDateMsg: "Please select start date",
        errEndDate: false,
        errEndDateMsg: "Please select end date",
        errDateDiff: false,
        errDateDiffMsg: "Start date cannot be greater than or equal to End date.",
    });
    useEffect(() => {
        const fetch = async () => {
            setLoader(true);
            const token = JSON.parse(localStorage.getItem("token_gen"));
            await getCampaign(token?.access_token, data?.code);
            setLoader(false);
        };
        fetch();
    }, [data]);

    useEffect(() => {
        location?.state?.tab && setKey(location?.state?.tab);
        location?.state?.tab && navigate("/my-campaigns/edit-campaign", { state: { data: data } });
    }, [location]);

    useEffect(() => {
        if (type) {
            const nextSixMonth = handleClick();
            data.startDateTime = getDate(new Date(), "YYYY-MM-DD");
            data.endDateTime = nextSixMonth;
            data.earningRule.rules[0].data[0].Sponsors = [
                {
                    Type: "Merchant",
                    Code: decrypt(localStorage.getItem("merchantcode")),
                    Percent: 100,
                },
            ];
            SetFormData(data);
        } else {
            Campaigns?.data[0] && SetFormData(Campaigns?.data[0]);
        }
        setFormPage(data.earningRule.rules[0].data[0].AccountType);
    }, [Campaigns]);

    useEffect(() => {
        clearCampaigns();
    }, []);

    const { errDateDiff } = error;

    let tempName = false,
        tempStartDate = false,
        tempEndDate = false,
        tempBusiness = false;

    useEffect(() => {
        if (formData.startDateTime > formData.endDateTime) {
            setError({ ...error, errDateDiff: true });
        } else {
            setError({ ...error, errDateDiff: false });
        }
    }, [formData.startDateTime, formData.endDateTime]); //eslint-disable-line

    const onChange = (e) => {
        if (e === false || e === true) {
            SetFormData({ ...formData, ...{ status: e } });
        } else if (e.value) {
            SetFormData({ ...formData, ...{ businessGoal: e.value } });
        } else {
            SetFormData({ ...formData, ...{ [e.target.name]: e.target.value } });
        }
        if (formData.name !== "") {
            tempName = false;
        }
        if (formData.startDateTime !== "") {
            tempStartDate = false;
        }
        if (formData.endDateTime !== "") {
            tempEndDate = false;
        }
        if (formData.businessGoal !== null) {
            tempBusiness = false;
        }
        setError({ ...error, errName: tempName ? true : false, errEndDate: tempEndDate ? true : false, errStartDate: tempStartDate ? true : false, errBusiness: tempBusiness ? true : false });
    };

    const handleTabChange = (callback) => {
        if (formData.name === "") {
            tempName = true;
        }
        if (formData.startDateTime === "") {
            tempStartDate = true;
        }
        if (formData.endDateTime === "") {
            tempEndDate = true;
        }
        if (formData.businessGoal === undefined) {
            tempBusiness = true;
        }
        if (!tempName && !tempStartDate && !tempEndDate && !errDateDiff && !tempBusiness) {
            callback.key && setKey(callback.key);
        }

        setError({ ...error, errName: tempName ? true : false, errEndDate: tempEndDate ? true : false, errStartDate: tempStartDate ? true : false, errBusiness: tempBusiness ? true : false });
    };

    const handleSelect = (k) => {
        setKey(k);
    };
    const handleNavigateBack = () => {
        location?.state?.tags && navigate(-1, { state: location?.state?.tags });
        !location?.state?.tags && navigate("/my-campaigns");
    };

    const renderCampaignDetailsTab = useMemo(() => {
        return <CampaignDetailsTab onChange={(e) => onChange(e)} formateData={formData} error={error} handleTabChange={handleTabChange} onCompleteIntro={handleSelect} />;
    }, [formData, error]); //eslint-disable-line
    const renderEarningRulesTab = useMemo(() => {
        return <EditEarningRulesTabLoyalty onChange={(e) => onChange(e)} formPage={formPage} formateData={formData} type={type} merchantTransactionSchema={data} tags={location?.state?.tags} handleTabChange={handleTabChange} onCompleteIntro={handleSelect} currentTab={key} sampleFor={sampleFor} />;
    }, [formData]); //eslint-disable-line

    return (
        <>
            <Header name={type === "sample" ? (sampleFor === "view" ? "View Sample Campaigns" : "Edit Sample Campaigns") : "Edit My Campaigns"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="dashboard__heading">
                                <div className="dashboard__headingLeft">
                                    <div className="dashboard__headingBackLink">
                                        <button onClick={handleNavigateBack}>
                                            <img src={backIcon2} alt="" />
                                        </button>
                                    </div>
                                    <div className="dashboard__headingContent">
                                        <h3>{type === "sample" ? (sampleFor === "view" ? "View Sample Campaigns" : "Edit Sample Campaigns") : "Edit My Campaigns"}</h3>
                                        <p>{type === "sample" ? (sampleFor === "view" ? "How" : "Edit how") : "Edit how"} your customers will earn points</p>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard__tabSection">
                                <Tabs id="controlled-tab-example" activeKey={key} onSelect={handleSelect} onClick={handleTabChange}>
                                    <Tab eventKey="Campaign Details" id="cpm_deta" title="Campaign Details">
                                        {renderCampaignDetailsTab}
                                    </Tab>
                                    <Tab eventKey="Earning Rules" title="Earning Rules" id="kd" disabled={formData.name && formData.endDateTime && formData.startDateTime && formData.businessGoal && error.errDateDiff === false ? false : true}>
                                        {renderEarningRulesTab}
                                    </Tab>
                                    {type !== "sample" && (
                                        <Tab eventKey="Communication Flow" id="communication_flow" title="Communication Flow" disabled={formData.name && formData.endDateTime && formData.startDateTime && formData.businessGoal && error.errDateDiff === false ? false : true}>
                                            {key === "Communication Flow" && <CommunicationFlowTab code={formData?.code} />}
                                        </Tab>
                                    )}
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => ({
    Campaigns: state.Campaigns,
});
const mapDispatchToProps = {
    getCampaign,
    clearCampaigns,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(EditLoyaltyCampaign));
