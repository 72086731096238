export const COLUMNS = [
    {
        Header: "Name",
        Footer: "Name",
        accessor: "name",
        disableFilters: true,
        sticky: "left",
    },

    {
        Header: "Email",
        Footer: "Email",
        accessor: "email",
    },
    {
        Header: "is Card Attached ",
        Footer: "is Card Attached ",
        accessor: "cardStatus",
        canSort: true,
    },
    {
        Header: "is Email Confirmed ",
        Footer: "is Email Confirmed ",
        accessor: "emailconfirmed",
    },
    {
        Header: "Contact Number",
        Footer: "Contact Number",
        accessor: "contactnumber",
    },

    {
        Header: "Industry",
        Footer: "Industry",
        accessor: "industry",
    },
    {
        Header: "What Need",
        Footer: "What Need",
        accessor: "need",
    },
    {
        Header: "Company Name",
        Footer: "Company Name",
        accessor: "companyname",
    },
    {
        Header: "Date",
        Footer: "Date",
        accessor: "Created",
    },
    {
        Header: "Project Details",
        Footer: "Project Details",
        accessor: "projectdetails",
    },

    {
        Header: "islogin",
        Footer: "islogin",
        accessor: "isLogin",
    },
    {
        Header: "Country",
        Footer: "Country",
        accessor: "country",
    },
    {
        Header: "ip",
        Footer: "ip",
        accessor: "ip",
    },
];
