import { baseURL } from "../utils/url";
import { hideSiteLoader,showSiteLoader } from "./modals";
import { AUTH, LOGOUT, GET_CUSTOMER_LIST } from "./type";
export const Token_Generator = async () => {
    try {
        const fetchResponse = await fetch(baseURL + "tokengenerator", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const signing = (token, data) => async (dispatch) => {
    try {
        dispatch(showSiteLoader());
        const fetchResponse = await fetch(baseURL + "signin", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        fetchResponse
            .json()
            .then((res) => {
                if (res.message === "Valid Login Details") {
                    sessionStorage.setItem("token", JSON.stringify(true));
                    localStorage.setItem("token_rare", JSON.stringify(true));
                    dispatch({
                        type: AUTH,
                        payload: res,
                    });
                    return res;
                } else {
                    return dispatch({
                        type: AUTH,
                        payload: res,
                    });
                }
            })
            .catch(() => {
                dispatch(hideSiteLoader())
                return null;
            });
    } catch (error) {
        return error;
    }
};

export const logout = () => async (dispatch) => {
    dispatch({ type: LOGOUT });
};
