import { Link } from "react-router-dom";

export const EMAILCOLUMNS = [
    {
        Header: "Purpose",
        Footer: "Purpose",
        accessor: "purpose",
        sticky: "left",
     
    },

    {
        Header: "File Name",
        Footer: "File Name",
        accessor: "fileName",
       
    },
   

    {
        Header: "Total Email",
        Footer: "Total Email",
        accessor: "totalEmails",
    },

    {
        Header: "Success",
        Footer: "Success",
        accessor: "totalSuccess",
    },
  
    {
        Header: "Pending",
        Footer: "Pending",
        accessor: "totalPending",
    },

    {
        Header: "Fail",
        Footer: "Fail",
        accessor: "totalFail",
    },
];
