import { GET_BUSINESS_GOAL, CLEAR_BUSINESS_GOAL } from "../actions/type";

const initialState = {
    data: [],
};

const getBusinessGoal = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_BUSINESS_GOAL:
            return {
                ...state,
                data: payload,
            };
        case CLEAR_BUSINESS_GOAL:
            return {
                data: [],
            };
        default:
            return state;
    }
};
export default getBusinessGoal;
