


import { Modal, Button, CloseButton } from "react-bootstrap";
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import {whatsapp, facebook, freshmenu, flipkart, twitter, twillio, ccd, magento, paypal, jiomart, linkedin, razorpay, shopify, twid, yatra, sendgrid} from "../../Image/Index";
import {modalCard, startTour} from '../../actions/intro'
import { Link, useLocation, useNavigate } from 'react-router-dom';

const ModalDocumentation=(props)=>{
const [show, setShow]=useState(true)
const location = useLocation();
const modalRefresh = location.state;
const navigate=useNavigate()
const dispatch = useDispatch();


const endTour=()=>{

    props.close(true)

}
    return (
  

    <>    

      <Modal show={show} onHide={endTour} centered>
        <Modal.Header className="custom_header" closeButton></Modal.Header>
        <Modal.Body className="dashboard__transactionsModal">
          <div className="">
            <div className="integration___heading">
              <h4>Technology Integrations</h4>
              <p>Witness our smart technology partnership network and quick integration with varied platforms. Connect with third party systems such as Facebook, Twitter, Sendgrid or integrate with your POS system and website shopping cart. Join hands to build superior Loyalty Experiences!</p>
            </div>
            <div className="integration___row">
              <div className="integration___box">
                <img src={whatsapp}/> 
              </div>
              <div className="integration___box">
                <img src={facebook}/> 
              </div>
              <div className="integration___box">
                <img src={freshmenu}/> 
              </div>
              <div className="integration___box">
                <img src={twillio}/> 
              </div>
              <div className="integration___box">
                <img src={twid}/> 
              </div>
              <div className="integration___box">
                <img src={ccd}/> 
              </div>
              <div className="integration___box">
                <img src={twitter}/> 
              </div>
              <div className="integration___box">
                <img src={linkedin}/> 
              </div>
              <div className="integration___box">
                <img src={paypal}/> 
              </div>
              <div className="integration___box">
                <img src={magento}/> 
              </div>
              <div className="integration___box">
                <img src={razorpay}/> 
              </div>
              <div className="integration___box">
                <img src={flipkart}/> 
              </div>
              <div className="integration___box">
                <img src={jiomart}/> 
              </div>
              <div className="integration___box">
                <img src={shopify}/> 
              </div>
              <div className="integration___box">
                <img src={yatra}/> 
              </div>
              <div className="integration___box">
                <img src={sendgrid}/> 
              </div>
            </div>
          </div>
        <Button onClick={endTour}>Next</Button>
        </Modal.Body>
      </Modal>
    </>
    
    
    )
}
export default ModalDocumentation