import React, { useEffect, useState } from 'react'
import Header from '../../layout/Header';
import { Modal, Button, CloseButton } from "react-bootstrap";
import swal from 'sweetalert';
import _ from 'lodash';
import { GetThemeData, PostSelectedData, PostThemeData, deleteThemeData } from '../../../actions/auth';
import { Link } from 'react-router-dom';

function Theme1() {
    const [loader, setLoader] = useState(false);
    const [editTest, setEditTest] = useState(true)
    const [dataTheme, setDataTheme] = useState()
    const [selectedItem, setSelectedItem] = useState();
    const [show, setShow] = useState(false);
    const [preview, setPreview] = useState(false);
    const handleClose = () => setShow(false);
    const handlePreview = () => setPreview(false);
    const [newTheme, setNewTheme] = useState({});
    const [dataPreview ,setDataPreview] = useState()

    const handleCampaigns = () => {
        setShow(true);
        setState(defaultTheme)
        setEditTest(true)
    };

    const defaultTheme = {
        themeName: "",
        bgColor: "#fafafa",
        txtColor: "#000000",
        btnBgColor: "#000000",
        btnTxtColor: "#FFFFFF",
        headerBgColor: "#e3f2fd",
        headerTxtColor: "#000000",
        divTxtColor: "#FFFFFF",
        linkBgColor: "#2056a5",
        divBgColor: "#7F8C8D",
        dataTxtColor: "#FFFFFF",
        dataBgColor: "#7F8C8D",
        campaignTxtColor: "#FFFFFF",
        campiagnBgColor: "#7F8C8D",
        linkColor: "#FFFFFF",
        footerbgColor: "#000000",
        font: "Roboto"

    };

    const [state, setState] = useState(defaultTheme);
    const handleChange = evt => {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
    }
    const getThemeObj = () => {
        const themeObj = {};
        themeObj[_.camelCase(state.themeName)] = {
            "name": state.themeName,
            "colors": {
                "body": state.bgColor,
                "text": state.txtColor,
                "footer": state.footerbgColor,
                "header": {
                    "text": state.headerTxtColor,
                    "background": state.headerBgColor,
                },
                "overviewData": {
                    "text": state.dataTxtColor,
                    "background": state.dataBgColor,
                },
                "overviewCampaign": {
                    "text": state.campaignTxtColor,
                    "background": state.campiagnBgColor,
                },
                "button": {
                    "text": state.btnTxtColor,
                    "background": state.btnBgColor,
                },
                "div": {
                    "text": state.divTxtColor,
                    "background": state.divBgColor,
                },
                "link": {
                    "text": state.linkColor,
                    "background": state.linkBgColor,
                }
            },
            "font": state.font
        };
        const body = themeObj[_.camelCase(state.themeName)];
        return { themeName: state.themeName, body };
    }
    useEffect(() => {
        const updated = getThemeObj();
        setNewTheme({ ...updated });
    }, [state]);

    const createTheme = () => {
        setLoader(true);
        if (editTest === true) {

            if (dataTheme === undefined || dataTheme?.length < 5) {
                let tempToken = JSON.parse(localStorage.getItem("token_gen"));
                tempToken = tempToken?.access_token;
                PostThemeData(tempToken, newTheme).then((res) => {
                    if (res.message === "success") {
                        swal({
                            position: "center",
                            icon: "success",
                            title: "Theme Created Successfully",
                            showConfirmButton: false,
                            timer: 2000,
                        });
                        setState(defaultTheme)
                        setShow(false);
                        setEditTest(true)
                        fetch()
                            .then((data) => {
                                setDataTheme(res.data.themesData)
                                setSelectedItem(res.data.selectedTheme)
                                setLoader(false);
                            })
                            .catch((error) => {
                            });
                    } else {
                        swal({
                            position: "center",
                            icon: "error",
                            title: res.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                        setLoader(false)
                    }
                });
            }
            else {
                swal({
                    position: "center",
                    icon: "error",
                    title: "Only five theme are allowed",
                    showConfirmButton: false,
                    timer: 2000,
                })
                setLoader(false)
            }
        } else {
            let tempToken = JSON.parse(localStorage.getItem("token_gen"));
            tempToken = tempToken?.access_token;
            PostThemeData(tempToken, newTheme).then((res) => {
                if (res.message === "success") {
                    swal({
                        position: "center",
                        icon: "success",
                        title: "Theme Update Successfully",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    setState(defaultTheme)
                    setShow(false);
                    setEditTest(true)
                    fetch()
                        .then((data) => {
                            setDataTheme(res.data.themesData)
                            setSelectedItem(res.data.selectedTheme)
                            setLoader(false);
                        })
                        .catch((error) => {
                        });
                } else {
                    swal({
                        position: "center",
                        icon: "error",
                        title: res.message,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                    setLoader(false)
                }
            });
        }
    }

    const fetch = async () => {
        setLoader(true);
        let tempToken = JSON.parse(localStorage.getItem("token_gen"));
        tempToken = tempToken?.access_token;
        try {
            const res = await GetThemeData(tempToken);
            setDataTheme(res.data.themesData)
            setSelectedItem(res.data.selectedTheme)
            setEditTest(true)
        } catch (error) {
        }
        setLoader(false);
    };

    useEffect(() => {
        fetch();
    }, []);

    const handleDelete = async (item) => {
        setLoader(true);
        let token = JSON.parse(localStorage.getItem("token_gen"));
        deleteThemeData(token?.access_token, item.id)
            .then((Setting) => {
                swal({
                    position: "center",
                    icon: "success",
                    title: "Theme Deleted Successfully",
                    showConfirmButton: false,
                    timer: 10000,
                });
                setEditTest(true)
                fetch()
                    .then((data) => {
                        setDataTheme(data.themesData)
                        setSelectedItem(data.selectedTheme)
                    })
                    .catch((error) => {
                    });
                setLoader(false);
            })
            .catch((err) => {
                swal({
                    position: "center",
                    icon: "error",
                    title: err.message,
                    showConfirmButton: false,
                    timer: 10000,
                });
                setLoader(false);
            });
    };
const handlePreviewData  = (item) => {
    console.log(item)
    setPreview(true)
    setDataPreview(item)
}
    const handleEdit = async (item) => {
        setShow(true);
        setState({
            bgColor: item?.body?.colors?.body,
            btnBgColor: item?.body?.colors?.button?.background,
            btnTxtColor: item?.body?.colors?.button?.text,
            headerBgColor: item?.body?.colors?.header?.background,
            headerTxtColor: item?.body?.colors?.header?.text,
            dataBgColor: item?.body?.colors?.overviewData?.background,
            dataTxtColor: item?.body?.colors?.overviewData?.text,
            campiagnBgColor: item?.body?.colors?.overviewCampaign?.background,
            campaignTxtColor: item?.body?.colors?.overviewCampaign?.text,
            divBgColor: item?.body?.colors?.div?.background,
            divTxtColor: item?.body?.colors?.div?.text,
            font: "Roboto",
            footerbgColor: item?.body?.colors?.footer,
            linkBgColor: item?.body?.colors?.link?.background,
            linkColor: item?.body?.colors?.link?.text,
            themeName: item?.themeName,
            txtColor: item?.body?.colors?.text,
        })
        setEditTest(false)
    }

    const themeSwitcher = (item) => {
        setLoader(true);
        let tempToken = JSON.parse(localStorage.getItem("token_gen"));
        tempToken = tempToken?.access_token;
        PostSelectedData(tempToken, item.id).then((res) => {
            if (res.message === "success") {
                swal({
                    position: "center",
                    icon: "success",
                    title: "Theme Activated Successfully",
                    showConfirmButton: false,
                    timer: 5000,
                });
                setEditTest(true)
                fetch()
                    .then((data) => {
                        setDataTheme(data.themesData)
                        setSelectedItem(data.selectedTheme)
                    })
                    .catch((error) => {
                    });
                setLoader(false);
            } else {
                swal({
                    position: "center",
                    icon: "error",
                    title: res.message,
                    showConfirmButton: false,
                    timer: 5000,
                });
            }
            setLoader(false)
        });
    };
    return (
        <>
            <Header name={"Theme"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>
                        <div className="dashboard__heading">
                            <div className="dashboard__headingLeft">
                                <div className="dashboard__headingContent">
                                    <h3>Select a Theme from below</h3>
                                    <button className="btn btn-primary" onClick={handleCampaigns} >Create a Theme</button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {dataTheme?.length >= 1 ?
                                dataTheme.map((item, index) => (
                                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4" key={index}>
                                        {/* <ThemeCard selected={selectedItem} data={item} /> */}
                                        <div className="dashboard__programsCard" style={{ backgroundColor: item?.body?.colors?.body }}>
                                            <div >

                                                <div className='d-flex justify-content-between'>

                                                
                                                <h5 style={{ color: item?.body?.colors?.text }}>
                                                    {item?.themeName}
                                                </h5>

                                                <div > <Link onClick={() => handlePreviewData(item)}><i className="fa-solid fa-eye" title="preview" style={{ fontSize: "20px" }}></i></Link><Link onClick={() => handleEdit(item)}><i className="fa fa-edit" title="Edit" style={{ fontSize: "20px",marginLeft:"10px" }}></i></Link>   <Link onClick={() => handleDelete(item)}><i className="fa fa-trash" title="delete" style={{ fontSize: "20px",marginLeft:"5px" }}></i></Link></div>
                                                    {/* <div style={{ backgroundColor: item?.body?.colors?.header?.background, color: item?.body?.colors?.header?.text, height: "30px" }}>
                                                        <p style={{ padding: "5px" }}>Header Color</p>
                                                    </div> */}

                                                </div>


                                                {selectedItem === item?.id ?
                                                    <p style={{ fontSize: "13px", color: item?.body?.colors?.text }}>This theme are activated on customer portal</p>
                                                    :
                                                    <p style={{ fontSize: "13px", color: item?.body?.colors?.text }}>Click the activate button to set this theme </p>
                                                }
                                            </div>
                                            {/* <div style={{ backgroundColor: item?.body?.colors?.div?.background, color: item?.body?.colors?.div?.text, height: "30px" }}>
                                                <p style={{ padding: "5px" }}>Div Color</p>
                                            </div> */}
                                            {/* <div className='row' style={{ marginTop: "10px" }}>
                                                <div className='col-md-8'>
                                                    <button className="btn" style={{ backgroundColor: item?.body?.colors?.button?.background, color: item?.body?.colors?.button?.text, }} >
                                                        Button Color
                                                    </button> {'  '}
                                                </div>
                                                <div className='col-md-4'>
                                                    <button className="btn" style={{ backgroundColor: item?.body?.colors?.link?.background, color: item?.body?.colors?.link?.text }}>
                                                        <Link to="" style={{ color: item?.body?.colors?.link?.text }}>Link Color</Link>
                                                    </button>
                                                </div>
                                            </div> */}
                                            {/* <div style={{ marginTop: "10px", backgroundColor: item?.body?.colors?.footer, color: item?.body?.colors?.div?.text, height: "30px" }}>
                                                <p style={{ padding: "5px" }}>Footer Color</p>
                                            </div> */}
                                            <button className='themeData' onClick={() => { themeSwitcher(item) }} style={{ backgroundColor: selectedItem === item?.id ? "green" : "#1064EA" }}> {selectedItem === item?.id ? "Activated" : "Activate"}</button>
                                        </div>
                                    </div>
                                )) : (
                                    <h4>There is no theme to show</h4>
                                )}
                        </div>
                    </div>
                    <Modal show={show} id="showm" onHide={handleClose} size="lg" centered keyboard={false}>
                        <Modal.Header> {editTest === true ?
                            <Modal.Title style={{ fontSize: "20px" }}>Create New Theme</Modal.Title> :
                            <Modal.Title style={{ fontSize: "20px" }}>Update Theme</Modal.Title>
                        }
                            <CloseButton onClick={handleClose} variant="white" />
                        </Modal.Header>
                        <Modal.Body>
                            <div className=''>
                                <div className="">
                                    <div className='theme-selection'>
                                        <label htmlFor="th_name">Theme Name:</label> {' '}
                                        <input
                                            type="text"
                                            id="themeName"
                                            name="themeName"
                                            value={state.themeName}
                                            placeholder="Specify a name"
                                            onChange={handleChange} />
                                    </div>
                                    <div className='row theme-selection'>
                                        <div className='col-md-6 color-align'>
                                            <label htmlFor="bg_color">Background Color:</label> {' '}
                                            <input type="color" id="bg_color" name="bgColor" value={state.bgColor} onChange={handleChange} />
                                        </div>
                                        <div className=' col-md-6 color-align'>
                                            <label htmlFor="txt_color">Text Color:</label> {' '}
                                            <input type="color" id="txt_color" name="txtColor" value={state.txtColor} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className='row theme-selection'>
                                        <div className='col-md-6 color-align'>
                                            <label htmlFor="header_bg_color">Header Background Color:</label> {' '}
                                            <input type="color" id="header_bg_color" name="headerBgColor" value={state.headerBgColor} onChange={handleChange} />
                                        </div>
                                        <div className='col-md-6 color-align'>
                                            <label htmlFor="header_txt_color">Text Color:</label> {' '}
                                            <input type="color" id="header_txt_color" name="headerTxtColor" value={state.headerTxtColor} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className='row theme-selection'>
                                        <div className='col-md-6 color-align'>
                                            <label htmlFor="btn_bg_color">Button Background Color:</label> {' '}
                                            <input type="color" id="btn_bg_color" name="btnBgColor" value={state.btnBgColor} onChange={handleChange} />
                                        </div>
                                        <div className='col-md-6 color-align'>
                                            <label htmlFor="btn_txt_color">Text Color:</label> {' '}
                                            <input type="color" id="btn_txt_color" name="btnTxtColor" value={state.btnTxtColor} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className='row theme-selection'>
                                        <div className='col-md-6 color-align'>
                                            <label htmlFor="btn_bg_color">Link Background Color:</label> {' '}
                                            <input type="color" id="link_bg_color" name="linkBgColor" value={state.linkBgColor} onChange={handleChange} />
                                        </div>
                                        <div className='col-md-6 color-align'>
                                            <label htmlFor="link_color">Text Color:</label> {' '}
                                            <input type="color" id="link_color" name="linkColor" value={state.linkColor} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className='row theme-selection'>
                                        <div className='col-md-6 color-align'>
                                            <label htmlFor="div_bg_color">Div Background Color:</label> {' '}
                                            <input type="color" id="diiv_bg_color" name="divBgColor" value={state.divBgColor} onChange={handleChange} />
                                        </div>
                                        <div className='col-md-6 color-align'>
                                            <label htmlFor="div_txt_color">Text Color:</label> {' '}
                                            <input type="color" id="div_txt_color" name="divTxtColor" value={state.divTxtColor} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className='row theme-selection'>
                                        <div className='col-md-6 color-align'>
                                            <label htmlFor="data_bg_color">Overview data Background Color:</label> {' '}
                                            <input type="color" id="data_bg_color" name="dataBgColor" value={state.dataBgColor} onChange={handleChange} />
                                        </div>
                                        <div className='col-md-6 color-align'>
                                            <label htmlFor="data_txt_color">Text Color:</label> {' '}
                                            <input type="color" id="data_txt_color" name="dataTxtColor" value={state.dataTxtColor} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className='row theme-selection'>
                                        <div className='col-md-6 color-align'>
                                            <label htmlFor="campaign_bg_color">Overview Campaign Background Color:</label> {' '}
                                            <input type="color" id="campaign_bg_color" name="campiagnBgColor" value={state.campiagnBgColor} onChange={handleChange} />
                                        </div>
                                        <div className='col-md-6 color-align'>
                                            <label htmlFor="campaign_txt_color">Text Color:</label> {' '}
                                            <input type="color" id="campaign_txt_color" name="campaignTxtColor" value={state.campaignTxtColor} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className='row theme-selection'>
                                    <div className='col-md-6 color-align'>
                                        <label htmlFor="footer_color">Footer Color:</label> {' '}
                                        <input type="color" id="footer_color" name="footerbgColor" value={state.footerbgColor} onChange={handleChange} />
                                    </div>
                                    </div>
                                    <div>
                                        {editTest === true ?
                                            <button className='btn btn-primary'
                                                style={{ float: 'right' }}
                                                onClick={createTheme}
                                                disabled={state.themeName.trim().length === 0}>
                                                Create Theme
                                            </button> :
                                            <button className='btn btn-primary'
                                                style={{ float: 'right' }}
                                                onClick={createTheme}
                                                disabled={state.themeName.trim().length === 0}>
                                                Update Theme
                                            </button>}

                                    </div>
                                </div>
                                {/* <div className="themeData">
                                    <span><b>Preview</b></span>
                                    <div className='preview' style={{ backgroundColor: state.bgColor, color: state.txtColor, fontFamily: state.font }}>
                                        <p>
                                            This is for preview only. Pick the color and font from the
                                            left side to see it working.
                                        </p>
                                        <div style={{ backgroundColor: state.headerBgColor, color: state.headerTxtColor, fontFamily: state.font}}>
                                            <p style={{ padding: "5px" }}>header Color</p>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-8'>
                                                <button className="btn" style={{ backgroundColor: state.btnBgColor, color: state.btnTxtColor, fontFamily: state.font }}>
                                                   Button Color
                                                </button> {'  '}
                                            </div>
                                            <div className='col-md-4'>
                                                <button className="btn" style={{ backgroundColor: state.linkBgColor, color: state.linkColor, fontFamily: state.font }}>
                                                    <a href="#" style={{ color: state.linkColor, fontFamily: state.font }}>Link Color</a>
                                                </button>
                                            </div>
                                        </div>
                                        <div style={{ backgroundColor: state.divBgColor, color: state.divTxtColor, fontFamily: state.font, height: "30px" }}>
                                            <p style={{ marginTop: "10px", padding: "5px" }}>Div Color</p>
                                        </div>
                                        <div style={{ backgroundColor: state.footerbgColor, color: state.divTxtColor, fontFamily: state.font, height: "30px" }}>
                                            <p style={{ marginTop: "30px", padding: "5px" }}>Footer Color</p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={preview} id="showm" onHide={handlePreview} size="lg" centered keyboard={false}>
                        <Modal.Header> 
                            <Modal.Title style={{ fontSize: "20px" }}>Preview</Modal.Title> 
                            
                       
                            <CloseButton onClick={handlePreview} variant="white" />
                        </Modal.Header>
                        <Modal.Body>
                            <div className=''>
                               
                                <div className="themeData">
                                    <span><b>{dataPreview?.themeName}</b></span>
                                    <div className='preview' style={{ backgroundColor: dataPreview?.body?.colors?.body, color: dataPreview?.body?.colors?.text, fontFamily: dataPreview?.body?.font }}>
                                        <p>
                                            This is for preview only. 
                                        </p>
                                        <div style={{ backgroundColor: dataPreview?.body?.colors?.header?.background, color: dataPreview?.body?.colors?.header?.text, fontFamily: dataPreview?.body?.font }}>
                                            <p style={{ padding: "5px" }}>header Color</p>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-8'>
                                                <button className="btn" style={{ backgroundColor: dataPreview?.body?.colors?.button?.background, color: dataPreview?.body?.colors?.button?.text, fontFamily: dataPreview?.body?.font}}>
                                                   Button Color
                                                </button> {'  '}
                                            </div>
                                            <div className='col-md-4'>
                                                <button className="btn" style={{ backgroundColor: dataPreview?.body?.colors?.link?.background, color: dataPreview?.body?.colors?.link?.text, fontFamily: dataPreview?.body?.font}}>
                                                    <a href="#" style={{ color:dataPreview?.body?.colors?.link?.text, fontFamily: dataPreview?.body?.font }}>Link Color</a>
                                                </button>
                                            </div>
                                        </div>
                                        <div style={{ backgroundColor: dataPreview?.body?.colors?.div?.background, color: dataPreview?.body?.colors?.link?.text, fontFamily: dataPreview?.body?.font, height: "30px" }}>
                                            <p style={{ marginTop: "10px", padding: "5px" }}>Div Color</p>
                                        </div>
                                        <div style={{ backgroundColor: dataPreview?.body?.colors?.footer, color: dataPreview?.body?.colors?.div?.text, fontFamily: dataPreview?.body?.font, height: "30px" }}>
                                            <p style={{ marginTop: "30px", padding: "5px" }}>Footer Color</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={handlePreview}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </>
    )
}

export default Theme1