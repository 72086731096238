import React from "react";
import { Navigate } from "react-router-dom";
const AdminPublicRoute = ({ children }) => {
  
        const temp = localStorage.getItem("saasemail");

        if (temp) {
            return <Navigate to="/register" />;
        }
    

    return children;
};

export default AdminPublicRoute;
