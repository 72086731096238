import React, { useEffect, useState } from "react";
import Paginated from "./Paginated";
import { COLUMNS } from "./column";
import { RegisterUser } from "../../actions/auth";
import AdminHeader from "../layout/AdminHeader";

const Register = () => {

    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [filterOption, setFilterOption] = useState("all");

    const fetchData = async () => {
        setLoader(true);
        const response = await RegisterUser();
        response?.forEach((val) => {
            val.emailconfirmed = val.emailconfirmed === "Y" ? "Y" : "N";
        });
        response?.forEach((val) => {
            val.cardStatus = val.cardStatus === "Active" ? "Y" : "";
        });
        response?.forEach((val) => {
            val.Created = val.Created.split("T")[0];
        });
        const filteredData = response.filter((val) => !val.email || (!val.email.endsWith("@clavax.com") && !val.email.endsWith("@clavax.us")));
        filteredData.sort((a, b) => (a.Created > b.Created ? -1 : 1));
        if (filterOption === "live") {
            const liveData = filteredData.filter((val) => val.isLive === "y");
            setData(liveData);
        } else {
            setData(filteredData);
        }
        setLoader(false);
    };

    useEffect(() => {
        fetchData();
    }, [filterOption]);

    const handleFilterChange = (e) => {
        setFilterOption(e.target.value);
    };

    return (
        <>
            <AdminHeader name={"Registered User"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__customersCard">
                        <div className="dashboard__header">
                            <div className="dashboard__left">
                                <h4>Registered User Details</h4>
                            </div>
                            <div className="dashboard__right">
                                <select value={filterOption} onChange={handleFilterChange}>
                                    <option value="all">All</option>
                                    <option value="live">Live</option>
                                </select>
                            </div>
                        </div>
                        <div className="dashboard__body">
                            <Paginated data={data} columns={COLUMNS} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Register;
