import { Modal, Button, CloseButton } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Fragment, useState, useEffect } from "react";
import { payment_done, payment_failed } from "../../../Image/Index";

const PaymentDoneModal = (props) => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (props.show) {
            setShow(true);
        }
    }, [props.show]);

    const handleClose = () => {
        setShow(false);
        navigate("/profile-settings?p03");
    };

    return (
        <Fragment>
            <Modal show={show} size="md" onHide={handleClose} backdrop={true}>
                <Modal.Header className="custom_header" closeButton></Modal.Header>
                <Modal.Body className="payment_status_modal">
                    <img src={payment_done} width={100} />
                    {/* <img src={payment_failed} width={100} /> */}
                    <h4 className="guides">Payment Done Successfully</h4>
                    {/* <h4 className="guides">Payment failed</h4> */}
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};
export default PaymentDoneModal;
