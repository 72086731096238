import React, { useCallback, useEffect, useState } from "react";
import Header from "../../layout/Header";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { csvFileIcon, backIcon2, loading } from "../../../Image/Index";
import { histTransactionBulkUpload, histCustomerBulkUpload, customerValidateUserJourneyCSVTEXT, transactionValidateUserJourneyCSV, customerValidateUserJourneyCSV, fileUploadDetails, transactionValidateUserJourneyCSVTEXT, GetSchemabyMerchantCode } from "../../../actions/auth";
import { generateString, getDate, selectValueGetter } from "../../../utils/helper";
import { COLUMNS } from "./userJourneyPartials/column";
import Paginated from "./userJourneyPartials/Paginated";
import { getFileUploadDetail, clearFileUploadDetail } from "../../../actions/uploadData";
import { connect } from "react-redux";
import SelectValidator from "../../partial/SelectValidator";
import { ValidatorForm } from "react-form-validator-core";
import { getCustomer } from "../../../actions/schema";

const UploadingExistingDatas = ({ getFileUploadDetail, clearFileUploadDetail, UploadFileData }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [schemaNames, setSchemaNames] = useState([]);
    const [selectedSchema, setSelectedSchema] = useState();
    const [updatedheaders, setHeaders] = useState([]);
    const [fileCustomer, setFileCustomer] = useState(null);
    const [fileTransaction, setFileTransaction] = useState(null);
    const [message, setMessage] = useState("");
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState();
    const [schemaSelectionMessage, setSchemaSelectionMessage] = useState("");
    const [customerState, setCustomerState] = useState({
        messageCustomer: "",
        progressCompleteCustomer: true,
        checkValidationCustomer: true,
        status: {},
    });
    const [transactionState, setTransactionState] = useState({
        messageTransaction: "",
        checkValidationTransaction: true,
        progressCompleteTransaction: true,
        status: {},
    });
    const [state, setState] = useState({
        isFileUploaded: false,

        showRecordTable: false,
        showBoth: false,
        display: false,
        showCustomer: false,
        showTransaction: false,
        fileUnderProcess: false,
    });
    const [recordTable, setRecordTable] = useState();
    const [flag, setFlag] = useState(false);

    const { messageTransaction, checkValidationTransaction, progressCompleteTransaction } = transactionState;
    const { messageCustomer, progressCompleteCustomer, checkValidationCustomer } = customerState;
    const { isFileUploaded, showBoth, display, showCustomer, showTransaction, fileUnderProcess, showRecordTable } = state;

    // const onchangeFileTransaction = async (event) => {
    //     const maxFileSize = 5; // Max File Size
    //     if(!event.target.files[0]){
    //         setTransactionState({ ...transactionState, checkValidationTransaction: true, messageTransaction: ""});
    //     }

    //     else if (event.target.files[0].type === "text/csv") {
    //         if (event.target.files[0].size > maxFileSize * 1024 * 1024) {
    //             setTransactionState({ ...transactionState, checkValidationTransaction: true, messageTransaction: "maximum file size should be 5MB" });
    //         } else {
    //             let response = await transactionValidateUserJourneyCSV(event.target.files[0]);
    //             const { message, totalCount } = response;
    //             if (message === "valid csv") {
    //                 setFileTransaction(event.target.files[0]);
    //                 setTransactionState({ ...transactionState, checkValidationTransaction: false, messageTransaction: message });
    //             } else {
    //                 setTransactionState({ ...transactionState, checkValidationTransaction: true, messageTransaction: message });
    //             }
    //         }
    //     } else {
    //         setTransactionState({ ...transactionState, checkValidationTransaction: true, messageTransaction: "File isn't in csv format" });
    //     }
    // };

    const [responseData, setResponseData] = useState();
    const fetchSchema = async () => {
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const id = localStorage.getItem("Id");

        const response = await GetSchemabyMerchantCode(token?.access_token, id);

        setResponseData(response);
    };
    useEffect(() => {
        fetchSchema();
    }, []);

    useEffect(() => {
        const extractSchemaNames = () => {
            if (responseData && responseData.data) {
                const names = responseData.data.map((item) => item.schemaName);
                setSchemaNames(names);
            }
        };

        extractSchemaNames();
    }, [responseData]);
    const [selectschema, setselectshema] = useState();
    const handleSchemaSelection = useCallback(
        (e) => {
            const selectedSchema = e.value;

            if (!selectedSchema) {
                setSchemaSelectionMessage("Please select a schema");
                return;
            }

            setSchemaSelectionMessage("");

            const selectedSchemaObj = responseData?.data?.find((item) => item.schemaName === selectedSchema);

            const fieldNames = selectedSchemaObj?.fields?.map((field) => field.fieldName) || [];

            setHeaders([...fieldNames]);
            setSelectedSchema(selectedSchema);
            setselectshema(selectedSchema); // Set the selected schemaName in state
        },
        [responseData, setSchemaSelectionMessage]
    );
    useEffect(() => {
        if (schemaNames.length === 1) {
            const defaultOption = {
                label: schemaNames[0],
                value: schemaNames[0],
                default: true,
            };
            handleSchemaSelection(defaultOption);
        }
    }, [handleSchemaSelection, schemaNames]);

    let options = schemaNames.map((name) => ({
        label: name,
        value: name,
    }));

    const expectedHeaders = updatedheaders;
    const downloadTransactionCSV = (event) => {
        event.preventDefault();
        if (!selectedSchema) {
            setSchemaSelectionMessage("Please select a schema");
            return;
        }

        const csvContent = expectedHeaders.join(",") + "\n";

        const element = document.createElement("a");
        const file = new Blob([csvContent], { type: "text/csv" });
        element.href = URL.createObjectURL(file);
        element.download = "TransactionData.csv";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    const onchangeFileTransaction = async (event) => {
        const maxFileSize = 5;

        if (!event.target.files[0]) {
            setTransactionState((prevState) => ({
                ...prevState,
                checkValidationTransaction: true,
                messageTransaction: "",
            }));
            setFileTransaction(null);
        } else if (event.target.files[0].type === "text/csv") {
            if (event.target.files[0].size > maxFileSize * 1024 * 1024) {
                setTransactionState((prevState) => ({
                    ...prevState,
                    checkValidationTransaction: true,
                    messageTransaction: "Maximum file size should be 5MB",
                }));
                setFileTransaction(null);
            } else {
                const fileHeaders = await getCSVFileHeader(event.target.files[0]);

                const cleanedFileHeaders = fileHeaders.map((header) => header.replace(/\\/g, "").replace(/\"/g, ""));
                const areHeaderEqual = (data, fileHeaders) => {
                    if (data.length !== fileHeaders.length) {
                        return false;
                    }
                    for (let i = 0; i < data.length; i++) {
                        if (!fileHeaders.includes(data[i])) {
                            return false;
                        }
                    }
                    return true;
                };

                if (areHeaderEqual(expectedHeaders, cleanedFileHeaders)) {
                    let response = await transactionValidateUserJourneyCSVTEXT(event.target.files[0]);
                    const { message, totalCount } = response;

                    if (message === "valid csv") {
                        setFileTransaction(event.target.files[0]);
                        setTransactionState((prevState) => ({
                            ...prevState,
                            checkValidationTransaction: false,
                            messageTransaction: message,
                        }));
                    } else {
                        setTransactionState((prevState) => ({
                            ...prevState,
                            checkValidationTransaction: true,
                            messageTransaction: message,
                        }));
                        setFileTransaction(null);
                    }
                } else {
                    setTransactionState((prevState) => ({
                        ...prevState,
                        checkValidationTransaction: true,
                        messageTransaction: "The file headers do not match the expected headers.",
                    }));
                    setFileTransaction(null);
                }
            }
        } else {
            setTransactionState((prevState) => ({
                ...prevState,
                checkValidationTransaction: true,
                messageTransaction: "File isn't in CSV format",
            }));
            setFileTransaction(null);
        }
    };

    const getCSVFileHeader = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const contents = e.target.result;
                const lines = contents.split(/\r\n|\n/);
                const headers = lines[0].split(",");
                resolve(headers);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsText(file);
        });
    };

    const fetch = async () => {
        const deletedCol = ["card_1", "Emp_Code", "PRG20190430125908_CustomerCode", "regagent", "lastName", "Emp_Name", "emp_email_ID", "Manager_Name", "Manager_email_ID", "HOD_Name", "HOD_Email_ID", "Sr_Mangement_HR", "Birthday", "HR", "IsManager", "IsHOD", "Incharge_and_above", "totalSpend", "Upi", "Division", "CustomerType", "DOJ"];
        try {
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const tedata = await getCustomer(token?.access_token);
            let schema = Object.keys(tedata.schema.properties);
            const properties = schema.filter((item) => !deletedCol.includes(item));
            setData(properties);
        } catch (error) {}
    };

    useEffect(() => {
        fetch();
    }, []);

    const downloadCSV = (event) => {
        event.preventDefault();
        const csvContent = data.join(",") + "\n";
        const element = document.createElement("a");
        const file = new Blob([csvContent], { type: "text/csv" });
        element.href = URL.createObjectURL(file);
        element.download = "CustomerRegistrationHist.csv";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };
    const onchangeFileCustomer = async (event) => {
        const maxFileSize = 5; // Max File Size
        if (!event.target.files[0]) {
            // No file selected or canceled, disable submit button
            setCustomerState({
                ...customerState,
                checkValidationCustomer: true,
                messageCustomer: "",
            });
            setFileCustomer(null); // Clear the file state
        } else if (event.target.files[0].type === "text/csv") {
            if (event.target.files[0].size > maxFileSize * 1024 * 1024) {
                setCustomerState({
                    ...customerState,
                    checkValidationCustomer: true,
                    messageCustomer: "Maximum file size should be 5MB",
                });
                setFileCustomer(null); // Clear the file state
            } else {
                const fileHeaders = await getCSVFileHeaders(event.target.files[0]);

                const cleanedFileHeaders = fileHeaders.map((header) => header.replace(/\\/g, "").replace(/\"/g, ""));
                const areHeadersEqual = (data, fileHeaders) => {
                    if (data.length !== fileHeaders.length) {
                        return false;
                    }
                    for (let i = 0; i < data.length; i++) {
                        if (!fileHeaders.includes(data[i])) {
                            return false;
                        }
                    }
                    return true;
                };

                if (areHeadersEqual(data, cleanedFileHeaders)) {
                    let response = await customerValidateUserJourneyCSVTEXT(event.target.files[0]);

                    const { message, totalCount } = response;

                    if (message === "valid csv") {
                        setFileCustomer(event.target.files[0]);
                        setCustomerState({
                            ...customerState,
                            checkValidationCustomer: false,
                            messageCustomer: message,
                        });
                    } else {
                        setCustomerState({
                            ...customerState,
                            checkValidationCustomer: true,
                            messageCustomer: message,
                        });
                        setFileCustomer(null); // Clear the file state
                    }
                } else {
                    setCustomerState({
                        ...customerState,
                        checkValidationCustomer: true,
                        messageCustomer: "The file headers do not match the expected headers.",
                    });
                    setFileCustomer(null); // Clear the file state
                }
            }
        } else {
            setCustomerState({
                ...customerState,
                checkValidationCustomer: true,
                messageCustomer: "File isn't in CSV format",
            });
            setFileCustomer(null); // Clear the file state
        }
    };

    const getCSVFileHeaders = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const contents = e.target.result;
                const lines = contents.split(/\r\n|\n/);
                const headers = lines[0].split(",");
                resolve(headers);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsText(file);
        });
    };

    const fetchData = async () => {
        setLoader(true);
        await clearFileUploadDetail();
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const merchantcode = localStorage.getItem("merchantcode");
        // (await location?.state?.from) === "transaction" && getFileUploadDetail(token?.access_token, merchantcode, "Transaction");
        // (await location?.state?.from) === "customer" && getFileUploadDetail(token?.access_token, merchantcode, "Customer");
        // (await location?.state?.from) === "upload" && getFileUploadDetail(token?.access_token, merchantcode, "All");
        getFileUploadDetail(token?.access_token, merchantcode, "All");
        setLoader(false);
    };

    // const uploadFileCustomer = async () => {
    // 	const token = JSON.parse(localStorage.getItem("token_gen"));
    // 	setCustomerState({ ...customerState, checkValidationCustomer: true });
    // 	// setCustomerState({ ...customerState, progressLoading: true });
    // 	// const res = await customerBulkUpload(fileCustomer, token?.access_token);
    // 	if (true) {
    // 		setCustomerState({ ...customerState, progressCompleteCustomer: false, messageCustomer: "res.message", checkValidationCustomer: true });
    // 		// setMessage(res.message);
    // 	} else {
    // 		setCustomerState({ ...customerState, progressCompleteCustomer: true, messageCustomer: "res.message", checkValidationCustomer: false });
    // 		// setMessage(res.message);
    // 	}
    // };
    // 	useEffect(() => {
    // 		const token = JSON.parse(localStorage.getItem("token_gen"));
    // 		const merchantcode =  localStorage.getItem("merchantcode");
    // 		fileUploadDetails(token?.access_token,merchantcode,"Transaction")
    // },[])
    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        let temp = [];
        location?.state?.from === "customer" ? (temp = Array.isArray(UploadFileData?.data) && UploadFileData?.data.filter((e) => e.fileType === "CustomerRegistration")) : location?.state?.from === "transaction" ? (temp = Array.isArray(UploadFileData?.data) && UploadFileData?.data.filter((e) => e.fileType === "CustomerTransaction")) : (temp = Array.isArray(UploadFileData?.data) && UploadFileData?.data);
        getRecordTable(temp);
    }, [UploadFileData]);

    const getRecordTable = (res) => {
        setRecordTable(res);
    };

    const onSubmit = async () => {
        const fileCode = generateString(5);
        const schemanamec = "CustomerSchema";
        setLoader(true);
        setCustomerState({ ...customerState, checkValidationCustomer: true });
        setTransactionState({ ...transactionState, checkValidationTransaction: true });
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const merchantcode = localStorage.getItem("merchantcode");
        if (location?.state?.from === "customer") {
            const customrer = await histCustomerBulkUpload(fileCustomer, token?.access_token, false, false, fileCode, schemanamec);
            await fileUploadDetails(token?.access_token, merchantcode, "Customer");
            // return res.message==="success"&& navigate("/analytical-report")
            setCustomerState({ ...customerState, progressCompleteCustomer: true, messageCustomer: customrer.message, checkValidationCustomer: true });
            setState({ isFileUploaded: true });
            setFlag(true);
        }
        if (location?.state?.from === "transaction") {
            const transaction = histTransactionBulkUpload(fileTransaction, token?.access_token, false, false, fileCode, selectschema);
            await fileUploadDetails(token?.access_token, merchantcode, "Transaction");
            // return res.message==="success"&& navigate("/analytical-report")

            setTransactionState({ ...transactionState, progressCompleteTransaction: false, checkValidationTransaction: true });
            setState({ isFileUploaded: true });
            setFlag(true);
        }
        if (location?.state?.from === "upload") {
            const customrer = await histCustomerBulkUpload(fileCustomer, token?.access_token, true, true, fileCode, schemanamec);
            const transaction = (await customrer.message) === "success" && histTransactionBulkUpload(fileTransaction, token?.access_token, true, true, fileCode, selectschema);
            await transaction
                .then((res) => {
                    // return res.message==="success"&& navigate("/analytical-report")
                    fileUploadDetails(token?.access_token, merchantcode, "Customer").then((cusres) => {
                        setCustomerState({ ...customerState, progressCompleteCustomer: true, messageCustomer: customrer.message, checkValidationCustomer: true, status: cusres.message });
                        setTransactionState({ ...transactionState, progressCompleteTransaction: false, messageTransaction: res.message, checkValidationTransaction: true });
                        setState({ isFileUploaded: true });
                        setFlag(true);
                    });
                })
                .catch(() => {});
        }
        setLoader(false);

        // return transaction.message==="success"&& navigate("/analytical-report")
    };

    // useEffect(() => {
    //     getFileStatus();
    // }, []);

    // useEffect(() => {
    //      getFileStatus();
    // }, [isFileUploaded]);

    const getFileStatus = async () => {
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const merchantcode = localStorage.getItem("merchantcode");
        setLoader(true);
        if (location?.state.from === "customer") {
            let customerStatus = await fileUploadDetails(token?.access_token, merchantcode, "Customer");
            if (!customerStatus.message.isProcessed) {
                setState({
                    ...state,
                    // fileType: customerStatus.message.fileType,
                    // BlobFilePath: customerStatus.message.BlobFilePath,
                    // RecordProcessCount: customerStatus.message.recordProcessCount,
                    // TotalRecords: customerStatus.message.totalRecords,
                    // UploadedDate: customerStatus.message.uploadedDate,
                    fileUnderProcess: true,
                    showRecordTable: false,
                    setFlag: true,
                });
            }
            if (customerStatus.message.isProcessed) {
                setState({
                    ...state,
                    showCustomer: true,
                    // fileType: customerStatus.message.fileType,
                    // BlobFilePath: customerStatus.message.BlobFilePath,
                    // RecordProcessCount: customerStatus.message.recordProcessCount,
                    // TotalRecords: customerStatus.message.totalRecords,
                    // UploadedDate: customerStatus.message.uploadedDate,
                    fileUnderProcess: false,
                    showRecordTable: true,
                });
                setFlag(false);
            }
            if (customerStatus.message === "No Data Available") {
                setState({ showBoth: false, fileUnderProcess: false, showRecordTable: true });
                setFlag(false);
            }
        } else if (location?.state.from === "transaction") {
            let transactionStatus = await fileUploadDetails(token?.access_token, merchantcode, "Transaction");
            if (!transactionStatus.message.isProcessed) {
                setState({
                    ...state,
                    // fileType: transactionStatus.message.fileType,
                    // BlobFilePath: transactionStatus.message.BlobFilePath,
                    // RecordProcessCount: transactionStatus.message.recordProcessCount,
                    // TotalRecords: transactionStatus.message.totalRecords,
                    // UploadedDate: transactionStatus.message.uploadedDate,
                    fileUnderProcess: true,
                    showRecordTable: false,
                });
                setFlag(true);
            }
            if (transactionStatus.message.isProcessed) {
                setState({
                    ...state,
                    showTransaction: true,
                    // fileType: transactionStatus.message.fileType,
                    // BlobFilePath: transactionStatus.message.BlobFilePath,
                    // RecordProcessCount: transactionStatus.message.recordProcessCount,
                    // TotalRecords: transactionStatus.message.totalRecords,
                    // UploadedDate: transactionStatus.message.uploadedDate,
                    fileUnderProcess: false,
                    showRecordTable: true,
                });
                setFlag(false);
            }
            if (transactionStatus.message === "No Data Available") {
                setState({ showBoth: false, fileUnderProcess: false, showRecordTable: false });
                setFlag(false);
            }
        }
        // if (location?.status?.from === "upload") {
        else {
            let customerStatus = await fileUploadDetails(token?.access_token, merchantcode, "Customer");
            if (customerStatus.message.isProcessed) {
                let transactionStatus = await fileUploadDetails(token?.access_token, merchantcode, "Transaction");
                if (customerStatus.message.isProcessed && !transactionStatus.message.isProcessed) {
                    setState({
                        ...state,
                        // fileType: transactionStatus.message.fileType,
                        // BlobFilePath: transactionStatus.message.BlobFilePath,
                        // RecordProcessCount: transactionStatus.message.recordProcessCount,
                        // TotalRecords: transactionStatus.message.totalRecords,
                        // UploadedDate: transactionStatus.message.uploadedDate,
                        fileUnderProcess: true,
                        showRecordTable: false,
                    });
                    setFlag(true);
                }
                if (customerStatus.message.isProcessed && transactionStatus.message.isProcessed) {
                    setState({
                        ...state,
                        showBoth: true,
                        showRecordTable: true,
                        fileUnderProcess: false,
                        // fileType: transactionStatus.message.fileType,
                        // BlobFilePath: transactionStatus.message.BlobFilePath,
                        // RecordProcessCount: transactionStatus.message.recordProcessCount,
                        // TotalRecords: transactionStatus.message.totalRecords,
                        // UploadedDate: transactionStatus.message.uploadedDate,
                        // customerRecordProcessCount: customerStatus.message.recordProcessCount,
                        // transactionRecordProcessCount: transactionStatus.message.recordProcessCount,
                        // customerTotalRecords: customerStatus.message.totalRecords,
                        // transactionTotalRecords: transactionStatus.message.totalRecords,
                        // customerBlobFilePath: customerStatus.message.blobFilePath,
                        // transactionBlobFilePath: transactionStatus.message.blobFilePath,
                        // customerUploadedDate: getDate(customerStatus.message.uploadedDate, "MM-DD-YYYY"),
                        // UploadedDate: getDate(transactionStatus.message.uploadedDate, "MM-DD-YYYY"),
                    });
                    setFlag(false);
                }
            }
            if (!customerStatus.message.isProcessed) {
                setState({
                    ...state,
                    // fileType: customerStatus.message.fileType,
                    // BlobFilePath: customerStatus.message.BlobFilePath,
                    // RecordProcessCount: customerStatus.message.recordProcessCount,
                    // TotalRecords: customerStatus.message.totalRecords,
                    // UploadedDate: customerStatus.message.uploadedDate,
                    fileUnderProcess: true,
                    showRecordTable: false,
                });
                setFlag(true);
            }
            if (customerStatus.message === "No Data Available") {
                setState({ showBoth: true, showRecordTable: true });
                setFlag(false);
            }
        }
        setLoader(false);
    };

    // useEffect(() => {
    //     const token = JSON.parse(localStorage.getItem("token_gen"));
    //     const merchantcode = localStorage.getItem("merchantcode");
    //     // console.log(transactionState.progressCompleteTransaction)
    //     intervalId =
    //         !transactionState.progressCompleteTransaction &&
    //         setInterval(() => {
    //             if (!customerState?.status?.isProcessed && !transactionState.progressCompleteTransaction) {
    //                 fileUploadDetails(token?.access_token, merchantcode, "Customer").then((cusres) => {
    //                     setCustomerState({ ...customerState, progressCompleteCustomer: true, checkValidationCustomer: true, status: cusres.message });
    //                 });
    //             } else if (customerState.status.isProcessed && !transactionState.status.isProcessed) {
    //                 fileUploadDetails(token?.access_token, merchantcode, "Transaction").then((trares) => {
    //                     setTransactionState({ ...transactionState, progressCompleteTransaction: true, checkValidationTransaction: true });
    //                 });
    //             }
    //         }, 5000);
    // }, [customerState.status.isProcessed]);

    const handleRefresh = (e) => {
        e.preventDefault();
        getFileStatus();
    };

    useEffect(() => {
        let timer;
        if (flag === true) {
            timer = setInterval(() => {
                getFileStatus();
            }, 30000);
        }
        return () => {
            clearInterval(timer);
        };
    }, [flag]);

    const handleSubmit = () => {};
    return (
        <>
            <Header name={"Uploading Historical Data"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="uploadingExistingData">
                <div className="container">
                    <div className="uploadingExistingData__main">
                        <div className="dashboard__headingBackLink dashboard__headingBackLink2">
                            <Link to="" onClick={() => navigate(-1)}>
                                <img style={{ width: "18px" }} src={backIcon2} alt="" />
                                <span>Back</span>
                            </Link>
                        </div>
                        <div className="uploadingExistingData__left">
                            <img src={"/Images/uploadExistingDataImg.png"} alt="" />
                        </div>

                        <div className="uploadingExistingData__right">
                            {fileUnderProcess === false ? (
                                <div className="uploadingExistingData__content">
                                    <div className="uploadingExistingData__heading">
                                        <h1>Upload Data</h1>
                                        <h4>We have provided a formatted excel sheet where you can import the data and upload it so that all the right fields match to generate reports.</h4>
                                    </div>
                                    <div className="uploadingExistingData__uploadBox">
                                        {location?.state?.from === "upload" ? (
                                            <>
                                                <div className="uploadingExistingData__uploadBoxTop">
                                                    <div className="uploadingExistingData__uploadBoxLabel">
                                                        <label>Historical Customer Data</label>
                                                        <span className="edit_btn" csvalue="Edit">
                                                            <i className="fa-solid fa-info-circle"></i>
                                                            <div className="show___div">
                                                                <table className="">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Field Name</th>
                                                                            <th>DataType</th>
                                                                            <th>Format</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>name</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>mobile</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>email</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>lastName</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>gender</td>
                                                                            <td>string</td>
                                                                            <td>M/F</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>dob</td>
                                                                            <td>string</td>
                                                                            <td>YYYY-MM-DD</td>
                                                                        </tr>
                                                                        {/* <tr>
                                                                            <td>Emp_Name</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>emp_email_ID</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Manager_Name</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Manager_email_ID</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>HOD_Name</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>HOD_Email_ID</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Division</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Sr_Mangement_HR</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Birthday</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>DOJ</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Emp_Code</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr> */}
                                                                        <tr>
                                                                            <td>description</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>designation</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        {/* <tr>
                                                                            <td>HR</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>IsManager</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>IsHOD</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Incharge_and_above</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>totalSpend</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr> */}
                                                                        <tr>
                                                                            <td>referralCode</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>referredBy</td>
                                                                            <td>string</td>
                                                                            <td></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <div className="uploadingExistingData__uploadBoxUploadFile">
                                                        <form encType="multipart/form-data">
                                                            <input className="form-control" placeholder="Uploaded File" type="file" accept={".csv"} onChange={onchangeFileCustomer} />
                                                        </form>
                                                        {/* <button type="button" className="btn btn-primary" disabled={checkValidationCustomer} onClick={uploadFileCustomer}>
                                       Browse
                                   </button> */}
                                                    </div>
                                                    <div className="uploadingExistingData__uploadBoxDownloadFile">
                                                        <Link onClick={downloadCSV} download="CustomerRegistrationHist" target="_blank">
                                                            <img src={csvFileIcon} alt="" />
                                                            <span>Customer Sample</span>
                                                        </Link>
                                                    </div>
                                                    {/* {messageCustomer === "empty file" && <span>This file is empty</span>}
                               {messageCustomer === "file length should be 100 rows" && <span>file length should be 100 rows</span>} */}
                                                    {messageCustomer && messageCustomer != "valid csv" && messageCustomer != "success" && <span style={{ color: "red" }}>{messageCustomer}</span>}
                                                    {messageCustomer === "File isn't in csv format" && <span style={{ color: "red" }}>File isn't in csv format</span>}
                                                    {messageCustomer === "exceed maximum file size" && <span style={{ color: "red" }}>File size exceeds the limit of 25 MB</span>}
                                                </div>
                                                <div className="uploadingExistingData__uploadBoxTop">
                                                    <div className="uploadingExistingData__uploadBoxLabel">
                                                        <label className="htd_label">Historical Transaction Data</label>
                                                        <label className="htd_label">Select Schema:</label>
                                                    </div>

                                                    <ValidatorForm onSubmit={handleSubmit} className="" style={{ width: "calc(80% - 1rem)" }}>
                                                        <SelectValidator placeholder="Select" className="htd_select" name="choice" value={selectValueGetter(options, selectedSchema)} options={options} onChange={handleSchemaSelection} />
                                                    </ValidatorForm>
                                                    {schemaSelectionMessage && (
                                                        <p className="schema-selection-message" style={{ color: "red" }}>
                                                            {schemaSelectionMessage}
                                                        </p>
                                                    )}

                                                    <div className="uploadingExistingData__uploadBoxUploadFile">
                                                        <form encType="multipart/form-data">
                                                            <input className="form-control" placeholder="Uploaded File" type="file" accept={".csv"} onChange={onchangeFileTransaction} />
                                                        </form>

                                                        {/* <button type="button" className="btn btn-primary" disabled={checkValidationTransaction} onClick={uploadFileTransaction}>
                                       Browse
                                   </button> */}
                                                    </div>

                                                    <div className="uploadingExistingData__uploadBoxDownloadFile">
                                                        <Link onClick={downloadTransactionCSV} download="TransactionRegistrationHist" target="_blank">
                                                            <img src={csvFileIcon} alt="" />
                                                            <span>Transaction Sample</span>
                                                        </Link>
                                                    </div>
                                                    {/* {messageTransaction === "empty file" && <span>This file is empty</span>}
                               {messageTransaction === "file length should be 100 rows" && <span>file length should be 100 rows</span>} */}
                                                    {messageTransaction && messageTransaction != "valid csv" && messageTransaction != "success" && <span style={{ color: "red" }}>{messageTransaction}</span>}
                                                    {messageTransaction === "File isn't in csv format" && <span style={{ color: "red" }}>File isn't in csv format</span>}
                                                    {messageTransaction === "exceed maximum file size" && <span style={{ color: "red" }}>File size exceeds the limit of 25 MB</span>}
                                                </div>{" "}
                                                
                                            </>
                                        ) : location?.state?.from === "customer" ? (
                                            <div className="uploadingExistingData__uploadBoxTop">
                                                <div className="uploadingExistingData__uploadBoxLabel">
                                                    <label>Historical Customer Data</label>
                                                    <span className="edit_btn" csvalue="Edit">
                                                        <i className="fa-solid fa-info-circle"></i>
                                                        <div className="show___div">
                                                            <table className="">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Field Name</th>
                                                                        <th>DataType</th>
                                                                        <th>Format</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>name</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td>mobile</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>email</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>lastName</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>gender</td>
                                                                        <td>string</td>
                                                                        <td>M/F</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>dob</td>
                                                                        <td>string</td>
                                                                        <td>YYYY-MM-DD</td>
                                                                    </tr>
                                                                    {/* <tr>
                                                                        <td>Emp_Name</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>emp_email_ID</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Manager_Name</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Manager_email_ID</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>HOD_Name</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>HOD_Email_ID</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Division</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Sr_Mangement_HR</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Birthday</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>DOJ</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Emp_Code</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr> */}
                                                                    <tr>
                                                                        <td>description</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>designation</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    {/* <tr>
                                                                        <td>HR</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>IsManager</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>IsHOD</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Incharge_and_above</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>totalSpend</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr> */}
                                                                    <tr>
                                                                        <td>referralCode</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>referredBy</td>
                                                                        <td>string</td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </span>
                                                </div>
                                                <div className="uploadingExistingData__uploadBoxUploadFile">
                                                    <form encType="multipart/form-data">
                                                        <input className="form-control" placeholder="Uploaded File" type="file" accept={".csv"} onChange={onchangeFileCustomer} />
                                                    </form>
                                                    {/* <button type="button" className="btn btn-primary" disabled={checkValidationCustomer} onClick={uploadFileCustomer}>
                                                Browse
                                            </button> */}
                                                </div>
                                                <div className="uploadingExistingData__uploadBoxDownloadFile">
                                                    <Link onClick={downloadCSV} download="CustomerRegistrationHist" target="_blank">
                                                        <img src={csvFileIcon} alt="" />
                                                        <span>Customer Sample</span>
                                                    </Link>
                                                </div>
                                                {/* {messageCustomer === "empty file" && <span>This file is empty</span>}
                                        {messageCustomer === "file length should be 100 rows" && <span>file length should be 100 rows</span>} */}
                                                {messageCustomer && messageCustomer != "valid csv" && messageCustomer != "success" && <span style={{ color: "red" }}>{messageCustomer}</span>}
                                                {messageCustomer === "File isn't in csv format" && <span style={{ color: "red" }}>File isn't in csv format</span>}
                                                {messageCustomer === "exceed maximum file size" && <span style={{ color: "red" }}>File size exceeds the limit of 25 MB</span>}
                                            </div>
                                        ) : (
                                            <div className="uploadingExistingData__uploadBoxTop">
                                                <div className="uploadingExistingData__uploadBoxLabel">
                                                    <label>Historical Transaction Data</label>
                                                </div>
                                                <label className="label_select_schema">Select Schema:</label>
                                                <ValidatorForm onSubmit={handleSubmit} className="select_schema_form">
                                                    <SelectValidator placeholder="Select" className="htd_select" name="choice" value={selectValueGetter(options, selectedSchema)} options={options} onChange={handleSchemaSelection} />
                                                </ValidatorForm>
                                                {schemaSelectionMessage && (
                                                    <p className="schema-selection-message" style={{ color: "red" }}>
                                                        {schemaSelectionMessage}
                                                    </p>
                                                )}
                                                <div className="uploadingExistingData__uploadBoxUploadFile">
                                                    <form encType="multipart/form-data">
                                                        <input className="form-control" placeholder="Uploaded File" type="file" accept={".csv"} onChange={onchangeFileTransaction} />
                                                    </form>
                                                    {/* <button type="button" className="btn btn-primary" disabled={checkValidationTransaction} onClick={uploadFileTransaction}>
                                              Browse
                                          </button> */}
                                                </div>
                                                <div className="uploadingExistingData__uploadBoxDownloadFile">
                                                    <Link onClick={downloadTransactionCSV} download="TransactionRegistrationHist" target="_blank">
                                                        <img src={csvFileIcon} alt="" />
                                                        <span>Transaction Sample</span>
                                                    </Link>
                                                </div>
                                                {/* {messageTransaction === "empty file" && <span>This file is empty</span>}
                                      {messageTransaction === "file length should be 100 rows" && <span>file length should be 100 rows</span>} */}
                                                {messageTransaction && messageTransaction != "valid csv" && messageTransaction != "success" && <span style={{ color: "red" }}>{messageTransaction}</span>}
                                                {messageTransaction === "File isn't in csv format" && <span style={{ color: "red" }}>File isn't in csv format</span>}
                                                {messageTransaction === "exceed maximum file size" && <span style={{ color: "red" }}>File size exceeds the limit of 25 MB</span>}

                                            </div>
                                            
                                        )}

                                        <div className="uploadingExistingData__uploadBoxBottom uploadingExistingData__uploadBoxBottom--column">
                                            {/* <Link className="w-100" to={messageCustomer === "success" && messageTransaction === "success" ? "/analytical-report" : "/uploading-existing-data"}> */}
                                            {location?.state?.from === "customer" || location?.state?.from === "transaction" ? (
                                                <button type="button" onClick={onSubmit} className="btn btn-primary w-100" disabled={checkValidationCustomer && checkValidationTransaction}>
                                                    Submit
                                                </button>
                                            ) : (
                                                <button type="button" onClick={onSubmit} className="btn btn-primary w-100" disabled={checkValidationCustomer || checkValidationTransaction}>
                                                    Submit
                                                </button>
                                            )}

                                            {/* </Link> */}
                                            <p>
                                                <strong>Note</strong>: You can contact Novus Support in case of very large data set or any issues with the data set fields using the 'Contact Novus Support' button below.
                                            </p>
                                        </div>
                                    </div>
                                    {/* <div className="uploadingExistingData__buttonSection">
                                <div className="uploadingExistingData__buttonSectionLeft">
                                    <Link to="/business-goals-selection" state={{ path: "/uploading-existing-datas" }}>
                                        Skipp
                                    </Link>
                                </div>
                              
                            </div> */}
                                </div>
                                
                                
                            ) : (
                                <>
                                    <div className="underprocessbox">
                                        <figure>
                                            <img src={loading} width="150" />
                                        </figure>
                                        <h3>Your File in under process</h3>
                                        <Link onClick={handleRefresh}>Refresh</Link>
                                    </div>
                                </>
                            )}
                            <div className="uploadingExistingData__buttonSection">
                            {fileUnderProcess === false ? (
                            <div className="uploadingExistingData__buttonSectionLeft">
                                    <Link to="/business-goals-selection" state={{ path: "/uploading-existing-datas" }}>
                                        Skip
                                    </Link>
                                </div>
                            ): null }
                                <div className="uploadingExistingData__buttonSectionRight">
                                    <Link to="/contact-us" className="btn btn-outline-primary">
                                        Contact Novus Support
                                        <i className="fa-solid fa-comment-dots"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {recordTable?.length > 0 && typeof recordTable === "object" ? (
                        <div className="dashboard__body">
                            <Paginated data={recordTable} columns={COLUMNS} />
                        </div>
                    ) : (
                        <h4>There is no record</h4>
                    )}
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => ({
    UploadFileData: state.UploadFileData,
});

const mapDispatchToProps = {
    getFileUploadDetail,
    clearFileUploadDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadingExistingDatas);
