import React, { useState } from 'react';
import Header from '../../layout/Header';
import { ValidatorForm } from 'react-form-validator-core';
import TextValidator from '../../partial/TextValidator';

import swal from 'sweetalert';
import { SendGridEmail } from '../../../actions/auth';

function SendGrid() {
    const [loader, setLoader] = useState(false);
    const [editorData, setEditorData] = useState("");
    const [data, setData] = useState();
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
        subject: "",
        sendgridApiKey: "",
        fromEmail: "",
        fromName: "",
        emails: [],
        body: "",
        purpose: ""
    });

    const { subject, fromEmail, fromName, sendgridApiKey, purpose } = formData;

    const handleSubmit = async (e) => {
        if (file) {
            e.preventDefault();
            setLoader(true);
            const token = JSON.parse(localStorage.getItem("token_gen"));
            await SendGridEmail(token?.access_token, formData,).then((res) => {
                if (res.message === "Sending Email SuccessFull") {
                    swal({
                        position: "center",
                        icon: "success",
                        title: res?.message,
                        showConfirmButton: false,
                        timer: 5000,
                    });
                    setLoader(false);
                }
                else {
                    swal({
                        position: "center",
                        icon: "error",
                        title: res?.message,
                        showConfirmButton: false,
                        timer: 5000,
                    });
                }

            });
        } else {
            setState({ ...state, error: true, errorMsg: "Please upload a file" });
        }
        setLoader(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFile = (event) => {
        console.log(event)
        setState({ ...state, file: event.target.files[0], error: false });
        const file = event.target.files[0];
        console.log(file)
        const reader = new FileReader();
        console.log(reader)
        const fileExtension = file?.name?.split('.').pop().toLowerCase();
        reader.onload = (e) => {
            const content = e.target.result;
            const lines = content.split('\n').filter(line => line.trim() !== '');
            const headers = lines[0].split(',').map(header => header.trim());
            const data = lines.slice(1).map(line => {
                const values = line.split(',').map(value => value.trim());
                let record = {};
                headers.forEach((header, index) => {
                    record[header] = values[index];
                });
                return record;
            });
            setFormData((prevFormData) => ({
                ...prevFormData,
                emails: data,
                fileName: file.name
            }));
        };
        reader.readAsText(file);
    };

    const [state, setState] = useState({
        file: "",
        error: true,
        errorMsg: "Note:- Only .csv type files are supported.",
    });

    const { file, error, errorMsg } = state;

    const handleEditorChange = (event) => {
        const data = event.editor.getData();
        setEditorData(data);
        setFormData((prevFormData) => ({
            ...prevFormData,
            body: data
        }));
    };

    return (
        <div>
            <Header name={"Send Email"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className='dashboard__content'>
                <div className="dashboard__transactionsCard">
                    <div className="dashboard__header">
                        <div className="dashboard__left">
                            <h4 id='transction_list'>Send Email</h4>
                        </div>
                    </div>
                    <ValidatorForm className="dashboard__customerViewMain" onSubmit={(e) => handleSubmit(e)}>
                        <div className='dashboard__customerViewCard'>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="form-group withIcon">
                                        <label>Purpose</label>
                                        <TextValidator
                                            placeholder="Purpose"
                                            className="form-control"
                                            name="purpose"
                                            value={purpose}
                                            onChange={handleChange}
                                            validators={["required"]}
                                            errorMessages={["Please enter purpose"]}

                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="form-group withIcon">
                                        <label>Subject</label>
                                        <TextValidator
                                            placeholder="Subject"
                                            className="form-control"
                                            name="subject"
                                            value={subject}
                                            onChange={handleChange}
                                            validators={["required"]}
                                            errorMessages={["Please enter subject"]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="form-group withIcon">
                                        <label>From Name</label>
                                        <TextValidator placeholder="From Name" className="form-control" name="fromName" onChange={handleChange} value={fromName}
                                            validators={["required"]}
                                            errorMessages={["Please enter from name"]} />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="form-group withIcon">
                                        <label>From Email</label>
                                        <TextValidator placeholder="Email Id" className="form-control" name="fromEmail" onChange={handleChange} value={fromEmail}
                                            validators={["required"]}
                                            errorMessages={["Please enter from email"]} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="form-group withIcon">
                                        <label>Send Grid Api Key</label>
                                        <TextValidator
                                            placeholder="send grid api key"
                                            className="form-control"
                                            name="sendgridApiKey"
                                            value={sendgridApiKey}
                                            onChange={handleChange}
                                            validators={["required"]}
                                            errorMessages={["Please enter send grid api key"]}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="form-group withIcon">
                                        <label>Email Data</label>
                                        <div encType="multipart/form-data">
                                            <input className="form-control" placeholder="Uploaded File" type="file" accept=".csv" required onChange={handleFile} />
                                            {error && <span style={{ color: "red" }}>{errorMsg}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                          
                            <div className='customer_group_form'>
                                <button type='submit' style={{ marginTop: "10px" }} className="btn btn-primary btn-lg">
                                    Send
                                </button>
                            </div>
                        </div>
                    </ValidatorForm>
                </div>
            </div>
        </div>
    );
}

export default SendGrid;
