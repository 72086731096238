import React, { memo, useEffect, useMemo, useState } from "react";
import Header from "../components/layout/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { dashboardIconTwo, backIcon2, dashboardIconThree, dashboardIconOne } from "../Image/Index";
import LineChartAnalyticalReport2 from "../components/charts/LineChartAnalyticalReport2";
import { decrypt } from "../utils/encodingdecoding";
import { connect } from "react-redux";
import { getBusinessGoal, clearBusinessGoal } from "../actions/businessGoal";
import { clearCampaigns } from "../actions/campaigns";
import { Card } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import BarChartAnalyticalReportGrowth from "../components/charts/BarChartAnalyticalReportGrowth";
import { getUserJourneyReport } from "../actions/dashboard";
import IDBIMccData from "../components/pages/userJourney/IDBIMccData.json";
import TransactionCount from "../components/pages/userJourney/TransactionCount.json";
import CustomerCount from "../components/pages/userJourney/CustomerCount.json";
import BarChartAnalyticalReport1 from "../components/charts/BarChartAnalyticalReport1";
import { growthprospect, totalspend } from "../components/pages/campaigns/services";
import introJs from "intro.js";
import "intro.js/introjs.css";
import ModalCard from "../components/layout/ModalCard";
const GrowthProspect = memo(({ getBusinessGoal, clearBusinessGoal, BusinessGoal, clearCampaigns }) => {
    const location = useLocation();
    const [name, setName] = useState();
    const navigate = useNavigate();
    const [activeKey, setActiveKey] = useState("-1");
    const [loader, setLoader] = useState(false);
    const [state, setState] = useState({
        tags: [],
        disable: true,
        data: [
            { confidenceLowerBound: 93534768, confidenceUpperBound: 107016000, merchantId: "5814", month: "5", transactionAmount: 100275384, year: "2023" },
            { confidenceLowerBound: 93522736, confidenceUpperBound: 107006560, merchantId: "5814", month: "6", transactionAmount: 100264650, year: "2023" },
            { confidenceLowerBound: 93493944, confidenceUpperBound: 106981544, merchantId: "5814", month: "7", transactionAmount: 100237740, year: "2023" },
            { confidenceLowerBound: 93460024, confidenceUpperBound: 106951944, merchantId: "5814", month: "8", transactionAmount: 100205980, year: "2023" },
            { confidenceLowerBound: 93493944, confidenceUpperBound: 106915800, merchantId: "5814", month: "9", transactionAmount: 100167144, year: "2023" },
            { confidenceLowerBound: 93370528, confidenceUpperBound: 106874368, merchantId: "5814", month: "10", transactionAmount: 100122450, year: "2023" },
        ],
        goals: [
            {
                id: "AwarenessAndDiscovery",
                businessgoal: "Awareness and Discovery",
                description: "Grab the potential customers when they become aware of your business through various rewards programs such as loyalty points, new deals & offers and gift cards.",
                idButton: "awareness",
            },
            {
                id: "GainConsideration",
                businessgoal: "Gain Consideration",
                description: "Once aware, customers begin researching different financial services, comparing features, pricing, and reading reviews. At this stage give great deals and offers on various credit cards and services to engage customers.",
            },
            {
                id: "IncreasePurchases",
                businessgoal: "Increase Purchases",
                description: "Before opening a bank account, getting a loan, or applying for a credit card customer always looks for great deals and offers. This stage requires various rewards and incentives to keep customers engaged.",
            },
            {
                id: "TakeTheOwnership",
                businessgoal: "Take the Ownership",
                description: "Provide customers with new offers and deals after the purchase to retail them for long.",
            },
            {
                id: "BuildLoyalty",
                businessgoal: "Build Loyalty",
                description: "Convert your customers into brand advocates to increase the word-of-mouth referrals, and online reviews.",
            },
        ],
    });
    const [FormData, setFormData] = useState([]);
    const [formDataGraph, setFormdataGraph] = useState([]);
    const [Report1, setReport1] = useState([]);
    const [year, setYear] = useState({});
    const [year2, setYear2] = useState({});
    const [Report2, setReport2] = useState([]);
    const [Report3, setReport3] = useState([]);
    const [Report4, setReport4] = useState([]);

    const [title, settTitle] = useState("Awareness and Discovery");
    const [amount, setAmount] = useState([]);
    const [response, setResponse] = useState({});
    const [generalData, setGeneralData] = useState([]);
    const [businessgoalIndex, setBusinessgoalIndex] = useState();
    const [graphTransactionCount, setGraphTransactionCount] = useState();
    const [graphCustomerCount, setGraphCustomerCount] = useState();
    const [graphTCWithoutGoal, setGraphTCWithotGoal] = useState();
    const [graphCCWithoutGoal, setGraphCCWithotGoal] = useState();
    const [predictedData, setPredictedData] = useState([]);
    const [historicalData, setHistoricalData] = useState([]);
    const [histPredData, setHistPredData] = useState([]);
    const intro = introJs();
    const [show, setshow] = useState(false);
    const [growthAvailable, setSrowthAvailable] = useState([]);
    const StaticData = {
        TotalCustomer: 2950,
        NewCustomer: 238,
        LoyalCustomer: 494,
        CanNotLoseThem: 293,
        Champions: 498,
        Hibernation: 120,
        AtRisk: 263,
        AboutToSleep: 350,
        Needattention: 182,
        PotentialLoyalist: 303,
        Promising: 209,
        Total_Male: 734114,
        Total_female: 314433,
        AgeWise: {
            "18-20": 27523,
            "21-30": 285847,
            "31-40": 285923,
            "41-50": 267839,
            "51-60": 181415,
            "61-70": 0,
            "71-80": 0,
            "81-90": 0,
        },
        Average: 25020,
        Totaltrn: 1048547,
        Monthwisetrn_result: {
            "4-2023": "31753741",
            "3-2023": "708715127",
            "2-2023": "991600187",
            "1-2023": "1091293931",
            "12-2022": "1126547324",
            "11-2022": "1103449398",
            "10-2022": "1142555985",
            "9-2022": "770228680",
            "8-2022": "794629926",
            "7-2022": "793859284",
            "6-2022": "781460546",
            "5-2022": "789124447",
            "4-2022": "764396944",
            "3-2022": "815216355",
            "2-2022": "730358829",
            "1-2022": "818814120",
            "12-2021": "820733134",
            "11-2021": "796195069",
            "10-2021": "815852248",
            "9-2021": "790869482",
            "8-2021": "808597838",
            "7-2021": "822675739",
            "6-2021": "795575362",
            "5-2021": "822056289",
            "4-2021": "798654613",
            "3-2021": "819967038",
            "2-2021": "747704509",
            "1-2021": "816375358",
            "12-2020": "826058670",
            "11-2020": "792869299",
            "10-2020": "820248055",
            "9-2020": "793207870",
            "8-2020": "816779482",
            "7-2020": "821551168",
            "6-2020": "790715281",
            "5-2020": "823755891",
            "4-2020": "343590609",
        },
        Monthwisetrn_count: {
            "4-2023": "946",
            "3-2023": "33554",
            "2-2023": "53246",
            "1-2023": "58589",
            "12-2022": "60325",
            "11-2022": "58517",
            "10-2022": "60839",
            "9-2022": "23471",
            "8-2022": "24273",
            "7-2022": "24138",
            "6-2022": "23761",
            "5-2022": "24092",
            "4-2022": "23356",
            "3-2022": "25022",
            "2-2022": "22459",
            "1-2022": "25219",
            "12-2021": "25222",
            "11-2021": "24395",
            "10-2021": "25117",
            "9-2021": "24473",
            "8-2021": "24895",
            "7-2021": "25280",
            "6-2021": "24439",
            "5-2021": "25184",
            "4-2021": "24557",
            "3-2021": "25302",
            "2-2021": "23001",
            "1-2021": "25091",
            "12-2020": "25398",
            "11-2020": "24287",
            "10-2020": "25208",
            "9-2020": "24259",
            "8-2020": "25255",
            "7-2020": "25143",
            "6-2020": "24378",
            "5-2020": "25280",
            "4-2020": "10576",
        },
        datastatus: "Sample ",
        discriminator: "SampleReportData",
        id: "cea17f66-9936-48a2-9ff3-4ffa59d71a2e",
        _rid: "e6g0AKwYiWPkHgAAAAAAAA==",
        _self: "dbs/e6g0AA==/colls/e6g0AKwYiWM=/docs/e6g0AKwYiWPkHgAAAAAAAA==/",
        _etag: '"0000ea23-0000-2000-0000-646312030000"',
        _attachments: "attachments/",
        _ts: 1684214275,
    };
    let complete = false;
    let exitOnLast = false;
    const { tags, disable, goals, data } = state;
    useEffect(() => {
        userJourny();
    }, []);
    const userJourny = async () => {
        setLoader(true);
        //const token = JSON.parse(localStorage.getItem("token_gen"));
        //const Id = localStorage.getItem("Id");
        // const tedata = await getUserJourneyReport(token?.access_token, Id);
        //  await getDashboard(token?.access_token, Id, "2023");
        // setFormData(tedata);
        setFormdataGraph(StaticData);
        setLoader(false);
    };

    useEffect(() => {
        fetchPredictedData();
        fetchHistoricalData();
    }, []);

    useEffect(() => {
        mergedBothData();
    }, [historicalData, predictedData]);

    const fetchPredictedData = async () => {
        // let id = decrypt(localStorage.getItem("schemaName")) == "Restaurant" ? "5814" : decrypt(localStorage.getItem("schemaName")) == "Travel" ? "4722" : "00";
        // let data1 = await growthprospect(id);

        if (data) {
            const lastIndex = data?.length - 1;
            const lastValue = (await data) && data[lastIndex];
            let allMonths = Array.from({ length: lastValue.month }, (_, i) => i + 1);
            let tempPredictedData = allMonths.map((month) => {
                let matchingData = data?.find((d) => parseInt(d.month) == month);
                return matchingData ? matchingData.transactionAmount : 0;
            });
            setPredictedData(tempPredictedData);
        }
    };

    const fetchHistoricalData = async () => {
        let data = [
            { next: 0, id: 5814, year: 2020, month: 5, transactionAmount: 3043159, avg: 19889.93, count: 153, max: 39856, min: 1032, prev: 0 },
            { next: 0, id: 5814, year: 2020, month: 6, transactionAmount: 101199120, avg: 20518.88, count: 4932, max: 39992, min: 1000, prev: 0 },
            { next: 0, id: 5814, year: 2020, month: 7, transactionAmount: 101550780, avg: 20053.47, count: 5064, max: 39984, min: 1002, prev: 0 },
            { next: 0, id: 5814, year: 2020, month: 8, transactionAmount: 102081730, avg: 20428.6, count: 4997, max: 39997, min: 1004, prev: 0 },
            { next: 0, id: 5814, year: 2020, month: 9, transactionAmount: 99958260, avg: 20508.47, count: 4874, max: 39999, min: 1002, prev: 0 },
            { next: 0, id: 5814, year: 2020, month: 10, transactionAmount: 102399740, avg: 20329.51, count: 5037, max: 39989, min: 1003, prev: 0 },
            { next: 0, id: 5814, year: 2020, month: 11, transactionAmount: 101105200, avg: 20417.04, count: 4952, max: 39999, min: 1020, prev: 0 },
            { next: 0, id: 5814, year: 2020, month: 12, transactionAmount: 100257640, avg: 20128.02, count: 4981, max: 39998, min: 1013, prev: 0 },
            { next: 0, id: 5814, year: 2021, month: 1, transactionAmount: 103021100, avg: 20757.83, count: 4963, max: 39999, min: 1005, prev: 0 },
            { next: 0, id: 5814, year: 2021, month: 2, transactionAmount: 93177080, avg: 20528.11, count: 4539, max: 39994, min: 1016, prev: 0 },
            { next: 0, id: 5814, year: 2021, month: 3, transactionAmount: 102857464, avg: 20493.62, count: 5019, max: 39965, min: 1000, prev: 0 },
            { next: 0, id: 5814, year: 2021, month: 4, transactionAmount: 100680080, avg: 20517.64, count: 4907, max: 39990, min: 1004, prev: 0 },
            { next: 0, id: 5814, year: 2021, month: 5, transactionAmount: 101118200, avg: 20317.1, count: 4977, max: 39993, min: 1005, prev: 0 },
            { next: 0, id: 5814, year: 2021, month: 6, transactionAmount: 102457650, avg: 20544.95, count: 4987, max: 39990, min: 1001, prev: 0 },
            { next: 0, id: 5814, year: 2021, month: 7, transactionAmount: 102485850, avg: 20476.69, count: 5005, max: 39997, min: 1010, prev: 0 },
            { next: 0, id: 5814, year: 2021, month: 8, transactionAmount: 103243040, avg: 20227.87, count: 5104, max: 39998, min: 1009, prev: 0 },
            { next: 0, id: 5814, year: 2021, month: 9, transactionAmount: 98709464, avg: 20504.67, count: 4814, max: 39997, min: 1000, prev: 0 },
            { next: 0, id: 5814, year: 2021, month: 10, transactionAmount: 105076910, avg: 20782.62, count: 5056, max: 39999, min: 1013, prev: 0 },
            { next: 0, id: 5814, year: 2021, month: 11, transactionAmount: 100859144, avg: 20338.6, count: 4959, max: 39977, min: 1001, prev: 0 },
            { next: 0, id: 5814, year: 2021, month: 12, transactionAmount: 105064376, avg: 20617.03, count: 5096, max: 39997, min: 1010, prev: 0 },
            { next: 0, id: 5814, year: 2022, month: 1, transactionAmount: 104219520, avg: 20621.2, count: 5054, max: 39996, min: 1030, prev: 0 },
            { next: 0, id: 5814, year: 2022, month: 2, transactionAmount: 93716820, avg: 20524.93, count: 4566, max: 39991, min: 1003, prev: 0 },
            { next: 0, id: 5814, year: 2022, month: 3, transactionAmount: 104732110, avg: 20296.92, count: 5160, max: 39986, min: 1000, prev: 0 },
            { next: 0, id: 5814, year: 2022, month: 4, transactionAmount: 99201990, avg: 20568.52, count: 4823, max: 39998, min: 1007, prev: 0 },
            { next: 0, id: 5814, year: 2022, month: 5, transactionAmount: 101716504, avg: 20282.45, count: 5015, max: 39998, min: 1005, prev: 0 },
            { next: 0, id: 5814, year: 2022, month: 6, transactionAmount: 99742970, avg: 20285.33, count: 4917, max: 39997, min: 1006, prev: 0 },
            { next: 0, id: 5814, year: 2022, month: 7, transactionAmount: 102654100, avg: 20291.38, count: 5059, max: 39996, min: 1000, prev: 0 },
            { next: 0, id: 5814, year: 2022, month: 8, transactionAmount: 104798940, avg: 20305.94, count: 5161, max: 39998, min: 1013, prev: 0 },
            { next: 0, id: 5814, year: 2022, month: 9, transactionAmount: 101318650, avg: 20518.15, count: 4938, max: 39999, min: 1001, prev: 0 },
            { next: 0, id: 5814, year: 2022, month: 10, transactionAmount: 102274010, avg: 20418.05, count: 5009, max: 39996, min: 1002, prev: 0 },
            { next: 0, id: 5814, year: 2022, month: 11, transactionAmount: 103126984, avg: 20914.01, count: 4931, max: 39994, min: 1002, prev: 0 },
            { next: 0, id: 5814, year: 2022, month: 12, transactionAmount: 103193720, avg: 20531.98, count: 5026, max: 39992, min: 1012, prev: 0 },
            { next: 0, id: 5814, year: 2023, month: 1, transactionAmount: 104849150, avg: 20615.25, count: 5086, max: 39996, min: 1003, prev: 0 },
            { next: 0, id: 5814, year: 2023, month: 2, transactionAmount: 92477680, avg: 20196.04, count: 4579, max: 40000, min: 1000, prev: 0 },
            { next: 0, id: 5814, year: 2023, month: 3, transactionAmount: 103471910, avg: 20735.85, count: 4990, max: 40000, min: 1007, prev: 0 },
            { next: 0, id: 5814, year: 2023, month: 4, transactionAmount: 87868616, avg: 20655.53, count: 4254, max: 39982, min: 1000, prev: 0 },
        ];
        let tempHistoricalData = [];
        data?.filter((e) => {
            if (e.year == "2023") {
                tempHistoricalData.push(e.transactionAmount);
            }
        });
        setHistoricalData(tempHistoricalData);
    };

    const mergedBothData = () => {
        if (historicalData?.length && predictedData?.length) {
            const tempPredictedData = Array.from(predictedData);
            let startIndex = historicalData.length;
            let endIndex = predictedData.length;
            let temp = tempPredictedData.splice(startIndex, endIndex - startIndex + 1);
            setHistPredData(historicalData.concat(temp));
            setSrowthAvailable(percent(historicalData.concat(temp), 110));
        }
    };

    useEffect(() => {
        if (formDataGraph[0]?.Monthwisetrn_count) {
            const asArray = Object.entries(formDataGraph[0]?.Monthwisetrn_count).map((arr) => {
                const data = arr[0];
                return data.split("-")[1];
            });
            function onlyUnique(value, index, array) {
                return array.indexOf(value) === index;
            }
            var uniques = asArray.filter(onlyUnique);
            setYear(uniques.sort().reverse());
            filterData(uniques[0], formDataGraph[0]?.Monthwisetrn_count).then((res) => {
                setReport1(res);
            });
        }
    }, [formDataGraph]);

    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        setState({ ...state, data: BusinessGoal });
    }, [BusinessGoal]);

    useEffect(() => {
        const industry = decrypt(localStorage.getItem("schemaName"));
        data.map((item) => {
            if (item.industry === industry) {
                setState({ ...state, goals: item.goals });
            }
        });
    }, [data]);

    const fetch = async () => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        await clearBusinessGoal();
        //   await getBusinessGoal(token.access_token);
        setLoader(false);
    };

    const percent = (arr, per) => {
        for (var i = 0; i < arr.length; i++) {
            // if (i < 4) arr[i] = 0;
            arr[i] = (arr[i] * per) / 100 + arr[i];
        }
        return arr;
    };
    const handleChange = async (e, tag, index, data) => {
        var data;
        //data = [...predictedData];
        data = data;
        if (decrypt(localStorage.getItem("schemaName")) == "Restaurant") {
            await setReport2(percent(data, index == 0 ? 20 : index == 1 ? 30 : index == 2 ? 25 : index == 3 ? 40 : index == 4 ? 35 : 32));
        } else if (decrypt(localStorage.getItem("schemaName")) == "Travel") {
            await setReport2(percent(data, index == 0 ? 20 : index == 1 ? 30 : index == 2 ? 25 : index == 3 ? 40 : index == 4 ? 35 : index == 5 ? 30 : index == 6 ? 25 : 45));
        } else {
            await setReport2(percent(data, index == 0 ? 20 : index == 1 ? 30 : index == 2 ? 25 : index == 3 ? 40 : index == 4 ? 35 : 32));
        }
        settTitle(tag.businessgoal);
        if (tags.includes(tag.businessgoal)) {
            let temp = tags.filter((item, index) => item != tag.businessgoal);
            setState({ ...state, tags: temp, disable: temp.length <= 0 ? true : false });
        } else {
            let temp = tag.businessgoal;
            let businessGoal = temp.replace(/-/g, " ");
            setState({ ...state, tags: [...tags, `business goal:${businessGoal.toLowerCase()}`], disable: false });
            setBusinessgoalIndex(Number(index) + 1);
        }
    };

    const handleClick = async () => {
        await clearCampaigns();
        navigate("/sample-campaigns", { state: tags });
    };
    const handleSkipClick = async () => {
        let tags = [];
        let goal;
        goals.map((element) => {
            goal = element.businessgoal.replace(/-/g, " ");
            tags.push(`business goal:${goal.toLowerCase()}`);
        });
        await clearCampaigns();
        navigate("/sample-campaigns", { state: tags, skip: true });
    };

    useEffect(() => {
        if (localStorage.getItem("name")) {
            let tempName = decrypt(localStorage.getItem("name"));
            tempName = `${tempName[0].toUpperCase()}${tempName.slice(1)}`;
            setName(tempName);
        }
    }, []);
    const filterData = async (data, object) => {
        const asArray = await Object.entries(object);
        let filtered = await asArray.filter(([key, value]) => key.split("-")[1] === data);
        let year = filtered[0];
        if (year && year[0]) {
            year = year[0].split("-")[1];
        }

        let allMonths = Array.from({ length: 12 }, (_, i) => i + 1);
        let mergedArray = allMonths.map((month) => {
            let matchingData = filtered.find((d) => parseInt(d[0].split("-")[0]) == month);
            return matchingData ? matchingData : [`${month}-${year}`, 0];
        });

        const n = mergedArray.length;
        for (let i = 0; i < n - 1; i++) {
            for (let j = 0; j < n - i - 1; j++) {
                const monthA = parseInt(mergedArray[j][0].split("-")[0]);
                const monthB = parseInt(mergedArray[j + 1][0].split("-")[0]);
                if (monthA > monthB) {
                    const temp = mergedArray[j];
                    mergedArray[j] = mergedArray[j + 1];
                    mergedArray[j + 1] = temp;
                }
            }
        }
        const tempdata = await mergedArray.map((arr) => {
            return arr[1];
        });
        return tempdata;
    };

    useEffect(() => {
        const uniqueYears = [...new Set(IDBIMccData.map((item) => item.Year))];
        const datat = IDBIMccData.filter((data) => data.Year == 2023);
        let allMonths = Array.from({ length: 12 }, (_, i) => i + 1);
        let mergedArray = allMonths.map((month) => {
            let matchingData = datat?.find((d, i) => d.month == month);
            return matchingData
                ? matchingData
                : {
                      Year: 2023,
                      avg: "0",
                      count: "0",
                      id: "0",
                      max: "0",
                      min: "0",
                      month: month,
                  };
        });
        setAmount(
            mergedArray.map((data) => {
                return data.transactionAmount;
            })
        );
    }, []);

    const LineChartAnalyticalReport = useMemo(() => {
        return <LineChartAnalyticalReport2 data={Report2} amount={amount} title={title} data2={Report3} predictedData={predictedData?.length ? predictedData : []} histPredData={histPredData?.length ? histPredData : []} growthAvailable={growthAvailable?.length ? growthAvailable : []} />;
    }, [, Report2, amount, title, , Report3, Report4, predictedData, histPredData, growthAvailable]);

    useEffect(() => {
        transactionCount();
        customerCount();
    }, [businessgoalIndex]);

    const transactionCount = () => {
        const merchant_code = decrypt(localStorage.getItem("merchantcode")); //"MER000003";

        let tempGoal = [];
        let tempTransactionCount = [];
        TransactionCount.filter((e) => {
            if (merchant_code === e.merchant_code) {
                tempGoal.push(e.goal);
            }
        });
        tempGoal.forEach((e) =>
            e.filter((arr, index) => {
                if (businessgoalIndex === Number(index) + 1) {
                    tempTransactionCount.push(arr);
                }
            })
        );
        setGraphTransactionCount(tempTransactionCount[0]);
    };

    const customerCount = () => {
        const merchant_code = decrypt(localStorage.getItem("merchantcode")); //"MER000003";

        let tempGoal = [];
        let tempCustomerCount = [];
        CustomerCount.filter((e) => {
            if (merchant_code === e.merchant_code) {
                tempGoal.push(e.goal);
            }
        });
        tempGoal.forEach((e) =>
            e.filter((arr, index) => {
                if (businessgoalIndex === Number(index) + 1) {
                    tempCustomerCount.push(arr);
                }
            })
        );
        setGraphCustomerCount(tempCustomerCount[0]);
    };

    useEffect(() => {
        if (!businessgoalIndex) {
            withoutBusinessGoalTransaction();
            withoutBusinessGoalCustomer();
        }
    }, []);
    const withoutBusinessGoalTransaction = () => {
        let merchant_code = decrypt(localStorage.getItem("merchantcode")); //"MER000003";
        let tempGoal = [];
        let tempTransactionCount = [];
        TransactionCount.filter((e) => {
            if (merchant_code === e.merchant_code) {
                tempGoal.push(e.goal);
            }
        });
        tempGoal.forEach((e) =>
            e.filter((arr, index) => {
                if (index == 0 || index == 1 || index == 2) {
                    tempTransactionCount.push(arr[index]);
                }
            })
        );
        setGraphTCWithotGoal(tempTransactionCount);
    };
    const withoutBusinessGoalCustomer = () => {
        let merchant_code = decrypt(localStorage.getItem("merchantcode")); // "MER000003";
        let tempGoal = [];
        let tempCustomerCount = [];
        CustomerCount.filter((e) => {
            if (merchant_code === e.merchant_code) {
                tempGoal.push(e.goal);
            }
        });
        tempGoal.forEach((e) =>
            e.filter((arr, index) => {
                if (index == 0 || index == 1 || index == 2) {
                    tempCustomerCount.push(arr[index]);
                }
            })
        );
        setGraphCCWithotGoal(tempCustomerCount);
    };
    useEffect(() => {
        const handleLoad = () => {
            const steps = [
                {
                    element: document.getElementById("total_spend_graph"),
                    title: "Total Spend",
                    intro: "Gain insights into historical customer spending patterns, predicts future behavior, identifies growth prospects, and unveils untapped potential for increased spending.",
                },
                {
                    element: document.getElementById("differentCampain"),
                    title: "Accelerate Growth",
                    intro: "Accelerate your business growth by implementing strategies and campaigns aimed at rapidly expanding your customer base and boosting their engagement.",
                },
                {
                    element: document.getElementById("AwarenessAndDiscovery"),
                    title: "Awareness ",
                    intro: " Leverage the Brand Awareness feature to craft loyalty campaigns focused on raising awareness of your brand.",
                },
                // {
                //     element: document.getElementById("GainConsideration"),
                //     title: "Gain Consideration ",
                //     intro: " Capture the attention of potential customers and position your brand favorably in their minds",
                // },
                // {
                //     element: document.getElementById("IncreasePurchases"),
                //     title: "Increase Purchases ",
                //     intro: " Encourage existing customers to shop more frequently and spend more by offering rewards, discounts, or incentives through your loyalty program.",
                // },
                // {
                //     element: document.getElementById("TakeTheOwnership"),
                //     title: "Take the Ownership ",
                //     intro: " Empower customers by giving them a sense of ownership in your brand. Offer exclusive benefits, and personalized experiences to foster a deeper connection.",
                // },
                // {
                //     element: document.getElementById("BuildLoyalty"),
                //     title: "Build Loyalty ",
                //     intro: "Strengthen customer loyalty by continuously engaging with them, offering valuable rewards, and creating memorable experiences.",
                // },
                {
                    element: document.getElementById("campaignsinfos"),
                    title: "Campaigns  ",
                    intro: "Develop and oversee a variety of loyalty campaigns catering to specific customer segments.",
                },
            ];
            intro.setOptions({
                steps: steps,
                doneLabel: "Next",
                exitOnOverlayClick: false,
                tooltipClass: "customTooltip",
            });

            intro.start();
        };
        setTimeout(() => {
            handleLoad();
        }, 700);
        return () => {
            intro.exit();
        };
    }, []);
    intro.oncomplete(function (element) {
        complete = true;
        setTimeout(() => {
            if (!exitOnLast) {
                navigate("/intro/customers-list");
            }
        }, 700);
    });
    intro.onexit(() => {
        if (!complete) {
            localStorage.setItem("firstLogin", "");
            setshow(true);
        }
    });
    intro.onchange(function (element) {
        switch (this._currentStep) {
            case 2:
                handleChange(null, { businessgoal: "Awareness and Discovery", description: "Grab the potential customers when they become awar…oyalty points, new deals & offers and gift cards." }, "0", [0, 0, 0, 0, 100275384, 100264650, 100237740, 100205980, 100167144, 100122450]);
                document.getElementById("awareness").checked = true;
                break;
            case 3:
                // handleChange(null, { businessgoal: "Gain Consideration", description: "Once aware, customers begin researching different …us credit cards and services to engage customers." }, "1", [0, 0, 0, 0, 100275384, 100264650, 100237740, 100205980, 100167144, 100122450]);
                //document.getElementById("GainConsideration").checked = true;
                setTimeout(() => {
                    let cross = document.getElementsByClassName("introjs-skipbutton")[0];
                    cross &&
                        cross?.addEventListener("click", function () {
                            exitOnLast = true;
                            localStorage.setItem("firstLogin", "");
                            setshow(true);
                        });
                }, 600);
                break;

            case 5:
                handleChange(null, { businessgoal: "Take the Ownership", description: "Provide customers with new offers and deals after the purchase to retail them for long." }, "3", [0, 0, 0, 0, 100275384, 100264650, 100237740, 100205980, 100167144, 100122450]);
                document.getElementById("TakeTheOwnership").checked = true;
                break;
            case 6:
                handleChange(null, { businessgoal: "Build Loyalty", description: "Convert your customers into brand advocates to inc… the word-of-mouth referrals, and online reviews." }, "4", [0, 0, 0, 0, 100275384, 100264650, 100237740, 100205980, 100167144, 100122450]);
                document.getElementById("BuildLoyalty").checked = true;
                break;
        }
    });
    return (
        <>
            <Header name={"Growth Prospect"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            {show && <ModalCard show={show} />}
            <div className="analyticalReport">
                <div className="analyticalReportRFMAnalysis">
                    <div className="analyticalReportRFMAnalysis__topSection analyticalReportRFMAnalysis__topSection__gp">
                        <div className="container">
                            <div className="analyticalReportRFMAnalysis__leftSection">
                                <div className="dashboard__headingBackLink dashboard__headingBackLink2">
                                    {location?.state?.prevPath && (
                                        <Link to={"/upload-existing-data"}>
                                            <img style={{ width: "18px" }} src={backIcon2} alt="" />
                                            <span>Back</span>
                                        </Link>
                                    )}
                                </div>
                                {/* <h4>Welcome to Novus Loyalty,</h4>
                                <h2>{name}</h2> */}
                                <p>Growth prospects refer to the potential opportunities for expanding the customer base, increasing sales, and generating higher revenue in the future. With Novus, identify new markets or customer segments and create effective marketing strategies to attract and retain customers.</p>
                            </div>
                        </div>
                    </div>
                    <div className="analyticalReportChartAnalysisTwo">
                        <div className="container">
                            <div className="analyticalReportChartAnalysisTwo__bottomSection">
                                <div className="analyticalReportChartAnalysisTwo__heading">{/* <h2>Growth Prospect</h2> */}</div>
                                <div className="analyticalReportChartAnalysisTwo__leftSection">
                                    <div className="analyticalReportChartAnalysisTwo__box">
                                        <div className="analyticalReportChartAnalysisTwo__header">
                                            <div className="analyticalReportChartAnalysisTwo__left">
                                                <div className="analyticalReportChartAnalysisTwo__image">
                                                    <img src={dashboardIconTwo} alt="" />
                                                </div>
                                                <div className="analyticalReportChartAnalysisTwo__details">
                                                    <h3>Total Spend</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="total_spend_graph" className="analyticalReportChartAnalysisTwo__chartSection">
                                            {LineChartAnalyticalReport}
                                        </div>
                                    </div>
                                </div>
                                <div className="analyticalReportChartAnalysisTwo__rightSection analyticalReportChartAnalysisTwo__rightSection__gp">
                                    <div className="businessGoalsSelection__bottomSection">
                                        <h5 id="differentCampain">How different campaign can accelerate your growth</h5>
                                        <div className="">
                                            {Array.isArray(goals) ? (
                                                <Accordion className="businessGoalsSelection__accordion" onSelect={(e) => setActiveKey(e)}>
                                                    {goals &&
                                                        goals.map((item, index) => (
                                                            <Card id={item.id} key={index} className="businessGoalsSelection__accordionItem">
                                                                <Card.Header className="businessGoalsSelection__accordionHeading">
                                                                    <div className={index == activeKey ? "businessGoalsSelection__accordionButton active" : "businessGoalsSelection__accordionButton"}>
                                                                        <input id={item.idButton} type="radio" name="businessgoal" value={index} onChange={(e) => handleChange(e, item, e.target.value)} />
                                                                        {item.businessgoal}
                                                                        {/* defaultChecked={!(!!index)}  */}
                                                                        {/* <AccordionButton eventKey={index.toString()}>
                                                                            <i className="fa-solid fa-chevron-right"></i>
                                                                        </AccordionButton> */}
                                                                    </div>
                                                                </Card.Header>
                                                                {/* <Accordion.Collapse className="businessGoalsSelection__accordionCollapse" eventKey={index.toString()}>
                                                                    <Card.Body className="businessGoalsSelection__accordionBody">{item.description}</Card.Body>
                                                                </Accordion.Collapse> */}
                                                            </Card>
                                                        ))}
                                                </Accordion>
                                            ) : (
                                                <h6>There is no business goal</h6>
                                            )}
                                            <div className="businessGoalsSelection__buttonSection businessGoalsSelection__buttonSectiongp">
                                                <div className="businessGoalsSelection__buttonSectionLeft">
                                                    <Link onClick={handleSkipClick}>Skip</Link>
                                                </div>
                                                <div className="businessGoalsSelection__buttonSectionRight">
                                                    <Link to="/contact-us" className="btn btn-outline-primary">
                                                        Contact Support
                                                    </Link>
                                                    <button className="btn btn-primary" disabled={disable ? true : false} onClick={handleClick}>
                                                        Select Campaigns
                                                        <i className="fa-solid fa-arrow-right"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="analyticalReportChartAnalysisOne__bottomSection">
                                    <div className="analyticalReportChartAnalysisOne__heading">
                                        <h2>Customer Spending Overview</h2>
                                    </div>
                                    <div className="analyticalReportChartAnalysisOne__leftSection">
                                        <div className="analyticalReportChartAnalysisOne__box">
                                            <div className="analyticalReportChartAnalysisOne__header">
                                                <div className="analyticalReportChartAnalysisOne__left">
                                                    <div className="analyticalReportChartAnalysisOne__image">
                                                        <img src={dashboardIconThree} alt="" />
                                                    </div>
                                                    <div className="analyticalReportChartAnalysisOne__details">
                                                        <h3> Customers Count</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="analyticalReportChartAnalysisOne__chartSection">
                                                <BarChartAnalyticalReportGrowth goalData={graphCustomerCount} withoutGoal={graphCCWithoutGoal} title={"Customer Count"} backgroundColor={"rgba(53, 162, 235, 0.5)"} />
                                            </div>
                                        </div>

                                        {/* <div className="analyticalReportChartAnalysisOne__box">
                                            <div className="analyticalReportChartAnalysisOne__header">
                                                <div className="analyticalReportChartAnalysisOne__left">
                                                    <div className="analyticalReportChartAnalysisOne__image">
                                                        <img src={dashboardIconThree} alt="" />
                                                    </div>
                                                    <div className="analyticalReportChartAnalysisOne__details">
                                                        <h3>Transactions</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="analyticalReportChartAnalysisOne__reportSection">
                                                <h3>{formDataGraph[0]?.Totaltrn ? formDataGraph[0]?.Totaltrn : 0}</h3>
                                            </div>
                                        </div> */}
                                        {/* <div className="analyticalReportChartAnalysisOne__box">
                                            <div className="analyticalReportChartAnalysisOne__header">
                                                <div className="analyticalReportChartAnalysisOne__left">
                                                    <div className="analyticalReportChartAnalysisOne__image">
                                                        <img src={dashboardIconTwo} alt="" />
                                                    </div>
                                                    <div className="analyticalReportChartAnalysisOne__details">
                                                        <h3>Customer Average Spend</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="analyticalReportChartAnalysisOne__reportSection">
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="analyticalReportChartAnalysisOne__rightSection">
                                        <div className="analyticalReportChartAnalysisOne__box">
                                            <div className="analyticalReportChartAnalysisOne__header">
                                                <div className="analyticalReportChartAnalysisOne__left">
                                                    <div className="analyticalReportChartAnalysisOne__image">
                                                        <img src={dashboardIconOne} alt="" />
                                                    </div>
                                                    <div className="analyticalReportChartAnalysisOne__details">
                                                        <h3>Transactions Count</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="analyticalReportChartAnalysisOne__chartSection">
                                                <BarChartAnalyticalReport1 data={Report1} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {location?.state?.prevPath && (
                                    <div className="analyticalReportChartAnalysisTwo__rightSection">
                                        <div className="analyticalReportChartAnalysisTwo__paragraph">
                                            <p>With the Help of AI and existing data, use Novus Loyalty to set up campaign to supercharge your growth.</p>
                                        </div>
                                        <div className="analyticalReportChartAnalysisTwo__buttonSection">
                                            <Link to="/business-goals-selection" className="btn btn-primary">
                                                Select Your Business Goals
                                                <i className="fa-solid fa-arrow-right"></i>
                                            </Link>

                                            <Link disabled className="btn btn-link">
                                                Skip
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

const mapStateToProps = (state) => ({
    BusinessGoal: state.BusinessGoal.data,
    Campaigns: state.Campaigns.data,
});
const mapDispatchToProps = {
    getBusinessGoal,
    clearBusinessGoal,
    clearCampaigns,
};

export default connect(mapStateToProps, mapDispatchToProps)(GrowthProspect);
