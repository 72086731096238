import { CLEAR_TRANSACTION_LIST, GET_TRANSACTION_LIST, SEARCH_TRANSACTION_LIST } from "../actions/type";

const initialState = {
    data: [],
};

const Transaction = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_TRANSACTION_LIST:
            return {
                ...state,
                data: payload,
            };
            case SEARCH_TRANSACTION_LIST:
                return {
                    ...state,
                    data: payload,
                };
        case CLEAR_TRANSACTION_LIST:
            return {
                ...state,
                data: [],
            };

        default:
            return state;
    }
};
export default Transaction;
