import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Header from "../../../layout/Header";
import HeadingSection from "../RedemptionHeadingSection";
import { transactionRedeem } from "../../campaigns/services";
import JSONPretty from "react-json-pretty";
let token;
const RedemptionAPI = () => {
    const [state, setState] = useState({
        responseCopied: false,
        requestCopied: false,
        loading: false,
        responseText: " ",
        tokenErr: false,
        tokenErrMsg: "",
        tokenSucc: false,
        tokenSuccMsg: "",
        catchErr: false,
        catchErrMsg: "",
        disable: false,
    });
    const currentDate = new Date();


const year = currentDate.getFullYear();
const month = currentDate.getMonth() + 1; 
const day = currentDate.getDate();
const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;


    const { requestCopied, responseCopied, loading, responseText, tokenErr, tokenErrMsg, catchErr, catchErrMsg, tokenSucc, tokenSuccMsg, disable } = state;
    const requestText = `{
    "customer" : {
        "customercode" : "CUS0341496903"
    },
    "redeemDetail" : {
        "walletType" : "point",
        "values" : 1,
        "transactionType" : "redeem",
        "transactionDate" :"${formattedDate}"
    }
}`;
    useEffect(() => {
        let tokenSuccess, tokenError, catchError;
        if (tokenSucc) {
            tokenSuccess = setTimeout(() => {
                setState({ tokenSucc: false, tokenSuccMsg: "" });
            }, 2000);
        }
        if (tokenErr) {
            tokenError = setTimeout(() => {
                setState({ tokenErr: false, tokenErrMsg: "" });
            }, 2000);
        }
        if (catchErr) {
            catchError = setTimeout(() => {
                setState({ catchErr: false, catchErrMsg: "" });
            }, 2000);
        }
        return () => {
            clearTimeout(tokenSuccess);
            clearTimeout(tokenError);
            clearTimeout(catchError);
        };
    }, [tokenErr, tokenSucc, catchErr]);

    const handleCopy = (copy) => {
        copy === "request" ? setState({ requestText, responseText, requestCopied: true }) : setState({ responseText, requestText, responseCopied: true });
        setTimeout(() => {
            copy === "request" && setState({ requestText, responseText, requestCopied: "" });
        }, 1000);
        setTimeout(() => {
            copy === "response" && setState({ responseText, requestText, responseCopied: "" });
        }, 1000);
    };
    const handleAccessToken = async () => {
        let tempToken = JSON.parse(localStorage.getItem("token_gen"));
        token = tempToken?.access_token;
        setState({ tokenSucc: true, tokenSuccMsg: "Token generated successfully" });
    };

    const createCustomer = async () => {
        try {
            let body = JSON.parse(document.getElementById("api").value);
            if (token) {
                body = JSON.stringify(body);
                setState({ loading: true, disable: true });
                const redeem = await transactionRedeem(token, body);
                setState({ responseText: JSON.stringify(redeem), loading: false, disable: false });
            } else {
                setState({ tokenErr: true, tokenErrMsg: "Please generate access token" });
            }
        } catch (error) {
            setState({ ...state, catchErr: true, catchErrMsg: "Invalid JSON format" });
        }
    };
    return (
        <>
            <Header name={"Redemption"} />
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__apiPlaygroundBox">
                        <div className="dashboard__apiPlaygroundHeading">
                            <h2>
                                <span className="badge rounded-pill bg-primary">POST</span> Redemption
                            </h2>
                        </div>
                        <div className="dashboard__apiPlaygroundBottom">
                            <div className="dashboard__apiPlaygroundEndpoint">
                                <p>
                                    <span>API Endpoint</span>
                                    <Link to="#!">{process.env.REACT_APP_CLASERVER}Transaction/Redeem</Link>
                                </p>
                            </div>
                            <div className="dashboard__apiPlaygroundButton">
                                <button className="btn btn-primary" onClick={handleAccessToken}>
                                    Generate Access Token
                                </button>
                            </div>
                        </div>
                        <div className="dashboard__apiPlaygroundCardMain">
                            {tokenSucc && (
                                <div className="alert alert-success" role="alert">
                                    {tokenSuccMsg}
                                </div>
                            )}
                            {tokenErr && (
                                <div className="alert alert-danger" role="alert">
                                    {tokenErrMsg}
                                </div>
                            )}
                            {catchErr && (
                                <div className="alert alert-danger" role="alert">
                                    {catchErrMsg}
                                </div>
                            )}
                            <div className="dashboard__apiPlaygroundCardHead">
                                <h3>API Simulate</h3>
                            </div>
                            <div className="dashboard__apiPlaygroundCardBox">
                                <div className="dashboard__apiPlaygroundLeft">
                                    <div className="dashboard__requestSection">
                                        <div className="dashboard__header">
                                            <div className="dashboard__left">
                                                <h5>Request</h5>
                                            </div>
                                            <div className="dashboard__right">
                                                <CopyToClipboard text={requestText} onCopy={() => handleCopy("request")}>
                                                    <Link className="btn btn-light" to="">
                                                        {requestCopied ? "Copied" : "Copy"}
                                                    </Link>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                        <div className="dashboard__body" id="APICodeBox">
                                            <textarea id="api" defaultValue={requestText}></textarea>
                                        </div>
                                        <div className="dashboard__footer">
                                            <button type="button" className="btn btn-light rounded-circle" title="Get Response" disabled={disable ? true : false} onClick={createCustomer}>
                                                <i className="fa-solid fa-play"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard__apiPlaygroundRight">
                                    <div className="dashboard__responseSection">
                                        <div className="dashboard__header">
                                            <div className="dashboard__left">
                                                <h5>Response</h5>
                                            </div>
                                            <div className="dashboard__right">
                                                <CopyToClipboard text={responseText} onCopy={() => handleCopy("response")}>
                                                    <Link className="btn btn-dark" to="">
                                                        {responseCopied ? "Copied" : "Copy"}
                                                    </Link>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                        <div className="dashboard__body" id="APICodeBox">
                                            {loading ? (
                                                <div className="mainPreloaderMain">
                                                    <div className="mainPreloader">
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <JSONPretty id="json-pretty" data={responseText} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <HeadingSection />
                    </div>
                </div>
            </div>
        </>
    );
};

export default RedemptionAPI;
